import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {IDesignTemplate, IVariant} from '@gfxco/contracts';
import {Badge, Image, Toast} from 'react-bootstrap';
import Modal from '../Modal';
import {getSizes, publishDesign} from '../../api';

import './ShopifyPublishModal.scss';
import {useAppDispatch} from '../../app/hooks';
import {resetDesignTemplates} from '../../features/designTemplates/loadDesignTemplates';
import {resetPublishedProducts} from '../../features/publishedProducts/loadPublishedProducts';
import Icon from '../Icons/Icon';
import LoadingModal from '../LoadingModal';
import Loading from '../Loading';

interface RequiredProps {
  design: IDesignTemplate;
}

interface OptionalProps {
  show?: boolean;
  onCloseModal?: () => void;
}

type Props = RequiredProps & OptionalProps;

const ShopifyPublishModal: React.FC<Props> = (props) => {
  const {design} = props;
  const navigate = useNavigate();
  const [variants, setVariants] = useState<IVariant[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [sideView, setSideView] = useState<string>(
    design.startingSide || 'front',
  );
  const [saveEnabled, setSaveEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showPublishModal, setShowPublishModal] = useState<boolean>(
    !!props.show,
  );
  const [productUrl, setProductUrl] = useState<string>();
  const [loadingSizes, setLoadingSizes] = useState<boolean>(false);

  const defaultDelay = 2000;

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoadingSizes(true);
    getSizes(props.design.id)
      .then((res) => {
        const sizesMapped: IVariant[] = (res || []).map((size) => ({
          option1: size,
          price: '0',
        }));
        setVariants(sizesMapped);
      })
      .finally(() => {
        setLoadingSizes(false);
      });
  }, [props.design.id]);

  useEffect(() => {
    if (props.show) setShowPublishModal(props.show);
  }, [props.show]);

  const handleOnSave = () => {
    setSaveEnabled(false);
    setShowPublishModal(false);
    setIsLoading(true);
    publishDesign({templateId: props.design.id, variants})
      .then((product) => {
        dispatch(resetDesignTemplates());
        dispatch(resetPublishedProducts());
        setTimeout(() => {
          if (product) setProductUrl(product.url);
          setIsLoading(false);
          setShowSuccess(true);
          setSaveEnabled(true);
        }, defaultDelay);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setShowPublishModal(true);
        setShowAlert(true);
        setTimeout(() => {
          setSaveEnabled(true);
        }, defaultDelay);
      });
  };

  const handleFlipSide = () => {
    const side = sideView === 'back' ? 'front' : 'back';
    setSideView(side);
  };

  const handleOnClose = () => {
    setShowPublishModal(false);
    setShowAlert(false);
    if (props.onCloseModal) props.onCloseModal();
  };

  return (
    <>
      <Modal.SimpleModal
        className="modal-middle-page modal-middle-colored"
        modalTile="Publish design"
        show={showPublishModal}
        onCloseModal={handleOnClose}
        isSaveEnabled={false}
      >
        <div
          id="shopify-publish-container"
          className="shopify-publish-container"
        >
          <Toast
            id="toast-message"
            className="alert-message"
            onClose={() => setShowAlert(false)}
            show={showAlert}
          >
            <Toast.Body>
              <span className="alert-title">Oops!</span>
              <div className="alert-text">
                Looks like we missed something. We{' '}
                <b>couldn&apos;t publish your product</b>, but don&apos;t worry,
                let&apos;s fix it!
              </div>
              <span
                onClick={() => setShowAlert(false)}
                className="alert-text-underline"
              >
                Try again
              </span>
            </Toast.Body>
          </Toast>
          <div className="container-sections">
            <div className="section image-section">
              <Image
                className="default-image"
                fluid
                src={sideView === 'back' ? design.proofBack : design.proofFront}
                alt={design.name}
              />
              <div className="image-side">
                You are currently viewing: <b>{`${sideView} Side`}</b>{' '}
                {design.proofBack && (
                  <button className="button-flip-side" onClick={handleFlipSide}>
                    <Icon name="flipIcon" />{' '}
                  </button>
                )}
              </div>
            </div>
            <div className="title section info-section">
              <div className="sizes-container">
                <span className="info-title">Product Title:</span>
                <h4>{design.name}</h4>

                <span className="info-title">Selected product sizes:</span>
                <div className="sizes-section">
                  {!loadingSizes &&
                    variants.map((variant) => (
                      <Badge key={`variant-${variant.option1}`}>
                        {variant.option1}
                      </Badge>
                    ))}
                  {loadingSizes && (
                    <Loading variant="black" text="Loading product sizes..." />
                  )}
                </div>
                <button
                  className="info-button info-edit-button"
                  onClick={() => navigate(`/designs/edit/${props.design.id}`)}
                >
                  <Icon name="pencil" />
                  Edit template
                </button>
                <button
                  onClick={handleOnSave}
                  disabled={
                    !saveEnabled || loadingSizes || variants.length === 0
                  }
                  className="info-button info-publish-button"
                >
                  <Icon name="publishIcon" />
                  Publish on Shopify
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.SimpleModal>
      <LoadingModal
        loading={isLoading}
        showSuccess={showSuccess}
        productUrl={productUrl}
        onClose={() => {
          setShowSuccess(false);
          setIsLoading(false);
          handleOnClose();
        }}
      />
    </>
  );
};

export default ShopifyPublishModal;
