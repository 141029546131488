import {Box, CssBaseline, styled} from '@mui/material';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import React from 'react';
import Toolbar from './Toolbar';
import './Dashboard.scss';

const drawerWidth = '16.5rem';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export interface PublicDashboardProps {
  children?: React.ReactNode;
}

export const PublicDashboard: React.FC<PublicDashboardProps> = (props) => {
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      <AppBar>
        <Toolbar
          handleDrawerOpen={handleDrawerOpen}
          drawerOpen={open}
          isPublic={true}
        />
      </AppBar>
      <Box
        component="main"
        sx={{flexGrow: 1, p: 3, padding: 0}}
        className="main-public"
      >
        {props.children}
      </Box>
    </Box>
  );
};
