import React from 'react';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {useNavigate, useLocation, RouteProps, Outlet} from 'react-router-dom';
import {AUTH_SESSION_STATUSES} from './constants';
import {useAppSelector, useAppDispatch} from './app/hooks';
import {getAuthStatus, checkUser} from './features/auth';

const PrivateRoute: React.FC<RouteProps> = () => {
  const {authStatus, route} = useAuthenticator((context) => [
    context.authStatus,
  ]);
  const authSession = useAppSelector(getAuthStatus);
  const isAuthenticated = authStatus === 'authenticated';
  const verifyUser = route === 'verifyUser';
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(checkUser());

    if (!isAuthenticated && authSession === AUTH_SESSION_STATUSES.LOGOUT) {
      const urlSearchParamsObj = new URLSearchParams({
        redirect: `${location.pathname}${location.search}`,
      });
      navigate(
        {
          pathname: `/login`,
          search: urlSearchParamsObj.toString(),
        },
        {
          replace: true,
        },
      );
    }
  }, [isAuthenticated, navigate, authSession]);

  if (isAuthenticated || verifyUser) {
    return <Outlet />;
  }

  return <></>;
};

export default PrivateRoute;
