import React from 'react';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';
import useQueryParams from '../../hooks/useQueryParams';
import {selectShop} from '../../features/shops/selectedShop';
import AllCollections from './AllCollections';
import ViewCollection from './ViewCollection';

interface ICollectionPageProps {
  handleOnDeleteCollection: (id: number) => void;
  handleOnDeleteImage: (id: number) => void;
}

const CollectionPage: React.FC<ICollectionPageProps> = (props) => {
  const {collection: name} = useParams();
  const [queryParams] = useQueryParams();
  const id = queryParams.collectionId;
  if (!name && !id) {
    return (
      <AllCollections
        handleOnDeleteImage={props.handleOnDeleteImage}
        handleOnDeleteCollection={props.handleOnDeleteCollection}
      />
    );
  }

  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;

  return (
    <ViewCollection
      handleOnDeleteCollection={props.handleOnDeleteCollection}
      handleOnDeleteImage={props.handleOnDeleteImage}
      name={name!}
      id={id}
      shopId={shopId}
    />
  );
};

export default CollectionPage;
