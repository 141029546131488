import React from 'react';
import {Form} from 'react-bootstrap';
import {
  IBuilderSlots,
  IImage,
  TWalkthroughImage,
  TWalkthroughStep,
  TemplateCanvaObj,
} from '@gfxco/contracts';

import SlotSelector from '../SlotSelector';
import ImageSelector from '../ImageSelector';
import GFXButton from '../Button';

import {IImageFile} from '../../libs/uploadImage';
import './WalkthroughForm.scss';

interface RequiredProps {
  shopId: number;
  design: TemplateCanvaObj;
  onSave: (step: TWalkthroughStep) => void;
}

interface OptionalProps {
  defaultValue?: TWalkthroughStep & {index?: number};
  disabled?: boolean;
  onCancel?: () => void;
  images?: TWalkthroughImage[];
  onUploadImage: (image: IImageFile) => void;
}

type WalkthroughFormProps = RequiredProps & OptionalProps;

const WalkthroughForm: React.FC<WalkthroughFormProps> = (props) => {
  const [walkthroughActionEdited, setWalkthroughActionEdited] = React.useState<
    Partial<TWalkthroughStep>
  >({
    text: props.defaultValue?.text,
    slotId: props.defaultValue?.slotId,
    imageName: props.defaultValue?.imageName,
    imageURL: props.defaultValue?.imageURL,
  });

  const mapImages = (image: TWalkthroughImage): IImage => {
    return {
      id: image.id,
      name: image.name || image.url,
      imageUrl: image.url,
    };
  };

  const mapSlots = (slot: IBuilderSlots) => {
    const validSlot =
      slot.editType === 'customizable' ||
      slot.editType === 'swap' ||
      slot.editType === 'customizableAndSwappable' ||
      slot.swappable;

    return {
      ...slot,
      disabled: !validSlot,
      errorMessage: validSlot
        ? undefined
        : 'Only swappable or customizable slots are allowed.',
    };
  };

  const frontSlots = props.design.front.objects.map(mapSlots);
  const backSlots =
    props.design.back && Array.isArray(props.design.back.objects)
      ? props.design.back.objects.map(mapSlots)
      : [];

  const mappedSlots = [...frontSlots, ...backSlots];

  const slotsBySection = {
    front: frontSlots,
    back: backSlots,
  };

  const handleSaveWalkthrough = () => {
    const step = {
      text: walkthroughActionEdited.text,
      slotId: walkthroughActionEdited.slotId,
      walkthroughImageId: walkthroughActionEdited.walkthroughImageId,
    } as TWalkthroughStep;

    props.onSave(step);
    setWalkthroughActionEdited({});
  };

  const handleCancel = () => {
    setWalkthroughActionEdited({});
    if (props.onCancel) {
      props.onCancel();
    }
  };

  React.useEffect(() => {
    setWalkthroughActionEdited({
      text: props.defaultValue?.text,
      slotId: props.defaultValue?.slotId,
      imageName: props.defaultValue?.imageName,
      imageURL: props.defaultValue?.imageURL,
      walkthroughImageId: props.defaultValue?.walkthroughImageId,
    });
  }, [props.defaultValue]);

  const validForm =
    walkthroughActionEdited.slotId &&
    walkthroughActionEdited.walkthroughImageId &&
    walkthroughActionEdited.text;

  const mappedImages = props.images?.map(mapImages) || [];

  return (
    <form className="walkthroughForm">
      <div className="walkthroughForm__input">
        <Form.Label htmlFor="walkthroughStepText">Step title:</Form.Label>
        <Form.Control
          type="text"
          id="walkthroughStepText"
          aria-describedby="passwordHelpBlock"
          value={walkthroughActionEdited?.text || ''}
          onChange={(e) =>
            setWalkthroughActionEdited((prev) => ({
              ...prev,
              text: e.target.value.toUpperCase(),
            }))
          }
          maxLength={12}
          disabled={props.disabled}
        />
        <Form.Text id="walkthroughStepTextHelpBlock" muted>
          Enter step text (Max 12 characters)
        </Form.Text>
      </div>
      <div className="walkthroughForm__input">
        <ImageSelector
          shopId={props.shopId}
          disabled={props.disabled}
          images={mappedImages}
          value={mappedImages.find(
            (i) => i.id === walkthroughActionEdited?.walkthroughImageId,
          )}
          onChange={(image) => {
            setWalkthroughActionEdited((prev) => ({
              ...prev,
              walkthroughImageId: image?.id,
            }));
          }}
          onUploadImage={props.onUploadImage}
        />
      </div>

      <div className="walkthroughForm__input">
        <SlotSelector
          disabled={props.disabled}
          slotsBySection={slotsBySection}
          value={mappedSlots.find(
            (s) => s.slotId === walkthroughActionEdited?.slotId,
          )}
          onChange={(slot) => {
            setWalkthroughActionEdited((prev) => ({
              ...prev,
              slotId: +slot.slotId!,
            }));
          }}
        />
      </div>
      <div className="walkthroughForm__input walkthroughForm__input--group">
        <GFXButton
          variant="link"
          disabled={props.disabled}
          onClick={() => handleCancel()}
        >
          Cancel
        </GFXButton>
        <GFXButton
          disabled={props.disabled || !validForm}
          onClick={() => handleSaveWalkthrough()}
        >
          Save walkthrough
        </GFXButton>
      </div>
    </form>
  );
};

export default WalkthroughForm;
