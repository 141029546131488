import React from 'react';

import {ISize} from '@gfxco/contracts';

export interface SizeItemProps {
  size: ISize;
  selected: boolean;
  onClick: (size: ISize) => void;
}

const SizeItem: React.FC<SizeItemProps> = ({size, selected, onClick}) => {
  return (
    <div
      className={`size-item ${selected ? 'selected' : ''}`}
      onClick={() => onClick(size)}
    >
      {size.code}
    </div>
  );
};

export default SizeItem;
