import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import './CloseConfirmationModal.scss';

interface RequiredProps {
  show: boolean;
}

interface OptionalProps {
  onContinue?: () => void;
  onClose?: () => void;
  title?: string;
  text?: string;
  closeText?: string;
  continueText?: string;
}

type Props = RequiredProps & OptionalProps;

const CloseConfirmationModal: React.FC<Props> = ({
  show,
  title,
  text,
  onContinue,
  onClose,
  closeText,
  continueText,
}) => {
  return (
    <Modal
      className="CloseConfirmationModal"
      backdropClassName="modal-progress-warning-backdrop"
      aria-labelledby="modal-progress-warning"
      centered
      show={show}
      onHide={() => onContinue!()}
    >
      <Modal.Body>
        <h2 className="modal-body-title">
          {title || 'There are unsaved changes'}
        </h2>
        <span className="modal-body-text">
          {text ||
            'Would you like to continue editing your design or close the window? Any unsaved changes will be lost.'}
        </span>
      </Modal.Body>

      <Modal.Footer>
        <Button className="button-link" onClick={() => onClose!()}>
          {closeText || 'Close without saving'}
        </Button>
        <Button
          className="button-primary-style"
          variant="primary"
          onClick={() => onContinue!()}
        >
          {continueText || 'Keep editing'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CloseConfirmationModal;
