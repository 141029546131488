import React from 'react';

import {Col, Row} from 'react-bootstrap';

import {useAppSelector} from '../../../app/hooks';

import GFXImage from '../../../components/Image/Image';
import Loading from '../../../components/Loading';
import SearchNotFound from '../../../components/SearchNotFound';

import {
  selectStickerImages,
  selectStickerImagesFetchLoading,
} from '../../../features/stickers/loadStickersImages';

import {ELoadingStates} from '@gfxco/contracts';

import './StickersImages.scss';

type StickersImagesProps = {};

const StickersImages: React.FC<StickersImagesProps> = (props) => {
  const stickerImages = useAppSelector(selectStickerImages);
  const stickerImagesFetchStatus = useAppSelector(
    selectStickerImagesFetchLoading,
  );

  return (
    <div id="StickersImages">
      {stickerImagesFetchStatus === ELoadingStates.LOADING && (
        <Loading spinnerOnly />
      )}
      {!stickerImages.length &&
        stickerImagesFetchStatus === ELoadingStates.LOADED && (
          <SearchNotFound entity="Sticker images" />
        )}
      {stickerImagesFetchStatus === ELoadingStates.LOADED &&
        !!stickerImages.length && (
          <Row>
            {stickerImages.map((i) => (
              <Col md="auto" key={i.id}>
                <GFXImage
                  key={i.id}
                  src={i.thumbnail}
                  placeholderSrc="/loading-render.gif"
                />
              </Col>
            ))}
          </Row>
        )}
    </div>
  );
};

export default StickersImages;
