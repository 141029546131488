import {CSSObject, styled, Theme, useTheme} from '@mui/material';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import {ChevronLeft} from 'react-bootstrap-icons';

import React from 'react';
import Toolbar from './Toolbar';
import NavigationMenu from './NavigationMenu';
import './Dashboard.scss';
import GfxLogo from '../../assets/gfx_logo_beta.svg';

const drawerWidth = '16.5rem';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxShadow: '0px 4px 39px 0px rgba(0, 0, 0, 0.17)',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '3.4rem',
  boxShadow: '0px 4px 39px 0px rgba(0, 0, 0, 0.17)',
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {...closedMixin(theme), overflow: 'visible'},
  }),
}));

export interface DashboardProps {
  children?: React.ReactNode;
}

export const Dashboard: React.FC<DashboardProps> = (props) => {
  const theme = useTheme();
  const menuOpenByDefault = window.innerWidth > 900;
  const [open, setOpen] = React.useState(menuOpenByDefault);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div id="Dashboard">
      <AppBar position="fixed" open={open}>
        <Toolbar handleDrawerOpen={handleDrawerOpen} drawerOpen={open} />
      </AppBar>
      <Drawer variant="permanent" open={open}>
        {open && (
          <div className={`drawer ${open ? 'open' : ''}`}>
            <div onClick={handleDrawerClose}>
              {theme.direction !== 'rtl' && (
                <ChevronLeft className="open-icon" />
              )}
            </div>
            <img src={GfxLogo} />
          </div>
        )}
        <NavigationMenu open={open} />
      </Drawer>
      {props.children}
    </div>
  );
};
