import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './CustomPagination.scss';

interface RequiredProps {
  limit: number;
  total: number;
  currentPage: number;
  handlePagination: (action: string, page?: number) => void;
}
interface OptionalProps {
  entity?: string;
  skipInfo?: boolean;
}

type CustomPaginationProps = RequiredProps & OptionalProps;

export const CustomPagination: React.FC<CustomPaginationProps> = ({
  limit,
  total,
  currentPage,
  handlePagination,
  skipInfo = false,
  entity = 'Orders',
}) => {
  let totalPages = Math.ceil(total / limit);

  if (total === 0) {
    totalPages = 1;
  }

  const pages = [];
  for (let i = 0; i < totalPages; i++) {
    pages.push(i);
  }

  let start = 1;
  let end = pages.length;
  const totalLoaded = Math.min(limit * currentPage, total);

  if (currentPage - 1 > 1) start = currentPage - 1;
  if (currentPage + 1 < pages.length) end = currentPage + 1;

  const handlePaginationClick = (
    evt: React.SyntheticEvent,
    action: string,
    page?: number,
  ) => {
    evt.stopPropagation();
    handlePagination(action, page);
  };

  return (
    <div id="CustomPagination">
      <Container fluid id={`CustomPagination-${entity}`}>
        <Row>
          <Col>
            {!skipInfo && (
              <span className="pagination-count">{`${totalLoaded} out of ${total} ${entity}`}</span>
            )}
          </Col>
          <Col>
            <Pagination className="pagination-content">
              <Pagination.First
                onClick={(e) => handlePaginationClick(e, 'first')}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={(e) => handlePaginationClick(e, 'prev')}
                disabled={currentPage === 1}
              />
              {start !== 1 && <Pagination.Ellipsis />}
              {pages.slice(start - 1, end).map((number) => (
                <Pagination.Item
                  key={number}
                  onClick={(e) => handlePaginationClick(e, 'page', number)}
                  active={currentPage === number + 1}
                >
                  {number + 1}
                </Pagination.Item>
              ))}
              {end !== pages.length && <Pagination.Ellipsis />}
              <Pagination.Next
                onClick={(e) => handlePaginationClick(e, 'next')}
                disabled={currentPage === pages.length}
              />
              <Pagination.Last
                onClick={(e) =>
                  handlePaginationClick(e, 'last', pages.length - 1)
                }
                disabled={currentPage === pages.length}
              />
            </Pagination>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CustomPagination;
