import {createListenerMiddleware} from '@reduxjs/toolkit';
import {Auth} from 'aws-amplify';
import {logoutAsync, checkUser} from '../../features/auth';

const authMiddleware = createListenerMiddleware();

authMiddleware.startListening({
  actionCreator: checkUser,
  effect: async (action, listenerApi) => {
    try {
      await Auth.currentAuthenticatedUser({bypassCache: true});
    } catch (error) {
      if (error === 'The user is not authenticated') {
        listenerApi.dispatch(logoutAsync());
      }
    }
  },
});

export default authMiddleware.middleware;
