import React from 'react';
import {SESSION_STORAGE_KEYS} from '../../constants';
// Types
import {ShopsResponse} from '@gfxco/contracts';

// Components
import GFXStoreItemComponent from '../../components/GFXStoreItemComponent/GFXStoreItemComponent';
import Button from '../../components/Button';
import {PublicDashboard} from '../../components/Dashboard';

// Hooks
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {
  getShopsAsync,
  selectShops,
  selectShopsFetchLoading,
} from '../../features/shops/loadShops';
import {
  selectShop,
  updateSelectedShop,
} from '../../features/shops/selectedShop';
import {logoutAsync} from '../../features/auth';
import useSessionStorage from '../../hooks/useSessionStorage';

// Assets
import GfxLogo from '../../assets/gfx_logo_beta_black.svg';

// Styles
import './ShopSelectorPage.scss';

export const ShopSelectorPage: React.FC = () => {
  const [selectedShop, setSelectedShop] = React.useState<ShopsResponse | null>(
    null,
  );
  const shops = useAppSelector(selectShops);
  const shopsFetchStatus = useAppSelector(selectShopsFetchLoading);
  const shopSelected = useAppSelector(selectShop);
  const {setValue, getValue} = useSessionStorage();
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    if (selectedShop) {
      setValue(SESSION_STORAGE_KEYS.SELECTED_SHOP, selectedShop.id.toString());
      dispatch(updateSelectedShop(selectedShop));
    }
  };

  React.useEffect(() => {
    if (shopsFetchStatus === 'idle') {
      dispatch(getShopsAsync());
    }
  }, [shopsFetchStatus, dispatch]);

  React.useEffect(() => {
    if (shops) {
      const selectedShopId = getValue(SESSION_STORAGE_KEYS.SELECTED_SHOP);

      if (selectedShopId) {
        const selectedShop = shops.find(
          (s) => s.id === parseInt(selectedShopId, 10),
        );
        if (selectedShop) {
          dispatch(updateSelectedShop(selectedShop));
        }
      }

      if (!shopSelected && shops.length === 1) {
        setValue(SESSION_STORAGE_KEYS.SELECTED_SHOP, shops[0].id.toString());
        dispatch(updateSelectedShop(shops[0]));
      }
    }
  }, [shops]);

  if (shopsFetchStatus !== 'loaded') {
    return <></>;
  }

  return (
    <PublicDashboard>
      <div className="ShopSelectorPage">
        <div className="ShopSelectorPage__WrapperShopList">
          <div className="ShopSelectorPage__WrapperShopList__shopsList">
            <a className="logo-link" href="http://www.gfxco.com">
              <img src={GfxLogo} />
            </a>
            <h1 className="ShopSelectorPage__WrapperShopList__shopsList__title">
              Select your store
            </h1>
            <p className="ShopSelectorPage__WrapperShopList__shopsList__text">
              to Continue with gfx
            </p>
            <div className="ShopSelectorPage__WrapperShopList__shopsList__list">
              {shops &&
                shops.map((s, index) => (
                  <GFXStoreItemComponent
                    key={s.id}
                    shop={s}
                    isSelectedStore={s.id === selectedShop?.id}
                    onClick={() => {
                      setSelectedShop(s);
                    }}
                    icon="defaultStoreImage"
                  />
                ))}
            </div>
            <div className="ShopSelectorPage__WrapperShopList__shopsList__actions">
              <Button
                onClick={() => dispatch(logoutAsync())}
                variant="link"
                className="gfx-btn-link-logout"
              >
                {'<Back'}
              </Button>
              <Button
                onClick={() => onSubmit()}
                disabled={selectedShop === null}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PublicDashboard>
  );
};
