import {IImage, IColor} from '@gfxco/contracts';
import React from 'react';
import ColorItem from '../../ConfigureProductInfo/Items/ColorItem';
import {ReactComponent as ImageSlotIcon} from '../../../assets/icons/image-slot-icon.svg';
import {ReactComponent as TextSlotIcon} from '../../../assets/icons/text-slot-icon.svg';
import './ItemSlot.scss';

type RequiredProps = {
  slotName: string;
};

type OptionalProps = {
  side?: 'front' | 'back';
  image?: IImage;
  color?: IColor;
  showIcon?: boolean;
  defaultImage?: string;
  bordered?: boolean;
};
type ItemSlotProps = RequiredProps & OptionalProps;

const ItemSlot: React.FC<ItemSlotProps> = ({
  image,
  color,
  side,
  slotName,
  defaultImage,
  showIcon = true,
  bordered,
}) => {
  return (
    <div id="ItemSlot">
      <div
        className={` item-slot-body ${bordered ? 'item-slot-bordered' : ''}`}
      >
        {showIcon && image && <ImageSlotIcon />}
        {showIcon && color && <TextSlotIcon />}
        {image && !defaultImage && (
          <div>
            <img src={image.imageUrl || defaultImage} />
          </div>
        )}
        {defaultImage && !image && (
          <div>
            <img src={defaultImage} />
          </div>
        )}
        {color && !defaultImage && (
          <div className={`${color.hex === '#FFFFFF' ? 'border' : ''}`}>
            <ColorItem color={color} selected={false} onClick={() => {}} />
          </div>
        )}
        <div className="text-box">
          <span className="type-description">{`${
            side || '-'
          } design slot`}</span>
          <span className="name-description">{slotName}</span>
        </div>
      </div>
    </div>
  );
};

export default ItemSlot;
