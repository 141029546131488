import Icon from '../../components/Icons/Icon';
import PrinterCollapsePanel from './PrinterCollapsePanel';
import './PrintersPage.scss';
import PrinterForm from './PrinterForm';
import WebhookForm from './WebhookForm';
import {
  ELoadingStates,
  IPrinterIntegrationFormValues,
  IWebhookIntegrationFormValues,
} from '@gfxco/contracts';
import PrinterTitle from './PrinterTitle';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import {
  connectFifthSunIntegrationAsync,
  connectPrintfulIntegrationAsync,
  connectSendArtsIntegrationAsync,
  getFifthSunIntegrationStatusAsync,
  getPrintfulIntegrationStatusAsync,
  getSendArtsIntegrationStatusAsync,
  IPrinterIntegration,
  IWebhookIntegration,
  resetPrintersIntegration,
  selectIntegrationStatusByPrinter,
} from '../../features/integrations/loadPrintersIntegration';
import React from 'react';
import {GFXToastLaunch} from '../../components/ToastMessage/ToastMessage';
import Skeleton from 'react-loading-skeleton';

const PrintersPage = () => {
  const dispatch = useAppDispatch();
  const shopSelected = useAppSelector(selectShop);
  if (!shopSelected) return <></>;
  const shopId = shopSelected?.id;
  const [innerShopId, setInnerShopId] = React.useState<number>(shopId);

  const printfulIntegrationStatus = useAppSelector((state) =>
    selectIntegrationStatusByPrinter(state, 'printful'),
  ) as IPrinterIntegration;

  const fifthSunIntegrationStatus = useAppSelector((state) =>
    selectIntegrationStatusByPrinter(state, 'fifthSun'),
  ) as IPrinterIntegration;

  const sendArtsIntegrationStatus = useAppSelector((state) =>
    selectIntegrationStatusByPrinter(state, 'sendArts'),
  ) as IWebhookIntegration;

  React.useEffect(() => {
    if (printfulIntegrationStatus.fetchStatus === ELoadingStates.IDLE) {
      dispatch(getPrintfulIntegrationStatusAsync(shopId));
    }
    if (printfulIntegrationStatus.fetchStatus === ELoadingStates.FAILED) {
      GFXToastLaunch('An error occurs getting Printful integration', 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [printfulIntegrationStatus.fetchStatus, shopId, dispatch]);

  React.useEffect(() => {
    if (fifthSunIntegrationStatus.fetchStatus === ELoadingStates.IDLE) {
      dispatch(getFifthSunIntegrationStatusAsync(shopId));
    }
    if (fifthSunIntegrationStatus.fetchStatus === ELoadingStates.FAILED) {
      GFXToastLaunch('An error occurs getting FifthSun integration', 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [fifthSunIntegrationStatus.fetchStatus, shopId, dispatch]);

  React.useEffect(() => {
    if (sendArtsIntegrationStatus.fetchStatus === ELoadingStates.IDLE) {
      dispatch(getSendArtsIntegrationStatusAsync(shopId));
    }
    if (sendArtsIntegrationStatus.fetchStatus === ELoadingStates.FAILED) {
      GFXToastLaunch('An error occurs getting Send Arts integration', 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [sendArtsIntegrationStatus.fetchStatus, shopId, dispatch]);

  React.useEffect(() => {
    if (printfulIntegrationStatus.connectLoadStatus === ELoadingStates.FAILED) {
      GFXToastLaunch(printfulIntegrationStatus.errorMessage, 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [printfulIntegrationStatus.connectLoadStatus, shopId, dispatch]);

  React.useEffect(() => {
    if (printfulIntegrationStatus.connectLoadStatus === ELoadingStates.FAILED) {
      GFXToastLaunch(printfulIntegrationStatus.errorMessage, 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [printfulIntegrationStatus.connectLoadStatus, dispatch]);

  React.useEffect(() => {
    if (fifthSunIntegrationStatus.connectLoadStatus === ELoadingStates.FAILED) {
      GFXToastLaunch(fifthSunIntegrationStatus.errorMessage, 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [fifthSunIntegrationStatus.connectLoadStatus, dispatch]);

  React.useEffect(() => {
    if (sendArtsIntegrationStatus.connectLoadStatus === ELoadingStates.FAILED) {
      GFXToastLaunch(sendArtsIntegrationStatus.errorMessage, 5000, {
        showAt: 'top',
        right: '3.5rem',
        top: '3.5rem',
        alertType: 'danger',
        showIcon: true,
      });
    }
  }, [sendArtsIntegrationStatus.connectLoadStatus, dispatch]);

  React.useEffect(() => {
    if (innerShopId !== shopId) {
      dispatch(resetPrintersIntegration());
      setInnerShopId(shopId);
    }
  }, [innerShopId, shopId, dispatch]);

  const handlePrintfulFormSubmit = (params: IPrinterIntegrationFormValues) => {
    dispatch(connectPrintfulIntegrationAsync({shopId, ...params}));
  };

  const handleFifthFormSubmit = (params: IPrinterIntegrationFormValues) => {
    dispatch(connectFifthSunIntegrationAsync({shopId, ...params}));
  };

  const handleRenderArtFormSubmit = (params: IWebhookIntegrationFormValues) => {
    dispatch(connectSendArtsIntegrationAsync({shopId, ...params}));
  };

  const contentLoading =
    printfulIntegrationStatus.fetchStatus === ELoadingStates.LOADING ||
    fifthSunIntegrationStatus.fetchStatus === ELoadingStates.LOADING ||
    sendArtsIntegrationStatus.fetchStatus === ELoadingStates.LOADING;

  return (
    <div id="PrintersPage" className="PrintersPage">
      <header className="PrintersPage__header">
        <div className="page-header">
          <div className="page-header__title">
            <h3>Printer configuration</h3>
            <p>Set up your fulfillment printers</p>
          </div>
        </div>
      </header>
      {contentLoading ? (
        <Skeleton
          className="CollapsePanel"
          containerClassName="PrintersPage__content"
          count={3}
          inline
        />
      ) : (
        <section className="PrintersPage__content">
          <PrinterCollapsePanel
            title={
              <PrinterTitle
                icon="samplePortal"
                title="Printful"
                description="Connect to our global services and we'll fulfill and ship your products automatically"
                tooltipId="printfulTooltip"
              />
            }
            description="On-demand printing and fulfillment."
            open
            connected={printfulIntegrationStatus.connected}
          >
            <PrinterForm
              id="printful"
              connected={printfulIntegrationStatus.connected}
              apiKey={printfulIntegrationStatus.apiKey}
              storeId={printfulIntegrationStatus.storeId}
              image={<Icon name="printfulLogo" />}
              onSubmitted={handlePrintfulFormSubmit}
              loading={
                printfulIntegrationStatus.connectLoadStatus ===
                ELoadingStates.LOADING
              }
              learnHowUrl="https://gfx-help.notion.site/Printful-setup-dc90a3e8e0cf46b8ab53434a4bca0d1a"
            />
          </PrinterCollapsePanel>
          <PrinterCollapsePanel
            title={
              <PrinterTitle
                icon="samplePortal"
                title="Fifth Sun"
                description="Fifthsun.com is a private label apparel which produces unique clothing for companies"
                tooltipId="fifthSunTooltip"
              />
            }
            description="Products, printing, branding, and fulfillment by connecting your store."
            connected={fifthSunIntegrationStatus.connected}
          >
            <PrinterForm
              id="fifthSun"
              connected={fifthSunIntegrationStatus.connected}
              apiKey={fifthSunIntegrationStatus.apiKey}
              storeId={fifthSunIntegrationStatus.storeId}
              image={<Icon name="fifthSunLogo" />}
              onSubmitted={handleFifthFormSubmit}
              loading={
                fifthSunIntegrationStatus.connectLoadStatus ===
                ELoadingStates.LOADING
              }
            />
          </PrinterCollapsePanel>
          <PrinterCollapsePanel
            title={
              <PrinterTitle
                icon="sendArts"
                title="Render Art"
                description="Generate your artwork in any size you want, connect it to your printer via a webhook"
                tooltipId="renderArtTooltip"
              />
            }
            description="Generate your own art and export it through a webhook. No external fulfillment required."
            connected={sendArtsIntegrationStatus.connected}
          >
            <WebhookForm
              id="renderArt"
              connected={sendArtsIntegrationStatus.connected}
              webhookUrl={sendArtsIntegrationStatus.webhookUrl}
              onSubmitted={handleRenderArtFormSubmit}
              loading={
                sendArtsIntegrationStatus.connectLoadStatus ===
                ELoadingStates.LOADING
              }
              learnHowUrl="https://gfx-help.notion.site/External-Orders-API-Documentation-7f0b4c350f40478ba2f97cb5b0a41938#1383415976e041f388e77b415103aca3"
            />
          </PrinterCollapsePanel>
        </section>
      )}
    </div>
  );
};

export default PrintersPage;
