import React, {useState} from 'react';
import Modal from '../Modal/SimpleModal';
import GFXToggle from '../Toggle';
import CardInfo from './CardInfo';
import DesignView from './DesignView';
import SelectorView from './SelectorView';
import ActionsView from './ActionsView';
import FooterView from './FooterView';
import {
  getModerationItem,
  deleteModerationHistoryItem,
  updateModerationHistoryItem,
} from '../../api';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import {
  updateItemAsync,
  selectEntitiesLoading,
} from '../../features/moderation/loadModerationOrders';
import {updateViewedItemAsync} from '../../features/moderation/loadDesignsCount';
import {
  IModerationOrder,
  IGetModerationItemResponse,
  EModerationItemStatus,
  ELoadingStates,
} from '@gfxco/contracts';

import './OrderModal.scss';
interface RequiredProps {
  open: boolean;
  closeModal: () => void;
  onNext: () => void;
  onPrevious: () => void;
  totalOrders: number;
  currentIndex: number;
}

interface OptionalProps {
  itemSelected?: IModerationOrder;
  printerFetchStatus: ELoadingStates;
  printerStatus: boolean;
  onAccept?: (itemId: number, shopId: number) => void;
  onReject?: (itemId: number, shopId: number) => void;
  onHold?: (itemId: number, shopId: number) => void;
}
type OrdersModalProps = RequiredProps & OptionalProps;

export type ColorBackground = '#fff' | '#B2AFB5' | '#525151' | '#000';

export const OrderModal: React.FC<OrdersModalProps> = (props) => {
  const [colorBackground, setColorBackground] =
    useState<ColorBackground>('#fff');
  const [viewSelected, setViewSelected] = useState<'front' | 'back'>('front');
  const [item, setItem] = useState<IGetModerationItemResponse>();
  const [isWaitingActions, setIsWaitingActions] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [direction, setDirection] = useState('');
  const shopSelected = useAppSelector(selectShop);
  const dispatch = useAppDispatch();
  const shopId = shopSelected?.id;

  const isOnHold = props.itemSelected?.moderationStatus === 'on-hold';
  const [navigationDisabled, setNavigationDisabled] = useState<boolean>(false);

  const entitiesLoading = useAppSelector((state) =>
    selectEntitiesLoading(state),
  );

  React.useEffect(() => {
    if (!isLoading || isReload) {
      getItemInfo();
      setIsReload(false);
      setNavigationDisabled(false);
      setIsWaitingActions(false);
      if (props.itemSelected && !props.itemSelected.inReview) {
        dispatch(updateViewedItemAsync(props.itemSelected.designId));
      }
    }
  }, [props.itemSelected, isReload]);

  React.useEffect(() => {
    if (!props.itemSelected?.itemId) return;

    const shouldDisableNavigation = entitiesLoading.includes(
      props.itemSelected?.itemId,
    );

    setNavigationDisabled(shouldDisableNavigation);
  }, [props.itemSelected?.itemId, entitiesLoading]);

  const getItemInfo = async () => {
    if (!props.itemSelected?.itemId) return;

    setIsLoading(true);
    try {
      const itemId = props.itemSelected?.itemId;
      const itemInfo = await getModerationItem(itemId);
      if (itemInfo) {
        setItem(itemInfo);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnHold = (checked: boolean) => {
    if (!props.itemSelected?.itemId) return;

    const status = checked
      ? EModerationItemStatus.ON_HOLD
      : EModerationItemStatus.PENDING;

    handleAction(status, props.itemSelected?.itemId);
  };

  const handleOnDeleteComment = async (id: number) => {
    try {
      await deleteModerationHistoryItem(id);
      setIsReload(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnEditComment = async (id: number, details: string) => {
    try {
      await updateModerationHistoryItem(id, details);
      setIsReload(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAction = (
    status: EModerationItemStatus,
    itemId: number,
    isComment?: boolean,
    details?: string,
  ) => {
    if (!shopId) return;
    dispatch(
      updateItemAsync({
        itemId,
        shopId,
        status,
        details: isComment ? details : `Design ${status}`,
        isComment,
      }),
    );
  };

  if (!props.itemSelected) {
    return <></>;
  }

  const handleWaitingActions = (action: boolean) => {
    setIsWaitingActions(action);
  };

  const handleOnClose = () => {
    if (navigationDisabled || isWaitingActions) return;
    props.closeModal();
  };

  let modalClasses = `modal-middle-page ${
    isOnHold ? 'modal-middle-selected' : 'modal-middle-colored'
  }
  `;

  if (navigationDisabled || isWaitingActions) {
    modalClasses += 'modal-disabled';
  }

  return (
    <Modal
      className={modalClasses}
      show={props.open}
      modalTile={''}
      onCloseModal={() => handleOnClose()}
    >
      <div id="order-modal">
        <div
          className={`order-modal-header ${
            [
              EModerationItemStatus.ACCEPTED,
              EModerationItemStatus.REJECTED,
            ].includes(props.itemSelected?.moderationStatus) ||
            navigationDisabled ||
            isWaitingActions
              ? 'gfx-input-disabled'
              : ''
          }`}
        >
          <div>
            <span className="header-title">Detailed design view</span>
          </div>
          <GFXToggle
            id="update-item-onHold"
            label="Put order On Hold"
            checked={isOnHold}
            onChange={(e) => {
              handleOnHold(e.target.checked);
            }}
            disabled={
              [
                EModerationItemStatus.ACCEPTED,
                EModerationItemStatus.REJECTED,
              ].includes(props.itemSelected?.moderationStatus) ||
              navigationDisabled ||
              isWaitingActions
            }
          />
        </div>
        <div
          className={`order-modal-content ${direction}`}
          onAnimationEnd={() => setDirection('')}
        >
          <CardInfo
            id={props.itemSelected.id}
            createdAt={props.itemSelected.createdAt}
            printInfo={props.itemSelected.printInfo}
            customerEmail={props.itemSelected.customerEmail}
            shopifyOrderId={props.itemSelected.orderName}
            templateId={props.itemSelected.templateId}
            designId={props.itemSelected.designId}
            itemId={props.itemSelected.itemId}
            generatedUrl={item?.generatedUrl}
            designNumber={props.itemSelected.designNumber}
            customerName={props.itemSelected.customerName}
          />
          <div>
            <div className="order-modal-content">
              <SelectorView
                proofFront={props.itemSelected.proofFront}
                proofBack={props.itemSelected.proofBack}
                artFront={props.itemSelected.artFront}
                artBack={props.itemSelected.artBack}
                onChangeColor={(color: string) =>
                  setColorBackground(color as ColorBackground)
                }
                onChangeView={(view: 'front' | 'back') => setViewSelected(view)}
                colorSelected={colorBackground}
                viewSelected={viewSelected}
              />
              <DesignView
                proofFront={props.itemSelected.proofFront}
                proofBack={props.itemSelected.proofBack}
                artBack={props.itemSelected.artBack}
                artFront={props.itemSelected.artFront}
                viewSelected={viewSelected}
                colorBackground={colorBackground}
                designId={props.itemSelected.designId}
                templateId={props.itemSelected.templateId}
                itemId={props.itemSelected.itemId}
                status={props.itemSelected.moderationStatus}
                renderDisabled={navigationDisabled || isWaitingActions}
              />
            </div>

            <div className="order-modal-content">
              <ActionsView
                itemId={props.itemSelected.itemId}
                onAction={handleAction}
                disabled={
                  [
                    EModerationItemStatus.ACCEPTED,
                    EModerationItemStatus.REJECTED,
                    EModerationItemStatus.ON_HOLD,
                  ].includes(props.itemSelected?.moderationStatus) ||
                  navigationDisabled
                }
                comments={item?.history}
                isLoading={
                  isLoading &&
                  props.printerFetchStatus !== ELoadingStates.LOADED
                }
                onDeleteComment={handleOnDeleteComment}
                status={props.itemSelected?.moderationStatus}
                onEditComment={handleOnEditComment}
                onWait={handleWaitingActions}
                printerIntegrated={props.printerStatus}
              />
            </div>
          </div>
        </div>
        <FooterView
          onNext={() => {
            props.onNext();
            setDirection('left');
          }}
          onPrevious={() => {
            props.onPrevious();
            setDirection('right');
          }}
          disabled={navigationDisabled || isWaitingActions}
          totalOrders={props.totalOrders}
          currentIndex={props.currentIndex}
        />
      </div>
    </Modal>
  );
};
