import {ELoadingStates} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getShopPrinters} from '../../api';
import {RootState} from '../../app/store';

interface ShopPrinterState {
  connected: boolean;
  fetchStatus: ELoadingStates;
  printers?: string[];
}

const initialState: ShopPrinterState = {
  connected: false,
  fetchStatus: ELoadingStates.IDLE,
};

export const getShopPrintersAsync = createAsyncThunk(
  'shopPrinters/fetch',
  async (shopId: number) => {
    const response = await getShopPrinters(shopId);
    return response;
  },
);

export const loadShopPrinters = createSlice({
  name: 'shopPrinters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShopPrintersAsync.pending, (state) => {
        state = {
          connected: false,
          fetchStatus: ELoadingStates.LOADING,
        };
      })
      .addCase(getShopPrintersAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.connected =
            'connected' in action.payload ? action.payload.connected : false;
          state.fetchStatus = ELoadingStates.LOADED;

          state.printers =
            'printers' in action.payload ? action.payload.printers : undefined;
        }
      })
      .addCase(getShopPrintersAsync.rejected, (state) => {
        state = {
          connected: false,
          fetchStatus: ELoadingStates.FAILED,
        };
      });
  },
});

export const selectShopPrinterStatus = (state: RootState) => {
  return state.shopPrinters.connected;
};

export const selectShopPrintersFetchLoading = (state: RootState) => {
  return state.shopPrinters.fetchStatus;
};

export const selectShopPrintersList = (state: RootState) => {
  return {
    storeId: state.shopPrinters.printers,
  };
};

export default loadShopPrinters.reducer;
