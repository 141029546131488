import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import {PortalDesign} from '@gfxco/contracts';
import {sendToPrintful, sendToDubow} from '../../api';
import './PrintForm.scss';

interface RequiredProps {
  designs: PortalDesign[];
  printer: string;
}

interface OptionalProps {}

type PrintFormProps = RequiredProps & OptionalProps;

export const PrintForm: React.FC<PrintFormProps> = (props) => {
  const {designs, printer} = props;

  const [isPrintEnabled, setIsPrintEnabled] = React.useState(false);
  const [sendToPrintStatus, setSendToPrintStatus] = React.useState('');

  React.useEffect(() => {
    if (!printer || designs.length <= 0) {
      setIsPrintEnabled(false);
    } else {
      setIsPrintEnabled(true);
    }
  }, [printer, designs]);

  const handleSendToPrinter = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    setSendToPrintStatus('loading');

    const target = event.target as HTMLFormElement;

    const recipient = target.recipient?.value;
    const address1 = target.address1?.value;
    const address2 = target.address2?.value;
    const city = target.city?.value;
    const stateCode = target.stateCode?.value;
    const zipCode = target.zipCode?.value;
    const email = target.email?.value;
    const orderName = target.orderName?.value;

    try {
      if (printer === 'dubow') {
        await sendToDubow({
          recipient,
          address1,
          address2,
          city,
          stateCode,
          zipCode,
          email,
          orderName,
          printer,
          designs,
        });
      } else {
        await sendToPrintful({
          recipient,
          address1,
          address2,
          city,
          stateCode,
          zipCode,
          email,
          orderName,
          printer,
          designs,
        });
      }
    } catch (error) {
      console.error(error);
      setSendToPrintStatus('errored');
      return;
    }

    setSendToPrintStatus('success');
  };

  return (
    <div id="PrintForm">
      <Form className="address-information" onSubmit={handleSendToPrinter}>
        <Form.Group>
          <Form.Label htmlFor="orderName">
            Order Name: * (Netflix Sample)
          </Form.Label>
          <Form.Control
            type="text"
            id="orderName"
            name="orderName"
            aria-label="orderName"
            placeholder="Netflix Sample ST"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="recipient">Recipient: * (Jhon Doe)</Form.Label>
          <Form.Control
            type="text"
            id="recipient"
            name="recipient"
            aria-label="recipient"
            defaultValue="Kerstin Pittman"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="address1">
            Address 1: * (1234 S Moody)
          </Form.Label>
          <Form.Control
            type="text"
            id="address1"
            name="address1"
            aria-label="address1"
            defaultValue="4682 great western ln s"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="address2">Address 2: (Suite 4)</Form.Label>
          <Form.Control
            type="text"
            id="address2"
            name="address2"
            aria-label="address2"
            placeholder="Suite 4"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="city">City: * (Los Angeles)</Form.Label>
          <Form.Control
            type="text"
            id="city"
            name="city"
            aria-label="city"
            defaultValue="Jacksonville"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="stateCode">State Code: * (CA, FL)</Form.Label>
          <Form.Control
            type="text"
            id="stateCode"
            name="stateCode"
            aria-label="stateCode"
            defaultValue="FL"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="zipCode">ZIP: * (95135)</Form.Label>
          <Form.Control
            type="text"
            id="zipCode"
            name="zipCode"
            aria-label="zipCode"
            defaultValue="32257"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="email">Email: * (mail@domain.com)</Form.Label>
          <Form.Control
            type="email"
            id="email"
            name="email"
            aria-label="email"
            defaultValue="kerstin@gfxco.com"
            required
          />
        </Form.Group>
        {sendToPrintStatus === 'success' && (
          <Alert variant="success">Send to Printer succesfuly</Alert>
        )}
        {sendToPrintStatus === 'errored' && (
          <Alert variant="danger">Send to Printer errored</Alert>
        )}
        <div className="submit-group">
          <Button
            variant="primary"
            type="submit"
            disabled={!isPrintEnabled || sendToPrintStatus === 'loading'}
          >
            Send to Printer
          </Button>
        </div>
      </Form>
    </div>
  );
};
