import * as Contracts from '@gfxco/contracts';
import {loadClient, handleError} from './api';

export const renderfiles = async (
  parameters: Contracts.IRenderFilesRequest,
) => {
  try {
    const client = await loadClient();
    const params = {
      templateId: parameters.templateId,
      itemId: parameters.itemId,
      section: parameters.section,
      type: parameters.type,
    };
    const response = await client.post<Contracts.IRenderFilesResponse>(
      `/designs/${parameters.designId}/render`,
      params,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
