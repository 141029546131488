import React from 'react';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {
  getDeveloperOptionsAsync,
  selectWebhooks,
  selectDeveloperOptionsFetchLoading,
} from '../../../features/shops/loadDeveloperOptions';
import {selectShop} from '../../../features/shops/selectedShop';

import Experimentals from './Experimentals';
import WebhooksSection from './WebhooksSection';
import {ErrorMessage} from '../../../components/ErrorMessage';
import './DeveloperOptions.scss';

export const DeveloperOptions: React.FC = (props) => {
  const shopSelected = useAppSelector(selectShop);
  const webhooks = useAppSelector((state) =>
    selectWebhooks(state, shopSelected?.id),
  );
  const developerOptionsFetchStatus = useAppSelector((state) =>
    selectDeveloperOptionsFetchLoading(state, shopSelected?.id),
  );

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (shopSelected && developerOptionsFetchStatus === 'idle') {
      dispatch(getDeveloperOptionsAsync(shopSelected.id));
    }
  }, [shopSelected, developerOptionsFetchStatus]);

  if (developerOptionsFetchStatus === 'idle') {
    return <></>;
  }

  if (developerOptionsFetchStatus === 'failed') {
    return (
      <ErrorMessage
        message="Sorry, something went wrong."
        retryAction={() => {
          if (shopSelected) {
            dispatch(getDeveloperOptionsAsync(shopSelected.id));
          }
        }}
        backToHome
      />
    );
  }

  if (developerOptionsFetchStatus === 'loading') {
    return <span>Loading...</span>;
  }

  return (
    <>
      <WebhooksSection className="customPanel" webhooks={webhooks} />
      <Experimentals className="customPanel" shopSelected={shopSelected} />
    </>
  );
};

export default DeveloperOptions;
