import React from 'react';
import {useNavigate} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Alert from 'react-bootstrap/Alert';
import {ThreeDots} from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';

import {
  ELoadingStates,
  IDesignTemplate,
  ISlotTemplateCanvas,
} from '@gfxco/contracts';

import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import usePagination from '../../../hooks/usePagination';
import useQueryParams from '../../../hooks/useQueryParams';
import {selectShop} from '../../../features/shops/selectedShop';

import CustomPagination from '../../CustomPagination';
import DeleteTemplate from '../../DeleteTemplate';
import ShopifyPublishModal from '../../ShopifyPublishModal';
import GFXImage from '../../Image/Image';
import AddToProductListingItem from './AddToProductListingItem';
import NoResultView from '../NoResultView';

import {
  getDesignTemplatesAsync,
  getDesignTemplatesByPage,
  selectTotalDesignTemplates,
  fetchStatusByPage,
  resetDesignTemplatesPages,
} from '../../../features/designTemplates/loadDesignTemplates';

import {
  getShopifyIntegrationStatusAsync,
  selectIntegrationStatus,
  selectIntegrationsFetchLoading,
} from '../../../features/integrations/loadShopifyIntegration';

import './DesignsList.scss';
import LoadingModal from '../../LoadingModal';
import DuplicateNewProduct from '../DuplicateNewProductModal/DuplicateNewProduct';
import Icon from '../../Icons/Icon';

interface RequiredProps {
  onEdit: (draftCanva: ISlotTemplateCanvas) => void;
}

interface OptionalProps {}

type DesignListProps = RequiredProps & OptionalProps;

const DesignsList: React.FC<DesignListProps> = ({onEdit}) => {
  const [queryParams] = useQueryParams();
  const sortBy = queryParams.sortBy || 'newest';
  const search = queryParams.search || '';
  const navigate = useNavigate();
  const [duplicatingTemplate, setDuplicatingTemplate] = React.useState(false);
  const [templateToCopy, setTemplateToCopy] =
    React.useState<ISlotTemplateCanvas | null>(null);
  const [{offset, currentPage, limit}, handlePagination] = usePagination();
  const [showShopifyPublishModal, setShowShopifyPublishModal] =
    React.useState(false);
  const [designSelected, setDesignSelected] = React.useState<
    IDesignTemplate | undefined
  >();
  const designs = useAppSelector((state) =>
    getDesignTemplatesByPage(state, currentPage),
  );
  const totalDesigns = useAppSelector(selectTotalDesignTemplates);

  const selectedShop = useAppSelector(selectShop);
  if (!selectedShop) return <></>;
  const shopId = selectedShop?.id;

  const dispatch = useAppDispatch();

  const designTemplateStatus = useAppSelector((state) =>
    fetchStatusByPage(state, currentPage),
  );

  const integrationConnected = useAppSelector((state) =>
    selectIntegrationStatus(state),
  );

  const integrationFetchStatus = useAppSelector((state) =>
    selectIntegrationsFetchLoading(state),
  );

  React.useEffect(() => {
    if (integrationFetchStatus === ELoadingStates.IDLE) {
      dispatch(getShopifyIntegrationStatusAsync(shopId));
    }
  }, [integrationFetchStatus, dispatch]);

  const lookForDesigns = () => {
    if (selectedShop) {
      dispatch(
        getDesignTemplatesAsync({
          shopId: selectedShop.id,
          offset,
          page: currentPage,
          limit,
          sortBy,
          search,
        }),
      );
    }
  };

  React.useEffect(() => {
    handlePagination('reset');
    dispatch(resetDesignTemplatesPages());
  }, [queryParams.sortBy, queryParams.search]);

  React.useEffect(() => {
    if (designTemplateStatus === 'idle') {
      lookForDesigns();
    }
  }, [selectedShop, designTemplateStatus, offset, limit]);

  React.useEffect(() => {
    if (shopId) {
      lookForDesigns();
    }
  }, [shopId]);

  const handleAddToProductListing = (design: IDesignTemplate) => {
    setDesignSelected(design);
    setShowShopifyPublishModal(true);
  };

  const handleEditSlotControls = (designId: number) => {
    navigate(`controls/${designId}`, {
      state: {params: queryParams},
    });
  };

  const handleEditDesign = (designId: number) => {
    return navigate(`/designs/edit/${designId}`);
  };

  const contentLoaded = !['idle', 'loading'].includes(designTemplateStatus);

  return (
    <div id="DesignsList">
      {designSelected && (
        <ShopifyPublishModal
          show={showShopifyPublishModal}
          onCloseModal={() => setShowShopifyPublishModal(false)}
          design={designSelected}
        />
      )}
      {designTemplateStatus === 'error' && (
        <Alert variant="danger">
          There was an error loading your design templates. Please reload the
          page or contact support.
        </Alert>
      )}
      {contentLoaded && designs.length > 0 && (
        <>
          <div className="design-list-container">
            {designs.map((design) => {
              return (
                <div
                  className="design-item"
                  key={design.id}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleEditSlotControls(design.id);
                  }}
                >
                  {design.duplicated && (
                    <div className="badge-new-design">New Item</div>
                  )}
                  <div className="design-item-menu">
                    <div className="design-menu-icon">
                      <AddToProductListingItem
                        design={design}
                        handleAddToProductListing={handleAddToProductListing}
                        integrationConnected={integrationConnected}
                      />
                    </div>
                    <Dropdown
                      className="design-menu-icon"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <Dropdown.Toggle variant="outline-light">
                        <ThreeDots />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleEditDesign(design.id)}
                        >
                          <Icon name="editDesign" /> Edit design
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleEditSlotControls(design.id)}
                        >
                          <Icon name="templateConfiguration" /> Template
                          configuration
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setTemplateToCopy(design)}
                        >
                          <Icon name="duplicate2" /> Duplicate
                        </Dropdown.Item>
                        <DeleteTemplate
                          templateId={design.id}
                          updateTemplates={lookForDesigns}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <GFXImage
                    src={
                      design.startingSide === 'front'
                        ? design.proofFront + `?v=${Date.now()}`
                        : design.proofBack + `?v=${Date.now()}`
                    }
                    className="design-item-image"
                    placeholderSrc="/loading-render.gif"
                  />
                  <p className="design-item-name" title={design.name}>
                    {design.name}
                  </p>
                </div>
              );
            })}
          </div>
          <CustomPagination
            limit={limit}
            currentPage={currentPage}
            total={totalDesigns}
            handlePagination={handlePagination}
            entity="Design Templates"
          />
          <LoadingModal
            loading={duplicatingTemplate}
            showSuccess={false}
            title="Duplicating template"
            description="Please wait while we duplicate the template."
            onClose={() => setDuplicatingTemplate(false)}
          />
          <DuplicateNewProduct
            template={templateToCopy}
            visible={!!templateToCopy}
            onClose={() => setTemplateToCopy(null)}
          />
        </>
      )}
      {contentLoaded && designs.length === 0 && <NoResultView />}
      {!contentLoaded && (
        <Skeleton
          className="design-item skeleton"
          containerClassName="design-list-container"
          count={limit}
          inline
        />
      )}
    </div>
  );
};

export default DesignsList;
