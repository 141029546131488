import React from 'react';
import './Tabs.scss';

interface Tab {
  name: string;
  key: string;
  disabled?: boolean;
}

interface TabsProps {
  tabs: Tab[];
  onTabClick: (key: string) => void;
  initialTab?: string;
}

export const GFXTabs: React.FC<TabsProps> = (props) => {
  const initialTab = props.initialTab || 'slot-config';
  const [currentTab, handleTabChange] = React.useState<string>(initialTab);

  React.useEffect(() => {
    props.onTabClick(currentTab);
  }, [currentTab]);

  return (
    <div className="tabs">
      {props.tabs.map((tab, index) => {
        return (
          <div
            className={`tab ${currentTab === tab.key ? 'active' : ''}  ${
              tab.disabled ? 'disabled' : ''
            }`}
            key={index}
            onClick={!tab.disabled ? () => handleTabChange(tab.key) : undefined}
          >
            {tab.name}
          </div>
        );
      })}
    </div>
  );
};

export default GFXTabs;
