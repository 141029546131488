import {IWebhook} from '@gfxco/contracts';
import React from 'react';
import {Table} from 'react-bootstrap';
import WebhookOutlinedIcon from '@mui/icons-material/WebhookOutlined';

interface OptionalProps {
  className?: string;
  webhooks?: IWebhook[];
}

type WebhooksSectionProps = OptionalProps;

export const WebhooksSection: React.FC<WebhooksSectionProps> = (props) => (
  <div className={props.className ? props.className : ''}>
    <header>
      <WebhookOutlinedIcon />
      <h5>Webhooks</h5>
    </header>

    {props.webhooks && props.webhooks.length > 0 && (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Provider</th>
            <th>Name</th>
            <th>Format</th>
            <th>Created at</th>
            <th>Updated at</th>
          </tr>
        </thead>
        <tbody>
          {props.webhooks.map((webhook) => (
            <tr key={`${webhook.provider}-${webhook.id}`}>
              <td>{webhook.id}</td>
              <td>{webhook.provider}</td>
              <td>{webhook.name}</td>
              <td>{webhook.format}</td>
              <td>{new Date(webhook.created_at).toLocaleDateString()}</td>
              <td>{new Date(webhook.updated_at).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}

    {(!props.webhooks || props.webhooks.length === 0) && (
      <span>Nothing to show.</span>
    )}
  </div>
);

export default WebhooksSection;
