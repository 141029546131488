import React from 'react';
import {IColor} from '@gfxco/contracts';
import ColorItem from '../../ConfigureProductInfo/Items/ColorItem';

import './ItemColor.scss';

interface RequiredProps {
  color: IColor;
}

interface OptionalProps {}

type ItemColorProps = RequiredProps & OptionalProps;

const ItemColor: React.FC<ItemColorProps> = ({color}) => {
  return (
    <div id="ItemColor">
      <div className={`${color.name === 'white' ? 'border' : ''}`}>
        <ColorItem color={color} selected={false} onClick={() => {}} />
      </div>
      <div className="color-description">
        <span className="type-description">Item Color:</span>
        <span className="name-description">{color.name}</span>
      </div>
    </div>
  );
};

export default ItemColor;
