import {IDraftCanva, IDraftCanvaRequest} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import DraftCanvasApi from '../../api/draftCanvasApi';
import {RootState} from '../../app/store';

export interface DesignTemplateState {
  value: IDraftCanva[][];
  fetchStatus: string[];
  fetchDetailStatus: string;
  total: number;
}

const initialState: DesignTemplateState = {
  value: [],
  fetchStatus: [],
  fetchDetailStatus: '',
  total: 0,
};

export const getDraftCanvasAsync = createAsyncThunk(
  'draftCanvas/fetch',
  async (parameters: IDraftCanvaRequest) => {
    const response = await DraftCanvasApi.getDraftCanvas(parameters);
    // The value we return becomes the `fulfilled` action payload
    return {...response, ...parameters};
  },
);

export const getDraftCanvaAsync = createAsyncThunk(
  'draftCanvas/fetch_detail',
  async (draftCanvaId: number) => {
    const response = await DraftCanvasApi.getDraftCanvaById(draftCanvaId);

    // The value we return becomes the `fulfilled` action payload
    return {...response, draftCanvaId};
  },
);

export const loadDraftCanvas = createSlice({
  name: 'draftCanvas',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetDraftCanvas: (state) => {
      state.value = [];
      state.fetchStatus = [];
      state.total = 0;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getDraftCanvasAsync.pending, (state, action) => {
        state.fetchStatus[action.meta.arg.offset] = 'loading';
      })
      .addCase(getDraftCanvasAsync.fulfilled, (state, action) => {
        state.fetchStatus[action.payload.offset] = 'success';
        state.value[action.payload.offset] = action.payload.draftCanvas || [];
        state.total = action.payload.totalDraftCanvas || 0;
      })
      .addCase(getDraftCanvasAsync.rejected, (state, action) => {
        state.fetchStatus[action.meta.arg.offset] = 'error';
      })
      .addCase(getDraftCanvaAsync.fulfilled, (state, action) => {
        state.fetchDetailStatus = 'success';
        state.value[0] = state.value[0]
          ? [...state.value[0], action.payload]
          : [action.payload];
      });
  },
});

export const selectDraftCanvas = (state: RootState) => {
  return state.draftCanvas.value || [];
};

export const getDraftCanvaById = (state: RootState, id: number) => {
  for (const entitiesList of state.draftCanvas.value) {
    if (entitiesList) {
      for (const entity of entitiesList) {
        if (entity.id === id) {
          return entity;
        }
      }
    }
  }
};

export const selectTotalDraftCanvas = (state: RootState) => {
  return state.draftCanvas.total || 0;
};

export const selectDraftCanvasFetchStatus = (state: RootState) => {
  return state.draftCanvas.fetchStatus;
};

export const {resetDraftCanvas} = loadDraftCanvas.actions;

export default loadDraftCanvas.reducer;
