import React from 'react';
import {RouteProps, Outlet} from 'react-router-dom';
import {Dashboard} from '../../components/Dashboard';

const DashboardPage: React.FC<RouteProps> = () => {
  return (
    <>
      <Dashboard>
        <div className="content-container">
          <Outlet />
        </div>
      </Dashboard>
    </>
  );
};

export default DashboardPage;
