import React, {useState} from 'react';
import './DropdownButton.scss';
import DropdownList from './DropdownList';
import {IColor, IImage} from '@gfxco/contracts';
import {IList, ITagList} from '../types';

export interface RequiredProps {
  type: 'tag' | 'image' | 'color' | 'fillColor';
  items: IColor[] | ITagList[] | IImage[];
  icon: React.ReactNode;
  text: string;
  onClose: () => void;
  onClick: () => void;
  selected: boolean;
}
export interface OptionalProps {
  hoverText?: string;
  isMulti?: boolean;
  onSelect?: (item: IList) => void;
  onSelectMulti?: (item: IList[]) => void;
  disable?: boolean;
  selectedItems?: IList[];
  showHex?: boolean;
}

type DropdownButtonProps = RequiredProps & OptionalProps;

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  items,
  type,
  icon,
  text,
  hoverText,
  onSelect,
  onClose,
  onClick,
  selected,
  disable,
  onSelectMulti,
  selectedItems,
  isMulti = false,
  showHex = false,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div className="dropdown-button">
      <div className="item-container">
        <div
          id="Dropdown-option-tag"
          className={`option ${selected ? 'selected' : ''} ${
            disable ? 'option-disable' : ''
          }`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onClick}
        >
          <div>{icon}</div>
          <div>
            <span>{text}</span>
          </div>
        </div>
        {isHovered && !selected && hoverText && (
          <span className="hover-text">{hoverText}</span>
        )}

        {selected && (
          <DropdownList
            type={type}
            list={items}
            onSelect={onSelect}
            onClose={onClose}
            onSelectMulti={onSelectMulti}
            isMulti={isMulti}
            selectedList={selectedItems}
            showHex={showHex}
          />
        )}
      </div>
    </div>
  );
};

export default DropdownButton;
