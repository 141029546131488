import {
  IPrinterIntegrationRequest,
  IPrinterIntegrationResponse,
  IPrinterStatusResponse,
  IWebhookIntegrationRequest,
  IWebhookIntegrationResponse,
  IWebhookStatusResponse,
} from '@gfxco/contracts';
import {handleError, loadClient} from './api';

export const connectPrintful = async (params: IPrinterIntegrationRequest) => {
  const client = await loadClient();
  const response = await client.post<IPrinterIntegrationResponse>(
    `/printers/printful`,
    {
      ...params,
    },
  );
  return response.data;
};

export const getPrintfulIntegrationStatus = async (shopId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<IPrinterStatusResponse>(
      `/printers/printful`,
      {
        params: {
          shopId,
        },
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getFifthSunIntegrationStatus = async (shopId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<IPrinterStatusResponse>(
      `/printers/fifthSun`,
      {
        params: {
          shopId,
        },
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const connectFifthSun = async (params: IPrinterIntegrationRequest) => {
  const client = await loadClient();
  const response = await client.post<IPrinterIntegrationResponse>(
    `/printers/fifthSun`,
    {
      ...params,
    },
  );
  return response.data;
};

export const getSendArtsIntegrationStatus = async (shopId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<IWebhookStatusResponse>(
      `/printers/sendArts`,
      {
        params: {
          shopId,
        },
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const connectSendArts = async (params: IWebhookIntegrationRequest) => {
  const client = await loadClient();
  const response = await client.post<IWebhookIntegrationResponse>(
    `/printers/sendArts`,
    {
      ...params,
    },
  );
  return response.data;
};
