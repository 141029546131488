import React, {FormEvent} from 'react';
import {View} from '@aws-amplify/ui-react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PasswordField from '../../../components/PasswordField';
import {useAppDispatch} from '../../../app/hooks';
import {completeNewPassword, logoutAsync} from '../../../features/auth';
import {ICognitoUser} from '@gfxco/contracts';

import '@aws-amplify/ui-react/styles.css';
import './ChangePasswordForm.scss';

interface RequiredProps {
  username: string;
  user: ICognitoUser | undefined;
}

interface OptionalProps {}

type SignInFormProps = RequiredProps & OptionalProps;

const ChangePasswordForm: React.FC<SignInFormProps> = ({username, user}) => {
  const [requestStatus, setRequestStatus] = React.useState('');
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [formError, setFormError] = React.useState('');
  const dispatch = useAppDispatch();

  const isLoading = requestStatus === 'loading' || requestStatus === 'success';

  const handleCompleteNewPassword = async (event: FormEvent) => {
    setRequestStatus('loading');
    setPasswordError('');
    setConfirmPasswordError('');
    event.preventDefault();
    event.stopPropagation();
    const target = event.target as HTMLFormElement;
    const password = target.password.value;
    const confirmPassword = target.confirmPassword.value;

    let isFormErrored = false;

    if (!password) {
      setPasswordError('Please enter your password');
      isFormErrored = true;
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Please repeat your password');
      isFormErrored = true;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      isFormErrored = true;
    }

    if (isFormErrored) {
      setRequestStatus('error');
      return;
    }

    if (!user) {
      return;
    }

    try {
      await dispatch(
        completeNewPassword({
          user,
          password,
        }),
      ).unwrap();
    } catch (error: any) {
      setRequestStatus('error');
      setFormError(error.message);
      console.error('Error changing password', error);
    }

    try {
      await dispatch(logoutAsync()).unwrap();
    } catch (error: any) {
      setRequestStatus('error');
      setFormError(error.message);
      console.error('Error logging out', error);
    }
  };

  return (
    <div id="ChangePasswordForm">
      <View className="auth-wrapper">
        <h2 className="sign-in-header">Change your password</h2>
        <Form
          className="sign-in-form change-password-form"
          noValidate
          onSubmit={handleCompleteNewPassword}
        >
          <Form.Group controlId="formBasicPassword">
            <Form.Label>
              New password <span className="required-symbol">*</span>
            </Form.Label>
            <PasswordField name="password" />
            <span className="input-info">
              *<strong>Password requirements</strong>: Minimum length{' '}
              <strong>8 character(s)</strong>. Contains at least{' '}
              <strong>1 number</strong>. Contains at least{' '}
              <strong>1 special character</strong>. Contains at least{' '}
              <strong>1 uppercase letter</strong>. Contains at least{' '}
              <strong>1 lowercase letter</strong>.
            </span>
            {passwordError && (
              <span className="form-feedback-error">{passwordError}</span>
            )}
          </Form.Group>
          <Form.Group controlId="formConfirmPassword">
            <Form.Label>
              Repeat password <span className="required-symbol">*</span>
            </Form.Label>
            <PasswordField name="confirmPassword" />
            {confirmPasswordError && (
              <span className="form-feedback-error">
                {confirmPasswordError}
              </span>
            )}
          </Form.Group>
          {formError && (
            <span className="form-feedback-error">{formError}</span>
          )}
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Continue'}
          </Button>
        </Form>
      </View>
    </div>
  );
};

export default ChangePasswordForm;
