import {useState} from 'react';

const useSessionStorage = () => {
  const [storage, setStorage] = useState(sessionStorage);

  const setValue = (key: string, value: string) => {
    storage.setItem(key, value);
    setStorage(sessionStorage);
  };

  const removeValue = (key: string) => {
    storage.removeItem(key);
    setStorage(sessionStorage);
  };

  const getValue = (key: string) => {
    return storage.getItem(key);
  };

  return {setValue, removeValue, getValue};
};

export default useSessionStorage;
