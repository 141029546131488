import React from 'react';
import classnames from 'classnames';
import {ChevronUp, ChevronDown} from 'react-bootstrap-icons';

import Icon from '../../../components/Icons/Icon';

import './PrinterCollapsePanel.scss';

type PrinterCollapsePanelProps = {
  title: string | React.ReactNode;
  children: React.ReactNode;
  connected?: boolean;
  description?: string;
  open?: boolean;
};

const PrinterCollapsePanel: React.FC<PrinterCollapsePanelProps> = ({
  title,
  children,
  connected,
  description,
  open = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(open);

  const handleClickCollapse = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <section className="CollapsePanel">
      <header className="CollapsePanel__header">
        <div className="title">
          {typeof title === 'string' ? <h4>{title}</h4> : title}
        </div>
        {description && <p className="description">{description}</p>}
        <div className="CollapseStatus">
          <div
            className={classnames({
              connectedStatus: true,
              'connectedStatus--connected': connected,
            })}
          >
            <Icon
              name={connected ? 'checkIcon' : 'notConnected'}
              height={16}
              width={16}
            />
            <span>{connected ? 'Connected' : 'Not connected'}</span>
          </div>

          <button
            className="CollapseStatus__icon"
            onClick={handleClickCollapse}
          >
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </button>
        </div>
      </header>
      {isOpen && (
        <div
          className={classnames({
            CollapsePanel__content: true,
            'CollapsePanel__content--open': isOpen,
            'CollapsePanel__content--closed': !isOpen,
          })}
        >
          {children}
        </div>
      )}
    </section>
  );
};

export default PrinterCollapsePanel;
