import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import {ThreeDots} from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';

import {IDraftCanva} from '@gfxco/contracts';

import draftIcon from '../../../assets/icons/icon_draft.svg';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import usePagination from '../../../hooks/usePagination';
import useQueryParams from '../../../hooks/useQueryParams';
import {selectShop} from '../../../features/shops/selectedShop';
import GFXImage from '../../Image/Image';
import NoResultView from '../NoResultView';

import {
  getDraftCanvasAsync,
  selectDraftCanvas,
  selectTotalDraftCanvas,
  selectDraftCanvasFetchStatus,
  resetDraftCanvas,
} from '../../../features/draftCanvas/loadDraftCanvas';

import './DraftCanvasList.scss';
import CustomPagination from '../../CustomPagination';

interface RequiredProps {
  onEdit: (draftCanva: IDraftCanva) => void;
}

interface OptionalProps {}

type DraftCanvasListProps = RequiredProps & OptionalProps;

const DraftCanvasList: React.FC<DraftCanvasListProps> = ({onEdit}) => {
  const [{offset, limit, currentPage}, handlePagination] = usePagination();
  const [queryParams] = useQueryParams();
  const allDraftCanvas = useAppSelector(selectDraftCanvas);
  const draftCanvas = allDraftCanvas[offset] || [];
  const totalDrafts = useAppSelector(selectTotalDraftCanvas);
  const draftCanvasFetchStatus = useAppSelector(selectDraftCanvasFetchStatus);
  const selectedShop = useAppSelector(selectShop);
  const dispatch = useAppDispatch();

  const draftCanvaStatus = draftCanvasFetchStatus[offset] || 'idle';

  React.useEffect(() => {
    if (draftCanvaStatus === 'idle') {
      fetchDraftCanvas();
    }
  }, [selectedShop, draftCanvasFetchStatus, offset, limit]);

  React.useEffect(() => {
    handlePagination('reset');
    dispatch(resetDraftCanvas());
  }, [queryParams.sortBy, queryParams.search]);

  React.useEffect(() => {
    dispatch(resetDraftCanvas());
  }, [selectedShop]);

  const fetchDraftCanvas = () => {
    if (selectedShop) {
      dispatch(
        getDraftCanvasAsync({
          shopId: selectedShop.id,
          offset,
          limit,
          sortBy: queryParams.sortBy,
          search: queryParams.search,
        }),
      );
    }
  };

  const handleEditDraft = (id: number) => {
    const draftCanvaToEdit = draftCanvas.find((draft) => draft.id === id);
    if (draftCanvaToEdit) {
      onEdit(draftCanvaToEdit);
    }
  };

  const contentLoaded = !['idle', 'loading'].includes(draftCanvaStatus);

  return (
    <div id="DraftCanvasList">
      {draftCanvaStatus === 'error' && (
        <Alert variant="danger">
          There was an error loading your draft canvas. Please reload the page
          or contact support.
        </Alert>
      )}
      {contentLoaded && draftCanvas.length > 0 && (
        <>
          <div className="draft-canvas-container">
            {draftCanvas.map((design) => {
              const designId = design.id || 0;
              return (
                <div
                  className="draft-canva-item"
                  key={design.id}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleEditDraft(designId);
                  }}
                >
                  <Badge className="draft-budge">Draft</Badge>
                  <Dropdown
                    className="design-menu-icon"
                    onClick={(event) => {
                      handleEditDraft(designId);
                    }}
                  >
                    <Dropdown.Toggle variant="outline-light">
                      <ThreeDots />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleEditDraft(designId)}>
                        Continue Editing
                      </Dropdown.Item>
                      <Dropdown.Item href="#">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <GFXImage
                    src={draftIcon}
                    className="draft-canva-item-image"
                    placeholderSrc="/loading-render.gif"
                  />
                  <p className="draft-item-name" title={design.productName}>
                    {design.productName}
                  </p>
                </div>
              );
            })}
          </div>
          <CustomPagination
            limit={limit}
            currentPage={currentPage}
            total={totalDrafts}
            handlePagination={handlePagination}
            entity="Draft Canvas"
          />
        </>
      )}
      {contentLoaded && draftCanvas.length === 0 && <NoResultView />}
      {!contentLoaded && (
        <Skeleton
          className="draft-canva-item skeleton"
          containerClassName="draft-canvas-container"
          count={limit}
          inline
        />
      )}
    </div>
  );
};

export default DraftCanvasList;
