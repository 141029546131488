import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {Modal} from 'react-bootstrap';
import {
  ELoadingStates,
  IShopifyIntegrationFormValues,
  IShopifyIntegrationResponse,
} from '@gfxco/contracts';

import ShopifyIntegrationForm from '../../../../components/ShopifyIntegrationForm';
import GFXButton from '../../../../components/Button';
import {connectShopify} from '../../../../api';
import {useAppDispatch, useAppSelector} from '../../../../app/hooks';
import {selectShop} from '../../../../features/shops/selectedShop';
import {
  getShopifyIntegrationStatusAsync,
  selectIntegrationStatus,
  selectIntegrationsFetchLoading,
  selectIntegrationsParameters,
} from '../../../../features/integrations/loadShopifyIntegration';

import './ShopifyIntegrationSection.scss';

const {REACT_APP_HELP_CENTER_URL: HelpCenterURL = ''} = process.env;

interface OptionalProps {
  className?: string;
}

type Props = OptionalProps;

const ShopifyIntegrationSection: React.FC<Props> = (props) => {
  const shopSelected = useAppSelector(selectShop);

  if (!shopSelected) {
    return <></>;
  }

  const [error, setError] = React.useState({show: false, message: ''});
  const dispatch = useAppDispatch();

  const shopId = shopSelected?.id;

  const integrationStatus = useAppSelector(selectIntegrationStatus);

  const integrationFetchStatus = useAppSelector(selectIntegrationsFetchLoading);

  const integrationParameters = useAppSelector(selectIntegrationsParameters);

  React.useEffect(() => {
    if (integrationFetchStatus === ELoadingStates.IDLE) {
      dispatch(getShopifyIntegrationStatusAsync(shopId));
    }
  }, [integrationFetchStatus, dispatch, shopId]);

  if (
    [ELoadingStates.IDLE, ELoadingStates.LOADING].includes(
      integrationFetchStatus,
    )
  ) {
    return (
      <Skeleton
        className="integration-loading"
        containerClassName="integration-loading-container"
        count={1}
        inline
      />
    );
  }

  const connected =
    integrationStatus && integrationFetchStatus === ELoadingStates.LOADED;

  function isInstanceOfIShopifyIntegrationResponse(
    obj: any,
  ): obj is IShopifyIntegrationResponse {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      'error' in obj &&
      typeof obj.error === 'boolean' &&
      'message' in obj &&
      typeof obj.message === 'string'
    );
  }

  const handleConnect = async (params: IShopifyIntegrationFormValues) => {
    try {
      const response = await connectShopify({shopId, ...params});
      if (isInstanceOfIShopifyIntegrationResponse(response) && response.error) {
        setError({show: true, message: response.message});
        return;
      }
      dispatch(getShopifyIntegrationStatusAsync(shopId));
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Unexpected error, contact support';

      setError({show: true, message: errorMessage});
    }
  };

  const handleClose = () => {
    setError({show: false, message: ''});
  };

  return (
    <div className={`shopify-integration-section ${props.className}`}>
      <Modal
        id="shopify-integration-error"
        show={error.show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3>Oops!</h3>
          <p>
            It appears there may be an issue with your Shopify access token
            configuration. The problem could be related to the domain, the API
            key, the API secret key, or the Admin API access token. Please,
            review your Shopify configuration and try again.
          </p>
          <code>{error.message}</code>
        </Modal.Body>
        <Modal.Footer>
          <GFXButton variant="link" target="_blank" href={HelpCenterURL}>
            Learn more
          </GFXButton>
          <GFXButton variant="link" onClick={handleClose}>
            Got it!
          </GFXButton>
        </Modal.Footer>
      </Modal>

      <ShopifyIntegrationForm
        onConnect={handleConnect}
        connected={connected}
        {...integrationParameters}
      />
    </div>
  );
};

export default ShopifyIntegrationSection;
