import React from 'react';
import {useNavigate, RouteProps, Outlet} from 'react-router-dom';
import {useAppSelector} from './app/hooks';
import {selectShopConfigs} from './features/shops/selectedShop';

const SubscriptionRoute: React.FC<RouteProps> = () => {
  const shopConfigs = useAppSelector(selectShopConfigs);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (
      shopConfigs &&
      (['', undefined, null].includes(shopConfigs.stripeSubscriptionToken) ||
        shopConfigs.stripeSubscriptionStatus !== 'active') &&
      (['', undefined, null].includes(shopConfigs.shopifySubscriptionId) ||
        !shopConfigs.shopifySubscriptionStatus)
    ) {
      navigate('/');
    }
  }, [navigate, shopConfigs]);

  return <Outlet />;
};

export default SubscriptionRoute;
