import {
  IUpdateShopParams,
  IInstallationConfigs,
  ELoadingStates,
} from '@gfxco/contracts';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getGeneralShopConfigs} from '../../api';
import {RootState} from '../../app/store';

export interface ShopState {
  value?: IInstallationConfigs;
  fetchStatus: ELoadingStates;
}

const initialState: ShopState = {
  value: {
    ecommerceSync: false,
    printerSync: false,
    totalTemplates: 0,
    totalPublished: 0,
    extensionInstalled: false,
    imagesCreated: false,
  },
  fetchStatus: ELoadingStates.IDLE,
};

export const getGeneralShopConfigsAsync = createAsyncThunk(
  'shops/installation-fetch',
  async (parameters: IUpdateShopParams) => {
    const response = await getGeneralShopConfigs(parameters);
    return response;
  },
);

export const loadInstallationConfigs = createSlice({
  name: 'installation-configs',
  initialState,
  reducers: {
    updateField: (
      state,
      action: PayloadAction<{
        field: keyof IInstallationConfigs;
        value: boolean | number;
      }>,
    ) => {
      if (action.payload.value === undefined || !state.value) return;

      state.value = {
        ...state.value,
        [action.payload.field]: action.payload.value,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralShopConfigsAsync.pending, (state) => {
        state.fetchStatus = ELoadingStates.LOADING;
      })
      .addCase(getGeneralShopConfigsAsync.fulfilled, (state, action) => {
        state.fetchStatus = ELoadingStates.LOADED;
        state.value = action.payload;
      })
      .addCase(getGeneralShopConfigsAsync.rejected, (state) => {
        state.fetchStatus = ELoadingStates.FAILED;
      });
  },
});

export const selectGeneralConfigsShops = (state: RootState) =>
  state.installationConfigs.value;
export const selectGeneralConfigsFetchLoading = (state: RootState) =>
  state.installationConfigs.fetchStatus;

export default loadInstallationConfigs.reducer;
