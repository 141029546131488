import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';

import {AUTH_SESSION_STATUSES, SESSION_STORAGE_KEYS} from '../../constants';
import {getAuthStatus, logoutAsync} from '../../features/auth';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import useSessionStorage from '../../hooks/useSessionStorage';

import './LogoutPage.scss';

const LogoutPage = () => {
  const navigate = useNavigate();
  const {authStatus} = useAuthenticator((context) => [context.authStatus]);
  const {removeValue} = useSessionStorage();
  const authSession = useAppSelector(getAuthStatus);
  const isAuthenticated = authStatus === 'authenticated';
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    removeValue(SESSION_STORAGE_KEYS.SELECTED_SHOP);
    dispatch(logoutAsync());
  }, []);

  React.useEffect(() => {
    if (!isAuthenticated && authSession === AUTH_SESSION_STATUSES.LOGOUT) {
      navigate(
        {
          pathname: `/login`,
          search: location.search,
        },
        {
          replace: true,
        },
      );
    }
  }, [isAuthenticated, navigate, authSession]);

  return <div id="LogoutPage">Logging out...</div>;
};

export default LogoutPage;
