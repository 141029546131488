import {handleError, loadPaymentServiceClient} from './api';
import {
  GetSubscriptionResponse,
  CustomerResponse,
  PaginationFilters,
  GetInvoicesResponse,
} from '@gfxco/contracts';

const getInvoices = async (params: PaginationFilters) => {
  const {sortBy, offset, limit} = params;
  try {
    const client = await loadPaymentServiceClient();
    const response = await client.get<GetInvoicesResponse>(
      `/invoices/?sortBy=${sortBy}&offset=${offset}&limit=${limit}`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getSubscription = async (shopId: number) => {
  try {
    const client = await loadPaymentServiceClient();
    const response = await client.get<GetSubscriptionResponse>(
      `/subscriptions/${shopId}`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const createCustomer = async (shopId: number, email: string, name: string) => {
  try {
    const client = await loadPaymentServiceClient();
    const response = await client.post<CustomerResponse>('/customers', {
      shopId,
      email,
      name,
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getServices = async () => {
  try {
    const client = await loadPaymentServiceClient();
    const response = await client.get('/services');

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const cancelSubscription = async (shopId: number) => {
  try {
    const client = await loadPaymentServiceClient();
    const response = await client.delete(`/subscriptions/${shopId}`);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const createSubscription = async (
  paymentMethodId: string,
  customerId: string,
  prices: string[],
) => {
  try {
    const client = await loadPaymentServiceClient();
    const response = await client.post(`/subscriptions`, {
      paymentMethodId,
      customerId,
      prices,
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export default {
  getInvoices,
  getSubscription,
  createCustomer,
  getServices,
  cancelSubscription,
  createSubscription,
};
