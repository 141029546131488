import React from 'react';
import {useNavigate} from 'react-router-dom';
import {IBreadCrumbItem} from './types';
import {ChevronLeft} from 'react-bootstrap-icons';

import './CollectionBreadcrumb.scss';

interface RequiredProps {
  items: IBreadCrumbItem[];
}

interface OptionalProps {
  isLink?: boolean;
}

type CollectionBreadcrumbProps = RequiredProps & OptionalProps;

const CollectionBreadcrumb: React.FC<CollectionBreadcrumbProps> = ({
  items,
  isLink = false,
}) => {
  const navigate = useNavigate();

  const handleNavigation = (navigation?: string, onAction?: () => void) => {
    if (navigation) navigate(navigation);
    if (onAction) onAction();
  };

  return (
    <ul id="CollectionBreadcrumb">
      {items.map((item, index) => (
        <li
          key={`breadcrumb-${index}`}
          onClick={() => handleNavigation(item.navigation, item.onClick)}
        >
          <div className="breadcrumb-item">
            {item.icon || <></>}
            <a
              className={`breadcrumb-link ${
                isLink ? 'breadcrumb-link-title' : ''
              }`}
            >
              {isLink && index === 0 && items.length > 1 ? '' : item.name}
            </a>
            {items.length !== index + 1 && (
              <ChevronLeft className="breadcrumb-separator" />
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default CollectionBreadcrumb;
