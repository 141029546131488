import {
  IModerationOrder,
  IModerationOrdersRequest,
  ELoadingStates,
  IPutUpdateItemRequest,
  IRenderFilesRequest,
  IPutBulkUpdateItemRequest,
  IPutBulkAllUpdateItemRequest,
} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  getModerationOrders,
  putUpdateItem,
  renderfiles,
  putBulkUpdateItem,
  putBulkAllUpdateItem,
} from '../../api';
import {RootState} from '../../app/store';

export interface IModerationOrderExtended extends IModerationOrder {
  selected?: boolean;
}

export interface ModerationOrdersState {
  entities: IModerationOrderExtended[];
  fetchStatus: ELoadingStates;
  renderFetchStatus: ELoadingStates;
  updateFetchStatus: ELoadingStates;
  bulkUpdateFetchStatus: ELoadingStates;
  entitiesLoading: number[];
  total: number;
  allSelected: boolean;
}

const initialState: ModerationOrdersState = {
  entities: [],
  fetchStatus: ELoadingStates.IDLE,
  renderFetchStatus: ELoadingStates.IDLE,
  updateFetchStatus: ELoadingStates.IDLE,
  bulkUpdateFetchStatus: ELoadingStates.IDLE,
  entitiesLoading: [],
  total: 0,
  allSelected: false,
};
export const getModerationOrdersAsync = createAsyncThunk(
  'moderationOrders/fetch',
  async (parameters: IModerationOrdersRequest) => {
    const filters = {
      status: parameters.status,
      orderBy: parameters.orderBy,
      orderId: parameters.orderId,
      shopId: parameters.shopId,
      date: parameters.date,
      review: parameters.review,
      templateId: parameters.templateId,
      productColor: parameters.productColor,
      productType: parameters.productType,
      cursor: parameters.cursor,
      pageSize: parameters.pageSize,
    };

    const response = await getModerationOrders(filters);
    return response;
  },
  {
    condition(arg, thunkAPI) {
      const fetchStatus = selectModerationOrdersFetchLoading(
        thunkAPI.getState() as RootState,
      );
      if (fetchStatus === ELoadingStates.LOADING) {
        return false;
      }
    },
  },
);

export const updateItemAsync = createAsyncThunk(
  'moderationOrders/updateItem',
  async (parameters: IPutUpdateItemRequest & {shopId: number}) => {
    const body: IPutUpdateItemRequest = {
      itemId: parameters.itemId,
      status: parameters.status,
      details: parameters.details,
      isComment: parameters.isComment,
    };
    const response = await putUpdateItem(body);
    return response;
  },
);

export const renderFilesAsync = createAsyncThunk(
  'moderationOrders/renderFiles',
  async (parameters: IRenderFilesRequest) => {
    const response = await renderfiles(parameters);
    return response;
  },
);

export const updateBulkItemsAsync = createAsyncThunk(
  'moderationOrders/updateBulkItems',
  async (
    parameters: IPutBulkUpdateItemRequest | IPutBulkAllUpdateItemRequest,
  ) => {
    if ((parameters as IPutBulkUpdateItemRequest).itemIds) {
      return await putBulkUpdateItem(parameters as IPutBulkUpdateItemRequest);
    }

    return await putBulkAllUpdateItem(
      parameters as IPutBulkAllUpdateItemRequest,
    );
  },
);

export const loadModerationOrders = createSlice({
  name: 'moderationOrders',
  initialState,
  reducers: {
    resetModerationOrders: (state) => {
      state.entities = initialState.entities;
      state.fetchStatus = initialState.fetchStatus;
      state.total = initialState.total;
    },
    updateSelectOrder: (state, action) => {
      const {itemId, selected} = action.payload;
      state.entities = state.entities.map((e) => {
        if (e.itemId === itemId) {
          return {...e, selected};
        }
        return e;
      });
      state.allSelected =
        state.entities.every((e) => e.selected) &&
        state.entities.length === state.total;
    },
    updateSelectAllOrders: (state, action) => {
      const {selected} = action.payload;
      state.entities = state.entities.map((e) => {
        return {...e, selected};
      });
      state.allSelected = selected;
    },
    resetBulkUpdateFetchStatus: (state) => {
      state.bulkUpdateFetchStatus = ELoadingStates.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModerationOrdersAsync.pending, (state) => {
        state.fetchStatus = ELoadingStates.LOADING;
      })
      .addCase(getModerationOrdersAsync.fulfilled, (state, action) => {
        state.fetchStatus = ELoadingStates.LOADED;
        if (action.payload) {
          state.entities = [
            ...state.entities,
            ...action.payload.results.map((e) => ({
              ...e,
              selected: state.allSelected,
            })),
          ];
          state.total = action.payload.total;
        }
      })
      .addCase(getModerationOrdersAsync.rejected, (state) => {
        state.fetchStatus = ELoadingStates.FAILED;
      })
      .addCase(updateItemAsync.pending, (state, action) => {
        const {meta} = action;
        const {arg} = meta;
        const {itemId} = arg;
        state.updateFetchStatus = ELoadingStates.LOADING;
        state.entitiesLoading.push(itemId);
      })
      .addCase(updateItemAsync.fulfilled, (state, action) => {
        const {meta} = action;
        const {arg} = meta;
        const {itemId, status} = arg;
        const entitiesUpdated = state.entities.map((e) => {
          if (e.itemId === itemId) {
            return {...e, moderationStatus: status};
          }
          return e;
        });

        state.entities = entitiesUpdated;
        state.updateFetchStatus = ELoadingStates.LOADED;
        state.entitiesLoading = state.entitiesLoading.filter(
          (e) => e !== itemId,
        );
      })
      .addCase(renderFilesAsync.pending, (state, action) => {
        const {meta} = action;
        const {arg} = meta;
        const {itemId} = arg;
        state.renderFetchStatus = ELoadingStates.LOADING;
        state.entitiesLoading.push(itemId);
      })
      .addCase(renderFilesAsync.rejected, (state, action) => {
        const {meta} = action;
        const {arg} = meta;
        const {itemId} = arg;
        state.renderFetchStatus = ELoadingStates.FAILED;
        state.entitiesLoading = state.entitiesLoading.filter(
          (e) => e !== itemId,
        );
      })
      .addCase(renderFilesAsync.fulfilled, (state, action) => {
        const {meta, payload} = action;
        const {arg} = meta;
        const {itemId} = arg;
        const entitiesUpdated = state.entities.map((e) => {
          if (e.itemId === itemId) {
            return {
              ...e,
              artBack: payload?.art_back || e.artBack,
              artFront: payload?.art_front || e.artFront,
              proofBack: payload?.proof_back || e.proofBack,
              proofFront: payload?.proof_front || e.proofFront,
            };
          }
          return e;
        });
        state.renderFetchStatus = ELoadingStates.LOADED;
        state.entities = entitiesUpdated;
        state.entitiesLoading = state.entitiesLoading.filter(
          (e) => e !== itemId,
        );
      })
      .addCase(updateBulkItemsAsync.pending, (state) => {
        state.bulkUpdateFetchStatus = ELoadingStates.LOADING;
      })
      .addCase(updateBulkItemsAsync.fulfilled, (state) => {
        state.bulkUpdateFetchStatus = ELoadingStates.LOADED;
      })
      .addCase(updateBulkItemsAsync.rejected, (state) => {
        state.bulkUpdateFetchStatus = ELoadingStates.FAILED;
      });
  },
});

export const selectModerationOrders = (state: RootState) => {
  if (!state.moderationOrders || !state.moderationOrders.entities) {
    return [];
  }

  return state.moderationOrders.entities;
};

export const selectModerationOrdersSelected = (state: RootState) => {
  if (!state.moderationOrders || !state.moderationOrders.entities) {
    return [];
  }

  return state.moderationOrders.entities.filter((e) => e.selected);
};

export const selectTotalModerationOrders = (state: RootState) => {
  if (!state.moderationOrders) {
    return 0;
  }
  return state.moderationOrders.total;
};

export const selectModerationOrdersFetchLoading = (state: RootState) => {
  if (!state.moderationOrders || !state.moderationOrders.fetchStatus) {
    return ELoadingStates.IDLE;
  }
  return state.moderationOrders.fetchStatus;
};

export const selectRenderFilesFetchLoading = (state: RootState) => {
  if (!state.moderationOrders || !state.moderationOrders.renderFetchStatus) {
    return ELoadingStates.IDLE;
  }
  return state.moderationOrders.renderFetchStatus;
};

export const selectUpdateFetchStatus = (state: RootState) => {
  if (!state.moderationOrders || !state.moderationOrders.updateFetchStatus) {
    return ELoadingStates.IDLE;
  }
  return state.moderationOrders.updateFetchStatus;
};

export const selectBulkUpdateFetchStatus = (state: RootState) => {
  if (
    !state.moderationOrders ||
    !state.moderationOrders.bulkUpdateFetchStatus
  ) {
    return ELoadingStates.IDLE;
  }
  return state.moderationOrders.bulkUpdateFetchStatus;
};

export const selectEntitiesLoading = (state: RootState) => {
  if (!state.moderationOrders || !state.moderationOrders.entitiesLoading) {
    return [];
  }
  return state.moderationOrders.entitiesLoading;
};

export const selectAllSelected = (state: RootState) => {
  if (!state.moderationOrders || !state.moderationOrders.allSelected) {
    return false;
  }
  return state.moderationOrders.allSelected;
};

export const {
  resetModerationOrders,
  updateSelectOrder,
  updateSelectAllOrders,
  resetBulkUpdateFetchStatus,
} = loadModerationOrders.actions;

export default loadModerationOrders.reducer;
