import {
  DeveloperOptionsResponse,
  UpdateOrderPipelineRequest,
} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getShopDeveloperOptions, updateOrderPipeline} from '../../api';
import {RootState} from '../../app/store';

export interface DeveloperOptionsState {
  [shopId: number]: {
    value?: DeveloperOptionsResponse;
    status: 'idle' | 'loading' | 'failed' | 'loaded';
  };
}

const initialState: DeveloperOptionsState = {};

export const getDeveloperOptionsAsync = createAsyncThunk(
  'developerOptions/fetch',
  async (shopId: number) => {
    const response = await getShopDeveloperOptions(shopId);
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const updateOrderPipelineAsync = createAsyncThunk(
  'orderPipeline/update',
  async (params: UpdateOrderPipelineRequest) => {
    await updateOrderPipeline(params);
  },
);

export const loadShopWebhooks = createSlice({
  name: 'developerOptions',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getDeveloperOptionsAsync.pending, (state, action) => {
        const {meta} = action;
        const {arg: shopId} = meta;
        if (shopId) {
          state[shopId] = {
            value: undefined,
            status: 'loading',
          };
        }
      })
      .addCase(getDeveloperOptionsAsync.fulfilled, (state, action) => {
        const {meta} = action;
        const {arg: shopId} = meta;
        if (shopId && action.payload) {
          state[shopId] = {
            value: action.payload,
            status: 'loaded',
          };
        }
      })
      .addCase(getDeveloperOptionsAsync.rejected, (state, action) => {
        const {meta} = action;
        const {arg: shopId} = meta;
        if (shopId) {
          state[shopId] = {
            value: undefined,
            status: 'failed',
          };
        }
      })
      .addCase(updateOrderPipelineAsync.fulfilled, (state, action) => {
        const {meta} = action;
        const {arg} = meta;
        const {shopId} = arg;
        if (shopId) {
          state[shopId] = {
            value: undefined,
            status: 'idle',
          };
        }
      });
  },
});

export const selectWebhooks = (state: RootState, shopId?: number) => {
  if (!shopId || !state.developerOptions[shopId]) {
    return [];
  }
  return state.developerOptions[shopId].value?.webhooks;
};

export const selectOrderPipelineV2Enabled = (
  state: RootState,
  shopId?: number,
) => {
  if (!shopId || !state.developerOptions[shopId]) {
    return [];
  }
  return state.developerOptions[shopId].value?.orderPipelineV2Enabled;
};

export const selectDeveloperOptionsFetchLoading = (
  state: RootState,
  shopId?: number,
) => {
  if (!shopId || !state.developerOptions[shopId]) {
    return 'idle';
  }
  return state.developerOptions[shopId].status;
};

export default loadShopWebhooks.reducer;
