import {ELoadingStates, IImage} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getStickerImages} from '../../api';
import {RootState} from '../../app/store';

export interface StickerImagesState {
  entities: IImage[];
  fetchStatus: ELoadingStates;
  total: number;
}

const initialState: StickerImagesState = {
  entities: [],
  fetchStatus: ELoadingStates.IDLE,
  total: 0,
};
export const getStickerImagesAsync = createAsyncThunk(
  'stickersImages/fetch',
  async (parameters: {templateId: number}) => {
    const filters = {
      templateId: parameters.templateId,
    };

    const response = await getStickerImages(filters);
    return response;
  },
);

export const loadStickerImages = createSlice({
  name: 'stickerImages',
  initialState,
  reducers: {
    resetStickerImages: (state) => {
      state.entities = initialState.entities;
      state.fetchStatus = initialState.fetchStatus;
      state.total = initialState.total;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStickerImagesAsync.pending, (state) => {
        state.fetchStatus = ELoadingStates.LOADING;
      })
      .addCase(getStickerImagesAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.entities = [...action.payload.results];

          state.fetchStatus = ELoadingStates.LOADED;

          state.total = +action.payload.total;
        }
      })
      .addCase(getStickerImagesAsync.rejected, (state) => {
        state.fetchStatus = ELoadingStates.FAILED;
      });
  },
});

export const selectStickerImages = (state: RootState) => {
  if (!state.stickerImages || !state.stickerImages.entities) {
    return [];
  }

  return state.stickerImages.entities;
};

export const selectTotalStickerImages = (state: RootState) => {
  if (!state.stickerImages) {
    return 0;
  }
  return state.stickerImages.total;
};

export const selectStickerImagesFetchLoading = (state: RootState) => {
  if (!state.stickerImages || !state.stickerImages.fetchStatus) {
    return ELoadingStates.IDLE;
  }
  return state.stickerImages.fetchStatus;
};

export const {resetStickerImages} = loadStickerImages.actions;

export default loadStickerImages.reducer;
