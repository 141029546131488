import React from 'react';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '../../app/hooks';
import useQueryParams from '../../hooks/useQueryParams';
import {getEntityById} from '../../features/collections/loadCollections';
import {selectCollections} from '../../features/collections/loadCollectionsDetails';
import {selectShop} from '../../features/shops/selectedShop';
import ViewSubcollection from './ViewSubcollection';
import {ICollectionEntity} from '@gfxco/contracts';

interface ICollectionPageProps {
  handleOnDeleteCollection: (id: number) => void;
  handleOnDeleteImage: (id: number) => void;
}

const SubcollectionPage: React.FC<ICollectionPageProps> = (props) => {
  const {collection: name, subcollection} = useParams();

  const findSubCollectionByName = (subcollections: ICollectionEntity[]) => {
    const subCategory = subcollections?.find(
      (item) => item.name === subcollection,
    );
    return subCategory?.id;
  };

  const [queryParams] = useQueryParams();
  const id = queryParams.collectionId;

  if (!id) return <></>;

  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;
  const collection = useAppSelector((state) => getEntityById(state, id));

  let subCollectionId;
  if (collection && collection.subCollections) {
    subCollectionId = findSubCollectionByName(collection.subCollections);
  }

  if (!subCollectionId) {
    const collections = useAppSelector(selectCollections);
    const collection = collections.find((collection) => collection.id === +id);

    if (collection && collection.subCollections) {
      subCollectionId = findSubCollectionByName(collection.subCollections);
    }
  }

  if (name && subcollection)
    return (
      <ViewSubcollection
        onDeleteCollection={props.handleOnDeleteCollection}
        onDeleteImage={props.handleOnDeleteImage}
        collectionName={name}
        name={subcollection}
        shopId={shopId}
        id={subCollectionId}
        collectionId={id}
      />
    );

  return <></>;
};

export default SubcollectionPage;
