"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.constants = exports.ELoadingStates = void 0;
__exportStar(require("./DatabaseModel"), exports);
__exportStar(require("./ModerationOrdersContract"), exports);
__exportStar(require("./ShopsContract"), exports);
__exportStar(require("./FontsContract"), exports);
__exportStar(require("./DesignEditLinkContract"), exports);
__exportStar(require("./SamplePortalContract"), exports);
__exportStar(require("./PrintersContract"), exports);
__exportStar(require("./DeveloperOptionsContract"), exports);
__exportStar(require("./ProductTypeContracts"), exports);
__exportStar(require("./DesignFlowContract"), exports);
__exportStar(require("./TemplateBuilderContract"), exports);
__exportStar(require("./DesignObj"), exports);
__exportStar(require("./ManagementOrdersContract"), exports);
__exportStar(require("./ImageContract"), exports);
__exportStar(require("./UploadContract"), exports);
__exportStar(require("./TemplateContract"), exports);
__exportStar(require("./DraftCanvas"), exports);
__exportStar(require("./GetTemplateContract"), exports);
__exportStar(require("./TemplateExceptionsContract"), exports);
__exportStar(require("./LinkedSlotsContract"), exports);
__exportStar(require("./LegacyContract"), exports);
__exportStar(require("./SlotsContract"), exports);
__exportStar(require("./GetInvoiceContract"), exports);
__exportStar(require("./Shared"), exports);
__exportStar(require("./DraftCanvas"), exports);
__exportStar(require("./ShopConfigsContract"), exports);
__exportStar(require("./Subscriptions"), exports);
__exportStar(require("./CustomerContract"), exports);
__exportStar(require("./ServicesContract"), exports);
__exportStar(require("./TemplateImagesContract"), exports);
__exportStar(require("./ProductListingContract"), exports);
__exportStar(require("./IntegrationContract"), exports);
__exportStar(require("./CollectionContract"), exports);
__exportStar(require("./RenderFilesContract"), exports);
__exportStar(require("./CognitoContract"), exports);
__exportStar(require("./LabelContract"), exports);
__exportStar(require("./UserContract"), exports);
__exportStar(require("./StoreConnection"), exports);
__exportStar(require("./WalkthroughContract"), exports);
__exportStar(require("./CustomErrorContract"), exports);
__exportStar(require("./OrderItemHistory"), exports);
var ELoadingStates;
(function (ELoadingStates) {
    ELoadingStates["IDLE"] = "idle";
    ELoadingStates["LOADING"] = "loading";
    ELoadingStates["FAILED"] = "failed";
    ELoadingStates["LOADED"] = "loaded";
})(ELoadingStates = exports.ELoadingStates || (exports.ELoadingStates = {}));
exports.constants = {
    USER_PERMISSIONS: {
        PRODUCTS: 'PRODUCTS',
        ORDERS: 'ORDERS',
        SAMPLES: 'SAMPLES',
        MYSTORE: 'MYSTORE',
        PRINTERS: 'PRINTERS',
        SETTINGS: 'SETTINGS',
        ALL: '*',
        DEVELOPER: 'DEVELOPER',
    },
    BUILDER: {
        DUPLICATED_IMAGE: 'DUPLICATED_IMAGE',
    },
};
