import React from 'react';
import Select, {
  ControlProps,
  OptionProps,
  SingleValueProps,
  components,
  StylesConfig,
} from 'react-select';

import {ReactComponent as CheckIconBlue} from '../../assets/icons/check-fill-blue-icon.svg';

interface IStoreOption {
  label: string;
  value: number;
  isSelectedStore: boolean;
}

interface GFXStoreSelectProps {
  options: IStoreOption[];
  value: IStoreOption;
  onChange?: (value: IStoreOption) => void;
  isDisabled?: boolean;
}

const GFXStoreSelect: React.FC<GFXStoreSelectProps> = ({
  options,
  value,
  onChange,
  isDisabled = false,
}) => {
  const customStyles: StylesConfig<IStoreOption> = {
    control: (provided) => ({
      ...provided,
      color: '#545454',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      lineHeight: 'normal',
      fontWeight: 400,
      fontFamily: 'Aller Light',
      paddingLeft: '0.5rem',
      borderColor: 'transparent',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#4573BB',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      lineHeight: 'normal',
      fontWeight: 400,
      fontFamily: 'Aller Light',
      paddingLeft: '0.5rem',
    }),
    container: (provided, {isDisabled}) => ({
      ...provided,
      border: isDisabled ? '1px solid #F0EBEB' : '1px solid #C4D6F7',
      backgroundColor: '#FFF',
      borderRadius: '0.25rem',
    }),
    option: (provided, {isSelected}) => ({
      ...provided,
      backgroundColor: isSelected ? '#EBF4FC' : provided.backgroundColor,
      color: isSelected ? '#4573BB' : '#545454',
    }),
  };

  const Control = ({children, ...props}: ControlProps<IStoreOption, false>) => {
    return (
      <components.Control {...props}>
        {props.selectProps.value && <span>Selected Store:</span>}
        {children}
      </components.Control>
    );
  };

  const Option = ({children, ...props}: OptionProps<IStoreOption, false>) => {
    return (
      <components.Option {...props}>
        <div style={{display: 'flex'}}>
          {props.data.isSelectedStore && (
            <CheckIconBlue
              style={{
                marginRight: '0.5rem',
                width: '0.75rem',
                height: '0.75rem',
                display: 'flex',
                alignSelf: 'center',
              }}
            />
          )}
          {children}
        </div>
      </components.Option>
    );
  };

  const SingleValue = ({
    children,
    ...props
  }: SingleValueProps<IStoreOption, false>) => {
    return (
      <components.SingleValue {...props}>
        <div style={{display: 'flex'}}>
          {props.data.isSelectedStore && (
            <CheckIconBlue
              style={{
                marginRight: '0.5rem',
                width: '0.75rem',
                height: '0.75rem',
                display: 'flex',
                alignSelf: 'center',
              }}
            />
          )}
          {children}
        </div>
      </components.SingleValue>
    );
  };

  return (
    <Select
      options={options}
      components={{Control, Option, SingleValue}}
      isSearchable={false}
      styles={customStyles}
      value={value}
      placeholder="Select a store to import"
      isDisabled={isDisabled}
      onChange={(selectedOption) => {
        onChange && onChange(selectedOption as IStoreOption);
      }}
    />
  );
};

export default GFXStoreSelect;
