import * as Contracts from '@gfxco/contracts';
import {loadClient, handleError} from './api';

const addImage = async (body: Contracts.IAddImagesRequest) => {
  try {
    const client = await loadClient();
    const bodyParams = {
      ...body,
      size: body.size / 1024, // file size in KB
    };
    const response = await client.post<Contracts.IImage>(`/images`, bodyParams);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getImages = async (params: Contracts.IImageRequest) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.IImagesResponse>(`images`, {
      params,
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getTemplateImagesTags = async (params: {
  templateId: number;
  searchBy?: string;
}) => {
  try {
    const {templateId, searchBy} = params;
    const client = await loadClient();
    const response = await client.get<{
      results: {tag: string}[];
      total: number;
    }>(`templates/${templateId}/tags`, {
      params: {searchBy},
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateImage = async (body: Contracts.IImage) => {
  try {
    const {id, ...bodyParams} = body;
    const client = await loadClient();
    const response = await client.put<Contracts.IImage>(
      `/images/${id}`,
      bodyParams,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const deleteImage = async (id: number) => {
  try {
    const client = await loadClient();
    const response = await client.delete<Contracts.IDeleteCollectionResponse>(
      `/images/${id}`,
    );

    return response;
  } catch (error: any) {
    return error.response || handleError(error);
  }
};

const getImageById = async (id: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.IImage>(`/images/${id}`);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export default {
  addImage,
  getImages,
  getTemplateImagesTags,
  updateImage,
  deleteImage,
  getImageById,
};
