import React from 'react';
import Icon from '../Icons/Icon';
import './SearchNotFound.scss';

type SearchNotFoundProps = {
  entity: string;
};

const SearchNotFound: React.FC<SearchNotFoundProps> = ({entity}) => {
  return (
    <div id="SearchNotFound">
      <div className="search-not-found-content">
        <Icon name="notFound" />

        <span className="search-not-found-title">{`${entity} not found`}</span>
        <span className="search-not-found-text">{`Looks like the ${entity} you're looking for does not exist,`}</span>
        <span className="search-not-found-text">please try again</span>
      </div>
    </div>
  );
};

export default SearchNotFound;
