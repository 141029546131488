import {IBuilderSlots} from '@gfxco/contracts';
import React from 'react';
import Button from 'react-bootstrap/Button';
import * as Icons from 'react-bootstrap-icons';
import debounce from 'lodash.debounce';
import ImageIco from '../../assets/icons/image-slot-icon.svg';
import TextSlotIcon from '../../assets/icons/text-slot-icon.svg';
import BackgroundIco from '../../assets/icons/background-slot-icon.svg';
import {ReactComponent as AliasEditIcon} from '../../assets/icons/alias-edit-icon.svg';
import {capitalizeFirstLetter} from '../../libs/formatUtils';
import Icon from '../Icons/Icon';

import './SlotAlias.scss';

type SlotAliasProps = {
  slot: IBuilderSlots;
  setSlotAlias: (uuid: string, name: string) => void;
  onRemoveSlot: (uuid: string) => void;
  index: number;
  totalSlots?: number;
  onClick?: () => void;
  onChangeOrder?: (newPosition: number) => void;
  isActive?: boolean;
};

const setSlotMask: (e: IBuilderSlots) => string = (element) => {
  if (element.isBackground) {
    return 'Background';
  }

  if (element.type === 'rect' || element.type === 'image') {
    return 'Image';
  }

  if (element.type === 'group' && element.objects && element.objects.length) {
    return setSlotMask(element.objects[0]);
  }

  return capitalizeFirstLetter(element.type);
};

const getInternalImage = (slot: IBuilderSlots) => {
  if (slot.type === 'group' && slot.objects && slot.objects.length) {
    return slot.objects[0].src;
  }
  // Background keeps as SLOT to avoid updates.
  if (slot.type === 'slot' && slot.objects && slot.objects.length) {
    return slot.objects[0].src;
  }

  return null;
};

const SlotsAlias: React.FC<SlotAliasProps> = (props) => {
  const {slot, index, totalSlots = 1, isActive, onChangeOrder} = props;
  const [editionMode, setEditionMode] = React.useState<boolean>(false);
  const [isChangingOrder, setIsChangingOrder] = React.useState<boolean>(false);
  const [textboxValue, setTextboxVal] = React.useState<string | null>(
    slot.alias,
  );
  const inputRef = React.useRef<HTMLInputElement>(null);
  const slotMask = setSlotMask(slot);
  const imageAdded = getInternalImage(slot);
  const isBackground = props.slot.isBackground;

  const getSlotIcon = () => {
    let slotIco = ImageIco;
    if (slot.isBackground) {
      slotIco = BackgroundIco;
    } else if (slotMask === 'Textbox') {
      slotIco = TextSlotIcon;
    }
    return slotIco;
  };

  React.useEffect(() => {
    if (editionMode && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [editionMode]);

  const turnDownEdition = (evt: any) => {
    const Key = evt.key;
    if (Key === 'Enter') {
      save();
    }
  };

  const onChangeTextAlias = (evt: any) => {
    if (editionMode) {
      evt.preventDefault();
      setTextboxVal(evt.target.value);
    }
  };

  const enableEditionMode = (evt: any) => {
    if (!slot.isBackground) {
      setEditionMode(true);
    }
  };

  const save = () => {
    setEditionMode(false);
    props.setSlotAlias(textboxValue!, slot.uuid);
  };

  const editionInput = (
    <input
      type="text"
      name=""
      ref={inputRef}
      onBlur={() => save()}
      id={slot.uuid}
      value={textboxValue || ''}
      placeholder={textboxValue || 'Add your slot alias'}
      onChange={onChangeTextAlias}
      onKeyDown={turnDownEdition}
    />
  );

  const BasicFillColorComponent = () => (
    <div
      className="slot-alias-box__preview_color"
      style={{backgroundColor: slot.fill}}
    ></div>
  );

  const ImagePreviewComponent = () => (
    <div
      className="slot-alias-box__preview_color"
      style={{
        backgroundImage: `url("${imageAdded as string}")`,
        backgroundSize: 'contain',
      }}
    ></div>
  );

  const NoEditionComponent = (props: {slot: IBuilderSlots}) => {
    const isBackground = props.slot.isBackground;
    const hasAlias = props.slot.alias;
    return (
      <p>
        {hasAlias ? props.slot.alias : isBackground ? 'Background' : 'No name'}
      </p>
    );
  };

  const handleArrowClickUp = async (evt: any) => {
    setIsChangingOrder(true);
    if (onChangeOrder) {
      await onChangeOrder(index - 1);
    }
    setIsChangingOrder(false);
  };

  const handleArrowClickDown = async (evt: any) => {
    setIsChangingOrder(true);
    if (onChangeOrder) {
      await onChangeOrder(index + 1);
    }
    setIsChangingOrder(false);
  };

  const slotActiveClass = isActive ? 'slot-alias-box--active' : '';
  const slotContainerActiveClass = isActive ? 'slot-container--active' : '';

  return (
    <div className={`slot-container ${slotContainerActiveClass}`}>
      <div className="slot-alias-box__close_button">
        <Icons.X
          cursor={'pointer'}
          size={18}
          onClick={() => props.onRemoveSlot(slot.uuid)}
        />
      </div>
      <div
        className={`slot-alias-box ${slotActiveClass}`}
        onClick={props.onClick}
      >
        {imageAdded ? <ImagePreviewComponent /> : <BasicFillColorComponent />}
        <div className="slot-alias-box__slot_type">
          <img src={getSlotIcon()} alt="" />
          <p>{slotMask}</p>
        </div>
        <div className="slot-alias-box__slot_info" onClick={enableEditionMode}>
          {editionMode ? editionInput : <NoEditionComponent slot={slot} />}
          <AliasEditIcon />
        </div>
      </div>
      <div className="slot-order-arrows">
        {!isBackground && (
          <>
            <Button
              variant="outline-secondary"
              onClick={debounce(handleArrowClickUp, 500)}
              disabled={index === 0 || isChangingOrder}
            >
              <Icon name="longUpArrowIcon" />
            </Button>
            <Button
              variant="outline-secondary"
              onClick={debounce(handleArrowClickDown, 500)}
              disabled={index === totalSlots - 1 || isChangingOrder}
            >
              <Icon name="longDownArrowIcon" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default SlotsAlias;
