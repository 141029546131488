import React from 'react';
import './ShopCollectionsSelector.scss';
import {ICollectionResponse, IImage} from '@gfxco/contracts';
import CollectionsPanelOption from '../CollectionsPanel/CollectionsPanelOption';
import {IPagination} from '../../hooks/usePagination';
import ImageAPI from '../../api/images';
import ShopImageSelectorItem from '../ShopImageSelector/ShopImageSelectorItem';
import useSimplePagination from '../../hooks/useSimplePagination';

type ShopCollectionsSelectorProps = {
  collections: ICollectionResponse[];
  getCollections: () => void;
  currentSelectedCollection: number | null;
  shopId: number;
  imageIdSelected: number | null;
  onSelectImage: (image: IImage) => Promise<void>;
  onCollectionClick: (collection: ICollectionResponse) => void;
};

const ShopCollectionsSelector: React.FC<ShopCollectionsSelectorProps> = (
  props,
) => {
  const {collections, currentSelectedCollection} = props;
  const [subCollectionSelected, setSubCollectionSelected] = React.useState<
    number | null
  >(null);
  const [subCollectionImages, setSubCollectionImages] = React.useState<
    IImage[]
  >([]);
  const [collectionImages, setCollectionImages] = React.useState<IImage[]>([]);
  const [collectionRequestLoading, setCollectionRequestLoading] =
    React.useState<boolean>(false);
  const ContainerImageRef = React.useRef<HTMLDivElement>(null);
  const [totalImages, setTotalImages] = React.useState<{
    subcollections: number;
    collections: number;
  }>({
    subcollections: 0,
    collections: 0,
  });

  const isViewingSubCollection = subCollectionSelected !== null;
  const showCollectionImages =
    !isViewingSubCollection && collectionImages.length > 0;
  const showSubCollectionImages =
    isViewingSubCollection && subCollectionImages.length > 0;

  const [collectionPagination, handleCollectionPagination] =
    useSimplePagination({
      limit: 16,
    });

  const [subcollectionPagination, handleSubcollectionPagination] =
    useSimplePagination({
      limit: 9,
    });

  React.useEffect(() => {
    const updateSubcollectionImages = async () => {
      if (subCollectionSelected) {
        try {
          setCollectionRequestLoading(true);
          const result = await fetchCollectionsImages(
            subCollectionSelected,
            subcollectionPagination,
          );
          const newSubCollImages = subCollectionImages.concat(
            result?.results || [],
          );
          setSubCollectionImages(newSubCollImages);
          setTotalImages((prev) => ({
            subcollections: result?.total || 0,
            collections: prev.collections,
          }));
          setCollectionRequestLoading(false);
        } catch (error) {
          console.error(error);
        }
      }
    };

    updateSubcollectionImages();
  }, [subCollectionSelected, subcollectionPagination.offset]);

  React.useEffect(() => {
    const updateCollectionImages = async () => {
      if (currentSelectedCollection) {
        try {
          setCollectionRequestLoading(true);
          const result = await fetchCollectionsImages(
            currentSelectedCollection,
            collectionPagination,
          );
          const newCollImages = collectionImages.concat(result?.results || []);
          setCollectionRequestLoading(false);
          setCollectionImages(newCollImages);
          setTotalImages((prev) => ({
            subcollections: prev.subcollections,
            collections: result?.total || 0,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (!currentSelectedCollection) {
      setCollectionImages([]);
      setTotalImages((prev) => ({
        subcollections: 0,
        collections: 0,
      }));
      setSubCollectionImages([]);
      setSubCollectionSelected(null);
      handleCollectionPagination('reset');
      return;
    }

    updateCollectionImages();
    props.getCollections();
  }, [currentSelectedCollection, collectionPagination.offset]);

  // Effect for scroll event
  React.useEffect(() => {
    const handleCollectionsScroll = () => {
      if (!ContainerImageRef.current) return;
      const element = ContainerImageRef.current;

      const scrollTop = element.scrollTop;
      const scrollHeight = element.scrollHeight;
      const clientHeight = element.clientHeight;

      const evaluateCollection =
        (!isViewingSubCollection &&
          collectionImages.length >= totalImages.collections) ||
        (isViewingSubCollection &&
          subCollectionImages.length >= totalImages.subcollections);

      if (
        clientHeight + scrollTop < scrollHeight * 0.7 ||
        evaluateCollection ||
        collectionRequestLoading
      ) {
        return;
      }

      if (isViewingSubCollection) {
        handleSubcollectionPagination('next');
        return;
      }

      handleCollectionPagination('next');
    };

    if (!ContainerImageRef.current) return;
    const element = ContainerImageRef.current;
    element.addEventListener('scroll', handleCollectionsScroll);

    return () => {
      element.removeEventListener('scroll', handleCollectionsScroll);
    };
  }, [
    ContainerImageRef.current,
    collectionImages,
    totalImages.collections,
    collectionRequestLoading,
  ]);

  const onSubCollectionClick = async (
    collection: Partial<ICollectionResponse>,
  ) => {
    if (!collection.id) return;
    if (subCollectionSelected === collection.id) {
      setSubCollectionSelected(null);
      setSubCollectionImages([]);
      handleSubcollectionPagination('reset');
      return;
    }
    setSubCollectionImages([]);
    handleSubcollectionPagination('reset');
    setSubCollectionSelected(collection.id!);
  };

  const fetchCollectionsImages = async (
    collectionId: number,
    pagination: IPagination,
  ) => {
    const collections = [collectionId];

    const images = await ImageAPI.getImages({
      shopId: props.shopId,
      limit: pagination.limit,
      offset: pagination.offset,
      collections,
    });

    if (!images) {
      return;
    }
    return images;
  };

  return (
    <div className="shop-collections-selector" ref={ContainerImageRef}>
      {collections.map((collection) => (
        <div key={collection.id} className="shop-collection-selector--item">
          <CollectionsPanelOption
            id={collection.id}
            showCheckbox={false}
            name={collection.name}
            description={collection.description}
            imagesCount={collection.imagesCount}
            subCollections={collection.subCollections}
            onClick={props.onCollectionClick}
            onCheck={() => {}}
            isSubItem={false}
            isOpened={currentSelectedCollection === collection.id}
            checked={false}
          />

          {currentSelectedCollection === collection.id &&
            collection.subCollections?.map((subCollection) => (
              <div
                key={subCollection.id}
                className="shop-collections-selector--sub-item"
              >
                <CollectionsPanelOption
                  id={subCollection.id}
                  showCheckbox={false}
                  name={subCollection.name}
                  description={subCollection.description}
                  imagesCount={subCollection.imagesCount}
                  onClick={() => onSubCollectionClick(subCollection)}
                  onCheck={() => {}}
                  isSubItem={true}
                  isOpened={false}
                  checked={false}
                />
                {subCollection.id === subCollectionSelected &&
                  showSubCollectionImages && (
                    <div className="shop-collections-selector--sub-item--images sub-collection-images">
                      {subCollectionImages.map((image) => (
                        <ShopImageSelectorItem
                          key={image.id}
                          image={image}
                          isSelected={false}
                          onClick={props.onSelectImage}
                        />
                      ))}
                    </div>
                  )}
              </div>
            ))}

          <div className="shop-collections-selector--item--images">
            {currentSelectedCollection === collection.id &&
              showCollectionImages &&
              collectionImages.map((image) => (
                <ShopImageSelectorItem
                  key={image.id}
                  image={image}
                  isSelected={image.id === props.imageIdSelected}
                  onClick={props.onSelectImage}
                />
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShopCollectionsSelector;
