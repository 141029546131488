import React from 'react';
import {TWalkthroughStep} from '@gfxco/contracts';

import GFXImage from '../Image';
import GFXButton from '../Button';
import {ReactComponent as EditIcon} from '../../assets/icons/edit-icon.svg';

import './WalkthroughAction.scss';

type WalkthroughActionProps = {
  stepNumber: number;
  onRemoveStep: () => void;
  onSelected?: (step: TWalkthroughStep | null) => void;
  selected?: boolean;
  step: TWalkthroughStep;
};

const WalkthroughAction: React.FC<WalkthroughActionProps> = (props) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  const handleSelect = () => {
    if (props.onSelected)
      props.onSelected({
        ...props.step,
      });
  };

  return (
    <div className="walkthrough-action" ref={divRef}>
      <div
        className={`walkthrough-action__image-container ${
          props.selected ? 'selected' : ''
        }`}
      >
        <div className="walkthrough-action__options">
          <div className="walkthrough-action__options-buttons">
            <GFXButton
              size="sm"
              variant="light"
              aria-label="Edit step"
              data-tooltip="Edit step"
              onClick={() => handleSelect()}
              style={{opacity: props.selected ? 0 : 1}}
            >
              <EditIcon />
            </GFXButton>
            <GFXButton
              size="sm"
              aria-label="Remove step"
              data-tooltip="Remove step"
              onClick={() => props.onRemoveStep()}
            >
              x
            </GFXButton>
          </div>
        </div>
        <div className="walkthrough-action__image">
          <GFXImage
            src={props.step.imageURL}
            alt=""
            placeholderSrc="/loading-render.gif"
          />
        </div>
        <span className="walkthrough-action__name">
          <span>{props.step.text}</span>
        </span>
      </div>

      <span className="walkthrough-action__stepNumber">
        Step #{props.stepNumber}
      </span>
    </div>
  );
};

export default WalkthroughAction;
