import React from 'react';

export interface IPagination {
  offset: number;
  limit: number;
  currentPage: number;
}
type PaginationParams = {
  limit?: number;
} & Partial<IPagination>;
const LIMIT_DEFAULT_VALUE = 15;

const useSimplePagination = (
  params?: PaginationParams,
): [IPagination, (action: string, page?: number) => void] => {
  const offset = params?.offset || 0;
  const currentPage = params?.currentPage || 1;
  const limit = params?.limit || LIMIT_DEFAULT_VALUE;
  const [values, setValues] = React.useState<IPagination>({
    offset,
    currentPage,
    limit,
  });

  const handlePagination = (action: string, page?: number) => {
    if (action === 'reset') {
      setValues({offset: 0, currentPage: 1, limit});
      return;
    }

    if (action === 'first') {
      setValues({offset: 0, currentPage: 1, limit: values.limit});
    } else if (action === 'prev') {
      setValues({
        offset: values.offset - values.limit,
        currentPage: values.currentPage - 1,
        limit: values.limit,
      });
    } else if (action === 'next') {
      setValues({
        offset: values.offset + values.limit,
        currentPage: values.currentPage + 1,
        limit: values.limit,
      });
    } else if (
      (action === 'last' || action === 'page') &&
      typeof page === 'number'
    ) {
      setValues({offset: limit * page, currentPage: page + 1, limit});
    }
  };

  return [
    {
      offset: values.offset,
      limit: values.limit,
      currentPage: values.currentPage,
    },
    handlePagination,
  ];
};

export default useSimplePagination;
