import axios from 'axios';
import {loadClient, handleError} from './api';
import {
  FontsDBModel,
  FontsRequest,
  FontsResponse,
  UpdateFontParams,
  CreateFontParams,
  DeleteFontConflictErrorResponse,
} from '@gfxco/contracts';

const getFonts = async (params: FontsRequest) => {
  const {shopId, sortBy, name, offset, limit} = params;
  try {
    const client = await loadClient();
    const response = await client.get<FontsResponse>(
      `/shops/${shopId}/fonts?sortBy=${sortBy}&name=${name}&offset=${offset}&limit=${limit}`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const deleteFontById = async (fontId: number, shopId: number) => {
  const client = await loadClient();
  try {
    await client.delete<void>(`/shops/${shopId}/fonts/${fontId}`);
    return {removed: true};
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.data && error.response?.status === 409) {
        const conflictError = error.response
          .data as DeleteFontConflictErrorResponse;
        return {
          removed: false,
          ...conflictError,
        };
      }
    }
    console.error(error);
    return {removed: false};
  }
};

const updateFontById = async (params: UpdateFontParams, data: any) => {
  const client = await loadClient();

  const result = await client.patch(
    `/shops/${params.shopId}/fonts/${params.fontId}`,
    data,
  );
  return result;
};

const createFont = async (
  params: CreateFontParams,
  data: Partial<FontsDBModel>,
) => {
  const client = await loadClient();
  try {
    return client.post(`/shops/${params.shopId}/fonts`, data);
  } catch (error) {
    console.error(error);
    return handleError(error);
  }
};

export default {
  deleteFontById,
  getFonts,
  updateFontById,
  createFont,
};
