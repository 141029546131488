import React from 'react';
import {
  ELoadingStates,
  ICollectionResponse,
  ICollectionsRequest,
} from '@gfxco/contracts';
import {Form} from 'react-bootstrap';
import {Plus, ChevronUp, ChevronDown} from 'react-bootstrap-icons';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {
  getCollectionsAsync,
  selectCollections,
  selectCollectionsFetchStatus,
  selectTotalCollections,
} from '../../features/collections/loadCollectionsDetails';
import CollectionOption from './CollectionOption/CollectionOption';
import {ReactComponent as FolderPlusIcon} from '../../assets/icons/folder-plus-icon.svg';
import './SelectCollections.scss';

import Loading from '../Loading';
import {GFXToastLaunch} from '../ToastMessage/ToastMessage';

type ICollectionType = ICollectionResponse & {blocked?: boolean};

type SelectImageRequiredProps = {
  shopId: number;
  currentCollections: ICollectionType[] | null;
  handleSelectedNewCollection: (evt: any) => void;
  onDeleteCollection: (collectionId: number, subCollection?: number) => void;
  markPendingChanges?: (v: boolean) => void;
  slotType: string;
  nativeCollectionsLoaded: boolean;
  defaultImage: any;
  templateId: string | number;
};

const SelectImage: React.FC<SelectImageRequiredProps> = (props) => {
  const {currentCollections, handleSelectedNewCollection, shopId} = props;
  const navigationRef = React.useRef<HTMLDivElement>(null);
  const collections = useAppSelector(selectCollections);
  const dispatch = useAppDispatch();
  const collectionsFetchStatus = useAppSelector(selectCollectionsFetchStatus);
  const totalCollections = useAppSelector(selectTotalCollections);
  const [offset, setOffset] = React.useState(0);
  const [openCollections, setOpenCollections] = React.useState<boolean>(false);
  const [selectedCollections, setSelectedCollections] = React.useState<
    ICollectionResponse[]
  >(currentCollections || []);
  const [isSelectingCollection, setIsSelectingCollection] =
    React.useState<boolean>(false);
  const [showDeleteNameConfirmation, setShowDeleteNameConfirmation] =
    React.useState<string>('');

  const limit = 20;

  React.useEffect(() => {
    const container = navigationRef.current;
    if (collectionsFetchStatus === ELoadingStates.LOADED && container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [collectionsFetchStatus, openCollections]);

  React.useEffect(() => {
    if (currentCollections) setSelectedCollections(currentCollections);
  }, [currentCollections]);

  React.useEffect(() => {
    if (collectionsFetchStatus === ELoadingStates.LOADED && offset !== 0) {
      getCollections();
    }
  }, [offset]);

  React.useEffect(() => {
    if (props.markPendingChanges) {
      props.markPendingChanges(isSelectingCollection);
    }
  }, [isSelectingCollection]);

  React.useEffect(() => {
    if (showDeleteNameConfirmation.length) {
      return GFXToastLaunch(
        `Collection ${showDeleteNameConfirmation} removed succesfully`,
        2000,
        {
          alertType: 'info',
          parentContainerId: 'collections-box',
          showAt: 'bottom',
          right: '0rem',
          showIcon: true,
          onDelayCompleted: () => setShowDeleteNameConfirmation(''),
        },
      );
    }
  }, [showDeleteNameConfirmation]);

  const handleScroll = () => {
    const container = navigationRef.current;
    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      if (
        scrollHeight - scrollTop - clientHeight < 100 ||
        collectionsFetchStatus === ELoadingStates.LOADING ||
        collections?.length === +totalCollections
      ) {
        return;
      }
      setOffset(offset + limit);
    }
  };

  const getCollections = () => {
    const params: ICollectionsRequest = {
      shopId,
      offset,
      limit,
    };

    dispatch(getCollectionsAsync(params));
  };

  const onDeleteCollection = (
    evt: React.SyntheticEvent,
    collectioNumber: number,
    collectionName?: string,
    subCollectionNumber?: number,
  ) => {
    evt.preventDefault();
    evt.stopPropagation();
    props.onDeleteCollection(collectioNumber, subCollectionNumber);
    setShowDeleteNameConfirmation(collectionName!);
  };

  const NoCollectionsChoosen = () => (
    <div className="empty-box">
      <FolderPlusIcon />
      <div className="text-box">
        <span>Search and add collection to your slots</span>
        <span className="text-bold">to see images on your template</span>
      </div>
    </div>
  );

  const handleOptionsChange = (collections: any) => {
    const isSelectingNew = currentCollections?.length !== collections.length;
    setIsSelectingCollection(isSelectingNew);
    setSelectedCollections(collections);
  };

  const onSelectedNewCollection = (evt: any) => {
    handleSelectedNewCollection(selectedCollections);
    setOpenCollections(false);
    setIsSelectingCollection(false);
  };

  const onOpenCollections = () => {
    if (openCollections) {
      setIsSelectingCollection(false);
    }
    setOpenCollections(!openCollections);
  };

  if (props.slotType === 'text') return null;

  return (
    <Form.Group id="SelectImage">
      <Form.Label className="collections-label">Assign collections:</Form.Label>
      <div className="collections-box" id="collections-box">
        <div>
          <div className="collections-select" onClick={onOpenCollections}>
            <div className="collections-select-label">
              <Plus />
              Add a collection
            </div>
            {openCollections ? <ChevronUp /> : <ChevronDown />}
          </div>
          {openCollections && (
            <div className="collections-select-options">
              <div className="options" ref={navigationRef}>
                {collections?.map((collection) => (
                  <CollectionOption
                    key={collection.id}
                    id={collection.id}
                    name={collection.name}
                    description={collection.description}
                    imagesCount={collection.imagesCount}
                    subCollections={collection.subCollections}
                    isSelect
                    selected={selectedCollections}
                    onChange={handleOptionsChange}
                    templateId={props.templateId}
                  />
                ))}
              </div>
              <div className="collections-button">
                <button
                  disabled={!isSelectingCollection}
                  onClick={onSelectedNewCollection}
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </div>

        {!props.nativeCollectionsLoaded ? <Loading /> : null}
        {!!props.nativeCollectionsLoaded && !currentCollections?.length && (
          <NoCollectionsChoosen />
        )}
        {!!props.nativeCollectionsLoaded && !!currentCollections?.length && (
          <div>
            {currentCollections?.map((collection, idx) => (
              <CollectionOption
                key={collection.id}
                id={collection.id}
                name={collection.name}
                description={collection.description}
                imagesCount={collection.imagesCount}
                subCollections={collection.subCollections}
                isSelect={false}
                index={idx + 1}
                templateId={props.templateId}
                onDelete={(evt, id, subId) =>
                  onDeleteCollection(evt, id, subId)
                }
                blocked={collection.blocked}
                defaultImage={props.defaultImage}
              />
            ))}
          </div>
        )}
      </div>
    </Form.Group>
  );
};

export default SelectImage;
