import React from 'react';
import {ReactComponent as ArrowLeftIcon} from '../../../assets/icons/arrow-return-left-icon.svg';
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/arrow-return-right-icon.svg';

import './FooterView.scss';
interface RequiredProps {
  onNext: (index: number) => void;
  onPrevious: (index: number) => void;
  currentIndex: number;
  totalOrders: number;
  disabled: boolean;
}

interface OptionalProps {}
type OrdersModalProps = RequiredProps & OptionalProps;

const FooterView: React.FC<OrdersModalProps> = ({
  onNext,
  onPrevious,
  currentIndex,
  totalOrders,
  disabled,
}) => {
  const isNextDisabled = currentIndex === totalOrders;
  const isPreviousDisabled = currentIndex === 0;

  const KEY = {
    right: 'ArrowRight',
    left: 'ArrowLeft',
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleOnKeyDown);

    return () => {
      window.removeEventListener('keydown', handleOnKeyDown);
    };
  }, [currentIndex, disabled]);

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (disabled) return;
    const keyPressed = event.code;
    if (keyPressed === KEY.right && !isNextDisabled) {
      onNext(currentIndex!);
    }
    if (keyPressed === KEY.left && !isPreviousDisabled) {
      onPrevious(currentIndex!);
    }
  };

  return (
    <div id="FooterView">
      <button
        className={`footer-button footer-button-right  ${
          isPreviousDisabled || disabled ? 'footer-button-disabled' : ''
        }`}
        onClick={() => onPrevious(currentIndex!)}
        disabled={isPreviousDisabled || disabled}
      >
        <ArrowLeftIcon />
        <span className="button-label">Previous design</span>
      </button>
      <button
        className={`footer-button footer-button-left ${
          isNextDisabled || disabled ? 'footer-button-disabled' : ''
        }`}
        onClick={() => onNext(currentIndex!)}
        disabled={isNextDisabled || disabled}
      >
        <ArrowRightIcon />
        <span className="button-label">Next design</span>
      </button>
    </div>
  );
};

export default FooterView;
