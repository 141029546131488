import React from 'react';

import {Row, Col} from 'react-bootstrap';

import {useAppSelector} from '../../../app/hooks';
import usePagination from '../../../hooks/usePagination';
import useQueryParams from '../../../hooks/useQueryParams';
import {selectShop} from '../../../features/shops/selectedShop';

import imagesApi from '../../../api/images';

import Button from '../../Button';
import GFXImage from '../../Image/Image';

import GFXCustomPagination from '../../CustomPagination/GFXCustomPagination';

import Loading from '../../Loading';
import {ELoadingStates, IImage, IImageRequest} from '@gfxco/contracts';

import './ImagesByCollection.scss';

type OptionalProps = {
  collectionId: number;
  onSelect?: () => void;
};

type RequiredProps = {};

type ImagesByCollectionProps = OptionalProps & RequiredProps;

const ImagesByCollection: React.FC<ImagesByCollectionProps> = ({
  collectionId,
  onSelect,
}) => {
  const shopSelected = useAppSelector(selectShop);
  const [queryParams] = useQueryParams();

  const shopId = shopSelected?.id;

  const [{offset, currentPage, limit}, handlePagination] = usePagination();
  const [images, setImages] = React.useState<IImage[]>([]);
  const [totalImages, setTotalImages] = React.useState<number>(0);
  const [imagesStatus, setImageStatus] = React.useState<ELoadingStates>(
    ELoadingStates.IDLE,
  );

  React.useEffect(() => {
    handlePagination('reset');
    setTotalImages(0);
    setImageStatus(ELoadingStates.IDLE);
    setImages([]);
  }, [queryParams.search, collectionId]);

  React.useEffect(() => {
    if (imagesStatus !== ELoadingStates.IDLE || !collectionId) return;
    getImages();
  }, [shopId, offset, imagesStatus, limit, collectionId]);

  const getImages = async () => {
    setImageStatus(ELoadingStates.LOADING);
    try {
      const params: IImageRequest & {page: number} = {
        shopId,
        offset,
        limit,
        page: currentPage,
        collections: [+collectionId],
        order: 'desc',
      };

      if (queryParams.search) params.name = queryParams.search;
      const images = await imagesApi.getImages(params);

      if (images) {
        setImages(images.results);
        setTotalImages(images.total);
      }
      setImageStatus(ELoadingStates.LOADED);
    } catch (error) {
      console.error(error);
      setImageStatus(ELoadingStates.FAILED);
    } finally {
      setImageStatus(ELoadingStates.LOADED);
    }
  };

  return (
    <div className="images-by-collection">
      {imagesStatus === ELoadingStates.LOADING && <Loading spinnerOnly />}
      {!!images && !!images.length && (
        <>
          <Row>
            {images.map((i) => (
              <Col md="auto" key={i.id}>
                <GFXImage
                  key={i.id}
                  src={i.thumbnail}
                  placeholderSrc="/loading-render.gif"
                />
              </Col>
            ))}
          </Row>
          <div className="images-footer">
            {onSelect && (
              <Button
                className="image-button-add-collection"
                onClick={() => onSelect()}
              >
                Add sticker collection
              </Button>
            )}
            <GFXCustomPagination
              limit={limit}
              currentPage={currentPage}
              total={totalImages}
              handlePagination={handlePagination}
              skipInfo
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ImagesByCollection;
