import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {
  getShopConfigsAsync,
  selectShop,
} from '../../features/shops/selectedShop';
import useQueryParams from '../../hooks/useQueryParams';
import {
  updateShopifyIntegrationAsync,
  selectIntegrationsFetchLoading,
} from '../../features/integrations/loadShopifyIntegration';
import {ELoadingStates} from '@gfxco/contracts';

import './SyncPage.scss';
import Loading from '../../components/Loading';
import CustomModal from '../../components/CustomModal';
import {GFXToastLaunch} from '../../components/ToastMessage/ToastMessage';

import {unPublishedProducts} from '../../api';

import Icon from '../../components/Icons/Icon';

export default function SyncPage() {
  const {authStatus, route} = useAuthenticator((context) => [
    context.authStatus,
  ]);
  const isAuthenticated = authStatus === 'authenticated';
  const verifyUser = route === 'verifyUser';
  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;
  const publicDomain = shopSelected?.public_shopify_domain;
  const [queryParams] = useQueryParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [syncDisabled, setSyncDisabled] = React.useState<boolean>(
    !!publicDomain,
  );

  const integrationFetchStatus = useAppSelector((state) =>
    selectIntegrationsFetchLoading(state),
  );

  React.useEffect(() => {
    if (!shopId || !queryParams.store) return;

    dispatch(getShopConfigsAsync(shopId));

    if (!publicDomain && integrationFetchStatus === ELoadingStates.IDLE) {
      dispatch(
        updateShopifyIntegrationAsync({shopId, domain: queryParams.store}),
      );
    }

    if (queryParams.store === publicDomain) {
      navigate('/');
    }
  }, [shopId]);

  React.useEffect(() => {
    const connected = integrationFetchStatus === ELoadingStates.LOADED;
    const failed = integrationFetchStatus === ELoadingStates.FAILED;

    if (connected) navigate('/?confirmation=true');

    if (failed) navigate(`/?store=${queryParams.store}&failed=true`);
  }, [integrationFetchStatus]);

  const handleReSync = async () => {
    if (!shopId) return;
    try {
      setSyncDisabled(false);
      await unPublishedProducts({shopId});
      dispatch(
        updateShopifyIntegrationAsync({shopId, domain: queryParams.store}),
      );
    } catch (error) {
      console.log(error);
      GFXToastLaunch(
        'An error occurred saving your design, please try again',
        2000,
        {
          showAt: 'top',
          right: '3.5rem',
          top: '7rem',
          showIcon: true,
          alertType: 'danger',
        },
      );
    }
  };

  if (isAuthenticated || verifyUser) {
    return (
      <div id="SyncPage">
        {!syncDisabled && (
          <div className="modal-connection">
            <Loading spinnerOnly />
            <span className="modal-title">Hello there!</span>
            <span className="modal-description">
              One moment while we are preparing your showcase for your new
              designs, just wait a minute.
            </span>
          </div>
        )}

        <CustomModal
          variant="grey"
          sizeType="middle-page"
          className="CustomModal-large-center"
          modalTile=""
          show={syncDisabled}
          isSaveEnabled={false}
          addCloseButton={false}
        >
          <div id="ConfirmationSync">
            <Icon name="message" />
            <span className="modal-loading-title">
              Looks like this account already has a store synced with gfx and
              Shopify.
            </span>
            <span className="modal-loading-description modal-text">
              Current store:
            </span>
            <div className="store-name-box">{shopSelected?.name || ''}</div>
            <div className="modal-loading-description modal-text">
              Syncing a new store could make changes to your currently published
              product:
            </div>

            <div className="modal-warning-item modal-text">
              <Icon name="xIcon" />
              You won&apos;t be able to receive new orders from your previous
              store.
            </div>
            <div className="modal-warning-item modal-text">
              <Icon name="xIcon" />
              Orders that are currently in moderation status will be
              automatically canceled.
            </div>
            <span className="modal-loading-description modal-text">
              Do you want to continue?
            </span>
            <div className="modal-actions-footer">
              <span
                className="sync-link footer-text"
                onClick={() => handleReSync()}
              >
                Yes, syncing a new store
              </span>
              <button
                className="info-publish-button footer-text"
                onClick={() => navigate('/')}
              >
                No, go to home page
              </button>
            </div>
          </div>
        </CustomModal>
      </div>
    );
  }

  return <></>;
}
