import {
  IDraftCanva,
  IDraftCanvaRequest,
  IDraftCanvaResponse,
} from '@gfxco/contracts';
import {loadClient, handleError} from './api';

const createDraftCanvas = async (draftCanva: IDraftCanva) => {
  try {
    const client = await loadClient();

    const response = await client.post<IDraftCanva>(
      `/draft-canvas`,
      draftCanva,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const updateDraftCanvas = async (draftCanva: IDraftCanva) => {
  try {
    const client = await loadClient();

    const response = await client.put<IDraftCanva>(
      `/draft-canvas/${draftCanva.id}`,
      draftCanva,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getDraftCanvas = async (filters: IDraftCanvaRequest) => {
  try {
    const client = await loadClient();

    const response = await client.get<IDraftCanvaResponse>(`/draft-canvas`, {
      params: filters,
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getDraftCanvaById = async (draftCanvaId: number) => {
  try {
    const client = await loadClient();

    const response = await client.get<IDraftCanva>(
      `/draft-canvas/${draftCanvaId}`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export default {
  createDraftCanvas,
  updateDraftCanvas,
  getDraftCanvas,
  getDraftCanvaById,
};
