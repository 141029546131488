import React from 'react';
import {TWalkthroughStep} from '@gfxco/contracts';

import GFXButton from '../Button';
import WalkthroughAction from './WalkthroughAction';
import {ReactComponent as WalkthroughStepsIcon} from '../../assets/icons/walkthrough-steps-icon.svg';

import './WalkthroughActions.scss';

interface RequiredProps {
  onAddAction: () => void;
  onRemoveAction: (stepId: number) => void;
}

interface OptionalProps {
  steps?: TWalkthroughStep[];
  onSelect?: (
    action: (TWalkthroughStep & {index?: number}) | undefined,
  ) => void;
  selected?: TWalkthroughStep & {index?: number};
}

type Props = RequiredProps & OptionalProps;

const WalkthroughActions: React.FC<Props> = (props) => {
  if (!props.steps || props.steps.length === 0) {
    return (
      <div id="NotWalkthrough">
        <WalkthroughStepsIcon />
        <p>
          Currently, there aren&apos;t any tutorial steps created for this
          template. Please click below to add your first step.
        </p>
        <GFXButton onClick={() => props.onAddAction()}>
          <i>+ Add Steps</i>
        </GFXButton>
      </div>
    );
  }

  const handleSelect = (action: TWalkthroughStep | null, index: number) => {
    if (props.onSelect) {
      if (!action) {
        props.onSelect(undefined);
        return;
      }
      props.onSelect({...action, index});
    }
  };

  return (
    <div className="walkthrough-actions-container">
      {props.steps.map((step, index) => {
        return (
          <WalkthroughAction
            key={index}
            stepNumber={index + 1}
            onSelected={(action) => handleSelect(action, index)}
            onRemoveStep={() => {
              props.onRemoveAction(step.id);
            }}
            selected={props.selected && props.selected.index === index}
            step={step}
          />
        );
      })}
      {!props.selected && (
        <GFXButton
          className="add-walkthrough-action"
          onClick={() => props.onAddAction()}
        >
          + Add new step
        </GFXButton>
      )}
    </div>
  );
};

export default WalkthroughActions;
