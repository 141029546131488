import React from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import * as Contracts from '@gfxco/contracts';
import {loginAsync} from '../../../features/auth';
import {useAppDispatch} from '../../../app/hooks';
import Icon from '../../../components/Icons/Icon';
import PasswordField from '../../../components/PasswordField';
import ConfirmEmailForm from '../ConfirmEmailForm';

import './AccountForm.scss';
import GFXInput from '../../../components/GFXInput';
import GFXButton from '../../../components/Button';

interface AccountFormRequiredProps {
  user: Contracts.ICognitoUser;
  updateUser: (updatedAttributes: {email: string}) => void;
  getUser: () => void;
}

const AccountForm: React.FunctionComponent<AccountFormRequiredProps> = ({
  user,
  updateUser,
  getUser,
}) => {
  const [email, setEmail] = React.useState('');
  const [isPasswordWrong, setIsPasswordWrong] = React.useState(false);
  const [showConfirmEmail, setShowConfirmEmail] = React.useState(false);
  const [requestStatus, setRequestStatus] =
    React.useState<Contracts.ELoadingStates>(Contracts.ELoadingStates.IDLE);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (
      user?.attributes?.email_verified !== undefined &&
      !user?.attributes?.email_verified
    ) {
      setShowConfirmEmail(true);
    }
  }, [user?.attributes?.email_verified]);

  const formHasChanges = () => {
    return email && email !== user?.attributes?.email;
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setRequestStatus(Contracts.ELoadingStates.LOADING);
    setIsPasswordWrong(false);
    const target = event.target as HTMLFormElement;
    const password = target['current-password-input-for-email'].value;

    try {
      await dispatch(
        loginAsync({username: user.username, password, setCookie: false}),
      ).unwrap();
    } catch (error) {
      setRequestStatus(Contracts.ELoadingStates.FAILED);
      setIsPasswordWrong(true);
      return;
    }

    await updateUser({
      email: email!,
    });

    user.attributes.email = email;

    setShowConfirmEmail(true);
    setRequestStatus(Contracts.ELoadingStates.LOADED);
  };

  const handleConfirmedEmail = async () => {
    await getUser();
    setShowConfirmEmail(false);
    setEmail('');
  };

  const handleTryAgain = () => {
    setShowConfirmEmail(false);
    setIsPasswordWrong(false);
    setEmail('');
  };

  if (showConfirmEmail) {
    return (
      <ConfirmEmailForm
        user={user}
        email={email}
        onConfirmEmail={handleConfirmedEmail}
        onTryAgain={handleTryAgain}
      />
    );
  }

  return (
    <Form
      className="account-form account-page-form"
      onSubmit={handleSubmitForm}
    >
      <Form.Group
        className="form-group-accounts form-group-icon"
        controlId="email-input"
      >
        <Form.Label>Current email*</Form.Label>
        <div className="input-icon">
          <Form.Control
            type="email"
            placeholder="Email"
            value={user?.attributes?.email || ''}
            disabled
          />
          <Icon name="lock" />
        </div>
        <span className="input-info">
          *All information notifications, data transmission, recaps and user
          activities will be sent via this email
        </span>
      </Form.Group>
      <Form.Group
        className="form-group-accounts form-group-icon"
        controlId="new-email-input"
      >
        <Form.Label>New email*</Form.Label>
        <GFXInput
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="New email"
          aria-label="New email"
        />
      </Form.Group>
      <Form.Group
        className="form-group-accounts form-group-icon"
        controlId="current-password-input-for-email"
      >
        <Form.Label>Current password*</Form.Label>
        <PasswordField name="current-password-input-for-email" />
        <span className="input-info">*Required to make changes</span>
      </Form.Group>
      {isPasswordWrong && (
        <Alert variant="danger">
          Current password is wrong. Please try again.
        </Alert>
      )}
      <GFXButton
        className="submit-button"
        variant="primary"
        disabled={!formHasChanges() || requestStatus === 'loading'}
        type="submit"
      >
        {requestStatus === Contracts.ELoadingStates.LOADING
          ? 'Loading...'
          : 'Save changes'}
      </GFXButton>
    </Form>
  );
};

export default AccountForm;
