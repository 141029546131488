import * as Contracts from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';

import {getPublishedProductEntities} from '../../api/productListing';
import {RootState} from '../../app/store';

const PublishedProduct = new schema.Entity('publishedProducts');
const publishedProductSchema = {publishedProducts: [PublishedProduct]};

export interface PublishedProductState {
  entities: {
    [id: number]: Contracts.ISlotTemplateCanvas;
  };
  pages: {[page: number]: number[]};
  pageFetchStatus: {[offset: number]: string};
  total: number;
}

const initialState: PublishedProductState = {
  entities: {},
  pages: {},
  pageFetchStatus: {},
  total: 0,
};

export const getPublishedProductsAsync = createAsyncThunk(
  'publishedProducts/fetch',
  async (parameters: Contracts.IDesignTemplateRequest) => {
    const response = await getPublishedProductEntities(parameters);

    const normalizedData = normalize(
      {publishedProducts: response?.designTemplates},
      publishedProductSchema,
    );

    return {
      entities: normalizedData.entities.publishedProducts,
      pages: normalizedData.result.publishedProducts,
      totalDesignTemplates: response?.totalDesignTemplates,
      ...parameters,
    };
  },
);

export const loadPublishedProducts = createSlice({
  name: 'publishedProducts',
  initialState,
  reducers: {
    resetPublishedProducts: (state) => {
      state.entities = {};
      state.pages = {};
      state.pageFetchStatus = {};
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPublishedProductsAsync.pending, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] = 'loading';
      })
      .addCase(getPublishedProductsAsync.fulfilled, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] = 'success';
        state.entities = {...state.entities, ...action.payload.entities};
        state.pages[action.meta.arg.page] = action.payload.pages;
        state.total = action.payload.totalDesignTemplates || 0;
      })
      .addCase(getPublishedProductsAsync.rejected, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] = 'error';
      });
  },
});

export const getEntityById = (state: RootState, id: number) => {
  return state.publishedProducts.entities[id];
};

export const getPublishedProductsByPage = (state: RootState, page: number) => {
  const ids = state.publishedProducts.pages[page] || [];
  if (ids.length > 0) {
    return ids.map((id) => getEntityById(state, id));
  } else {
    return [];
  }
};

export const selectTotalPublishedProducts = (state: RootState) => {
  return state.publishedProducts.total || 0;
};

export const fetchStatusByPage = (state: RootState, page: number) => {
  return state.publishedProducts.pageFetchStatus[page] || 'idle';
};

export const {resetPublishedProducts} = loadPublishedProducts.actions;

export default loadPublishedProducts.reducer;
