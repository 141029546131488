import React from 'react';
import {Outlet} from 'react-router-dom';
import debounce from 'lodash.debounce';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Search, Grid, ListTask} from 'react-bootstrap-icons';
import {
  ELoadingStates,
  ICollectionsRequest,
  ICollectionResponse,
} from '@gfxco/contracts';

import {ReactComponent as ImageIcon} from '../../assets/icons/image-icon.svg';
import {ReactComponent as FolderPlusIcon} from '../../assets/icons/folder-plus-icon.svg';
import {ReactComponent as FolderIcon} from '../../assets/icons/folder-icon.svg';
import {ReactComponent as EmptyFolderIcon} from '../../assets/icons/empty-folder-icon.svg';

import {useAppSelector, useAppDispatch} from '../../app/hooks';
import useQueryParams from '../../hooks/useQueryParams';
import {selectShop} from '../../features/shops/selectedShop';
import ImageFilters from './ImageFilters';
import Loading from '../Loading';
import {
  getCollectionsAsync,
  selectCollections,
  selectCollectionsFetchStatus,
  resetCollectionDetails,
  selectTotalCollections,
} from '../../features/collections/loadCollectionsDetails';

import {
  getCollectionsCountAsync,
  resetCollectionsCountFetchStatus,
  selectCollectionsCount,
  selectCollectionsCountFetchStatus,
} from '../../features/collections/loadCollectionsCount';

import {resetCollections} from '../../features/collections/loadCollections';

import CollectionItem from './CollectionItem';
import CollectionsWizard from './CollectionsWizard';
import './ImageDashboard.scss';

interface RequiredProps {
  handleOnDeleteCollection: (id: number) => void;
  handleOnDeleteImage: (id: number) => void;
}

interface OptionalProps {
  confirmImageDeleted?: number;
}
type ImageDashboardProps = RequiredProps & OptionalProps;

const ImageDashboard: React.FC<ImageDashboardProps> = (props) => {
  const shopSelected = useAppSelector(selectShop);
  const [queryParams, setQueryParams] = useQueryParams();
  const shopId = shopSelected?.id;
  const [offset, setOffset] = React.useState(0);
  const limit = 10;
  const preview = queryParams.preview || 'gallery';
  const collections = useAppSelector(selectCollections);
  const collectionsFetchStatus = useAppSelector(selectCollectionsFetchStatus);
  const totalCollections = useAppSelector(selectTotalCollections);

  const collectionsCount = useAppSelector(selectCollectionsCount);
  const collectionsCountFetchStatus = useAppSelector(
    selectCollectionsCountFetchStatus,
  );

  const [searchBy, setSearchBy] = React.useState('');
  const [collectionSelected, setCollectionSelected] = React.useState<
    number | undefined
  >();
  const [collectionEditing, setCollectionEditing] =
    React.useState<ICollectionResponse>();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [successMessage, setSuccessMessage] = React.useState<boolean>(false);
  const [parentId, setParentId] = React.useState<number>();
  const dispatch = useAppDispatch();
  const navigationRef = React.useRef<HTMLDivElement>(null);
  const timeoutRef = React.useRef<number | null>(null);

  React.useEffect(() => {
    const sortBy = queryParams.sortBy || 'newest';
    const search = queryParams.search || '';
    const preview = queryParams.preview || 'gallery';

    setQueryParams({...queryParams, sortBy, search, preview});
  }, []);

  React.useEffect(() => {
    if (collectionsFetchStatus === ELoadingStates.IDLE) {
      getCollections();
    }
  }, [shopId, collectionsFetchStatus]);

  React.useEffect(() => {
    if (collectionsCountFetchStatus === ELoadingStates.IDLE) {
      getCollectionsCount();
    }
  }, [shopId, collectionsCountFetchStatus]);

  React.useEffect(() => {
    getCollections();
  }, [offset]);

  React.useEffect(() => {
    dispatch(resetCollectionDetails());
    setOffset(0);
  }, [searchBy]);

  React.useEffect(() => {
    const collectionId = queryParams.collectionId;
    const collectionIdSelected = collectionId ? +collectionId : undefined;
    setCollectionSelected(collectionIdSelected);
  }, [queryParams.collectionId]);

  const handleScroll = () => {
    const container = navigationRef.current;
    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      if (
        scrollHeight - scrollTop - clientHeight < 100 ||
        collectionsFetchStatus === ELoadingStates.LOADING ||
        collections?.length === totalCollections
      ) {
        return;
      }
      setOffset(offset + limit);
    }
  };

  const handlePreviewChange = (type: 'list' | 'gallery') => {
    setQueryParams({...queryParams, preview: type});
  };

  const getCollections = () => {
    const params: ICollectionsRequest = {
      shopId,
      offset,
      limit,
    };
    if (searchBy.length) params.name = searchBy;
    dispatch(getCollectionsAsync(params));
  };

  const getCollectionsCount = () => {
    if (shopId) dispatch(getCollectionsCountAsync(shopId));
  };

  React.useEffect(() => {
    const container = navigationRef.current;
    if (collectionsFetchStatus === ELoadingStates.LOADED && container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [collectionsFetchStatus]);

  React.useEffect(() => {
    resetValues();
  }, [shopSelected]);

  if (collectionsFetchStatus === ELoadingStates.FAILED) {
    return <div>Error loading Lists!.</div>;
  }

  const resetValues = () => {
    dispatch(resetCollectionDetails());
    dispatch(resetCollections());
    dispatch(resetCollectionsCountFetchStatus());
    setOffset(0);
    setParentId(undefined);
    setCollectionEditing(undefined);

    // reload collections
    getCollections();
  };

  const EmptyFolders = () => (
    <div className="empty-collections">
      <EmptyFolderIcon />
      <span>{`Looks like you haven't created any collection yet`}</span>
    </div>
  );

  const handleOpenModal = (params?: {
    parentId?: number;
    collection?: ICollectionResponse;
  }) => {
    if (params && params.collection) setCollectionEditing(params.collection);
    setShowModal(true);
    setParentId(params?.parentId);
  };

  const handleOnSucess = () => {
    setSuccessMessage(true);
    timeoutRef.current = window.setTimeout(() => {
      setSuccessMessage(false);
    }, 8000);
  };

  const handleDeleteCollections = (id: number) => {
    props.handleOnDeleteCollection(id);
  };

  return (
    <div id="ImageDashboard">
      <div className="dashboard-header">
        <div className="header-title">
          <span className="title-text">Images</span>
          <span className="subtitle-text">
            Create collection of images for your designs
          </span>
        </div>
        <div className="settings-box">
          <div className="setting-item setting-image-item">
            <ImageIcon />
            <div className="item-data">
              <span>Space available:</span>
              <div className="data-used">
                <span className="data-value">230 MB </span>
                <span>of 500 MB</span>
              </div>
              <hr className="usage-bar" />
            </div>
          </div>
          <div className="setting-item setting-collection-item">
            <FolderIcon />
            <div className="item-data">
              <span>Collections created</span>
              <div className="collections-used">
                {collectionsCount &&
                  collectionsCountFetchStatus === ELoadingStates.LOADED && (
                    <span className="data-value">
                      {collectionsCount.total}{' '}
                    </span>
                  )}

                {(collectionsCountFetchStatus === ELoadingStates.IDLE ||
                  collectionsCountFetchStatus === ELoadingStates.LOADING) && (
                  <Loading spinnerOnly />
                )}

                <span>Collections</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="dashboard-container">
          <div>
            <div
              ref={navigationRef}
              id="images-menu"
              className="dashboard-navigation"
            >
              <div className="list-toolbar">
                <InputGroup className="search-bar-group search-bar-list">
                  <InputGroup.Text>
                    <Search />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search collections"
                    onChange={debounce((event) => {
                      setSearchBy(event.target.value);
                    }, 500)}
                    aria-label="SearchBar to filter by design templates name"
                  />
                </InputGroup>
                <button
                  className="add-button"
                  onClick={() => setShowModal(true)}
                >
                  <FolderPlusIcon />
                </button>
              </div>

              {collectionsFetchStatus === ELoadingStates.LOADING &&
                collections &&
                !collections.length && <Loading spinnerOnly />}
              {collectionsFetchStatus === ELoadingStates.LOADED &&
                collections &&
                !collections.length && <EmptyFolders />}
              {collections && !!collections.length && (
                <>
                  {collections.map(
                    ({
                      id,
                      name,
                      description,
                      imageUrl,
                      largeImageUrl,
                      subCollections,
                      imagesCount,
                    }) => (
                      <CollectionItem
                        key={id}
                        id={id}
                        name={name}
                        onDeleteCollection={handleDeleteCollections}
                        description={description}
                        imageUrl={imageUrl}
                        largeImageUrl={largeImageUrl}
                        imagesCount={imagesCount}
                        subCollections={subCollections}
                        onClick={(id: number) => setCollectionSelected(id)}
                        active={id === collectionSelected}
                        openModal={handleOpenModal}
                      />
                    ),
                  )}
                </>
              )}
            </div>
          </div>
          <div className="dashboard-page">
            <ImageFilters shop={shopId} />
            <div className="dashboard-preview">
              <button
                className={`button-preview ${
                  preview === 'list' ? 'button-preview-selected' : ''
                }`}
                onClick={() => handlePreviewChange('list')}
              >
                <ListTask />
              </button>
              <button
                className={`button-preview ${
                  preview === 'gallery' ? 'button-preview-selected' : ''
                }`}
                onClick={() => handlePreviewChange('gallery')}
              >
                <Grid />
              </button>
            </div>
            <div className="content-page">
              <Outlet />
            </div>
            {successMessage && (
              <div className="success-tooltip">
                <FolderIcon />
                <div>
                  <span>Your collection has been</span>
                  <span className="success-text"> Updated</span>
                  <span> successfully</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CollectionsWizard
        onDeleteImage={props.handleOnDeleteImage}
        confirmImageDeleted={props.confirmImageDeleted}
        open={showModal}
        onClose={() => {
          setShowModal(false);
          resetValues();
        }}
        parentId={parentId}
        collectionDraft={collectionEditing}
        onSucess={handleOnSucess}
      />
    </div>
  );
};

export default ImageDashboard;
