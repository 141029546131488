import React, {useState, useRef, useEffect} from 'react';
import {Search} from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import debounce from 'lodash.debounce';
import GarmentColor from '../../../../../components/ShareActions/GarmentColor';
import Tags from '../../../../../components/ShareActions/Tags';
import ImageAction from '../../../../../components/ShareActions/ImageAction';
import {ReactComponent as NotFound} from '../../../../../assets/icons/not-found.svg';

import {IImage, IColor} from '@gfxco/contracts';
import {IList, ITagList} from '../../types';
import './DropdownList.scss';

type RequiredProps = {
  type: string;
  list: (IColor | ITagList | IImage)[];
  onClose: () => void;
};

type OptionalProps = {
  onSelect?: (item: IList) => void;
  onSelectMulti?: (items: IList[]) => void;
  isMulti?: boolean;
  selectedList?: IList[];
  showHex?: boolean;
};

type ItemProps = {
  item: IImage | IColor | ITagList;
};

type DropdownListProps = RequiredProps & OptionalProps;

const DropdownList: React.FC<DropdownListProps> = ({
  type,
  list,
  onSelect,
  onSelectMulti,
  isMulti = false,
  selectedList = [],
  onClose,
  showHex,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<IList[]>(selectedList);
  const [options, setOptions] = useState<(IColor | ITagList | IImage)[]>(list);
  const [isOpen, setIsOpen] = useState(true);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOnClose);

    return () => {
      document.removeEventListener('mousedown', handleOnClose);
    };
  }, []);

  useEffect(() => {
    setOptions(list);
  }, [list]);

  const handleOnClose = (event: any) => {
    if (divRef?.current && !divRef.current.contains(event.target)) {
      setIsOpen(false);
      onClose();
    }
  };

  const handleOnChange = (value: string) => {
    let optionsList: (IColor | ITagList | IImage)[] = [];
    if (type === 'tag') {
      const listItems = list as ITagList[];
      optionsList = listItems.filter((option) =>
        compareOption(option.tag, value),
      );
    }

    if (type === 'image' || type === 'color') {
      const listItems = (list as IImage[]) || (list as IColor[]);
      optionsList = listItems.filter((option) =>
        compareOption(option.name, value),
      );
    }

    setOptions(optionsList);
  };

  const compareOption = (option: string, filterValue: string) =>
    option.toLowerCase().includes(filterValue.toLowerCase());

  const handleOptionClick = (option: IList) => {
    const {color, tag, image} = option;

    if (isMulti) {
      let newSelectedOptions: IList[] = [];
      if (selectedOptions) {
        let index = -1;

        if (type === 'fillColor' && color) {
          index = selectedOptions.findIndex((o) => o.fillColor === color.hex);
        }

        if (type === 'image' && image) {
          index = selectedOptions.findIndex((o) => o.image?.id === image.id);
        }

        if (type === 'tag' && tag)
          index = selectedOptions.findIndex((o) => o.tag === tag);

        if (index > -1) {
          newSelectedOptions = [
            ...selectedOptions.slice(0, index),
            ...selectedOptions.slice(index + 1),
          ];
        } else if (type === 'fillColor' && color) {
          newSelectedOptions = [...selectedOptions, {fillColor: color.hex}];
        } else {
          newSelectedOptions = [...selectedOptions, option];
        }
      }

      return setSelectedOptions(newSelectedOptions);
    }

    if (onSelect && option) {
      return onSelect(option);
    }
  };

  const handleMultiOptionsSelected = () => {
    if (onSelectMulti && selectedOptions.length) {
      onSelectMulti(selectedOptions);
      onClose();
    }
  };

  const DropdownItem = (props: ItemProps) => {
    const {item} = props;
    if (type === 'color' || type === 'fillColor') {
      const color = item as IColor;
      const selected = selectedOptions.find(
        (o) => o.fillColor === color.hex || o.color?.name === color.name,
      );

      return (
        <GarmentColor
          showTitle={false}
          color={item as IColor}
          onClick={handleOptionClick}
          selected={!!selected}
          showHex={showHex}
          showName={!showHex}
        />
      );
    }

    if (type === 'image') {
      const image = item as IImage;
      return (
        <ImageAction
          name={image?.name as string}
          url={image?.imageUrl as string}
          id={image?.id}
          showName={true}
          onClick={handleOptionClick}
          selected={!!selectedOptions.find((o) => o.image?.id === image?.id)}
        />
      );
    }

    const tagItem = item as ITagList;
    return (
      <Tags
        tagExceptionName={tagItem.tag}
        onClick={handleOptionClick}
        selected={!!selectedOptions.find((o) => o.tag === tagItem.tag)}
      />
    );
  };

  return (
    <div id="DropdownList">
      {isOpen && (
        <div className="selector-list list-toolbar" ref={divRef}>
          <InputGroup className="search-bar-group search-bar-list">
            <InputGroup.Text>
              <Search />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search by name"
              onChange={debounce((event) => {
                handleOnChange(event.target.value);
              }, 500)}
              aria-label="SearchBar to filter by name"
            />
          </InputGroup>
          {!options.length && (
            <div className="not-found-box">
              <NotFound />
              <span className="not-found-title">{`${
                type === 'tag' ? 'collection' : type
              } not found`}</span>
              <span className="not-found-text">{`Looks like the ${
                type === 'tag' ? 'collection' : type
              }s you're looking for does not exist,`}</span>
              <span className="not-found-text">please try again</span>
            </div>
          )}
          {!!options.length && (
            <div
              className={`selector-group ${type === 'tag' ? 'tag-group' : ''} ${
                type === 'image' ? 'image-group' : ''
              } ${isMulti ? 'selector-group-multi' : ''}`}
            >
              {options.map(
                (item: IColor | ITagList | IImage, index: number) => (
                  <DropdownItem
                    item={item}
                    key={`DropdownItem-${type}-${index}`}
                  />
                ),
              )}
            </div>
          )}
          {isMulti && (
            <div className="dropdown-footer">
              <div className="divider"></div>
              <Button className="btn" onClick={handleMultiOptionsSelected}>
                Add to exceptions
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownList;
