import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {selectShop} from '../../../features/shops/selectedShop';
import {
  getImagesAsync,
  resetImagesPages,
  getImagesByPage,
  selectTotalImages,
  fetchStatusByPage,
} from '../../../features/images/loadImages';
import {
  resetCollectionsPages,
  resetCollections,
} from '../../../features/collections/loadCollections';
import {resetCollectionDetails} from '../../../features/collections/loadCollectionsDetails';
import {resetCollectionsCountFetchStatus} from '../../../features/collections/loadCollectionsCount';
import usePagination from '../../../hooks/usePagination';
import useQueryParams from '../../../hooks/useQueryParams';
import {IImageRequest, ELoadingStates} from '@gfxco/contracts';
import PreviewSection from '../../../components/ImageDashboard/PreviewSection';
import CustomPagination from '../../../components/CustomPagination';
import Icon from '../../../components/Icons/Icon';
import './AllImages.scss';
import CollectionsWizard from '../../../components/ImageDashboard/CollectionsWizard';
import ImageBanner from '../../../components/ImageBanner';

interface RequiredProps {
  handleOnDeleteImage: (id: number) => void;
}

interface OptionalProps {}

type AllImagesProps = RequiredProps & OptionalProps;

const AllImages: React.FC<AllImagesProps> = (props) => {
  const [queryParams] = useQueryParams();
  const [showCollectionModal, setShowCollectionModal] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(true);
  const sortBy = queryParams.sortBy || 'newest';
  const search = queryParams.search || '';
  const preview = queryParams.preview || 'gallery';
  const [{offset, currentPage, limit}, handlePagination] = usePagination();
  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;

  const images = useAppSelector((state) => getImagesByPage(state, currentPage));
  const totalImages = useAppSelector((state) => selectTotalImages(state));
  const imagesStatus = useAppSelector((state) =>
    fetchStatusByPage(state, currentPage),
  );

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (imagesStatus === ELoadingStates.IDLE) {
      const params: IImageRequest & {page: number} = {
        shopId,
        offset,
        limit,
        page: currentPage,
        order: sortBy === 'newest' ? 'desc' : 'asc',
      };
      if (search) params.name = search;
      dispatch(getImagesAsync(params));
    }
  }, [shopId, offset, imagesStatus, limit]);

  React.useEffect(() => {
    handlePagination('reset');
    dispatch(resetImagesPages());
  }, [queryParams.sortBy, queryParams.search, shopSelected]);

  const handleOnReset = () => {
    dispatch(resetCollectionDetails());
    dispatch(resetCollectionsPages());
    dispatch(resetCollectionsCountFetchStatus());
    dispatch(resetCollections());
  };

  const handleOnCloseCollectionModal = () => {
    handleOnReset();
    setShowCollectionModal(false);
  };

  if (
    imagesStatus === ELoadingStates.LOADED &&
    (!images || images.length === 0)
  ) {
    return (
      <div id="AllImages">
        <ImageBanner show={showBanner} onClose={() => setShowBanner(false)} />
        <Card>
          <Card.Body className="empty-images">
            <Icon name="plusFolder" />
            <p>
              Create your first collection <span>to upload your images.</span>
            </p>
            <Button onClick={() => setShowCollectionModal(true)}>
              Let&apos;s Start!
            </Button>
          </Card.Body>
        </Card>
        <CollectionsWizard
          onDeleteImage={props.handleOnDeleteImage}
          open={showCollectionModal}
          onClose={handleOnCloseCollectionModal}
          collectionDraft={undefined}
        />
      </div>
    );
  }

  if (imagesStatus === ELoadingStates.FAILED) {
    return <div>Error loading images!.</div>;
  }

  return (
    <div id="AllImages">
      <PreviewSection
        type="images"
        list={images}
        handleOnClick={() => {}}
        onDeleteImage={props.handleOnDeleteImage}
        isLoading={imagesStatus !== ELoadingStates.LOADED}
        listView={preview === 'list'}
      />
      <CustomPagination
        limit={limit}
        currentPage={currentPage}
        total={totalImages}
        handlePagination={handlePagination}
        entity="Images"
      />
    </div>
  );
};

export default AllImages;
