import React from 'react';
import {Trash, Pencil, ChevronUp, ChevronDown} from 'react-bootstrap-icons';
import {ReactComponent as ImageSlotIcon} from '../../../assets/icons/image-slot-icon.svg';
import {ReactComponent as TextSlotIcon} from '../../../assets/icons/text-slot-icon.svg';
import {IColor, ILinkedSlotAction, ILegacyImage} from '@gfxco/contracts';
import {Actions} from '../../../libs/getLinkedSlotActions';
import {colors as defaultTextColors} from '../../../libs/getDefaultTextColors';
import './ActionItem.scss';
import ItemColor from '../ItemColor';
import ItemSlot from '../ItemSlot';
import ItemTextbox from '../LinkedSlotWizard/SelectorBox/ItemTextbox';

export interface RequiredProps {
  comparison: string;
  onDelete: (key: number) => void;
  onEdit: (key: number) => void;
  linkedSlotId: number;
  colors: IColor[];
  frontSlots: any[];
  backSlots: any[];
}
export interface OptionalProps {
  color?: IColor;
  text?: string;
  image?: ILegacyImage;
  slotId?: number;
  actions?: ILinkedSlotAction[];
}

type ActionItemProps = RequiredProps & OptionalProps;

export const ActionItem: React.FC<ActionItemProps> = ({
  comparison,
  image,
  color,
  actions,
  slotId,
  onDelete,
  onEdit,
  linkedSlotId,
  colors,
  frontSlots,
  backSlots,
  text,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const getColorSelected = (colorName: string) =>
    colors.find((color) => color.name === colorName);

  const getColorSelectedByHex = (hex: string) =>
    defaultTextColors.find((color) => color.hex === hex);

  const getSideSelected = (slotId: number) => {
    const frontSlot = frontSlots.find((slot) => slot.slotId === slotId);
    if (frontSlot) return 'front';
    const backSlot = backSlots.find((slot) => slot.slotId === slotId);
    if (backSlot) return 'back';
  };

  const getSlotName = (slotId: number) => {
    let slot = frontSlots.find((slot) => slot.slotId === slotId);
    let index = frontSlots.findIndex((slot) => slot.slotId === slotId);
    if (!slot) {
      slot = backSlots.find((slot) => slot.slotId === slotId);
      index = backSlots.findIndex((slot) => slot.slotId === slotId);
    }

    return slot?.alias || `Slot name ${index + 1}`;
  };

  const getDefaultImage = (slotId: number) => {
    let slot = frontSlots.find((slot) => slot.slotId === slotId);
    if (!slot) {
      slot = backSlots.find((slot) => slot.slotId === slotId);
    }

    if (!slot) return '';
    return slot.objects[0].src || '';
  };

  const colorActions =
    actions?.filter(({action}) => action === Actions.Color) || [];
  const textActions =
    actions?.filter(
      ({action}) => action === Actions.Fill || action === Actions.Reflects,
    ) || [];
  const imageActions =
    actions?.filter(({action}) => action === Actions.Slot) || [];

  const Item = (props: {
    action: string;
    image?: ILegacyImage;
    slotId?: number;
    colorName?: string;
    text?: string;
  }) => {
    return (
      <>
        {props.action === Actions.Slot && props.slotId && (
          <ItemSlot
            image={props.image}
            side={getSideSelected(props.slotId)}
            slotName={getSlotName(props.slotId)}
            showIcon={false}
            bordered
          />
        )}
        {props.action === Actions.Color && props.colorName && (
          <ItemColor color={getColorSelected(props.colorName)!} />
        )}
        {(props.action === Actions.Fill || props.action === Actions.Stroke) &&
          props.text &&
          props.slotId && (
            <>
              <ItemSlot
                color={getColorSelectedByHex(props.text)!}
                side={getSideSelected(props.slotId)}
                slotName={getSlotName(props.slotId)}
                defaultImage={getDefaultImage(props.slotId)}
                showIcon={false}
                bordered
              />
              <div>
                <ItemTextbox
                  type={Actions.Fill}
                  text={props.text}
                  compact={!open}
                />
              </div>
            </>
          )}
        {props.action === Actions.Reflects && props.slotId && slotId && (
          <>
            <ItemSlot
              side={getSideSelected(props.slotId)}
              slotName={getSlotName(props.slotId)}
              defaultImage={getDefaultImage(props.slotId)}
              showIcon={false}
              bordered
            />
            <div>
              <ItemTextbox type={Actions.Reflects} compact={!open} />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div id="ActionItem">
      <div className="actions">
        <div id="First" className="container first-action">
          <div className="description">
            <span className="action-text">IF </span>
            <span className="action-subtitle-text">
              (when the user selects this)
            </span>
          </div>
          <div
            className={`item ${
              !open &&
              (comparison === Actions.Fill || comparison === Actions.Reflects)
                ? 'compact-box'
                : ''
            }`}
          >
            <div className="item-body">
              {comparison === Actions.Slot && slotId && (
                <ItemSlot
                  image={image}
                  side={getSideSelected(slotId)}
                  slotName={getSlotName(slotId)}
                  bordered
                />
              )}
              {comparison === Actions.Color && color && (
                <ItemColor color={color} />
              )}
              {(comparison === Actions.Fill || comparison === Actions.Stroke) &&
                slotId &&
                text && (
                  <>
                    <ItemSlot
                      color={getColorSelectedByHex(text)!}
                      side={getSideSelected(slotId)}
                      slotName={getSlotName(slotId)}
                      defaultImage={getDefaultImage(slotId)}
                      bordered
                    />
                    <div className={`${!open ? 'compact-box-text' : ''}`}>
                      <ItemTextbox
                        type={Actions.Fill}
                        text={text}
                        compact={!open}
                      />
                    </div>
                  </>
                )}
              {comparison === Actions.Reflects && slotId && (
                <>
                  <ItemSlot
                    side={getSideSelected(slotId)}
                    slotName={getSlotName(slotId)}
                    showIcon={false}
                    defaultImage={getDefaultImage(slotId)}
                    bordered
                  />
                  <div className={`${!open ? 'compact-box-text' : ''}`}>
                    <ItemTextbox type={Actions.Reflects} compact={!open} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div id="Second" className="container second-action">
          <div className="description">
            <span className="action-text">THEN </span>
            <span className="action-subtitle-text">(will reflect this)</span>
          </div>
          <div className="item">
            <div className="collapsed-item">
              <div className="collapsed-column">
                <div className="collapsed-column-header">
                  <ImageSlotIcon />
                  <span className="collapsed-item-title">Image slots</span>
                </div>
                {!open && (
                  <span className="collapsed-item-count">{`${imageActions.length} slots`}</span>
                )}

                {open &&
                  !!imageActions.length &&
                  imageActions.map(({action, image, slotId, color, text}) => (
                    <Item
                      key={`action-${slotId}`}
                      action={action}
                      image={image}
                      slotId={slotId}
                      colorName={color}
                      text={text}
                    />
                  ))}
                {open && !imageActions.length && <span>-</span>}
              </div>
              <div className="collapsed-column">
                <div className="collapsed-column-header">
                  <TextSlotIcon />
                  <span className="collapsed-item-title">Text slots</span>
                </div>
                {!open && (
                  <span className="collapsed-item-count">{`${textActions.length} slots`}</span>
                )}
                {open &&
                  !!textActions.length &&
                  textActions.map(({action, image, slotId, color, text}) => (
                    <Item
                      key={`action-${slotId}`}
                      action={action}
                      image={image}
                      slotId={slotId}
                      colorName={color}
                      text={text}
                    />
                  ))}
                {open && !textActions.length && <span>-</span>}
              </div>
              <div className="collapsed-column">
                <div className="collapsed-column-header">
                  <TextSlotIcon />
                  <span className="collapsed-item-title">Item Color</span>
                </div>
                {!open && (
                  <span className="collapsed-item-count">{`${colorActions.length} Color`}</span>
                )}
                {open &&
                  !!colorActions.length &&
                  colorActions.map(({action, image, slotId, color, text}) => (
                    <Item
                      key={`action-${slotId}`}
                      action={action}
                      image={image}
                      slotId={slotId}
                      colorName={color}
                      text={text}
                    />
                  ))}
                {open && !colorActions.length && <span>-</span>}
              </div>
            </div>
          </div>
        </div>

        <div className="icon-container">
          <div onClick={() => setOpen(!open)} className="icon">
            {open ? <ChevronUp /> : <ChevronDown />}
          </div>

          <div className="icon" onClick={() => onEdit(linkedSlotId)}>
            <Pencil />
          </div>

          <div className="icon" onClick={() => onDelete(linkedSlotId)}>
            <Trash />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionItem;
