import React from 'react';
import Nav from 'react-bootstrap/Nav';
import './UnderlineTabs.scss';

interface RequiredProps {
  tabs: {
    name: string;
    path: string;
    key: number;
  }[];
  defaultActiveTab: number;
}

interface OptionalProps {}

type UnderlineTabsProps = RequiredProps & OptionalProps;

const UnderlineTabs: React.FC<UnderlineTabsProps> = ({
  tabs,
  defaultActiveTab,
}) => {
  const [activeTab] = React.useState(defaultActiveTab);
  return (
    <Nav
      id="UnderlineTabs"
      variant="underline"
      defaultActiveKey={defaultActiveTab}
      activeKey={activeTab}
    >
      {tabs.map((tab) => (
        <Nav.Item key={tab.key}>
          <Nav.Link
            href={tab.path}
            key={tab.key}
            onClick={(e) => {
              e.preventDefault();
            }}
            active={activeTab === tab.key}
          >
            {tab.name}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default UnderlineTabs;
