import React from 'react';
import {Form, InputGroup, Spinner} from 'react-bootstrap';
import debounce from 'lodash.debounce';
import {Search, XCircle} from 'react-bootstrap-icons';
import {ELoadingStates, IImage} from '@gfxco/contracts';

import CustomSelector from '../CustomSelector';
import GFXImage from '../Image';
import FileInput from '../FileInput';
import {GFXToastLaunch} from '../ToastMessage/ToastMessage';
import uploadImage, {IImageFile, updateFileName} from '../../libs/uploadImage';

import './ImageSelector.scss';

interface Required {
  shopId: number;
  images: IImage[];
}

interface Optional {
  value?: IImage;
  disabled?: boolean;
  onChange?: (image: IImage | null) => void;
  fileFolder?: string;
  onUploadImage?: (image: IImageFile) => void;
}

type Props = Required & Optional;

const ImageSelector: React.FC<Props> = (props) => {
  const [innerImages, setInnerImages] = React.useState<IImage[]>(props.images);
  const [imageSelected, setImageSelected] = React.useState<IImage | undefined>(
    props.value,
  );
  const [uploadFetchStatus, setUploadFetchStatus] =
    React.useState<ELoadingStates>(ELoadingStates.IDLE);

  const filterImages = (value: string) => {
    if (!value) {
      setInnerImages(props.images);
      return;
    }
    setInnerImages(() => {
      return [...props.images].filter(
        (image) =>
          image.name.toLowerCase().includes(value.toLowerCase()) ||
          image.imageUrl?.toLowerCase().includes(value.toLowerCase()),
      );
    });
  };

  const handleActionClick = () => {
    if (!imageSelected) {
      return;
    }
    if (props.onChange) {
      props.onChange(imageSelected);
    }
  };

  const handleFileChange = async (file: File) => {
    setUploadFetchStatus(ELoadingStates.LOADING);
    const newFileName = updateFileName(file.name, file.type);
    const imageUploaded = await uploadImage({
      file,
      fileName: newFileName,
      shopId: props.shopId,
      fileFolder: props.fileFolder,
      omitImageReference: true,
      limits: {
        width: 512,
        height: 512,
        weight: 1,
      },
    });

    if (props.onUploadImage) {
      props.onUploadImage(imageUploaded);
    }

    return imageUploaded;
  };

  const handleFileUploaded = (imagesLoaded: IImage[]) => {
    if (!imagesLoaded[0] || imagesLoaded[0].isError) {
      setUploadFetchStatus(ELoadingStates.FAILED);
      GFXToastLaunch(imagesLoaded[0].error ?? 'An error occurred.', 5000, {
        showAt: 'bottom',
        showIcon: true,
        alertType: 'danger',
      });
      return;
    }

    setUploadFetchStatus(ELoadingStates.LOADED);
  };

  React.useEffect(() => {
    setImageSelected(props.value);
  }, [props.value]);

  React.useEffect(() => {
    setInnerImages(props.images);
  }, [props.images]);

  const handleOnClear = () => {
    setImageSelected(undefined);
    setUploadFetchStatus(ELoadingStates.IDLE);
  };

  return (
    <CustomSelector
      text={imageSelected ? `${imageSelected.name}` : 'Select an icon'}
      disabled={props.disabled}
      actionText="Add image"
      action={() => handleActionClick()}
      onClear={() => handleOnClear()}
      disabledAction={!imageSelected}
    >
      <div className="image-selector">
        <InputGroup className="search-bar-group search-image-selector">
          <InputGroup.Text>
            <Search />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search by name"
            onChange={debounce((event) => {
              filterImages(event.target.value);
            }, 500)}
            aria-label="Search by name"
          />
        </InputGroup>
        <div className="image-list">
          <FileInput
            text={'Upload your icon'}
            onFileChange={handleFileChange}
            onFileLoaded={handleFileUploaded}
            acceptFiles={'.png,.jpg,.jpeg'}
          />

          {uploadFetchStatus === ELoadingStates.LOADING && (
            <div key={-1} className="walkthrough-image-container status">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          {uploadFetchStatus === ELoadingStates.FAILED && (
            <div key={-1} className="walkthrough-image-container status">
              <XCircle color="red" />
            </div>
          )}
          {innerImages.map((image, index) => {
            return (
              <ImageContainer
                key={index}
                selected={
                  imageSelected
                    ? imageSelected?.imageUrl === image.imageUrl
                    : false
                }
                image={image}
                onSelect={(image) => setImageSelected(image)}
              />
            );
          })}
        </div>
      </div>
    </CustomSelector>
  );
};

type ImageContainerProps = {
  image: IImage;
  onSelect: (image: IImage | undefined) => void;
  selected: boolean;
};

const ImageContainer: React.FC<ImageContainerProps> = (props) => {
  const handleSelect = (image: IImage) => {
    props.onSelect(image);
  };

  return (
    <div
      className={`walkthrough-image-container ${
        props.selected ? 'walkthrough-image-container--selected' : ''
      }`}
      onClick={() => handleSelect(props.image)}
    >
      <div className="walkthrough-image-container__icon">
        <Form.Check
          type={'checkbox'}
          className="walkthrough-image-check"
          readOnly
          disabled
          checked={props.selected}
        />
        <GFXImage
          src={props.image.imageUrl}
          alt={props.image.name || props.image.imageUrl}
        />
      </div>
      <div className="walkthrough-image-container__name">
        <span>{props.image.name}</span>
      </div>
    </div>
  );
};

export default ImageSelector;
