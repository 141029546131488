import {
  ELoadingStates,
  IExceptionResult,
  IGetExceptionsRequest,
} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import ExceptionsApi from '../../api/exceptions';
import {RootState} from '../../app/store';

export interface ExceptionsState {
  value?: IExceptionResult[];
  fechStatus: string;
  total?: number;
}

const initialState: ExceptionsState = {
  value: [],
  fechStatus: ELoadingStates.IDLE,
  total: 0,
};

export const getExceptionsAsync = createAsyncThunk(
  'exceptions/fetch',
  async (parameters: IGetExceptionsRequest) => {
    const response = await ExceptionsApi.getExceptions(parameters);
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const loadExceptions = createSlice({
  name: 'exceptions',
  initialState,
  reducers: {
    resetExceptions: (state) => {
      state = initialState;
      return state;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getExceptionsAsync.pending, (state) => {
        state.fechStatus = ELoadingStates.LOADING;
      })
      .addCase(getExceptionsAsync.fulfilled, (state, action) => {
        state.fechStatus = ELoadingStates.LOADED;
        state.value = action.payload?.results;
        state.total = action.payload?.total;
      })
      .addCase(getExceptionsAsync.rejected, (state) => {
        state.fechStatus = ELoadingStates.FAILED;
      });
  },
});

export const selectExceptions = (state: RootState) => state.exceptions.value;
export const selectTotalExceptions = (state: RootState) => {
  return state.exceptions.total || 0;
};

export const selectExceptionsFetchStatus = (state: RootState) =>
  state.exceptions.fechStatus || ELoadingStates.IDLE;

export const {resetExceptions} = loadExceptions.actions;
export default loadExceptions.reducer;
