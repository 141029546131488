import React, {FormEvent} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {View} from '@aws-amplify/ui-react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PasswordField from '../../../components/PasswordField';
import useQueryParams from '../../../hooks/useQueryParams';

import '@aws-amplify/ui-react/styles.css';
import './SignInForm.scss';

export interface ILoginProps {
  username: string;
  password: string;
  keepSignedIn: boolean;
}

interface RequiredProps {
  handleLogin: (data: ILoginProps) => void;
}

interface OptionalProps {}

type SignInFormProps = RequiredProps & OptionalProps;

const SignInForm: React.FC<SignInFormProps> = ({handleLogin}) => {
  const [signInStatus, setSignInStatus] = React.useState('');
  const [usernameError, setUsernameError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [formError, setFormError] = React.useState('');
  const navigate = useNavigate();

  const [queryParams] = useQueryParams();
  const params = decodeURIComponent(
    new URLSearchParams(queryParams).toString(),
  );

  const isLoginLoading =
    signInStatus === 'loading' || signInStatus === 'success';

  const handleSignIn = async (event: FormEvent) => {
    setSignInStatus('loading');
    setPasswordError('');
    setUsernameError('');
    setFormError('');
    event.preventDefault();
    event.stopPropagation();
    const target = event.target as HTMLFormElement;
    let username = target.username.value;
    const password = target.password.value;
    const keepSignedIn = target.signedIn.checked;

    let isFormErrored = false;

    if (!password) {
      setPasswordError('Please enter your password');
      isFormErrored = true;
    }

    if (!username) {
      setUsernameError('Please enter your username');
      isFormErrored = true;
    }

    if (isFormErrored) {
      setSignInStatus('error');
      return;
    }

    username = username.toLowerCase();

    try {
      await handleLogin({username, password, keepSignedIn});

      setSignInStatus('success');
    } catch (error: any) {
      if (error.name === 'UserNotConfirmedException') {
        navigate(`/signup/confirm?${params}`, {state: {username, password}});
      }
      setSignInStatus('error');
      setFormError(error.message);
      console.error('Error signing in', error);
    }
  };

  return (
    <div id="SignInForm">
      <View className="auth-wrapper">
        <h2 className="sign-in-header">Login to your account</h2>
        <Form className="sign-in-form" noValidate onSubmit={handleSignIn}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>
              Enter your email address:
              <span className="required-symbol">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              name="username"
              className={`${usernameError ? 'form-error' : ''}`}
              placeholder="Your email address"
              required
            />
            {usernameError && (
              <span className="form-feedback-error">{usernameError}</span>
            )}
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>
              Enter your password <span className="required-symbol">*</span>
            </Form.Label>
            <PasswordField
              name="password"
              className={`${passwordError ? ' input-error' : ''}`}
            />
            {passwordError && (
              <span className="form-feedback-error">{passwordError}</span>
            )}
          </Form.Group>
          <Form.Group controlId="keep-signed-in">
            <Form.Check
              id="keep-signed-in"
              name="signedIn"
              type="checkbox"
              label="Keep me signed in"
            />
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Link className="form-action-link" to="/forgot-password">
              Forgot password?
            </Link>
          </Form.Group>
          {formError && (
            <span className="form-feedback-error">{formError}</span>
          )}
          <Button variant="primary" type="submit" disabled={isLoginLoading}>
            {isLoginLoading ? 'Loading...' : 'Log in'}
          </Button>
        </Form>
        <p className="create-account-message">
          Don&apos;t have an account?{' '}
          <a href={`/signup?${params}`}>Create your account now</a>
        </p>
      </View>
    </div>
  );
};

export default SignInForm;
