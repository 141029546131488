import React from 'react';
import Icon from '../../../components/Icons/Icon';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

type PrinterTitleProps = {
  icon: 'samplePortal' | 'sendArts';
  title: string;
  description: string;
  tooltipId: string;
};

const PrinterTitle: React.FC<PrinterTitleProps> = ({
  icon,
  title,
  description,
  tooltipId,
}) => (
  <>
    <Icon name={icon} />
    <h4>{title}</h4>
    <OverlayTrigger
      placement="right"
      delay={{show: 200, hide: 400}}
      overlay={<Tooltip id={tooltipId}>{description}</Tooltip>}
    >
      <span className="d-inline-block tooltip-icon">
        <Icon name="information" />
      </span>
    </OverlayTrigger>
  </>
);

export default PrinterTitle;
