"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EOrderItemHistoryAction = exports.EModerationItemStatus = void 0;
var EModerationItemStatus;
(function (EModerationItemStatus) {
    EModerationItemStatus["PENDING"] = "pending";
    EModerationItemStatus["REJECTED"] = "rejected";
    EModerationItemStatus["ACCEPTED"] = "accepted";
    EModerationItemStatus["ON_HOLD"] = "on-hold";
})(EModerationItemStatus = exports.EModerationItemStatus || (exports.EModerationItemStatus = {}));
var EOrderItemHistoryAction;
(function (EOrderItemHistoryAction) {
    EOrderItemHistoryAction["FLAG"] = "flag";
    EOrderItemHistoryAction["REJECT"] = "reject";
    EOrderItemHistoryAction["ACCEPT"] = "accept";
    EOrderItemHistoryAction["COMMENT"] = "comment";
})(EOrderItemHistoryAction = exports.EOrderItemHistoryAction || (exports.EOrderItemHistoryAction = {}));
