import React from 'react';
import {XCircle, CheckCircle} from 'react-bootstrap-icons';
import {EModerationItemStatus, IOrderItemHistory} from '@gfxco/contracts';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '../../../libs/localStorageUtils';

import Comment from './Comment';
import ActionButton from './ActionButton';
import RejectTooltip from './RejectTooltip';
import './ActionsView.scss';

interface RequiredProps {
  itemId: number;
  onAction: (
    status: EModerationItemStatus,
    id: number,
    isComment?: boolean,
    details?: string,
  ) => void;
  disabled: boolean;
  onDeleteComment: (id: number) => void;
  onEditComment: (id: number, details: string) => void;
  onWait: (status: boolean) => void;
  printerIntegrated: boolean;
}

interface OptionalProps {
  isLoading?: boolean;
  comments?: IOrderItemHistory[];
  status: EModerationItemStatus;
}
type ActionsViewProps = RequiredProps & OptionalProps;

const ActionsView: React.FC<ActionsViewProps> = ({
  itemId,
  onAction,
  isLoading,
  disabled,
  comments,
  onDeleteComment,
  status,
  onEditComment,
  onWait,
  printerIntegrated,
}) => {
  const disableRejectWarning = getFromLocalStorage('disableRejectWarning');
  const [isAcceptWaiting, setIsAcceptWaiting] = React.useState(false);
  const [isAcceptEnable, setAcceptEnable] = React.useState(false);
  const timeoutRef = React.useRef<number | null>(null);
  const [isRejectWaiting, setIsRejectWaiting] = React.useState(false);
  const [isRejectEnable, setRejectEnable] = React.useState(false);
  const [isRejectWarning, setIsRejectWarning] = React.useState(false);

  const isCommentDisabled =
    status === EModerationItemStatus.ACCEPTED ||
    status === EModerationItemStatus.REJECTED;

  React.useEffect(() => {
    if (isAcceptEnable) {
      handleAction(EModerationItemStatus.ACCEPTED);
      setAcceptEnable(false);
    }
    if (isRejectEnable) {
      handleAction(EModerationItemStatus.REJECTED);
      setRejectEnable(false);
    }
  }, [isAcceptEnable, isRejectEnable]);

  const handleAcceptClick = () => {
    if (
      status !== EModerationItemStatus.PENDING ||
      disabled ||
      !printerIntegrated
    )
      return;
    if (isAcceptWaiting) {
      clearTimeout(timeoutRef.current!);
      onWait(false);
      return setIsAcceptWaiting(false);
    }

    setIsAcceptWaiting(true);
    onWait(true);
    timeoutRef.current = window.setTimeout(() => {
      setIsAcceptWaiting(false);
      onWait(false);
      setAcceptEnable(true);
    }, 2000);
  };

  const handleRejectClick = () => {
    if (status !== EModerationItemStatus.PENDING || disabled) return;

    if (!disableRejectWarning && !comments?.length) {
      return setIsRejectWarning(true);
    }

    if (isRejectWaiting) {
      clearTimeout(timeoutRef.current!);
      onWait(false);
      return setIsRejectWaiting(false);
    }

    setIsRejectWaiting(true);
    onWait(true);
    timeoutRef.current = window.setTimeout(() => {
      setIsRejectWaiting(false);
      onWait(false);
      setRejectEnable(true);
    }, 2000);
  };

  const handleAction = (status: EModerationItemStatus) => {
    if (disabled) return;
    return onAction(status, itemId);
  };

  const handleDisableRejectWarning = (allowDisable: boolean) => {
    if (allowDisable) saveToLocalStorage('disableRejectWarning', true);
    setIsRejectWarning(false);
  };

  return (
    <div id="ActionsView">
      <Comment
        itemId={itemId}
        onAction={onAction}
        onDeleteComment={onDeleteComment}
        isLoading={isLoading}
        comments={comments}
        disabled={isCommentDisabled}
        onEditComment={onEditComment}
      />
      <ActionButton
        type="Reject"
        isActive={status === EModerationItemStatus.REJECTED}
        disabled={disabled}
        isWaiting={isRejectWaiting}
        onClick={handleRejectClick}
        icon={<XCircle fontSize={'2rem'} />}
      />
      {!disableRejectWarning && isRejectWarning && (
        <RejectTooltip
          onClose={() => setIsRejectWarning(false)}
          onAccept={handleDisableRejectWarning}
        />
      )}
      <ActionButton
        type="Accept"
        isActive={status === EModerationItemStatus.ACCEPTED}
        disabled={disabled || !printerIntegrated}
        isWaiting={isAcceptWaiting}
        onClick={handleAcceptClick}
        icon={<CheckCircle fontSize={'2rem'} />}
        printerIntegrated={printerIntegrated}
      />
    </div>
  );
};

export default ActionsView;
