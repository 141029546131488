module.exports = Object.freeze({
  AUTH_SESSION_STATUSES: {
    LOGIN: 'LOGIN',
    SIGN_IN: 'SIGN_IN',
    LOGOUT: 'LOGOUT',
    COMPLETE_PASSWORD: 'COMPLETE_PASSWORD',
  },
  AUTH_STATUSES: {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
  },
  SESSION_STORAGE_KEYS: {
    SELECTED_SHOP: 'SELECTED_SHOP',
  },
});
