import {IColor} from '@gfxco/contracts';
import React from 'react';
import {ExclamationCircle, XCircle} from 'react-bootstrap-icons';
import IconButton from '@mui/material/IconButton';
import ColorItem from '../../ConfigureProductInfo/Items/ColorItem';

import './GarmentColor.scss';

export interface RequiredProps {}
export interface OptionalProps {
  color?: IColor;
  children?: React.ReactNode;
  showTitle?: boolean;
  showName?: boolean;
  showHex?: boolean;
  onClick?: (item: {color: IColor}) => void;
  isAligned?: boolean;
  name?: string;
  title?: string;
  selected?: boolean;
  onDelete?: (param: {color?: IColor; id?: number}) => void;
  showDelete?: boolean;
  id?: number;
}

type GarmentColorProps = RequiredProps & OptionalProps;

export const GarmentColor: React.FC<GarmentColorProps> = ({
  color,
  showTitle = false,
  showName = true,
  showHex = false,
  onClick,
  isAligned = false,
  title = 'Garment color',
  selected = false,
  showDelete = false,
  onDelete,
  id,
}) => {
  const handleOnClick = () => {
    if (onClick && color) {
      onClick({color});
    }
  };

  const handleOnDelete = () => {
    if (onDelete && id) onDelete({id});
    if (onDelete && color) onDelete({color});
  };

  const colorBorderValidation =
    color?.name === 'white' || color?.hex === '#FFFFFF';

  return (
    <div id="GarmentColor" onClick={handleOnClick}>
      <div className={`content ${selected ? 'selected' : ''}`}>
        {showTitle && (
          <>
            <span className="title">{title}</span>
            <div className="vertical-divider"></div>
          </>
        )}
        <div className={`${isAligned ? 'flex-content' : ''}`}>
          {color && color.hex && (
            <>
              {showName && <span className="text">{color?.name || '-'}</span>}
              {showHex && <span className="text">{color?.hex || '-'}</span>}
              <div
                className={`color-option ${
                  colorBorderValidation ? 'border' : ''
                }`}
              >
                <ColorItem
                  color={color}
                  selected={false}
                  onClick={handleOnClick}
                />
                {showDelete && (
                  <IconButton onClick={() => handleOnDelete()}>
                    <XCircle fontSize={'0.8rem'} />
                  </IconButton>
                )}
              </div>
            </>
          )}
          {!color && (
            <>
              <ExclamationCircle size={'1.5em'} color="#f10e06" />
              <span className="text">{'Not loaded'}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default GarmentColor;
