import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Loading from '../Loading';
import StepsComponent from './StepModal/Steps';
import saveIcon from './../../assets/icons/save-icon.svg';
import './StepModal/ModalMiddle.scss';
import {ChevronLeft, ChevronRight} from 'react-bootstrap-icons';

type Steps = {
  name: string;
  component: React.ReactNode;
};

interface RequiredProps {
  isSaveEnabled: boolean;
  steps: Steps[];
  selectedStepNumber: number;
}

interface OptionalProps {
  show?: boolean;
  onCloseModal?: () => void;
  onClickBack?: () => void;
  onClickSave?: () => void;
  isProgressWarningEnabled?: boolean;
  isLoading?: boolean;
  onClickNext?: () => void;
  showNextButton?: boolean;
  showSaveButton?: boolean;
  isNextEnabled?: boolean;
  showSteps?: boolean;
  nextStepLabel?: string;
  saveLabel?: string;
  centered?: boolean;
  isBackDisabled?: boolean;
  showCloseButton?: boolean;
  confirmationStyles?: boolean;
}

type ModalProps = RequiredProps & OptionalProps;

const GFXModal: React.FC<ModalProps> = (props) => {
  const {
    steps,
    selectedStepNumber,
    onCloseModal,
    onClickBack,
    onClickSave,
    isSaveEnabled,
    isProgressWarningEnabled = false,
    show,
    isLoading = false,
    onClickNext,
    showNextButton,
    isNextEnabled,
    showSaveButton = true,
    showSteps = true,
    nextStepLabel = 'Next',
    saveLabel = 'Save Template',
    centered,
    isBackDisabled,
    showCloseButton = true,
    confirmationStyles,
  } = props;

  const [showProgressWarning, setShowProgressWarning] = React.useState(false);
  const [ignoreWarning, setIgnoreWarning] = React.useState(false);
  const [saveStatus, setSaveStatus] = React.useState('iddle');

  React.useEffect(() => {
    if (ignoreWarning) {
      handleCloseModal();
    }
  }, [ignoreWarning]);

  const handleCloseModal = () => {
    if (saveStatus === 'loading') {
      return;
    }

    if (isProgressWarningEnabled && !ignoreWarning) {
      setShowProgressWarning(true);
      return;
    }

    setIgnoreWarning(false);
    if (onCloseModal) {
      onCloseModal();
    }
  };

  const handleBackButtonClick = () => {
    if (onClickBack) {
      onClickBack();
    }
  };

  const handleNextButtonClick = () => {
    if (onClickNext) {
      onClickNext();
    }
  };

  const handleSaveButtonClick = async () => {
    if (onClickSave && isSaveEnabled) {
      setSaveStatus('loading');
      try {
        await onClickSave();
        setSaveStatus('success');
        setShowProgressWarning(false);
      } catch (error) {
        setSaveStatus('errored');
        console.error('Error saving', error);
      }
    }
  };

  const selectedStep = steps[selectedStepNumber - 1];

  const shouldRenderBack = onClickBack && selectedStepNumber !== 1;
  return (
    <>
      <Modal
        className={`ModalMiddle modal-middle-page ${
          centered ? 'modal-footer-centered' : ''
        } ${confirmationStyles ? 'modal-styled' : ''}`}
        backdropClassName="ModalMiddle-backdrop modal-middle-page-backdrop"
        aria-labelledby="modal-middle-page"
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton={showCloseButton}></Modal.Header>

        <Modal.Body>
          {showSteps && (
            <div className="steps-container">
              <div className="steps-container__content">
                {steps.map((step, index) => {
                  const isSelected = index <= selectedStepNumber - 1;
                  return (
                    <StepsComponent
                      completionPercentage={steps.length / (index + 1)}
                      renderLine={index < selectedStepNumber - 1}
                      key={step.name}
                      isSelected={isSelected}
                      index={index}
                      step={step}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <div className="modal-body-content">
            {isLoading ? (
              <div className="modal-loading">
                <Loading text="Loading design data" />
              </div>
            ) : (
              <>
                {selectedStep && selectedStep.component}
                <Alert variant="danger" show={saveStatus === 'errored'}>
                  An error ocurred saving your design. Try again or contact
                  support
                </Alert>
              </>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            className={`${
              centered ? 'modal-footer-centered' : 'navigators-buttons'
            }`}
          >
            {shouldRenderBack && (
              <Button
                variant="secondary"
                onClick={handleBackButtonClick}
                className="step-modal-navigators-button"
                disabled={selectedStepNumber === 1 || isBackDisabled}
              >
                <ChevronLeft />
                Back
              </Button>
            )}
            {showNextButton && onClickNext && (
              <Button
                variant="secondary"
                onClick={handleNextButtonClick}
                className="step-modal-navigators-button step-modal-preview-button"
                disabled={!isNextEnabled}
              >
                {nextStepLabel}
                <ChevronRight />
              </Button>
            )}
          </div>
          {showSaveButton && onClickSave && (
            <Button
              variant="primary"
              className="step-modal-save-button"
              disabled={!isSaveEnabled || saveStatus === 'loading'}
              onClick={handleSaveButtonClick}
            >
              <img src={saveIcon} alt="" />
              {saveStatus === 'loading' ? 'Loading...' : `${saveLabel}`}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        className="modal-progress-warning"
        backdropClassName="modal-progress-warning-backdrop"
        aria-labelledby="modal-progress-warning"
        centered
        show={showProgressWarning}
        onHide={() => setShowProgressWarning(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to close the current design?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If you close the current design, you will lose all unsaved changes.
          Are you sure you want to close it?
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => setShowProgressWarning(false)}
          >
            Keep me here
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setIgnoreWarning(true);
              setShowProgressWarning(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GFXModal;
