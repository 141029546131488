import React from 'react';
import {View} from '@aws-amplify/ui-react';
import GfxLogo from '../../assets/gfx_logo_beta_black.svg';
import GfxLoginLogo from '../../assets/gfx_login_logo.svg';
import SignInCards from '../../components/SignInCards';

import '@aws-amplify/ui-react/styles.css';
import './PublicHomePage.scss';
import CookieBanner from '../../components/CookieBanner';
import {Outlet} from 'react-router-dom';

export default function PublicHomePage() {
  const [isConsentAccepted, setIsConsentAccepted] = React.useState(false);

  return (
    <div id="PublicHomePage">
      <View className="auth-wrapper">
        <div className="wrapper-container">
          <div className="info-login-container">
            <div className="login-logo">
              <span className="wrapper-logo">Welcome</span>
              <div>
                <span className="text">To</span>
                <img src={GfxLoginLogo} />
              </div>
            </div>

            <SignInCards />
          </div>
        </div>
        <div className="wrapper-credentials">
          <div className="auth-container">
            <a className="logo-link" href="http://www.gfxco.com">
              <img src={GfxLogo} />
            </a>
            <Outlet />
          </div>
        </div>
      </View>
      <CookieBanner
        accepted={isConsentAccepted}
        onAccept={() => setIsConsentAccepted(true)}
      />
    </div>
  );
}
