import React from 'react';
import {useParams} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import {useGFXInstance} from '@best-apps/gfx-editor';
import {EditLinkStatusEnum} from '@gfxco/contracts';
import {GFXEditor} from '../../components/GFXEditor';
import useQuery from '../../hooks/useQuery';
import {getDesignLink, updateEditDesignLinkStatus} from '../../api';
import './EditDesignLink.scss';

export const EditDesignLink: React.FC = () => {
  const [isValidLink, setIsValidLink] = React.useState(false);
  const [validateLinkStatus, setValidateLinkStatus] = React.useState('loading');
  const [saveDesignStatus, setSaveDesignStatus] = React.useState('');
  const gfx = useGFXInstance();
  const params = useParams();
  const query = useQuery();
  const token = params.token as string;
  const templateId = query.get('templateId') as string;
  const designNumber = query.get('designNumber') as string;
  const orderItemId = query.get('orderItemId') as string;

  React.useEffect(() => {
    validateDesignEditLink();
  }, [token, templateId, designNumber, orderItemId]);

  const validateDesignEditLink = async () => {
    setValidateLinkStatus('loading');
    try {
      const isValidLink = (await getDesignLink({
        token,
        templateId,
        designNumber,
        orderItemId,
      })) as boolean;
      setIsValidLink(isValidLink);
      setValidateLinkStatus('loaded');
    } catch (error) {
      console.error(error);
      setValidateLinkStatus('errored');
    }
  };

  if (validateLinkStatus === 'loading') {
    return (
      <>
        <Spinner
          animation="border"
          variant="secondary"
          role="status"
          className="edit-link-loading"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </>
    );
  }

  if (!isValidLink) {
    return (
      <>
        <Alert variant="danger">Link is invalid. Please contact support.</Alert>
      </>
    );
  }

  if (saveDesignStatus === 'success') {
    return (
      <>
        <Alert variant="success">
          The changes in your design were saved successfully. If you have any
          question, please contact support.
        </Alert>
      </>
    );
  }

  const handleSaveDesignClick = async () => {
    setSaveDesignStatus('loading');
    try {
      await gfx?.actions.saveDesign({designNumber, useSync: true});
      await updateEditDesignLinkStatus(
        token,
        EditLinkStatusEnum.DESIGN_UPDATED,
      );
    } catch (error) {
      console.error(error);
      setSaveDesignStatus('error');
    }
    setSaveDesignStatus('success');
  };

  return (
    <>
      <h1>Edit Design</h1>
      <div className="edit-design-container">
        <GFXEditor templateId={templateId} designNumber={designNumber} />
        <div className="edit-design-message">
          <h4>
            Update the design according to the support team instructions. After
            you&apos;re done with your changes, just click on the &ldquo;Save
            Design&rdquo; button below.
          </h4>
          {saveDesignStatus === 'error' && (
            <Alert variant="danger">
              There was an error saving your design. Try again later or contact
              support.
            </Alert>
          )}
          <Button variant="dark" size="lg" onClick={handleSaveDesignClick}>
            Save Design
          </Button>
        </div>
      </div>
    </>
  );
};
