import {IImageSizeMetadata} from '@gfxco/contracts';
import exifr from 'exifr';

export const getMetadataInformation = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      return resolve(exifr.parse(reader.result!, {iptc: true}).catch(reject));
    };
  });
};

const getKeywordsInformation = async (file: File) => {
  const metadata: any = await getMetadataInformation(file).catch(console.error);
  if (!metadata || !metadata.Keywords) {
    return null;
  }

  if (Array.isArray(metadata.Keywords)) {
    return metadata.Keywords.join(',');
  }

  return metadata.Keywords;
};

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const getMetadataImage: (
  file: File,
) => Promise<IImageSizeMetadata> = async (file: File) => {
  const image = new Image();
  const base64 = (await toBase64(file)) as string;
  image.src = base64;
  return new Promise((resolve, reject) => {
    image.onload = () => {
      const {width, height} = image;
      return resolve({width, height});
    };

    image.onerror = reject;
  });
};

export default getKeywordsInformation;
