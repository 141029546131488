import React from 'react';
import './ModerationFilters.scss';
import {InputGroup, Form} from 'react-bootstrap';
import {Search} from 'react-bootstrap-icons';
import {
  IGetModerationFiltersResponse,
  IModerationOrdersRequest,
} from '@gfxco/contracts';
import Chips from '../../../components/Chips';
import GFXCheckbox from '../../../components/GFXCheckbox';

type FilterType =
  | 'orderId'
  | 'date'
  | 'templateId'
  | 'productColor'
  | 'productType';

type Type =
  | FilterType
  | 'status'
  | 'orderBy'
  | 'review'
  | 'cursor'
  | 'pageSize';

type ModerationFiltersProps = {
  filtersOptions: IGetModerationFiltersResponse;
  inReviewDesigns?: number;
  newDesigns?: number;
  filters: IModerationOrdersRequest;
  totalOrders: number;
  onFiltersChange: (filters: IModerationOrdersRequest) => void;
  allSelected?: boolean;
  onSelectAllChange?: (selectAll: boolean) => void;
};

const MAP_FILTERS = {
  productType: 'Product type',
  productColor: 'Product color',
  date: 'Time',
  orderId: 'Order ID',
  templateId: 'Template ID',
};

const getFiltersLabel = (filtersSelected: IModerationOrdersRequest) => {
  const filters = Object.keys(filtersSelected).filter((k) =>
    Object.keys(MAP_FILTERS).includes(k),
  );

  const labels = filters.map((filter) => {
    return `${MAP_FILTERS[filter as FilterType]}: ${
      filtersSelected[filter as Type]
    }`;
  });

  return labels;
};

const ModerationFilters: React.FC<ModerationFiltersProps> = (props) => {
  const filterLabels = getFiltersLabel(props.filters);
  const [searchTemplateId, setSearchTemplateId] = React.useState<string>('');
  const [searchOrderId, setSearchOrderId] = React.useState<string>('');

  const KEY = {
    enter: 'Enter',
  };

  const handleFiltersChange = (type: Type, value: string) => {
    const currentFilters = {...props.filters};
    let newFilters;
    if (value.length) {
      newFilters = {[type]: value};
    } else {
      delete currentFilters[type];
    }
    props.onFiltersChange({
      ...currentFilters,
      ...newFilters,
    });
  };

  const handleDeleteFilter = (key: string) => {
    const value = key.split(':');
    if (value.length) {
      const property = Object.keys(MAP_FILTERS).find(
        (f) => MAP_FILTERS[f as FilterType] === value[0],
      );

      const newFilters = {...props.filters};
      if (property === 'templateId') setSearchTemplateId('');
      if (property === 'orderId') setSearchOrderId('');

      if (property) delete newFilters[property as Type];
      props.onFiltersChange(newFilters);
    }
  };

  const MProductTypes = new Map<string, string>();
  MProductTypes.set('framedPoster', 'Framed Poster');
  MProductTypes.set('hoodie', 'Hoodie');
  MProductTypes.set('infantOnesie', 'Infant OneSie');
  MProductTypes.set('longSleeve', 'Long Sleeve');
  MProductTypes.set('shirt', 'T-Shirt');
  MProductTypes.set('sweatshirt', 'Sweatshirt');
  MProductTypes.set('womensShirt', 'Women’s T-Shirt');

  const mappingProductType = (productType: string) => {
    if (MProductTypes.has(productType)) {
      return MProductTypes.get(productType);
    }
    return productType;
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const keyPressed = event.key;
    const currentFilters = {...props.filters};
    let newFilters;
    const property = event.target.name;
    if (property === 'templateId') {
      if (!searchTemplateId.length) {
        delete currentFilters.templateId;
      } else {
        const templateId = parseInt(searchTemplateId);
        if (!isNaN(templateId)) newFilters = {templateId};
      }
    }

    if (property === 'orderId') {
      if (!searchOrderId.length) {
        delete currentFilters.orderId;
      } else {
        newFilters = {orderId: searchOrderId};
      }
    }

    if (keyPressed === KEY.enter) {
      props.onFiltersChange({
        ...currentFilters,
        ...newFilters,
      });
    }
  };

  const handleOnSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onSelectAllChange) {
      props.onSelectAllChange(e.target.checked);
    }
  };

  return (
    <div id="ModerationFilters">
      <div className="title-content">
        <h1 className="moderation-title">Moderation</h1>
        <h2 className="moderation-subtitle">
          Maintain full control over the orders
        </h2>
        <h2 className="moderation-subtitle">
          that enter your production queue.
        </h2>
      </div>
      <div className="page-content">
        <div className="filters">
          <div className="list-toolbar">
            <InputGroup className="search-bar-group search-bar-list">
              <InputGroup.Text>
                <Search />
              </InputGroup.Text>
              <Form.Control
                placeholder="Template ID"
                name="templateId"
                value={searchTemplateId}
                onChange={(event) => setSearchTemplateId(event.target.value)}
                onKeyDown={handleOnKeyDown}
                aria-label="SearchBar to filter by design templates name"
              />
            </InputGroup>
            <InputGroup className="search-bar-group search-bar-list">
              <InputGroup.Text id="order-id">
                <Search />
              </InputGroup.Text>
              <Form.Control
                placeholder="Order Number"
                aria-label="Order ID"
                name="orderId"
                value={searchOrderId}
                onKeyDown={handleOnKeyDown}
                onChange={(event) => setSearchOrderId(event.target.value)}
              />
            </InputGroup>

            <Form.Select
              aria-label="Time"
              onChange={(event) =>
                handleFiltersChange('date', event.target.value)
              }
              value={props.filters.date ? props.filters.date : 'all'}
            >
              <option value="all">All-Time</option>
              <option value="last 12 hours">Last 12 hours</option>
              <option value="older">Older</option>
            </Form.Select>

            <Form.Select
              aria-label="Product Type"
              value={
                props.filters.productType ? props.filters.productType : 'all'
              }
              onChange={(event) =>
                handleFiltersChange('productType', event.target.value)
              }
            >
              <option value={''}>Product type</option>
              {props.filtersOptions.productTypes.map((productType) => (
                <option key={productType} value={productType}>
                  {mappingProductType(productType)}
                </option>
              ))}
            </Form.Select>
            <Form.Select
              aria-label="Product Color"
              value={
                props.filters.productColor ? props.filters.productColor : 'all'
              }
              onChange={(event) =>
                handleFiltersChange('productColor', event.target.value)
              }
            >
              <option value={''}>Product color</option>
              {props.filtersOptions.productColors.map((productColor) => (
                <option key={productColor} value={productColor}>
                  {productColor}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className="filters-selected">
          <div className="filter-section">
            <Chips
              defaultValue={filterLabels}
              showInput={false}
              onDelete={handleDeleteFilter}
            />
            <span className="total-filtered">
              {props.totalOrders} Orders found
            </span>
          </div>
          <div className="filter-section">
            <GFXCheckbox
              id="selectAllInput"
              name="selectAllInput"
              checked={props.allSelected}
              onChange={handleOnSelectAllChange}
              disabled={props.totalOrders === 0}
              label={`Select all ${
                props.allSelected ? props.totalOrders : ''
              } items found`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModerationFilters;
