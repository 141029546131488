import React from 'react';
import './InchesSizeInput.scss';
import classNames from 'classnames';
import Icon from '../Icons/Icon';

type InchesSizeInputProps = {
  width: number;
  height: number;
  disabled?: boolean;
  disableWidth?: boolean;
  disableHeight?: boolean;
  onChange: (type: string, evt: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
};

const InchesSizeInput: React.FC<InchesSizeInputProps> = ({
  width,
  height,
  onChange,
  onKeyDown,
  disabled,
  disableWidth = false,
  disableHeight = false,
}) => {
  const DPI_SCREEN = 300;
  const [currentUnity, setCurrentUnity] = React.useState<'in' | 'px'>('in');
  const [isSelectingUnity, setIsSelectingUnity] = React.useState(false);
  const [widthMaskData, setWidthMaskData] = React.useState<{
    in: number;
    px: number;
  }>({
    in: width,
    px: width * DPI_SCREEN,
  });

  const [heightMaskData, setHeightMaskData] = React.useState<{
    in: number;
    px: number;
  }>({
    in: height,
    px: height * DPI_SCREEN,
  });

  React.useEffect(() => {
    const inch = width;
    const px = +(width * DPI_SCREEN).toFixed(0);
    setWidthMaskData({
      in: inch,
      px,
    });
  }, [width]);

  React.useEffect(() => {
    const inch = height;
    const px = +(height * DPI_SCREEN).toFixed(0);
    setHeightMaskData({
      in: inch,
      px,
    });
  }, [height]);

  const onUnityClick = (unity?: 'in' | 'px') => {
    if (unity) {
      setCurrentUnity(unity);
      return;
    }
    if (currentUnity === 'in') {
      setCurrentUnity('px');
    } else {
      setCurrentUnity('in');
    }
  };
  const unity = currentUnity.toLocaleLowerCase() as 'in' | 'px';
  const unityNotSelected = currentUnity === 'in' ? 'px' : 'in';
  const classes = classNames({
    'inches-size-options': true,
    'inches-size-options--disabled': disabled,
  });

  const currentUnityClasses = classNames({
    'inches-size-options__unity--current-unity': true,
    'inches-size-options__unity--current-unity--selecting': isSelectingUnity,
  });

  const unitySelectionClasses = classNames({
    'select-unity': true,
    'select-unity--active': isSelectingUnity,
  });

  const onSizeInputChange = (
    type: string,
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = +evt.target.value;
    const valueInInches = currentUnity === 'in' ? value : value / DPI_SCREEN;
    evt.target.value = valueInInches.toString();
    return onChange(type, evt);
  };

  return (
    <div className={classes}>
      <div className="inches-size-options__controls">
        <p>W</p>
        <div className="input_sizes">
          <input
            type="number"
            value={widthMaskData[unity]}
            disabled={disableWidth || disabled}
            min={0}
            onKeyDown={onKeyDown}
            onChange={(evt) => onSizeInputChange('width', evt)}
          />
        </div>
      </div>
      <div className="inches-size-options__controls">
        <p>H</p>
        <div className={`input_sizes ${disableHeight ? 'disabled' : ''}`}>
          <input
            type="number"
            min={0}
            value={heightMaskData[unity]}
            disabled={disableHeight || disabled}
            onKeyDown={onKeyDown}
            onChange={(evt) => onSizeInputChange('height', evt)}
          />
        </div>
      </div>
      <div className="inches-size-options__unity">
        <div
          onClick={() => setIsSelectingUnity(!isSelectingUnity)}
          className={currentUnityClasses}
        >
          <span className="unity-text">{currentUnity}</span>
          <Icon name="downArrow" />
        </div>
        <div
          className={unitySelectionClasses}
          onClick={() => {
            onUnityClick(unityNotSelected);
            setIsSelectingUnity(false);
          }}
        >
          <span className="unity-text">{unityNotSelected}</span>
        </div>
      </div>
    </div>
  );
};

export default InchesSizeInput;
