import {IColor, ProductSkusResponse} from '@gfxco/contracts';
import React from 'react';
import HasNeckLabel from '../HasNeckLabel';
import GFXImage from '../Image';
import mapSizes from '../../libs/mapSizes';

type ProductSkuProps = {
  item: ProductSkusResponse;
  productType: string;
  isSelected: boolean;
  handleOnClick: (item: ProductSkusResponse) => void;
};
const ProductSku: React.FC<ProductSkuProps> = (props) => {
  const {item, productType, isSelected, handleOnClick} = props;
  return (
    <div
      className={`SelectProductSku__item ${
        isSelected ? ' SelectProductSku__Selected ' : ''
      }`}
      key={`${item.description}-${item.printer}`}
      onClick={() => handleOnClick(item)}
    >
      <div className="SelectProductSku__Image">
        <GFXImage src={item.url} placeholderSrc="/loading-render.gif" />
      </div>
      <div>
        <div className="SelectProductSku__Title">
          <span>{item.description}</span>
        </div>
        <div className="SelectProductSku__Text">
          <span>
            Printer:{' '}
            <span className="SelectProductSku__Text__Bold">{item.printer}</span>
          </span>
        </div>
        <div className="SelectProductSku__Text SelectProductSku__Sizes">
          <span>Sizes: {`${mapSizes(item.sizes, productType)}`}</span>
        </div>
        <div className="SelectProductSku__Colors">
          {item.colors.map((color: IColor) => (
            <div
              key={color.hex}
              className="SelectProductSku__ColorItem"
              style={{backgroundColor: color.hex}}
            ></div>
          ))}
          <HasNeckLabel hasLabel={item.hasLabel || false} />
        </div>
      </div>
    </div>
  );
};

export default ProductSku;
