import {
  ELoadingStates,
  IPrinterIntegrationRequest,
  IWebhookIntegrationRequest,
} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {
  connectFifthSun,
  connectPrintful,
  connectSendArts,
  getFifthSunIntegrationStatus,
  getPrintfulIntegrationStatus,
  getSendArtsIntegrationStatus,
} from '../../api';
import {RootState} from '../../app/store';

export interface IPrinterIntegration {
  connected: boolean;
  fetchStatus: ELoadingStates;
  storeId?: string;
  apiKey?: string;
  errorMessage?: string;
  connectLoadStatus: ELoadingStates;
}

export interface IWebhookIntegration {
  connected: boolean;
  fetchStatus: ELoadingStates;
  webhookUrl?: string;
  errorMessage?: string;
  connectLoadStatus: ELoadingStates;
}

const PrinterDefaultState: IPrinterIntegration = {
  connected: false,
  fetchStatus: ELoadingStates.IDLE,
  connectLoadStatus: ELoadingStates.IDLE,
};

const WebhookDefaultState: IWebhookIntegration = {
  connected: false,
  fetchStatus: ELoadingStates.IDLE,
  connectLoadStatus: ELoadingStates.IDLE,
};

interface PrinterIntegrationState {
  printful: IPrinterIntegration;
  fifthSun: IPrinterIntegration;
  sendArts: IWebhookIntegration;
}

interface SerializedError {
  message: string;
}

const initialState: PrinterIntegrationState = {
  printful: PrinterDefaultState,
  fifthSun: PrinterDefaultState,
  sendArts: WebhookDefaultState,
};

export const getPrintfulIntegrationStatusAsync = createAsyncThunk(
  'printersIntegration/fetchPrintful',
  async (shopId: number) => {
    const response = await getPrintfulIntegrationStatus(shopId);
    return response;
  },
);

export const getFifthSunIntegrationStatusAsync = createAsyncThunk(
  'printersIntegration/fetchFifthSun',
  async (shopId: number) => {
    const response = await getFifthSunIntegrationStatus(shopId);
    return response;
  },
);

export const getSendArtsIntegrationStatusAsync = createAsyncThunk(
  'printersIntegration/sendArts',
  async (shopId: number) => {
    const response = await getSendArtsIntegrationStatus(shopId);
    return response;
  },
);

export const connectPrintfulIntegrationAsync = createAsyncThunk(
  'printersIntegration/connectPrintful',
  async (params: IPrinterIntegrationRequest, {rejectWithValue}) => {
    try {
      const response = await connectPrintful(params);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        message: 'An error occurred while connecting to Printful',
      });
    }
  },
);

export const connectFifthSunIntegrationAsync = createAsyncThunk(
  'printersIntegration/connectFifthSun',
  async (params: IPrinterIntegrationRequest, {rejectWithValue}) => {
    try {
      const response = await connectFifthSun(params);
      return response;
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        message: 'An error occurred while connecting to Fifth Sun',
      });
    }
  },
);

export const connectSendArtsIntegrationAsync = createAsyncThunk(
  'printersIntegration/connectSendArts',
  async (params: IWebhookIntegrationRequest, {rejectWithValue}) => {
    try {
      const response = await connectSendArts(params);
      return response;
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        message: 'An error occurred while connecting Send Arts Workflow',
      });
    }
  },
);

export const loadIntegrations = createSlice({
  name: 'printersIntegration',
  initialState,
  reducers: {
    resetPrintersIntegration(state) {
      state.printful = PrinterDefaultState;
      state.fifthSun = PrinterDefaultState;
      state.sendArts = WebhookDefaultState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrintfulIntegrationStatusAsync.pending, (state) => {
        state.printful.connected = false;
        state.printful.fetchStatus = ELoadingStates.LOADING;
      })
      .addCase(getPrintfulIntegrationStatusAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.printful.connected = action.payload.connected;
          state.printful.fetchStatus = ELoadingStates.LOADED;
          state.printful.storeId = action.payload.storeId;
          state.printful.apiKey = action.payload.apiKey;
        }
      })
      .addCase(getPrintfulIntegrationStatusAsync.rejected, (state) => {
        state.printful.connected = false;
        state.printful.fetchStatus = ELoadingStates.FAILED;
        state.printful.storeId = undefined;
        state.printful.apiKey = undefined;
      })
      .addCase(getFifthSunIntegrationStatusAsync.pending, (state) => {
        state.fifthSun.connected = false;
        state.fifthSun.fetchStatus = ELoadingStates.LOADING;
      })
      .addCase(getFifthSunIntegrationStatusAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.fifthSun.connected = action.payload.connected;
          state.fifthSun.fetchStatus = ELoadingStates.LOADED;
          state.fifthSun.storeId = action.payload.storeId;
          state.fifthSun.apiKey = action.payload.apiKey;
        }
      })
      .addCase(getFifthSunIntegrationStatusAsync.rejected, (state) => {
        state.fifthSun.connected = false;
        state.fifthSun.fetchStatus = ELoadingStates.FAILED;
        state.fifthSun.storeId = undefined;
        state.fifthSun.apiKey = undefined;
      })
      .addCase(getSendArtsIntegrationStatusAsync.pending, (state) => {
        state.sendArts.connected = false;
        state.sendArts.fetchStatus = ELoadingStates.LOADING;
      })
      .addCase(getSendArtsIntegrationStatusAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.sendArts.connected = action.payload.connected;
          state.sendArts.fetchStatus = ELoadingStates.LOADED;
          state.sendArts.webhookUrl = action.payload.webhookUrl;
        }
      })
      .addCase(getSendArtsIntegrationStatusAsync.rejected, (state) => {
        state.sendArts.connected = false;
        state.sendArts.fetchStatus = ELoadingStates.FAILED;
        state.sendArts.webhookUrl = undefined;
      })
      .addCase(connectPrintfulIntegrationAsync.pending, (state) => {
        state.printful.connectLoadStatus = ELoadingStates.LOADING;
      })
      .addCase(connectPrintfulIntegrationAsync.fulfilled, (state, action) => {
        state.printful.connectLoadStatus = ELoadingStates.LOADED;
        if (action.payload) {
          state.printful.connected = true;
          state.printful.storeId = action.payload.storeId;
          state.printful.apiKey = action.payload.apiKey;
        }
      })
      .addCase(connectPrintfulIntegrationAsync.rejected, (state, action) => {
        state.printful.connectLoadStatus = ELoadingStates.FAILED;
        if (action.payload) {
          const error = action.payload as SerializedError;
          state.printful.errorMessage = error.message;
          state.printful.connected = false;
          state.printful.storeId = undefined;
          state.printful.apiKey = undefined;
        }
      })
      .addCase(connectFifthSunIntegrationAsync.pending, (state) => {
        state.fifthSun.connectLoadStatus = ELoadingStates.LOADING;
      })
      .addCase(connectFifthSunIntegrationAsync.fulfilled, (state, action) => {
        state.fifthSun.connectLoadStatus = ELoadingStates.LOADED;
        if (action.payload) {
          state.fifthSun.connected = true;
          state.fifthSun.storeId = action.payload.storeId;
          state.fifthSun.apiKey = action.payload.apiKey;
        }
      })
      .addCase(connectFifthSunIntegrationAsync.rejected, (state, action) => {
        state.fifthSun.connectLoadStatus = ELoadingStates.FAILED;
        if (action.payload) {
          const error = action.payload as SerializedError;
          state.fifthSun.errorMessage = error.message;
          state.fifthSun.connected = false;
          state.fifthSun.storeId = undefined;
          state.fifthSun.apiKey = undefined;
        }
      })
      .addCase(connectSendArtsIntegrationAsync.pending, (state) => {
        state.sendArts.connectLoadStatus = ELoadingStates.LOADING;
      })
      .addCase(connectSendArtsIntegrationAsync.fulfilled, (state, action) => {
        state.sendArts.connectLoadStatus = ELoadingStates.LOADED;
        if (action.payload) {
          state.sendArts.connected = true;
          state.sendArts.webhookUrl = action.payload.webhookUrl;
        }
      })
      .addCase(connectSendArtsIntegrationAsync.rejected, (state, action) => {
        state.sendArts.connectLoadStatus = ELoadingStates.FAILED;
        if (action.payload) {
          const error = action.payload as SerializedError;
          state.sendArts.errorMessage = error.message;
          state.sendArts.connected = false;
          state.sendArts.webhookUrl = undefined;
        }
      });
  },
});

export const selectIntegrationStatusByPrinter = (
  state: RootState,
  printer: keyof PrinterIntegrationState,
) => {
  return state.printersIntegration[printer];
};

export const {resetPrintersIntegration} = loadIntegrations.actions;

export default loadIntegrations.reducer;
