import React from 'react';
import {Link} from 'react-router-dom';
import {List} from 'react-bootstrap-icons';

import './Toolbar.scss';

import {Navbar} from 'react-bootstrap';
import GfxLogo from '../../../assets/gfx_logo_beta_collapse.svg';
import GFXSelectStoreDropdown from '../GFXSelectStoreDropdown/GFXSelectStoreDropdown';

export interface ToolbarProps {
  handleDrawerOpen: () => void;
  drawerOpen: boolean;
  children?: React.ReactNode;
  isPublic?: boolean;
}

export const Toolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <Navbar
      id="Toolbar"
      style={{display: 'flex', justifyContent: 'space-between'}}
    >
      <div style={{display: 'flex'}}>
        {!props.drawerOpen && (
          <div onClick={props.handleDrawerOpen}>
            <List className="menu-icon" />
          </div>
        )}
        {(!props.drawerOpen || props.isPublic) && (
          <Link to="/">
            <img src={GfxLogo} className="logo-img-closed" />
          </Link>
        )}
      </div>
      <div>
        <GFXSelectStoreDropdown />
      </div>
    </Navbar>
  );
};

export default Toolbar;
