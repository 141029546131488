import React from 'react';

import Icon from '../Icons/Icon';
import '@aws-amplify/ui-react/styles.css';
import './SignInCards.scss';

interface RequiredProps {}

interface OptionalProps {}

type InfoCardsProps = RequiredProps & OptionalProps;

const InfoCards: React.FC<InfoCardsProps> = () => {
  return (
    <div id="InfoCards">
      <div className="info-card">
        <div className="card-title">
          <Icon name="settings" />
          Sync your store
        </div>
        <div className="card-description">
          <span>
            Where your fans personalize and order products through your store
          </span>
        </div>
      </div>

      <div className="info-card">
        <div className="card-title">
          <Icon name="product" />
          Customize your designs
        </div>
        <div className="card-description">
          <span>
            Products aligned with your brand guidelines, including setting rules
            for customization
          </span>
        </div>
      </div>

      <div className="info-card">
        <div className="card-title">
          <Icon name="myStoryIcon" />
          Print and go
        </div>
        <div className="card-description">
          <span>
            Eliminate inventory management through network of leading
            direct-to-garment printers and fulfillment services
          </span>
        </div>
      </div>
    </div>
  );
};

export default InfoCards;
