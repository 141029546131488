import * as Contracts from '@gfxco/contracts';

import {loadClient, handleError} from './api';

const getSlotTemplateDetail = async (
  parameters: Contracts.ISlotTemplateDetailRequest,
) => {
  try {
    const client = await loadClient();
    const {templateId, ...params} = parameters;

    const response = await client.get<Contracts.ISlotTemplate>(
      `/templates/${templateId}/slot-template`,
      {
        params,
      },
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getDesignTemplatesEntities = async (
  params: Contracts.IDesignTemplateRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.IDesignTemplateResponse>(
      `template/design-templates`,
      {
        params,
      },
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getTemplateImages = async (params: {
  templateId: number;
  searchBy?: string;
}) => {
  try {
    const client = await loadClient();

    const response = await client.get<{
      results: Contracts.IImage[];
      total: number;
    }>(`/templates/${params.templateId}/images`);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const duplicateTemplate = async (templateId: number) => {
  try {
    const client = await loadClient();
    const response = await client.post(`/templates/${templateId}/duplicate`);

    return response.data as {
      templateId: number;
    };
  } catch (error) {
    return handleError(error);
  }
};

const duplicateTemplateNewGarment = async (
  templateId: number,
  newGarment: string,
  body?: {
    side?: string;
    name?: string;
    skus?: Contracts.ICatalogInfo;
    manufacturerId?: number;
  },
) => {
  try {
    const client = await loadClient();
    const response = await client.post(
      `/templates/${templateId}/duplicate/${newGarment}`,
      {
        name: body?.name,
        skus: body?.skus,
        side: body?.side,
        manufacturerId: body?.manufacturerId,
      },
    );

    return response.data as {
      templateId: number;
    };
  } catch (error) {
    return handleError(error);
  }
};

export default {
  getSlotTemplateDetail,
  getDesignTemplatesEntities,
  getTemplateImages,
  duplicateTemplate,
  duplicateTemplateNewGarment,
};
