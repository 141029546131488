import React from 'react';
import GFXImage from './../Image';
import {IDuplicateFormValues} from '../DesignsContent/DuplicateNewProductModal/DuplicateTypes';

type ProductTypeComponentProps = {
  classes: string;
  setValue: (key: string, value: any) => void;
  formValues: IDuplicateFormValues;
  isSelected: boolean;
  product: {
    code: string;
    name: string;
    thumbnail_url?: string;
  };
};

const ProductTypeComponent: React.FC<ProductTypeComponentProps> = (props) => {
  const {product, classes, setValue, isSelected} = props;
  return (
    <div
      key={product.code}
      className={classes}
      onClick={() => setValue('garmentType', product.code)}
    >
      <div className="product-type-item__image">
        <GFXImage src={product.thumbnail_url} alt={product.name} />
      </div>
      <div className="product-type-item__name">
        <input
          type="radio"
          name="garmentType"
          value={product.code}
          checked={isSelected}
          id={'radio-' + product.name}
          onChange={() => setValue('garmentType', product.code)}
        />
        <label htmlFor={'radio-' + product.name}>{product.name}</label>
      </div>
    </div>
  );
};

export default ProductTypeComponent;
