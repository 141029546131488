import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import './ActionButton.scss';

interface RequiredProps {
  disabled: boolean;
  isActive: boolean;
  type: 'Accept' | 'Reject';
  isWaiting: boolean;
  onClick: () => void;
  icon: React.ReactNode;
}

interface OptionalProps {
  printerIntegrated?: boolean;
}
type ActionButtonProps = RequiredProps & OptionalProps;

const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  disabled,
  isActive,
  type,
  isWaiting,
  icon,
  printerIntegrated,
}) => {
  const isAcceptDisabled = type === 'Accept' && !printerIntegrated;
  const getButtonLabel = () => {
    if (isWaiting) {
      if (type === 'Accept') return 'Accepting...';
      else return 'Rejecting...';
    }

    if (isActive) {
      if (type === 'Accept') return 'Accepted';
      else return 'Rejected';
    }

    return type;
  };

  return (
    <OverlayTrigger
      placement="top"
      trigger={isAcceptDisabled ? ['hover', 'focus'] : []}
      overlay={
        <Tooltip id="moderation-warning-tooltip" className="moderation-accept">
          Please ensure that at least one printer is configured.
        </Tooltip>
      }
    >
      <div
        className={`action-box ${
          isActive
            ? `${type.toLowerCase()}-active-button`
            : disabled
            ? 'button-disabled'
            : `${type.toLowerCase()}-button`
        } ${isWaiting ? 'waiting-box' : ''}`}
        onClick={() => onClick()}
      >
        {icon}
        <span>{getButtonLabel()}</span>
        {isWaiting && (
          <>
            <span className="revert-action">Click for Undo</span>
            <hr className={`waiting-bar ${type.toLowerCase()}-bar`} />
          </>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default ActionButton;
