import React from 'react';
import Form from 'react-bootstrap/Form';
import Icon from '../Icons/Icon';

import '@aws-amplify/ui-react/styles.css';
import './PasswordField.scss';

interface RequiredProps {
  name: string;
}

interface OptionalProps {
  className?: string;
  onChangeInput?: (value: string) => void;
}

type PasswordFieldProps = OptionalProps & RequiredProps;

const PasswordField: React.FC<PasswordFieldProps> = (props) => {
  const {name, className, onChangeInput} = props;
  const [isPasswordHidden, setIsPasswordHidden] = React.useState(true);

  const handleToggleHiddenPassword = () => {
    setIsPasswordHidden((prev) => !prev);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeInput) {
      onChangeInput(event.target.value);
    }
  };

  return (
    <div className={`password-container${className || ''}`}>
      <Form.Control
        type={isPasswordHidden ? 'password' : 'text'}
        name={name}
        placeholder="••••••••"
        required
        onChange={handleChange}
      />
      {isPasswordHidden ? (
        <Icon name="eyeClosedIcon" onClick={handleToggleHiddenPassword} />
      ) : (
        <Icon name="eyeIcon" onClick={handleToggleHiddenPassword} />
      )}
    </div>
  );
};

export default PasswordField;
