import React from 'react';
import Select, {StylesConfig} from 'react-select';
import {IColorOption} from '../../pages/MyStorePage/storeColorOptions.data';

interface GFXColorSelectProps {
  options: IColorOption[];
  value: IColorOption;
  onChange?: (value: IColorOption) => void;
}

const GFXColorSelect: React.FC<GFXColorSelectProps> = ({
  options,
  value,
  onChange,
}) => {
  const customStyles: StylesConfig<IColorOption> = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.hex,
      color:
        state.data.label === 'WHITE'
          ? '#838383'
          : state.data.label === 'LIGHT BLUE'
          ? '#4573BB'
          : '#fff',
      textAlign: 'center',
      borderRadius: '1rem',
      marginBottom: '0.2rem',
      marginLeft: '0.2rem',
      width: '97%',
      height: '2rem',
      fontSize: '0.8rem',
      border: state.data.label === 'WHITE' ? '1px solid #B2B2B2' : 'none',
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      border: '1px solid #E5E5E5',
      borderRadius: '4px',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #E5E5E5',
      },
      width: '100%',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.hex,
      color:
        state.data.label === 'WHITE'
          ? '#838383'
          : state.data.label === 'LIGHT BLUE'
          ? '#4573BB'
          : '#fff',
      textAlign: 'center',
      borderRadius: '1rem',
      border: state.data.label === 'WHITE' ? '1px solid #B2B2B2' : 'none',
    }),
    input: (provided) => ({
      ...provided,
      color: 'transparent',
      width: '100%',
    }),
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      isSearchable={false}
      value={value}
      defaultValue={options[0]}
      onChange={(selectedOption) => {
        onChange && onChange(selectedOption as IColorOption);
      }}
    />
  );
};

export default GFXColorSelect;
