import React from 'react';
import {Button, ButtonProps} from 'react-bootstrap';

import './Button.scss';

interface GFXButtonProps extends ButtonProps {
  loading?: boolean;
  loadingContent?: React.ReactNode;
}

const GFXButton: React.FC<GFXButtonProps> = (props) => {
  const {loadingContent, loading, ...restProps} = props;
  const innerLoadingContent = loadingContent ?? 'Loading...';

  return (
    <Button {...restProps} className={`gfx-btn ${props.className ?? ''}`}>
      {props.loading ? innerLoadingContent : props.children}
    </Button>
  );
};

export default GFXButton;
