import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ProductModalHeader from '../ProductModalHeader';
import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import {getProductSkus} from '../../api';
import {
  ProductSkusRequest,
  ProductSkusResponse,
  INextStep,
} from '@gfxco/contracts';

import './SelectProductSku.scss';
import ProductSku from './ProductSku';

interface OptionalProps {
  productType?: string;
  initialSkuDescription?: string;
  initialPrinter?: string;
}

interface RequiredProps {
  onNextStep: (params: INextStep) => void;
}

const TITLE = 'Select your product Printer';
type SelectProductSkuProps = RequiredProps & OptionalProps;

export const SelectProductSku: React.FC<SelectProductSkuProps> = ({
  productType = 'shirt',
  onNextStep,
  initialSkuDescription,
  initialPrinter,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [productSkus, setProductSkus] = React.useState<ProductSkusResponse[]>(
    [],
  );
  const selectedShop = useAppSelector(selectShop);
  const shopId = selectedShop!.id;
  const [isError, setIsError] = React.useState(false);

  const handleOnClick = (productSku: ProductSkusResponse) => {
    const nextStep = {
      step: 4,
      data: {
        colors: productSku.colors,
        sizes: productSku.sizes,
        printer: productSku.printer,
        skuDescription: productSku.description,
        hasLabel: productSku.hasLabel,
        manufacturerId: productSku.manufacturerId,
      },
    };
    onNextStep(nextStep);
  };

  React.useEffect(() => {
    if (!isLoading) {
      getProductSkusData();
    }
  }, []);

  const getProductSkusData = async () => {
    try {
      if (!shopId) return;
      setIsLoading(true);
      const params: ProductSkusRequest = {productType, shopId};
      const productSkus = await getProductSkus(params);
      if (productSkus) {
        setProductSkus(productSkus);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  if (isError) {
    return <div>Error Loading the Skus!.</div>;
  }

  return (
    <div className="SelectProductSku">
      <ProductModalHeader
        title={TITLE}
        subtitle="Create your design by choosing a product type from our catalog."
      />
      {!isLoading && (
        <div className="SelectProductSku__container">
          {productSkus.map((item) => {
            const itemKey = `${item.description}-${item.printer}`;
            const initialKey = `${initialSkuDescription}-${initialPrinter}`;
            const isSelected = initialKey === itemKey;
            return (
              <ProductSku
                key={itemKey}
                item={item}
                productType={productType}
                isSelected={isSelected}
                handleOnClick={handleOnClick}
              />
            );
          })}
        </div>
      )}
      {isLoading && (
        <Skeleton
          className="SelectProductSku__item skeleton"
          containerClassName="SelectProductSku__container container"
          count={5}
          inline
        />
      )}
    </div>
  );
};
export default SelectProductSku;
