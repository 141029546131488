import React from 'react';
import {XCircle} from 'react-bootstrap-icons';
import IconButton from '@mui/material/IconButton';
import './Tags.scss';

export interface RequiredProps {
  tagExceptionName: string;
}
export interface OptionalProps {
  onDelete?: (params: {id?: number; tag?: string}) => void;
  onClick?: (item: {tag: string}) => void;
  showDelete?: boolean;
  tagExceptionId?: number;
  selected?: boolean;
}

type TagsProps = RequiredProps & OptionalProps;

export const Tags: React.FC<TagsProps> = ({
  tagExceptionName,
  onDelete,
  onClick,
  tagExceptionId,
  showDelete = false,
  selected = false,
}) => {
  const handleOnDelete = () => {
    if (onDelete) {
      if (tagExceptionId) onDelete({id: tagExceptionId});
      onDelete({tag: tagExceptionName});
    }
  };

  const handleOnClick = () => {
    if (onClick && tagExceptionName) {
      onClick({tag: tagExceptionName});
    }
  };

  return (
    <div id="Tags" onClick={handleOnClick}>
      <div className={`content-tag ${selected ? 'tag-selected' : ''}`}>
        <span className="title">{tagExceptionName}</span>
        {showDelete && (
          <IconButton onClick={() => handleOnDelete()}>
            <XCircle fontSize={'0.8rem'} />
          </IconButton>
        )}
      </div>
    </div>
  );
};
export default Tags;
