import axios from 'axios';
import {
  IShopifyIntegrationRequest,
  IShopifyIntegrationResponse,
  IShopifyStatusResponse,
} from '@gfxco/contracts';
import {handleError, loadClient} from './api';

export const connectShopify = async (params: IShopifyIntegrationRequest) => {
  try {
    const client = await loadClient();
    const response = await client.post<IShopifyIntegrationResponse>(
      `/integrations/shopify`,
      {
        ...params,
      },
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const errorData = error.response.data;
        if (
          typeof errorData.error === 'boolean' &&
          typeof errorData.message === 'string'
        ) {
          return errorData as IShopifyIntegrationResponse;
        }
      }
    }
    return handleError(error);
  }
};

export const getShopifyIntegrationStatus = async (
  shopId: number,
  domain?: string,
) => {
  try {
    const client = await loadClient();
    const response = await client.get<IShopifyStatusResponse>(
      `/integrations/shopify`,
      {
        params: {
          shopId,
          domain,
        },
      },
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const errorData = error.response.data;
        if (
          typeof errorData.error === 'boolean' &&
          typeof errorData.message === 'string'
        ) {
          return errorData as IShopifyIntegrationResponse;
        }
      }
    }
    return handleError(error);
  }
};
