import {
  ICollectionResponse,
  ICollectionsRequest,
  ELoadingStates,
} from '@gfxco/contracts';
import {normalize, schema} from 'normalizr';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getCollectionsEntities} from '../../api/collections';
import {RootState} from '../../app/store';

const AllCollections = new schema.Entity('collectionEntities');
const collectionsSchema = {collectionsEntities: [AllCollections]};

export interface CollectionState {
  entities: {[id: number]: ICollectionResponse};
  total: number;
  fetchStatus: ELoadingStates;
}
interface DeleteCollectionPayload {
  id: number;
}

const initialState: CollectionState = {
  entities: {},
  fetchStatus: ELoadingStates.IDLE,
  total: 0,
};

export const getCollectionsAsync = createAsyncThunk(
  'collections/details/fetch',
  async (parameters: ICollectionsRequest) => {
    const response = await getCollectionsEntities(parameters);

    const normalizedData = normalize(
      {collectionsEntities: response?.results},
      collectionsSchema,
    );

    return {
      entities: normalizedData.entities.collectionEntities,
      totalCollections: response?.total,
    };
  },
);

export const loadCollections = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    resetCollectionDetails: (state) => {
      state = initialState;
      return state;
    },
    addCollectionImageCount: (state, action) => {
      const {collectionId, count} = action.payload;
      const currentImageCount = state.entities[collectionId].imagesCount;

      const newImageCount = currentImageCount ? +currentImageCount + count : 1;

      state.entities[collectionId] = {
        ...state.entities[collectionId],
        imagesCount: newImageCount.toString(),
      };
    },
    addSubCollectionImageCount: (state, action) => {
      const {collectionId, subcollectionId, count} = action.payload;

      const subCollections = state.entities[collectionId].subCollections?.map(
        (s) => {
          if (s.id !== +subcollectionId) return s;

          const currentImageCount = s.imagesCount;
          const newImageCount = currentImageCount
            ? +currentImageCount + count
            : 1;
          return {...s, imagesCount: newImageCount.toString()};
        },
      );

      state.entities[collectionId] = {
        ...state.entities[collectionId],
        subCollections,
      };
    },
    deleteCollection: (state, action) => {
      const {id} = action.payload as DeleteCollectionPayload;
      const newState = {...state};
      delete newState.entities[id];

      return state;
    },
    deleteSubCollection: (state, action) => {
      const {id} = action.payload as DeleteCollectionPayload;
      const newState = {...state};

      // Search and delete subcollection.
      Object.keys(newState.entities).forEach((key) => {
        const collection = newState.entities[+key];
        if (collection?.subCollections) {
          const index = collection.subCollections.findIndex(
            (subCollection) => subCollection.id === id,
          );
          if (index > -1) {
            collection.subCollections.splice(index, 1);
          }
        }
      });

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionsAsync.pending, (state) => {
        state.fetchStatus = ELoadingStates.LOADING;
      })
      .addCase(getCollectionsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.entities = {...state.entities, ...action.payload.entities};
          state.fetchStatus = ELoadingStates.LOADED;
          state.total = action.payload.totalCollections || 0;
        }
      })
      .addCase(getCollectionsAsync.rejected, (state) => {
        state.fetchStatus = ELoadingStates.FAILED;
      });
  },
});

export const getEntityById = (state: RootState, id: number) => {
  return state.collectionsDetails.entities[id];
};

export const selectCollections = (state: RootState) => {
  const ids = Object.keys(state.collectionsDetails.entities).reverse() || [];
  if (ids.length > 0) {
    return ids.map((id) => getEntityById(state, +id));
  } else {
    return [];
  }
};
export const selectCollectionsFetchStatus = (state: RootState) =>
  state.collectionsDetails.fetchStatus;
export const selectTotalCollections = (state: RootState) => {
  if (!state.collectionsDetails) {
    return 0;
  }
  return state.collectionsDetails.total;
};

export const {
  resetCollectionDetails,
  deleteCollection,
  deleteSubCollection,
  addCollectionImageCount,
  addSubCollectionImageCount,
} = loadCollections.actions;
export default loadCollections.reducer;
