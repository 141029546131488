import {ELoadingStates} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getShopifyIntegrationStatus} from '../../api';
import {RootState} from '../../app/store';

interface ShopifyIntegrationState {
  connected: boolean;
  fetchStatus: ELoadingStates;
  storeName?: string;
  apiKey?: string;
  apiSecretKey?: string;
  appPassword?: string;
}

const initialState: ShopifyIntegrationState = {
  connected: false,
  fetchStatus: ELoadingStates.IDLE,
};

export const getShopifyIntegrationStatusAsync = createAsyncThunk(
  'shopifyIntegrationStatus/fetch',
  async (shopId: number) => {
    const response = await getShopifyIntegrationStatus(shopId);
    return response;
  },
);

export const updateShopifyIntegrationAsync = createAsyncThunk(
  'shopifyIntegrationStatus/update',
  async (params: {shopId: number; domain: string}) => {
    const response = await getShopifyIntegrationStatus(
      params.shopId,
      params.domain,
    );
    return response;
  },
);

export const loadIntegrations = createSlice({
  name: 'shopifyIntegration',
  initialState,
  reducers: {
    resetIntegrationStatus: (state) => {
      state.connected = false;
      state.fetchStatus = ELoadingStates.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShopifyIntegrationStatusAsync.pending, (state) => {
        state = {
          connected: false,
          fetchStatus: ELoadingStates.LOADING,
        };
      })
      .addCase(getShopifyIntegrationStatusAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.connected =
            'connected' in action.payload ? action.payload.connected : false;
          state.fetchStatus = ELoadingStates.LOADED;

          state.storeName =
            'storeName' in action.payload ? action.payload.storeName : '';

          state.apiKey =
            'apiKey' in action.payload ? action.payload.apiKey : '';
          state.apiSecretKey =
            'apiSecretKey' in action.payload ? action.payload.apiSecretKey : '';

          state.appPassword =
            'appPassword' in action.payload ? action.payload.appPassword : '';
        }
      })
      .addCase(getShopifyIntegrationStatusAsync.rejected, (state) => {
        state = {
          connected: false,
          fetchStatus: ELoadingStates.FAILED,
        };
      })
      .addCase(updateShopifyIntegrationAsync.pending, (state) => {
        state = {
          connected: false,
          fetchStatus: ELoadingStates.LOADING,
        };
      })
      .addCase(updateShopifyIntegrationAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.connected =
            'connected' in action.payload ? action.payload.connected : false;
          state.fetchStatus = ELoadingStates.LOADED;
          state.storeName =
            'storeName' in action.payload ? action.payload.storeName : '';

          state.apiKey =
            'apiKey' in action.payload ? action.payload.apiKey : '';
          state.apiSecretKey =
            'apiSecretKey' in action.payload ? action.payload.apiSecretKey : '';

          state.appPassword =
            'appPassword' in action.payload ? action.payload.appPassword : '';
        }
      })
      .addCase(updateShopifyIntegrationAsync.rejected, (state) => {
        state = {
          connected: false,
          fetchStatus: ELoadingStates.FAILED,
        };
      });
  },
});

export const selectIntegrationStatus = (state: RootState) => {
  return state.integrations.connected;
};

export const selectIntegrationsFetchLoading = (state: RootState) => {
  return state.integrations.fetchStatus;
};

export const selectIntegrationsParameters = (state: RootState) => {
  return {
    storeName: state.integrations.storeName || '',
    apiKey: state.integrations.apiKey || '',
    apiSecretKey: state.integrations.apiSecretKey || '',
    appPassword: state.integrations.appPassword || '',
  };
};

export const {resetIntegrationStatus} = loadIntegrations.actions;

export default loadIntegrations.reducer;
