import React from 'react';
import './Dropdowns.scss';

type SimpleDropdownProps = {
  label?: string;
  items: string[];
  onSelect: (item: string) => void;
  selected: string;
};

const SimpleDropdown: React.FC<SimpleDropdownProps> = (props) => {
  const {items, onSelect, selected} = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const onDropdownClick = () => setIsOpen(!isOpen);

  const handleOptionClick = (item: string) => {
    onSelect(item);
    setIsOpen(false);
  };

  return (
    <div className="gfx-simple-dropdown">
      <div className="gfx-simple-dropdown__selected" onClick={onDropdownClick}>
        {props.label && <label>{props.label}:</label>}
        <p>{selected}</p>
      </div>
      {isOpen && (
        <div className="gfx-simple-dropdown__items">
          {items.map((item, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(item)}
              className="gfx-simple-dropdown__item"
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SimpleDropdown;
