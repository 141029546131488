import React from 'react';
import {Actions} from '../../../../../libs/getLinkedSlotActions';
import {colors as defaultTextColors} from '../../../../../libs/getDefaultTextColors';
import {ReactComponent as FillColorIcon} from '../../../../../assets/icons/fill-color-icon.svg';
import {ReactComponent as FillColorBorderIcon} from '../../../../../assets/icons/fill-color-border-icon.svg';
import {ReactComponent as ArrowRight} from '../../../../../assets/icons/arrow-down-right.svg';
import {ReactComponent as TextIcon} from '../../../../../assets/icons/text-reflect-icon.svg';

import './ItemTextbox.scss';

export interface RequiredProps {
  type: 'has-fill-color' | 'reflects';
}
export interface OptionalProps {
  text?: string;
  compact?: boolean;
}
type ItemTextboxProps = RequiredProps & OptionalProps;
export const ItemTextbox: React.FC<ItemTextboxProps> = ({
  type,
  text,
  compact,
}) => {
  return (
    <div id="ItemTextbox">
      {!compact && <ArrowRight className="icon" />}

      <div
        className={`rule-item-preview ${
          compact ? 'rule-item-preview-compact' : 'rule-item-preview-content'
        }`}
      >
        {text && text === '#FFFFFF' && <FillColorBorderIcon />}
        {text && text !== '#FFFFFF' && <FillColorIcon fill={text} />}
        {type === Actions.Reflects && <TextIcon />}

        <div className="content-title">
          <span className="content-type">
            {text
              ? 'Filled with color'
              : compact
              ? 'Reflect slot'
              : 'Reflect on other text slot'}
          </span>
          {text && !compact && (
            <span className="content-name">
              {defaultTextColors.find((c) => c.hex === text)?.name || '-'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemTextbox;
