import React from 'react';
import './Tooltip.scss';

interface RequiredProps {
  onAccept: () => void;
  onClose?: () => any;
  width?: string;
  height?: string;
  children: React.ReactNode;
  top: string;
  left?: string;
  acceptText?: string;
}

interface OptionalProps {}

type ModalProps = RequiredProps & OptionalProps;

const GFXTooltip: React.FC<ModalProps> = (props) => {
  const styles = {
    top: props.top,
    left: props.left,
    width: props.width,
    height: props.height,
  };
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleOnClose);

    return () => {
      document.removeEventListener('mousedown', handleOnClose);
    };
  }, []);

  const handleOnClose = (event: MouseEvent) => {
    if (
      divRef?.current &&
      !divRef.current.contains(event.target as Node) &&
      props.onClose
    ) {
      props.onClose();
    }
  };

  return (
    <div id="stepModalTooltip" ref={divRef} className="show" style={styles}>
      {props.onClose ? (
        <div className="close-button" onClick={props.onClose}>
          X
        </div>
      ) : null}
      <div className="content">
        {props.children}
        <p className="accept" onClick={props.onAccept}>
          {props.acceptText || 'Got it'}
        </p>
      </div>
    </div>
  );
};

export default GFXTooltip;
