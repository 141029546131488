import React from 'react';
import {useNavigate, Outlet, useLocation} from 'react-router-dom';
import {IDraftCanva, ISlotTemplateCanvas} from '@gfxco/contracts';

import DesignsContent from '../../components/DesignsContent';
import DesignsHeader from './DesignsHeader';

import './DesignsPage.scss';

const DesignsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleScratchDesignClick = () => {
    return navigate(`/designs/create${location.search}`);
  };

  const handleEditDraft = (draft: IDraftCanva) => {
    return navigate(`/designs/edit/draft-canvas/${draft.id}${location.search}`);
  };

  const handleEditDesign = (design: ISlotTemplateCanvas) => {
    return navigate(`/designs/edit/${design.id}${location.search}`);
  };

  if (location.pathname === '/designs') {
    return (
      <div id="DesignsPage">
        <DesignsHeader onScratchDesign={handleScratchDesignClick} />
        <DesignsContent
          onEdit={handleEditDesign}
          onEditDraft={handleEditDraft}
        />
      </div>
    );
  }

  return <Outlet />;
};

export default DesignsPage;
