import React from 'react';
import GFXButton from '../../../components/Button';
import Icon from '../../../components/Icons/Icon';
import RejectTooltip from '../../../components/OrderModal/ActionsView/RejectTooltip';
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from '../../../libs/localStorageUtils';

import './AcceptBulkItems.scss';

type AcceptBulkItemsProps = {
  ordersSelected: number;
  allSelected: boolean;
  totalOrders: number;
  loading?: boolean;
  handleClickAcceptItems: () => void;
};

const AcceptBulkItems: React.FC<AcceptBulkItemsProps> = (props) => {
  const disableAcceptBulkWarning = getFromLocalStorage<boolean | null>(
    'disableAcceptBulkWarning',
  );
  const [showWarning, setShowWarning] = React.useState(false);

  const disabledAcceptItems = props.allSelected
    ? false
    : props.ordersSelected === 0;

  const handleAcceptClick = () => {
    if (disableAcceptBulkWarning) {
      props.handleClickAcceptItems();
      return;
    }
    setShowWarning(true);
  };

  const handleAcceptRejectTooltip = (disableWarning: boolean) => {
    if (disableWarning) {
      saveToLocalStorage<boolean>('disableAcceptBulkWarning', true);
    }
    setShowWarning(false);
    props.handleClickAcceptItems();
  };

  return (
    <div id="acceptBulkItem">
      {showWarning && (
        <RejectTooltip
          text="The selected orders will be approved and sent to the printer. This action cannot be undone."
          acceptText="Got it!"
          onAccept={handleAcceptRejectTooltip}
          onClose={() => setShowWarning(false)}
          hideClose
          showCancel
        />
      )}
      {(props.ordersSelected > 0 || props.allSelected) && (
        <span className="selectedItemsText">
          {props.allSelected ? props.totalOrders : props.ordersSelected}{' '}
          selected items
        </span>
      )}
      <GFXButton
        id="acceptAllButton"
        disabled={disabledAcceptItems || props.loading}
        onClick={() => handleAcceptClick()}
        loading={props.loading}
      >
        <Icon name="checkIcon" />
        Accept selected items
      </GFXButton>
    </div>
  );
};

export default AcceptBulkItems;
