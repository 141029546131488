import React from 'react';

interface ContentSidebarProps {
  children: React.ReactNode;
  className?: string;
}

const ContentSidebar: React.FC<ContentSidebarProps> = ({
  children,
  className = '',
}) => {
  return <div className={`content-sidebar ${className}`}>{children}</div>;
};

export default ContentSidebar;
