import React from 'react';

import './Chips.scss';
import {XCircle} from 'react-bootstrap-icons';

interface OptionalProps {
  defaultValue?: string[];
  disabled?: boolean;
  showInput?: boolean;
  onDelete?: (key: string) => void;
  staticValue?: string[];
}

type ChipsProps = OptionalProps;

const Chips: React.FC<ChipsProps> = ({
  defaultValue,
  disabled,
  showInput = true,
  onDelete,
  staticValue = [],
}) => {
  const [chips, setChips] = React.useState<string[]>(defaultValue ?? []);
  const KEY = {
    backspace: 'Backspace',
    tab: 'Tab',
    enter: 'Enter',
  };

  React.useEffect(() => {
    if (defaultValue) setChips(defaultValue);
  }, [defaultValue]);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyPressed = event.key;

    if (
      keyPressed === KEY.enter ||
      (keyPressed === KEY.tab && event.target.value)
    ) {
      const newChip = event.target.value.trim();
      setChips((prev) => {
        if (newChip && prev.indexOf(newChip) < 0) {
          event.target.value = '';
          return prev.concat([newChip]);
        }
        return prev;
      });
    } else if (keyPressed === KEY.backspace) {
      if (!event.target.value && chips.length) {
        handleOnDeleteChip(chips[chips.length - 1]);
      }
    }
  };

  const handleOnDeleteChip = (chip: string) => {
    const indexToDelete = chips.indexOf(chip);
    if (!staticValue?.includes(chip)) {
      setChips((prev) => {
        if (indexToDelete >= 0) {
          return prev.filter((_, index) => index !== indexToDelete);
        }
        return prev;
      });
      if (onDelete) onDelete(chip);
    }
  };

  const clearInvalidChars = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const INVALID_CHARS = /[^a-zA-Z0-9 ]/g;

    if (INVALID_CHARS.test(event.target.value)) {
      event.target.value = event.target.value.replace(INVALID_CHARS, '');
    }
  };

  return (
    <div className={`gfx-chips ${disabled ? 'disabled' : ''}`}>
      {chips.map((chip, index) => (
        <span className="chip" key={index}>
          <span className="chip-value">{chip}</span>
          <button
            type="button"
            className="chip-delete-button"
            onClick={() => handleOnDeleteChip(chip)}
            disabled={disabled}
          >
            <XCircle />
          </button>
        </span>
      ))}
      {showInput && (
        <input
          type="text"
          className="chips-input"
          placeholder="+ Add tag"
          onKeyDown={handleOnKeyDown}
          onKeyUp={clearInvalidChars}
          disabled={disabled}
        />
      )}
    </div>
  );
};
export default Chips;
