import React from 'react';
import {ReactComponent as PrinterConnectedIcon} from '../../../assets/icons/printer-connected-icon.svg';
import {ReactComponent as PrinterDisconnectedIcon} from '../../../assets/icons/printer-disconnected-icon.svg';
import {ReactComponent as PrinterErrorIcon} from '../../../assets/icons/printer-error-icon.svg';
import Loading from '../../../components/Loading';
import './PrinterCollapseContent.scss';

interface IPrinterCollapseContentProps {
  image?: string | React.ReactNode;
  id: string;
  loading: boolean;
  connected: boolean;
  errored: boolean;
  children: React.ReactNode;
}

const PrinterCollapseContent: React.FC<IPrinterCollapseContentProps> = ({
  image,
  id,
  loading,
  connected,
  errored,
  children,
}) => {
  const DefaultFragmentStatus = () => (
    <div className="integration-status-box">
      <PrinterDisconnectedIcon />
      <span>
        {errored
          ? 'Please complete all to the information before trying to connect your printer'
          : 'Looks like the printer is not connected with your store yet.'}
      </span>
    </div>
  );

  const ConnectedFragmentStatus = () => (
    <div className="integration-status-box integration-status-box--connected">
      <PrinterConnectedIcon />
      <span>
        Your printer has been <b>connected</b> successfully
      </span>
    </div>
  );

  const ErrorFragmentStatus = () => (
    <div className="integration-status-box">
      <PrinterErrorIcon />
      <span>
        oops... something went wrong, please check your information and try
        again
      </span>
    </div>
  );

  const LoadingFragmentStatus = () => (
    <div className="integration-status-box">
      <Loading variant="primary" spinnerOnly />
    </div>
  );

  return (
    <div className="PrinterCollapseContent">
      <div className="PrinterCollapseContent__image">
        {image &&
          (typeof image === 'string' ? (
            <img src={image} alt={`${id}-img-alt`} />
          ) : (
            image
          ))}
      </div>
      <div className="PrinterCollapseContent__form-container">{children}</div>
      <div className="PrinterCollapseContent__status">
        {loading ? (
          <LoadingFragmentStatus />
        ) : connected ? (
          <ConnectedFragmentStatus />
        ) : errored ? (
          <ErrorFragmentStatus />
        ) : (
          <DefaultFragmentStatus />
        )}
      </div>
    </div>
  );
};

export default PrinterCollapseContent;
