import React from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import debounce from 'lodash.debounce';
import {IBuilderSlots} from '@gfxco/contracts';
import {Search} from 'react-bootstrap-icons';

import CustomSelector from '../CustomSelector';
import SlotItem from '../SlotItem';
import {capitalizeFirstLetter} from '../../libs/formatUtils';

import './SlotSelector.scss';

interface Slot extends IBuilderSlots {
  disabled?: boolean;
  errorMessage?: string;
}

interface SlotsBySection {
  [section: string]: Slot[];
}

interface Required {
  slotsBySection: SlotsBySection;
}

interface Optional {
  value?: Slot;
  disabled?: boolean;
  onChange?: (slot: Slot) => void;
}

type Props = Required & Optional;

const SlotSelector: React.FC<Props> = (props) => {
  const [innerSlotsBySection, setInnerSlotsBySection] =
    React.useState<SlotsBySection>(props.slotsBySection);
  const [slotSelected, setSlotSelected] = React.useState<Slot | undefined>(
    props.value,
  );

  const filterSlots = (value: string) => {
    if (!value) {
      setInnerSlotsBySection(props.slotsBySection);
      return;
    }
    setInnerSlotsBySection((prev) => {
      const newSlotsBySection: SlotsBySection = {};

      for (const key in prev) {
        newSlotsBySection[key] = props.slotsBySection[key].filter(
          (slot, index) =>
            slot.alias?.toLowerCase().includes(value.toLowerCase()) ||
            (!isNaN(parseInt(value)) && parseInt(value) === index + 1),
        );
      }

      return newSlotsBySection;
    });
  };

  const handleActionClick = () => {
    if (!slotSelected) {
      return;
    }
    if (props.onChange) {
      props.onChange(slotSelected);
    }
  };

  React.useEffect(() => {
    setSlotSelected(props.value);
  }, [props.value]);

  const getSlotNumber = (slotId: number | string) => {
    let slotIndex = 0;
    for (const key in props.slotsBySection) {
      const slots = props.slotsBySection[key];
      const index = slots.findIndex((s) => s.slotId === slotId);
      if (index < 0) {
        continue;
      }
      if (index >= 0) {
        slotIndex = index;
        break;
      }
    }
    return slotIndex + 1;
  };

  return (
    <CustomSelector
      text={
        slotSelected
          ? `${
              slotSelected.alias ||
              'Slot name ' + getSlotNumber(slotSelected.slotId!)
            }`
          : 'Select a slot'
      }
      disabled={props.disabled}
      actionText="Add to walkthrough"
      action={() => handleActionClick()}
      onClear={() => setSlotSelected(undefined)}
      disabledAction={!slotSelected}
    >
      <div className="slot-selector">
        <InputGroup className="search-bar-group search-slot-selector">
          <InputGroup.Text>
            <Search />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search by name"
            onChange={debounce((event) => {
              filterSlots(event.target.value);
            }, 500)}
            aria-label="Search by name"
          />
        </InputGroup>

        {Object.keys(innerSlotsBySection).map((section, index) => (
          <div className="slot-list" key={`${section}-${index}`}>
            <h5>{capitalizeFirstLetter(section)} elements:</h5>
            <div className="slot-property-columns">
              <div className="slot-property-columns__item">
                <p>Image: </p>
              </div>
              <div className="slot-property-columns__item">
                <p>Slot Type: </p>
              </div>
              <div className="slot-property-columns__item">
                <p>Name: </p>
              </div>
            </div>
            {innerSlotsBySection[section].map((slot, index) => {
              return (
                <SlotItem
                  key={index}
                  data-tooltip={slot.errorMessage}
                  index={getSlotNumber(slot.slotId!)}
                  slot={slot}
                  readonly
                  onSelect={(slot) => setSlotSelected(slot)}
                  selected={slot.slotId === slotSelected?.slotId}
                  disabled={slot.disabled}
                />
              );
            })}
          </div>
        ))}
      </div>
    </CustomSelector>
  );
};

export default SlotSelector;
