import * as Contracts from '@gfxco/contracts';
import {loadClient, handleError} from './api';

export const resendOrdersToPrinter = async (
  parameters: Contracts.ResendOrdersToPrinterParams &
    Contracts.ResendOrdersToPrinterBody,
) => {
  try {
    const client = await loadClient();
    const params = {
      itemId: parameters.itemId,
    };
    const response = await client.post<Contracts.IRenderFilesResponse>(
      `/orders/${parameters.id}/resend`,
      params,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getItemNotes = async (orderId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.IItemNotes[]>(`/orders/notes`, {
      params: {
        orderId,
      },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
