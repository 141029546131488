import * as Contracts from '@gfxco/contracts';
import {loadClient, handleError} from './api';

export const updateTemplate = async (
  templateId: number,
  parameters: Contracts.IUpdateTemplateRequest,
  options: {generateProofs: boolean} = {generateProofs: false},
) => {
  try {
    const client = await loadClient();
    const response = await client.put<void>(
      `/templates/${templateId}?generateProofs=${options.generateProofs}`,
      parameters,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getNextTemplateId = async () => {
  try {
    const client = await loadClient();
    const response = await client.get<{new_id: number}>(`/templates/next-id`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateWalkthrough = async (
  templateId: number,
  walkthroughActions: Contracts.WalkthroughStep[],
) => {
  try {
    const client = await loadClient();
    await client.put<void>(`/templates/${templateId}/walkthrough`, {
      walkthroughActions,
    });
  } catch (error) {
    return handleError(error);
  }
};
