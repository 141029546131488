import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ChevronLeft} from 'react-bootstrap-icons';
import {GetTemplateByIdResponse} from '@gfxco/contracts';

import GFXButton from '../../components/Button';
import WarningTooltip from '../../components/WarningTooltip';

import {useAppSelector} from '../../app/hooks';
import {selectIntegrationStatus} from '../../features/integrations/loadShopifyIntegration';

import Icon from '../../components/Icons/Icon';

interface RequiredProps {
  canvaTemplate: GetTemplateByIdResponse;
  isSaving: boolean;
  isPublishing: boolean;
  handleOnSave: () => void;
  handleOnRePublish: () => void;
  handleOnPublish: () => void;
  disableSaveButton?: boolean;
}

interface OptionalProps {
  className?: string;
  backTo?: string;
}

type Props = RequiredProps & OptionalProps;

const SlotControlsHeader: React.FC<Props> = (props) => {
  const className = props.className || '';
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = React.useState<boolean>(false);
  const integrationStatus = useAppSelector((state) =>
    selectIntegrationStatus(state),
  );

  const handleOnSave = async () => {
    if (props.isSaving) {
      return;
    }

    try {
      await props.handleOnSave();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnRePublish = () => {
    if (props.isPublishing || props.isSaving) {
      return;
    }
    props.handleOnRePublish();
    setShowWarning(false);
  };

  const disabledRepublish = integrationStatus
    ? props.isPublishing || !props.disableSaveButton
    : true;

  return (
    <div className={`design-controls__header ${className}`}>
      <div className="header__section">
        <GFXButton
          variant="link"
          className="back-button"
          onClick={() => navigate(props.backTo || '/designs')}
        >
          <ChevronLeft /> BACK
        </GFXButton>
        <div className="design-controls__header--active">
          <span>
            Slot template: <b>{props.canvaTemplate.id}</b>
          </span>
        </div>
      </div>

      <div className="header__section">
        <GFXButton
          variant="primary"
          href={`/designs/edit/${props.canvaTemplate.id}`}
          onClick={(event) => {
            event.preventDefault();
            return navigate(`/designs/edit/${props.canvaTemplate.id}`);
          }}
          disabled={!props.disableSaveButton}
        >
          <Icon name="edit" />
          Edit template
        </GFXButton>
        <GFXButton
          variant="primary"
          onClick={handleOnSave}
          loading={props.isSaving ? true : undefined}
          disabled={props.isSaving || props.disableSaveButton}
        >
          <Icon name="save" />
          Save design
        </GFXButton>
        {props.canvaTemplate.productId && (
          <div className="publish-action">
            <GFXButton
              variant="primary"
              onClick={() => setShowWarning(true)}
              loading={props.isPublishing ? true : undefined}
              disabled={disabledRepublish}
            >
              <Icon name="checkIcon" />
              Re-publish template
            </GFXButton>
            {showWarning && (
              <WarningTooltip
                id={props.canvaTemplate.id}
                onAccept={handleOnRePublish}
                onClose={() => setShowWarning(false)}
                text="Are you sure about activating this product? If you have modified this design, ensure thorough testing has been conducted before proceeding with activation."
                warning="This will not update sizes or prices, you will need to do it manually."
              />
            )}
          </div>
        )}
        {!props.canvaTemplate.productId && (
          <div className="publish-action">
            <GFXButton
              variant="primary"
              onClick={() => props.handleOnPublish()}
              loading={props.isPublishing ? true : undefined}
              disabled={!props.disableSaveButton}
            >
              <Icon name="publishIcon" />
              Publish on Shopify
            </GFXButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default SlotControlsHeader;
