import React from 'react';
import {Form, FormControlProps, InputGroup} from 'react-bootstrap';

import GFXButton from '../Button/Button';
import {ReactComponent as WriteIcon} from '../../assets/icons/write-icon.svg';

import './GFXInput.scss';

interface Props extends FormControlProps {
  className?: string;
}

const GFXInput: React.FC<Props> = (props) => {
  const {className = ''} = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <InputGroup className={`gfx-input-group ${className}`}>
      <Form.Control {...props} className="gfx-input" ref={inputRef} />
      <GFXButton
        variant="outline-secondary"
        onClick={() => inputRef.current?.focus()}
        tabIndex={-1}
      >
        <WriteIcon />
      </GFXButton>
    </InputGroup>
  );
};

export default GFXInput;
