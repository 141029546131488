import React from 'react';
import Button from 'react-bootstrap/Button';
import {PlusLg} from 'react-bootstrap-icons';
import {IImageFile} from '../../libs/uploadImage';

import './FileInput.scss';

const DEFAULT_ACCEPT_FILES = '.png,.jpg,.jpeg';

export interface RequiredProps {}

export interface OptionalProps {
  text?: string;
  onFileChange: (images: File) => Promise<IImageFile | undefined>;
  onFileLoaded?: (images: IImageFile[]) => void;
  acceptFiles?: string;
  icon?: React.ReactNode;
  button?: React.ReactNode;
  omitUpload?: boolean;
  disabled?: boolean;
  numberOfFiles?: number;
  onError?: (error: string) => void;
}

export type FileInputProps = RequiredProps & OptionalProps;

// eslint-disable-next-line react/display-name
const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      text,
      onFileChange,
      onFileLoaded,
      acceptFiles = DEFAULT_ACCEPT_FILES,
      icon,
      button,
      omitUpload = false,
      disabled = false,
      numberOfFiles,
      onError,
    }: FileInputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(inputRef.current);
      } else {
        ref.current = inputRef.current;
      }
    }, [ref]);

    const handleButtonClick = () => {
      inputRef.current?.click();
    };

    const handleChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
      const filesUploaded = evt.target.files;
      if (!filesUploaded || !filesUploaded.length || !onFileChange) {
        return;
      }

      if (numberOfFiles && filesUploaded.length > numberOfFiles) {
        if (onError) {
          onError(`You can only upload ${numberOfFiles} file(s)`);
        }
        return;
      }

      const imagesLoaded: IImageFile[] = [];

      for (const file of [...filesUploaded]) {
        try {
          const image = await onFileChange(file);
          if (!image) continue;
          if (omitUpload && image) {
            imagesLoaded.push({file, name: image.name});
          } else {
            imagesLoaded.push(image);
          }
        } catch (error: any) {
          imagesLoaded.push({
            isError: true,
            name: file.name,
            error: error.message!,
          });
        }
      }

      if (onFileLoaded) {
        onFileLoaded(imagesLoaded);
      }
    };

    return (
      <div className="FileInput">
        <input
          multiple
          type="file"
          accept={acceptFiles}
          ref={inputRef}
          style={{display: 'none'}}
          onChange={handleChange}
        />
        {button ? (
          <div onClick={handleButtonClick}>{button}</div>
        ) : (
          <Button
            className="btn-gfx-primary"
            onClick={handleButtonClick}
            disabled={disabled}
          >
            {!icon ? (
              <>
                <PlusLg /> {text}
              </>
            ) : (
              icon
            )}
          </Button>
        )}
      </div>
    );
  },
);

export default FileInput;
