import React, {FormEvent, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import useQuery from '../../hooks/useQuery';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {AUTH_SESSION_STATUSES} from '../../constants';
import '@aws-amplify/ui-react/styles.css';
import {
  getAuthStatus,
  confirmSignUpAsync,
  resendSignUpAsync,
} from '../../features/auth';
import Icon from '../../components/Icons/Icon';

import './ConfirmationUserPage.scss';

const ConfirmationUserPage = () => {
  const [verifyCodeStatus, setVerifyCodeStatus] = React.useState('');
  const [resendCodeStatus, setResendCodeStatus] = React.useState('');
  const [formError, setFormError] = React.useState('');
  const query = useQuery();

  const authSession = useAppSelector(getAuthStatus);
  const dispatch = useAppDispatch();

  const {route, submitForm} = useAuthenticator((context) => [
    context.route,
    context.submitForm,
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const username = location.state.username;
  const password = location.state.password;

  const isVerifyCodeLoading = verifyCodeStatus === 'loading';

  useEffect(() => {
    if (
      route === 'authenticated' &&
      authSession === AUTH_SESSION_STATUSES.LOGIN
    ) {
      if (query.has('redirect')) {
        const redirect = query.get('redirect');
        if (redirect) {
          navigate(redirect, {replace: true});
          return;
        }
      }
      navigate('/', {replace: true});
    }
  }, [route, navigate, authSession]);

  const confirmSignUp = async (event: FormEvent) => {
    try {
      setVerifyCodeStatus('loading');
      event.preventDefault();
      event.stopPropagation();
      const target = event.target as HTMLFormElement;
      const code = target.code.value;
      await dispatch(confirmSignUpAsync({username, code})).unwrap();
      submitForm({type: 'SIGN_IN', username, password});
      setVerifyCodeStatus('success');
    } catch (error: any) {
      console.error('Error confirming sign up', error);
      setVerifyCodeStatus('error');
      const code: string = error.code;
      if (code === 'LimitExceededException') {
        return setFormError(
          'Too many requests, please try again later. If the problem persists, please contact support.',
        );
      }
      setFormError(error.message);
    }
  };

  const handleReSendCode = async () => {
    setResendCodeStatus('loading');
    try {
      await dispatch(resendSignUpAsync({username})).unwrap();
      setResendCodeStatus('success');
    } catch (error: any) {
      setFormError(error.message);
      setResendCodeStatus('error');
      console.log('Error resending code', error);
    }
  };

  return (
    <div id="ConfirmationUserPage">
      <div className="confirmation-email-box">
        <Icon name="emailConfirmation" />
        <span className="confirmation-email-title">Check your inbox</span>
        <span className="confirmation-email-text">
          We have sent a verification email with an authentication code
        </span>
      </div>

      <Form className="sign-in-form" noValidate onSubmit={confirmSignUp}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>
            Authentication Code <span className="required-symbol">*</span>
            <br />
            <small>Code will expire in 24h</small>
          </Form.Label>
          <Form.Control name="code" required />
        </Form.Group>

        {formError && <p className="form-feedback-error">{formError}</p>}

        <Button variant="primary" type="submit" disabled={isVerifyCodeLoading}>
          {isVerifyCodeLoading ? 'Verifying...' : 'Verify my account'}
        </Button>

        <Form.Group controlId="formBasicCheckbox" onClick={handleReSendCode}>
          <Button
            variant="link"
            className="form-action-link"
            disabled={resendCodeStatus === 'loading'}
          >
            Re-send confirmation code
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default ConfirmationUserPage;
