import React from 'react';
import {
  ELoadingStates,
  TWalkthroughImage,
  TWalkthroughStep,
  TemplateCanvaObj,
} from '@gfxco/contracts';

import GFXButton from '../Button';
import WalkthroughActions from './WalkthroughActions';
import WalkthroughForm from './WalkthroughForm';
import {GFXToastLaunch} from '../ToastMessage/ToastMessage';

import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import {
  createWalkthroughImage,
  createWalkthroughStep,
  deleteWalkthroughStep,
  getWalkthroughImages,
  getWalkthroughSteps,
  updateWalkthroughStep,
} from '../../api';

import {IImageFile} from '../../libs/uploadImage';
import {ReactComponent as EyeIcon} from '../../assets/icons/eye-fill-icon.svg';
import './WalkthroughTapSection.scss';
import Loading from '../Loading';

const {
  REACT_APP_LEGACY_API_URL = 'http://localhost:3000/api',
  REACT_APP_EDITOR_URL = 'https://gfx-editor-staging-best-apps.vercel.app',
} = process.env;

type WalkthroughTapSectionProps = {
  templateId: number;
  designData: TemplateCanvaObj;
};

const WalkthroughTapSection: React.FC<WalkthroughTapSectionProps> = (props) => {
  const shopSelected = useAppSelector(selectShop);
  const [disabledEditForm, setDisabledEditForm] = React.useState(true);

  const [steps, setSteps] = React.useState<TWalkthroughStep[]>([]);
  const [stepsFetchStatus, setStepsFetchStatus] =
    React.useState<ELoadingStates>(ELoadingStates.IDLE);
  const [images, setImages] = React.useState<TWalkthroughImage[]>([]);
  const [imagesFetchStatus, setImagesFetchStatus] =
    React.useState<ELoadingStates>(ELoadingStates.IDLE);

  const [walkthroughActionEdited, setWalkthroughActionEdited] = React.useState<
    (TWalkthroughStep & {index?: number}) | undefined
  >();

  const shopId = shopSelected?.id;

  if (!shopId) {
    return null;
  }

  const existsWalkthroughSteps = steps && steps.length > 0;

  const getAsyncWalkthroughSteps = async (templateId: number) => {
    try {
      setStepsFetchStatus(ELoadingStates.LOADING);

      const steps = await getWalkthroughSteps({templateId});
      setStepsFetchStatus(ELoadingStates.LOADED);
      if (!steps) {
        throw new Error('Not found');
      }
      setSteps(steps);
    } catch (error) {
      setStepsFetchStatus(ELoadingStates.FAILED);
      console.error(error);
      GFXToastLaunch(
        'Something went wrong loading the walkthrough steps',
        3000,
        {
          showAt: 'bottom',
          alertType: 'danger',
          showIcon: true,
        },
      );
    }
  };

  const getAsyncWalkthroughImages = async (shopId: number) => {
    try {
      setImagesFetchStatus(ELoadingStates.LOADING);
      const images = await getWalkthroughImages({shopId});
      setImagesFetchStatus(ELoadingStates.LOADED);
      if (images) setImages(images);
    } catch (error) {
      setImagesFetchStatus(ELoadingStates.FAILED);
      console.error(error);
      GFXToastLaunch(
        'Something went wrong loading the walkthrough images',
        3000,
        {
          showAt: 'bottom',
          alertType: 'danger',
          showIcon: true,
        },
      );
    }
  };

  const createAsyncWalkthroughStep = async (step: TWalkthroughStep) => {
    try {
      const newStep = await createWalkthroughStep({
        text: step.text,
        walkthroughImageId: step.walkthroughImageId,
        slotId: step.slotId,
        templateId: props.templateId,
      });
      if (!newStep) {
        throw new Error('Error');
      }
      setSteps((prevSteps) => [...prevSteps, newStep]);
      GFXToastLaunch(
        'Your walkthrough step has been saved successfully',
        3000,
        {
          showAt: 'bottom',
          alertType: 'success',
          showIcon: true,
        },
      );
    } catch (error) {
      console.error(error);
      GFXToastLaunch(
        'Something went wrong creating the walkthrough step',
        3000,
        {
          showAt: 'bottom',
          alertType: 'danger',
          showIcon: true,
        },
      );
    }
  };

  const updateAsyncWalkthroughStep = async (
    stepId: number,
    step: Partial<TWalkthroughStep>,
  ) => {
    try {
      const updatedStep = await updateWalkthroughStep(stepId, step);
      if (!updatedStep) {
        throw new Error('Error');
      }
      setSteps((prevSteps) => {
        const newSteps = [...prevSteps];
        const index = newSteps.findIndex((step) => step.id === stepId);
        newSteps[index] = updatedStep;
        return newSteps;
      });
      GFXToastLaunch(
        'Your walkthrough step has been saved successfully',
        3000,
        {
          showAt: 'bottom',
          alertType: 'success',
          showIcon: true,
        },
      );
    } catch (error) {
      console.error(error);
      GFXToastLaunch(
        'Something went wrong updating the walkthrough step',
        3000,
        {
          showAt: 'bottom',
          alertType: 'danger',
          showIcon: true,
        },
      );
    }
  };

  const removeAsyncWalkthroughAction = async (stepId: number) => {
    try {
      await deleteWalkthroughStep(stepId);
      setSteps((prevSteps) => {
        const newSteps = [...prevSteps];
        const index = newSteps.findIndex((step) => step.id === stepId);
        newSteps.splice(index, 1);
        return newSteps;
      });

      GFXToastLaunch(
        'Your walkthrough step has been removed successfully',
        3000,
        {
          showAt: 'bottom',
          alertType: 'success',
          showIcon: true,
        },
      );
    } catch (error) {
      console.error(error);
      GFXToastLaunch(
        'Something went wrong removing the walkthrough step',
        3000,
        {
          showAt: 'bottom',
          alertType: 'danger',
          showIcon: true,
        },
      );
    }
  };

  const PREVIEW_LINK = `${REACT_APP_EDITOR_URL}/?v1TemplateId=${
    props.templateId
  }&walkthrough=true${
    REACT_APP_LEGACY_API_URL ? `&apiUrl=${REACT_APP_LEGACY_API_URL}` : ''
  }`;

  const onWalkthroughFormCancel = () => {
    setDisabledEditForm(true);
    setWalkthroughActionEdited(undefined);
  };

  const onSelectWalkthroughAction = (
    action: (TWalkthroughStep & {index?: number}) | undefined,
  ) => {
    setWalkthroughActionEdited(action);
    setDisabledEditForm(false);
  };

  const handleSaveWalkthroughForm = async (step: TWalkthroughStep) => {
    if (walkthroughActionEdited?.id) {
      await updateAsyncWalkthroughStep(walkthroughActionEdited.id, step);
    } else {
      await createAsyncWalkthroughStep(step);
    }
    setDisabledEditForm(true);
    setWalkthroughActionEdited(undefined);
    onWalkthroughFormCancel();
  };

  React.useEffect(() => {
    getAsyncWalkthroughImages(shopId);
  }, [shopId]);

  React.useEffect(() => {
    getAsyncWalkthroughSteps(props.templateId);
  }, [props.templateId]);

  const contentLoading =
    stepsFetchStatus === ELoadingStates.LOADING ||
    imagesFetchStatus === ELoadingStates.LOADING;

  const onUploadedWalkthroughImage = async (data: IImageFile) => {
    if (data.imageUrl) {
      const newImage = await createWalkthroughImage({
        name: data.name,
        url: data.imageUrl,
        size: data.fileSize,
        shopId,
      });

      if (newImage) setImages((prevImages) => [newImage, ...prevImages]);
    }
  };

  return (
    <div id="WalkthroughSection" className="walkthroughContainer">
      <div className="walkthroughContainer__form">
        <div className="header">
          <span>Edit your walkthrough steps</span>
        </div>

        <WalkthroughForm
          shopId={shopId}
          design={props.designData}
          images={images}
          defaultValue={walkthroughActionEdited}
          disabled={disabledEditForm}
          onSave={(step) => handleSaveWalkthroughForm(step)}
          onCancel={() => onWalkthroughFormCancel()}
          onUploadImage={onUploadedWalkthroughImage}
        />
      </div>
      <div className="walkthroughContainer__preview">
        <div className="header">
          <span>Walkthrough preview:</span>
          <GFXButton
            target="_blank"
            href={PREVIEW_LINK}
            disabled={!existsWalkthroughSteps}
          >
            <EyeIcon />
            <i>Live Preview</i>
          </GFXButton>
        </div>
        <div
          className={`stepsContainer ${
            !existsWalkthroughSteps ? 'centered' : ''
          }`}
        >
          {contentLoading && <Loading variant="black" />}
          {!contentLoading && (
            <WalkthroughActions
              steps={steps}
              onAddAction={() => setDisabledEditForm(false)}
              onRemoveAction={removeAsyncWalkthroughAction}
              onSelect={onSelectWalkthroughAction}
              selected={walkthroughActionEdited}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WalkthroughTapSection;
