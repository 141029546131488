import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, InputGroup} from 'react-bootstrap';
import UserTable from '../UserTable';
import UserRow from '../UserTable/UserRow/UserRow';
import debounce from 'lodash.debounce';
import {Search} from 'react-bootstrap-icons';
import {UserManagementData} from '@gfxco/contracts';

import {ReactComponent as SaveIcon} from '../../../assets/icons/save-icon.svg';
import AddUserIcon from '../../../assets/icons/add-user-icon';

import './UsersSection.scss';
import CustomPagination from '../../../components/CustomPagination/GFXCustomPagination';

interface Props {
  users: UserManagementData[];
  title: string;
  subtitle: string;
}

type RegularUserProps = {
  userType: 'regular';
  disableSaveButton: boolean;
  onUpdateUser: (user: UserManagementData) => void;
  onDeleteUser: (user: UserManagementData) => void;
  onCleanPermissions: (user: UserManagementData) => void;
  onHandleSaveButton: () => void;
  onInviteNewUser: () => void;
};

type OwnerUserProps = {
  userType: 'owner';
  disableSaveButton?: boolean;
  onUpdateUser?: (user: UserManagementData) => void;
  onDeleteUser?: (user: UserManagementData) => void;
  onCleanPermissions?: (user: UserManagementData) => void;
  onHandleSaveButton?: () => void;
  onInviteNewUser?: () => void;
};

type FinalProps = RegularUserProps | OwnerUserProps;

const UsersSection: React.FC<Props & FinalProps> = (props) => {
  const {
    userType,
    users,
    title,
    subtitle,
    disableSaveButton,
    onUpdateUser,
    onCleanPermissions,
    onDeleteUser,
    onHandleSaveButton,
    onInviteNewUser,
  } = props;

  const inputSearch = useRef<HTMLInputElement>(null);
  const [usersFiltered, setUsersFiltered] =
    useState<UserManagementData[]>(users);

  // Pagination
  const [offset, setOffset] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [limit] = React.useState(50);

  useEffect(() => {
    setUsersFiltered(users);
  }, [users]);

  const onHandlePagination = (action: string, page?: number) => {
    if (action === 'first') {
      setOffset(0);
      setCurrentPage(1);
    } else if (action === 'prev') {
      setOffset(offset - limit);
      setCurrentPage(currentPage - 1);
    } else if (action === 'next') {
      setOffset(offset + limit);
      setCurrentPage(currentPage + 1);
    } else if (
      (action === 'last' || action === 'page') &&
      typeof page === 'number'
    ) {
      setOffset(limit * page);
      setCurrentPage(page + 1);
    }
  };

  const onHandleSearch = (search: string) => {
    if (search === '') {
      setUsersFiltered(users);
      return;
    }
    const filtered = users.filter((user) =>
      user.username.toLowerCase().includes(search.toLowerCase()),
    );

    setUsersFiltered(filtered);
  };

  return (
    <div id="UsersSection">
      <div id="Content">
        <section id="OwnerUsers" className="sectionBox">
          <div className="ContentHeader">
            <div>
              <h1 className="title">{title}</h1>
              <h3 className="description">{subtitle}</h3>
            </div>
            {userType === 'regular' && (
              <div className="ContentHeaderActions">
                <InputGroup className="search-bar-group search-bar-user-management">
                  <InputGroup.Text>
                    <Search />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search User"
                    onChange={debounce((event) => {
                      event.stopPropagation();
                      onHandleSearch(event.target.value);
                    }, 500)}
                    aria-label="SearchBar to filter by user name"
                    ref={inputSearch}
                  />
                </InputGroup>
              </div>
            )}
          </div>

          <div className="ContentBody">
            <UserTable isRegularUser={userType === 'regular'}>
              {usersFiltered.map((user) => (
                <UserRow
                  key={user.username}
                  user={user}
                  onUpdateUser={onUpdateUser || (() => {})}
                  onDeleteUser={onDeleteUser || (() => {})}
                  onCleanPermissions={onCleanPermissions || (() => {})}
                />
              ))}
            </UserTable>
            {usersFiltered.length === 0 && (
              <div className="EmptyUsers">
                <AddUserIcon className="AddUserIcon" />
                <p>Do you need some help?</p>
                <p className="InviteLine">Invite new users to your platform</p>

                <Button
                  className="btn btn-primary"
                  onClick={onInviteNewUser || (() => {})}
                >
                  + Invite new users
                </Button>
              </div>
            )}
          </div>
          {userType === 'regular' && (
            <div className="ContentFooter">
              <div className="PaginationInfo">
                <CustomPagination
                  total={users.length}
                  currentPage={currentPage}
                  handlePagination={onHandlePagination}
                  limit={limit}
                  entity="Users"
                />
              </div>
              <div>
                <Button
                  type="submit"
                  disabled={disableSaveButton}
                  className="SaveChangesButton"
                  onClick={onHandleSaveButton}
                >
                  <SaveIcon /> &nbsp; Save changes
                </Button>
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default UsersSection;
