import {useGFXInstance} from '@best-apps/gfx-editor';
import React from 'react';
import Icon from './../../Icons/Icon';
import {ChevronDown} from 'react-bootstrap-icons';
import ShopImageSelector from '../../ShopImageSelector/ShopImageSelector';
import {IImage} from '@gfxco/contracts';
import InchesSizeInput from '../../InchesSizeInput/InchesSizeInput';

type TopToolbarImageProps = {
  setImageMap: (imageMap: IImage, slot: string) => void;
};

const TopToolbarImage: React.FC<TopToolbarImageProps> = (props) => {
  const gfx = useGFXInstance();
  if (!gfx) return null;
  const [isSelectingImage, setIsSelectingImage] = React.useState(false);
  const [activeObject, setActiveObject] = React.useState<
    typeof gfx.state.activeObject
  >({});
  const [internal] = activeObject.objects || [];
  const [userInputWidth, setUserInputWidth] = React.useState(0);
  const [userInputHeight, setUserInputHeight] = React.useState(0);

  const onImageChange = async (image: IImage) => {
    if (!activeObject || !activeObject.uuid) {
      return console.log('no active object found');
    }
    props.setImageMap(image, activeObject.uuid);
    await gfx?.actions.setCustomPropertyOnSlot(
      activeObject.uuid,
      image.thumbnail,
    );

    setIsSelectingImage(false);
  };

  const onSizeKeyPressed = async (
    evt: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const keyPressed = evt.key;
    if (keyPressed === 'Enter') {
      await gfx?.actions.setInchesSize(userInputWidth, userInputHeight);
    }
  };

  const onImageSizeChange = async (
    key: string,
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!activeObject || !activeObject.uuid || activeObject.isBackground) {
      return console.log('no active object found');
    }

    const target = evt.target as HTMLInputElement;
    const value = target.value;

    if (key === 'width') {
      target.value = value;
      return setUserInputWidth(+value);
    }
    if (key === 'height') {
      target.value = value;
      return setUserInputHeight(+value);
    }
  };

  const GFXSizes = async () => {
    const size = await gfx?.actions.getInchesSize();
    if (size) {
      setUserInputWidth(+size.width.toFixed(3));
      setUserInputHeight(+size.height.toFixed(3));
    }
  };

  React.useEffect(() => {
    // Effect to catch the active object
    if (!gfx) return;
    setIsSelectingImage(false);
    if (gfx && gfx.state.activeObject) {
      const activeObject = gfx.state.activeObject;
      return setActiveObject(activeObject);
    }

    if (!gfx.state.activeObject) {
      setUserInputWidth(0);
      setUserInputHeight(0);
      setActiveObject({});
    }
  }, [gfx, gfx?.state?.activeObject, gfx?.state?.activeObject?.src]);

  React.useEffect(() => {
    // Effect to update sizes internally
    if (activeObject.uuid) {
      GFXSizes();
    }
  }, [
    gfx?.state.activeObject,
    gfx?.state.activeObject?.width,
    gfx?.state.activeObject?.height,
    gfx?.state.activeObject?.scaleX,
    gfx?.state.activeObject?.scaleY,
    gfx?.state.activeObject?.scale,
  ]);

  const showToolbar =
    (internal && activeObject.uuid && ['image'].includes(internal?.type)) ||
    (activeObject.uuid && ['rect'].includes(activeObject?.type)) ||
    (isSelectingImage && activeObject.uuid);

  if (!showToolbar) {
    return null;
  }

  // when is a template the type is located in the internal obj.
  // when is just a canva, only have the following types rect/image.

  const srcImage = activeObject.objects?.length
    ? internal.src
    : activeObject.src;
  const fillImage = internal ? internal.fill : activeObject.fill;

  const onImageButtonClick = () => {
    setIsSelectingImage(!isSelectingImage);
  };

  return (
    <div className="gfx-builder-toolbar top-builder-toolbar">
      <div className="top-builder-toolbar__description-ico">
        <Icon name="backgroundIcon" />
      </div>
      <div
        className="top-builder-toolbar__image-picker-button"
        onClick={onImageButtonClick}
      >
        {isSelectingImage && (
          <ShopImageSelector onSelectImage={onImageChange} />
        )}
        <div
          className="preview_box"
          style={{
            backgroundColor: fillImage,
            backgroundImage: `url("${decodeURI(srcImage)}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
        <p>Image</p>
        <ChevronDown />
      </div>
      <InchesSizeInput
        width={userInputWidth}
        height={userInputHeight}
        onChange={onImageSizeChange}
        onKeyDown={onSizeKeyPressed}
      />
    </div>
  );
};

export default TopToolbarImage;
