import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getModerationFilters} from '../../api';
import {RootState} from '../../app/store';
import {
  IGetModerationFiltersRequest,
  IGetModerationFiltersResponse,
} from '@gfxco/contracts';

export interface ModerationFiltersState {
  value: IGetModerationFiltersResponse;
  status: 'idle' | 'loading' | 'failed' | 'loaded';
}

const initialState: ModerationFiltersState = {
  value: {
    productColors: [],
    productTypes: [],
  },
  status: 'idle',
};

export const getModerationFiltersAsync = createAsyncThunk(
  'moderationFilters/fetch',
  async (parameters: IGetModerationFiltersRequest) => {
    const response = await getModerationFilters(parameters);
    return response;
  },
);

export const loadModerationFilters = createSlice({
  name: 'ModerationFilters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getModerationFiltersAsync.pending, (state) => {
        state.value.productColors = [];
        state.value.productTypes = [];
        state.status = 'loading';
      })
      .addCase(getModerationFiltersAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = 'loaded';
          state.value = action.payload;
        }
      })
      .addCase(getModerationFiltersAsync.rejected, (state, action) => {
        state.value.productColors = [];
        state.value.productTypes = [];
        state.status = 'failed';
      });
  },
});

export const selectModerationFilters = (state: RootState) => {
  return state.moderationFilters.value;
};

export const selectModerationFiltersFetchLoading = (state: RootState) => {
  return state.moderationFilters.status;
};

export default loadModerationFilters.reducer;
