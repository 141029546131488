import React from 'react';

interface ContentMainProps {
  children: React.ReactNode;
  className?: string;
}

const ContentMain: React.FC<ContentMainProps> = ({
  children,
  className = '',
}) => {
  return <div className={`content-main ${className}`}>{children}</div>;
};

export default ContentMain;
