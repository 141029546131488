import React, {useEffect} from 'react';

import GFXImage from '../Image';
import GFXButton from '../Button';
import UploadImagePlus from '../../assets/icons/upload-image-plus-icon.svg';
import {ReactComponent as UploadIcon} from '../../assets/icons/upload-icon.svg';

import './GFXImageEditable.scss';

interface Props {
  src?: string | File;
  limits?: {
    width: number;
    height: number;
    weight: number;
  };
  onFileChange: (file: File) => void;
  onError?: (error: unknown) => void;
  addImageText?: string;
}

const GFXImageEditable: React.FC<Props> = (props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string | null>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    if (props.src instanceof File) {
      getBase64(props.src).then((imageLoaded) => {
        setImageUrl(imageLoaded);
      });
    } else if (typeof props.src === 'string') {
      setImageUrl(`${props.src}?v=${Date.now()}`);
    }
  }, [props.src]);

  const handleChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const filesUploaded = evt.target.files;

    if (filesUploaded && filesUploaded.item(0)) {
      setIsUploading(true);
      props.onFileChange(filesUploaded.item(0)!);
      setIsUploading(false);
    }
  };

  const getBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div
      className={`gfx-image-editable ${imageUrl ? null : 'no-image'} `}
      onClick={() => {
        if (!imageUrl) {
          handleClick();
        }
      }}
      style={{backgroundImage: `url(${imageUrl})`}}
    >
      {imageUrl ? null : (
        <div className="informationContent">
          <GFXImage
            src={isUploading ? '/loading-render.gif' : UploadImagePlus}
            className="addImagePlus"
          />
          <span>{props.addImageText ?? `No store image added`}</span>
        </div>
      )}
      <GFXButton>
        <UploadIcon
          onClick={() => {
            if (imageUrl) {
              handleClick();
            }
          }}
        />
      </GFXButton>
      <input
        type="file"
        onChange={handleChange}
        ref={inputRef}
        style={{display: 'none'}}
        accept=".png,.jpeg,.jpg"
      />
    </div>
  );
};

export default GFXImageEditable;
