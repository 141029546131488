import React from 'react';
import './FloatingYoutubeThumbnail.scss';
import GfxThumbnail from '../../../assets/gfx-presentation-thumbnail.png';
import Icon from '../../../components/Icons/Icon';

interface FloatingYoutubeVideoProps {
  openVideo?: boolean;
  onClosePopup?: () => void;
}

const FloatingYoutubeThumbnail: React.FC<FloatingYoutubeVideoProps> = (
  props,
) => {
  const videoId = 'HqLsIfb_vTA';
  const urlToDocs =
    'https://gfx-help.notion.site/Help-Center-1ed49d73504a42f482a9a3257c635fe2';
  const [openVideo, setOpenVideo] = React.useState(props.openVideo || false);

  React.useEffect(() => {
    setOpenVideo(props.openVideo || false);
  }, [props.openVideo]);

  const onOpenVideo = () => {
    setOpenVideo(true);
  };

  const onCloseVideo = () => {
    if (props.onClosePopup) {
      props.onClosePopup();
    }
    setOpenVideo(false);
  };

  return (
    <>
      <div className="floating-youtube-video">
        <div className="floating-youtube-video__header">
          <Icon name="videoPlayer" />
          <h2>How to install GFX on your shopify store</h2>
          <a target="__blank" href={urlToDocs}>
            See More
          </a>
        </div>
        <img
          onClick={onOpenVideo}
          width={301}
          height={181}
          src={GfxThumbnail}
          alt="youtube video placeholder"
          className="floating-youtube-video__image"
        />
      </div>
      {openVideo && (
        <div className="floating-youtube-modal">
          <div className="floating-youtube-modal__content">
            <div className="floating-youtube-modal__header">
              <p onClick={onCloseVideo}>Close X</p>
            </div>
            <iframe
              width="301"
              height="181"
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default FloatingYoutubeThumbnail;
