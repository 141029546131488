import * as Contracts from '@gfxco/contracts';
import {loadClient, handleError} from './api';

const getExceptions = async (params: Contracts.IGetExceptionsRequest) => {
  try {
    const client = await loadClient();
    const queryParams = {
      offset: params.offset,
      limit: params.limit,
      shopId: params.shopId,
      searchBy: params.searchBy,
      exceptionType: params.exceptionType,
      sortBy: params.sortBy,
    };
    const response = await client.get<Contracts.IGetExceptionsResponse>(
      `/templates/${params.templateId}/exceptions`,
      {
        params: queryParams,
      },
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const deleteException = async (params: Contracts.IDeleteExceptionsRequest) => {
  try {
    const client = await loadClient();
    const response = await client.delete<Contracts.IGetExceptionsResponse>(
      `/templates/${params.templateId}/exceptions/${params.exceptionId}`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const deleteBulkExceptions = async (
  params: Contracts.IDeleteBulkExceptionsRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.delete<Contracts.IGetExceptionsResponse>(
      `/templates/${params.templateId}/exceptions`,
      {data: {exceptionIds: params.exceptionIds}},
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const addTemplateExceptions = async (params: Contracts.IExceptionResult) => {
  try {
    const client = await loadClient();
    const response = await client.post<boolean>(
      `/templates/${params.templateId}/exceptions`,
      params,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export default {
  getExceptions,
  deleteException,
  deleteBulkExceptions,
  addTemplateExceptions,
};
