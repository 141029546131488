import React from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import * as Contracts from '@gfxco/contracts';

import './ProfileForm.scss';
import GFXInput from '../../../components/GFXInput';
import GFXButton from '../../../components/Button';

interface ProfileFormRequiredProps {
  user: {
    attributes: Contracts.CognitoUserAttributes;
  };
  updateUser: (attributes: Contracts.CognitoUserAttributes) => void;
}

interface ProfileFormOptionalProps {}

type ProfileFormProps = ProfileFormRequiredProps & ProfileFormOptionalProps;

const ProfileForm: React.FunctionComponent<ProfileFormProps> = ({
  user,
  updateUser,
}) => {
  const [givenName, setGivenName] = React.useState(
    user?.attributes?.given_name,
  );
  const [familyName, setFamilyName] = React.useState(
    user?.attributes?.family_name,
  );
  const [profileFormError, setProfileFormError] = React.useState<string>('');
  const [requestStatus, setRequestStatus] =
    React.useState<Contracts.ELoadingStates>(Contracts.ELoadingStates.IDLE);

  React.useEffect(() => {
    if (givenName !== user?.attributes?.given_name && !givenName) {
      setGivenName(user?.attributes?.given_name);
    }
    if (familyName !== user?.attributes?.family_name && !familyName) {
      setFamilyName(user?.attributes?.family_name);
    }
  }, [user]);

  const formHasChanges = () => {
    return (
      givenName !== user?.attributes?.given_name ||
      familyName !== user?.attributes?.family_name
    );
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setRequestStatus(Contracts.ELoadingStates.LOADING);
    setProfileFormError('');

    if (!givenName) {
      setProfileFormError('Name is required');
      setRequestStatus(Contracts.ELoadingStates.FAILED);
      return;
    }

    if (!familyName) {
      setProfileFormError('Last name is required');
      setRequestStatus(Contracts.ELoadingStates.FAILED);
      return;
    }

    try {
      await updateUser({
        given_name: givenName,
        family_name: familyName,
        name: `${givenName} ${familyName}`,
      });
    } catch (error: any) {
      setRequestStatus(Contracts.ELoadingStates.FAILED);
      setProfileFormError(error.message);
      return;
    }
    setRequestStatus(Contracts.ELoadingStates.LOADED);
  };

  return (
    <Form
      className="profile-form account-page-form"
      onSubmit={handleSubmitForm}
    >
      <Form.Group className="form-group-accounts" controlId="name-input">
        <Form.Label>Name</Form.Label>
        <div className="input-icon">
          <GFXInput
            type="text"
            value={givenName || ''}
            onChange={(event) => {
              setProfileFormError('');
              setGivenName(event.target.value);
            }}
            placeholder="Your name"
            aria-label="Your name"
          />
        </div>
      </Form.Group>
      <Form.Group
        className="form-group-accounts form-group-icon"
        controlId="lastname-input"
      >
        <Form.Label>Last name</Form.Label>
        <div className="input-icon">
          <GFXInput
            type="text"
            value={familyName || ''}
            onChange={(event) => {
              setProfileFormError('');
              setFamilyName(event.target.value);
            }}
            placeholder="Your last name"
            aria-label="Your last name"
          />
        </div>
      </Form.Group>
      {profileFormError && <Alert variant="danger">{profileFormError}</Alert>}
      <GFXButton
        className="submit-button"
        variant="primary"
        disabled={!formHasChanges() || requestStatus === 'loading'}
        type="submit"
      >
        {requestStatus === Contracts.ELoadingStates.LOADING
          ? 'Loading...'
          : 'Save changes'}
      </GFXButton>
    </Form>
  );
};

export default ProfileForm;
