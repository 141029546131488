import React, {memo} from 'react';

interface RequiredProps {
  proofUrl: string;
}

interface OptionalProps {
  alt: string;
}

type Props = RequiredProps & OptionalProps;

const ProofImage: React.FC<Props> = (props) => {
  return <img src={props.proofUrl} alt={props.alt} loading="lazy" />;
};

export default memo(ProofImage);
