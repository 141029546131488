import React from 'react';
import Table from 'react-bootstrap/Table';

import {GetSubscriptionResponse} from '@gfxco/contracts';

interface RequiredProps {
  subscription: GetSubscriptionResponse;
}

const ShopifySubscription: React.FC<RequiredProps> = ({subscription}) => {
  return (
    <div id="ShopifySubscription">
      <h1>Services</h1>
      <Table hover>
        <thead>
          <tr>
            <th>Shopify type service</th>
          </tr>
        </thead>
        <tbody>
          {subscription?.shopify_services.map((service) => {
            return (
              <tr key={service.id}>
                <td>{service.subscription_type}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ShopifySubscription;
