import * as Contracts from '@gfxco/contracts';
import {loadClient, handleError} from './api';

export const addCollection = async (body: Contracts.IAddCollectionRequest) => {
  try {
    const client = await loadClient();
    const bodyParams = {
      ...body,
    };
    const response = await client.post<Contracts.IAddCollectionRequest>(
      `/collections`,
      bodyParams,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getCollections = async (params: Contracts.ICollectionsRequest) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.IImagesResponse>(
      `collections`,
      {
        params,
      },
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCollectionsCount = async (shopId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<{total: number}>(`collections/count`, {
      params: {shopId},
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCollectionsEntities = async (
  params: Contracts.ICollectionsRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.get<{
      results: Contracts.ICollectionResponse[];
      total: number;
    }>(`collections/entities`, {
      params,
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getSubCollections = async (
  shopId: number,
  collectionId: number,
) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.ICollectionEntity[]>(
      `collections/${collectionId}/subcollections`,
      {
        params: {shopId},
      },
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const editCollection = async (
  body: Contracts.IEditCollectionRequest,
) => {
  try {
    const client = await loadClient();
    const {id, ...params} = body;
    const bodyParams = {
      ...params,
    };
    const response = await client.put<Contracts.IAddCollectionRequest>(
      `/collections/${id}`,
      bodyParams,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteCollection = async (id: number, shopId: number) => {
  try {
    const client = await loadClient();
    const response = await client.delete<Contracts.IDeleteCollectionResponse>(
      `/collections/${id}`,
      {data: {shopId}},
    );

    return response;
  } catch (error: any) {
    console.log(error);
    return error.response || handleError(error);
  }
};
