import React from 'react';
import GFXToggle from '../../../components/Toggle';
import {ReactComponent as InformationIcon} from '../../../assets/icons/information-icon.svg';
import './AutoApproveOption.scss';
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';

type ApprovalOptions = {
  enabled: boolean;
  approvalTime: number | null;
};

interface RequiredProps {
  value: boolean;
  onChange: (value: ApprovalOptions) => void;
}
interface OptionalProps {
  approvalTime?: number | null;
}

type Props = RequiredProps & OptionalProps;

const AutoApproveOption: React.FC<Props> = (props) => {
  const [autoApprovalEnabled, setAutoApprovalEnabled] = React.useState<boolean>(
    props.value,
  );
  const [approvalTime, setApprovalTime] = React.useState<number>(
    props.approvalTime ?? 1,
  );
  const handleOnChange = () => {
    props.onChange({
      enabled: autoApprovalEnabled,
      approvalTime: autoApprovalEnabled ? approvalTime : null,
    });
  };

  const handleAutoApprovalChange = (value: boolean) => {
    setAutoApprovalEnabled(value);
    if (!value) {
      setApprovalTime(1);
    }
  };

  const handleApprovalTimeChange = (value: number) => {
    setApprovalTime(value);
  };

  React.useEffect(() => {
    handleOnChange();
  }, [autoApprovalEnabled, approvalTime]);

  return (
    <div className="auto-approve-option">
      <div className="settings-group">
        <div className="settings">
          <label>
            Automatically approve all designs:{' '}
            <OverlayTrigger
              placement="top"
              delay={{show: 200, hide: 600}}
              overlay={
                <Tooltip
                  id="auto-approve-option-tooltip"
                  className="auto-approve-option-tooltip"
                >
                  Auto-approve will sent all designs directly to the printer
                  production queue. Save changes to apply this setting.
                </Tooltip>
              }
            >
              <InformationIcon />
            </OverlayTrigger>
          </label>
          <GFXToggle
            checked={autoApprovalEnabled}
            onChange={(e) => handleAutoApprovalChange(e.target.checked)}
            labelOff="OFF"
            labelOn="ON"
          ></GFXToggle>
        </div>
        <div className="settings">
          <label>
            Set auto approval time:{' '}
            <OverlayTrigger
              placement="top"
              delay={{show: 200, hide: 600}}
              overlay={
                <Tooltip
                  id="auto-approve-time-tooltip"
                  className="auto-approve-option-tooltip"
                >
                  An extended waiting time will allow you to check your designs
                  to avoid printing canceled items.
                </Tooltip>
              }
            >
              <InformationIcon />
            </OverlayTrigger>
          </label>
          <Form.Select
            aria-label="Auto approval time"
            value={approvalTime}
            onChange={(e) => handleApprovalTimeChange(parseInt(e.target.value))}
            disabled={!autoApprovalEnabled}
          >
            <option value={1}>1 hour</option>
            <option value={6}>6 hour</option>
            <option value={12}>12 hour</option>
            <option value={24}>24 hour</option>
          </Form.Select>
        </div>
      </div>
    </div>
  );
};

export default AutoApproveOption;
