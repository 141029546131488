import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {Amplify} from 'aws-amplify';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {Authenticator} from '@aws-amplify/ui-react';

import {store} from './app/store';
import {AppRoutes} from './routes';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import ErrorPage from './pages/ErrorPage/ErrorPage';

const {
  REACT_APP_AMPLIFY_AUTH_REGION,
  REACT_APP_AMPLIFY_AUTH_POOL_ID,
  REACT_APP_AMPLIFY_AUTH_WEB_CLIENT_POOL_ID,
} = process.env;

Amplify.configure({
  Auth: {
    region: REACT_APP_AMPLIFY_AUTH_REGION,
    identityPoolRegion: REACT_APP_AMPLIFY_AUTH_REGION,
    userPoolId: REACT_APP_AMPLIFY_AUTH_POOL_ID,
    userPoolWebClientId: REACT_APP_AMPLIFY_AUTH_WEB_CLIENT_POOL_ID,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

const container = document.getElementById('root')!;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: '*',
    Component: AppRoutes,
    errorElement: <ErrorPage />,
  },
]);

root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <Provider store={store}>
        <RouterProvider router={router} />
        <Authenticator />
      </Provider>
    </Authenticator.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
