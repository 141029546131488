import React from 'react';
import {FontsDBModel, OnSubmitFont} from '@gfxco/contracts';
import {Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';

import CustomModal from '../../components/CustomModal';
import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import {capitalizeFirstLetter} from '../../libs/formatUtils';
import {extractFileExtension} from '../../libs/uploadImage';

type FormValues = Partial<FontsDBModel & {fontFile: File[] | null | string}>;
interface RequiredProps {
  onSubmit: (
    font: OnSubmitFont,
    action: 'create' | 'update',
  ) => Promise<boolean>;
  errorMessage: string | null;
  isSubmitting: boolean;
  resetError: () => void;
  isModalOpen: boolean;
  onCloseModal: () => void;
  isCreatingFont: boolean;
}

interface OptionalProps {
  font?: FontsDBModel;
  isCreating?: boolean;
}

type FontEditProps = RequiredProps & OptionalProps;

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

type IFontErrors = {[key: string]: string};

const FontEditComponent: React.FC<FontEditProps> = (props) => {
  if (!props.font && !props.isCreating) {
    return null;
  }

  const [fontErrors, setFontErrors] = React.useState<IFontErrors>({});
  const shopSelected = useAppSelector(selectShop);
  const defaultValues = {
    id: props.font ? props.font.id : undefined,
    fontFile: null,
    clientName: props.font ? props.font.fontFamily : '',
    fontFamily: props.font ? props.font.fontFamily : '',
    normalizeChromeSafari: props.font ? props.font.normalizeChromeSafari : 29,
    hideOnNative: props.font ? props.font.hideOnNative : false,
    webOnly: false,
    shopId: shopSelected ? shopSelected.id! : undefined,
  };

  const {register, handleSubmit, watch, reset} = useForm<FormValues>({
    defaultValues,
  });
  const formStatuses = watch();

  React.useEffect(() => {
    setFontErrors({});
    props.resetError();
  }, [formStatuses.fontFamily, formStatuses.clientName, formStatuses.fontFile]);

  const handleFormSubmit = async (data: FormValues) => {
    setFontErrors({});
    let fileExtension: string = '';

    if (props.isCreating) {
      const file =
        data.fontFile && data.fontFile[0] ? (data.fontFile[0] as File) : null;
      if (!file) {
        console.log('no file');
        return;
      }
      fileExtension = extractFileExtension(file.name);
      const fontFile = await toBase64(file as File);
      data.fontFile = fontFile as string;
    }

    const result = await props.onSubmit(
      {
        ...data,
        fontFormat: fileExtension,
        clientName: data.fontFamily,
      } as OnSubmitFont,
      props.isCreating ? 'create' : 'update',
    );

    if (result) {
      await reset(defaultValues);
    }
  };

  const splitCamelCase = (textKey: string) =>
    textKey.replace(/([a-z])([A-Z])/g, '$1 $2');

  const handleFormError = (errors: any) => {
    const fontErrors: IFontErrors = {};

    for (const key of Object.keys(errors)) {
      const message = errors[key].message;
      let textKey = splitCamelCase(key);
      textKey = capitalizeFirstLetter(textKey);
      fontErrors[key] = message || `${textKey} is required`;
    }

    setFontErrors(fontErrors);
  };

  const ErrorAlert = (props: {userError: string | null}) => {
    return (
      <Form.Control.Feedback type="invalid" className="font-error-feedback">
        <p style={{margin: '0'}}>{props.userError}</p>
      </Form.Control.Feedback>
    );
  };

  const modalContent = (
    <div className="container edit-modal-content">
      <Form onSubmit={handleSubmit(handleFormSubmit, handleFormError)}>
        {props.isCreating && (
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Select or drop your font here</Form.Label>
            <Form.Control
              type="file"
              accept=".ttf,.otf,.woff"
              {...register('fontFile', {
                required: true,
                validate: (value: any) => value && value.length > 0,
              })}
            />
            <small>Allowed formats: ttf,otf,woff</small>
            {fontErrors.fontFile && (
              <ErrorAlert userError={fontErrors.fontFile} />
            )}
          </Form.Group>
        )}
        <Form.Group>
          <input type="hidden" {...register('id')} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Name your font family</Form.Label>
          <Form.Control
            type="text"
            {...register('fontFamily', {required: true})}
            disabled={!props.isCreating}
          />
          {fontErrors.fontFamily && (
            <ErrorAlert userError={fontErrors.fontFamily} />
          )}
        </Form.Group>
      </Form>
    </div>
  );

  return (
    <CustomModal
      variant="grey"
      sizeType="middle-page"
      className="fonts-modal"
      modalTile={props.isCreating ? 'Create Font' : 'Edit Font'}
      isSaveEnabled={true}
      onClickSave={handleSubmit(handleFormSubmit, handleFormError)}
      isProgressWarningEnabled={true}
      onCloseModal={props.onCloseModal}
      show={props.isModalOpen}
      progressWarningTitle="There are unsaved changes"
      progressWarningMessage="Would you like to continue editing your font or close the window? Any unsaved changes will be lost."
    >
      {modalContent}
    </CustomModal>
  );
};

export default FontEditComponent;
