import React from 'react';
import {SortDown, SortUp} from 'react-bootstrap-icons';
import useQueryParams from '../../hooks/useQueryParams';

import './SortLabel.scss';

const DEFAULT_OPTIONS = {
  newest: 'Created: Newest first',
  older: 'Created: Oldest first',
};

interface RequiredProps {
  handleSortChange: (selectedOption: any) => void;
}

interface OptionalProps {
  className?: string;
  sortValue?: string;
}

type SortLabelProps = RequiredProps & OptionalProps;

const SortSelect: React.FC<SortLabelProps> = ({
  handleSortChange,
  className = '',
  sortValue = 'newest',
}) => {
  const [queryParams] = useQueryParams();
  const sortNewest = queryParams.sortBy
    ? queryParams.sortBy === 'newest'
    : sortValue
    ? sortValue === 'newest'
    : true;

  const value = sortNewest ? 'newest' : 'older';
  const label = DEFAULT_OPTIONS[value];

  const handleOnClick = (option: boolean) => {
    const value = option ? 'newest' : 'older';
    const label = DEFAULT_OPTIONS[value];
    handleSortChange({value, label});
  };

  return (
    <div id="SortLabel" onClick={() => handleOnClick(!sortNewest)}>
      <span className={`sort-label ${className}`}>{label}</span>
      {sortNewest ? (
        <SortDown className="sort-icon" />
      ) : (
        <SortUp className="sort-icon" />
      )}
    </div>
  );
};

export default SortSelect;
