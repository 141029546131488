import {loadClient, handleError} from './api';
import {
  ISlot,
  IGetLinkedSlotsRequest,
  ILegacyLinkedSlotsResult,
  GetTemplateByIdRequest,
  ITemplateImage,
  ILinkedSlots,
  ILegacyGetLinkedSlotResponse,
  IDeleteLinkedSlotActionsRequest,
} from '@gfxco/contracts';

interface IUpdateSlotsByIdParams {
  templateId: number;
  slotId: number;
}

export interface IUpdateSlotPayload {
  slot: Partial<ISlot>;
  shopId: string;
  generateProofs: boolean;
}

const updateSlotsById = async (
  params: IUpdateSlotsByIdParams,
  data: IUpdateSlotPayload,
) => {
  const client = await loadClient();
  try {
    await client.put(`/templates/${params.templateId}/slots/${params.slotId}`, {
      ...data,
    });
  } catch (error) {
    return handleError(error);
  }
};

const getLinkedSlots = async (params: IGetLinkedSlotsRequest) => {
  try {
    const client = await loadClient();
    const queryParams = {
      ...params,
    };
    const response = await client.get<ILegacyGetLinkedSlotResponse>(
      `/linked-slots`,
      {
        params: queryParams,
      },
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const getSlotsImages = async (params: GetTemplateByIdRequest) => {
  try {
    const client = await loadClient();

    const response = await client.get<ITemplateImage>(
      `/templates/${params.templateId}/slots/images`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const deleteLinkedSlot = async (linkedSlotId: number) => {
  try {
    const client = await loadClient();
    const response = await client.delete<boolean>(
      `/linked-slots/${linkedSlotId}`,
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const createLinkedSlot = async (
  body: ILinkedSlots | ILegacyLinkedSlotsResult,
  shopId: number,
) => {
  try {
    const client = await loadClient();

    const response = await client.post<{message?: string}>(`/linked-slots`, {
      slotRule: body,
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const updateLinkedSlot = async (body: ILegacyLinkedSlotsResult) => {
  try {
    const client = await loadClient();
    const response = await client.put<boolean>(`/linked-slots/${body.id}`, {
      slotRules: [body],
    });

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const deleteBulkLinkedSlotActions = async (
  params: IDeleteLinkedSlotActionsRequest,
) => {
  try {
    const {linkedSlotId, actionsIds} = params;
    const client = await loadClient();
    const response = await client.delete<boolean>(
      `/linked-slots/${linkedSlotId}/actions`,
      {
        data: {actionsIds},
      },
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export default {
  updateSlotsById,
  getLinkedSlots,
  getSlotsImages,
  deleteLinkedSlot,
  createLinkedSlot,
  updateLinkedSlot,
  deleteBulkLinkedSlotActions,
};
