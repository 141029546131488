import {StatusOptions} from '@gfxco/contracts';
export interface OrdersRequest {
  offset: number;
  limit: number;
  shopId: number | undefined;
  status?: string;
  searchBy?: string;
  time?: string;
}

export interface Status {
  name: StatusOptions;
  color?: string;
  description: string;
}

interface DesignStatus {
  [key: string]: string;
}

interface InputDesignStatus {
  designStatus: string;
  date_shipped: string;
  processingStart?: string;
  printer?: string;
  moderationStatus?: string;
}

export interface InputOrders {
  riskDispute?: string;
  rejectedReason?: string;
  notes?: string;
}

const DEFAULT_ORDER_STATUS: Status = {name: '', description: 'None'};

const TIME_FILTERS = ['1', '30', '60', '75'];

export const ORDER_STATUS_MAPPING: Status[] = [
  {name: 'pending-designs', description: 'Pending Designs'},
  {name: 'waiting-art', description: 'Waiting arts'},
  {name: 'rendering-art', description: 'Rendering arts'},
  {name: 'accepted', description: 'Accepted', color: 'success'},
  {name: 'in-process', description: 'In process'},
  {name: 'printing', description: 'Printing', color: 'primary'},
  {name: 'shipped', description: 'Shipped', color: 'success'},
  {name: 'rejected', description: 'Cancelled', color: 'primary'},
  {name: 'refunded', description: 'Refunded', color: 'primary'},
  {name: 'error', description: 'Error', color: 'danger'},
];

const DESIGNS_STATUS_MAPPING: DesignStatus = {
  product_replacement: 'Product replacement',
  'design-rendered': 'Art rendered',
  flagged: 'On moderation',
  'rendering-error': 'Rendering error',
};

export const getOrderStatus = (status: string): Status => {
  const orderStatus = ORDER_STATUS_MAPPING.find((item) => item.name === status);
  if (!orderStatus) return DEFAULT_ORDER_STATUS;
  return orderStatus;
};

export const getDesignStatus = (input: InputDesignStatus): string => {
  if (!input.designStatus) {
    if (!input.processingStart) return 'Waiting Art';
    return 'Rendering Art';
  }
  if (
    input.designStatus === 'accepted' ||
    input.designStatus === 'auto-accepted'
  ) {
    if (input.date_shipped) return 'Shipped';
    if (input.printer) return 'Printing';
    return 'Accepted';
  }

  if (input.moderationStatus === 'pending') return 'On moderation';

  const designStatus = DESIGNS_STATUS_MAPPING[input.designStatus];
  if (!designStatus) return 'Rejected';
  return designStatus;
};

export const getOrderNotes = (input: InputOrders): string[] => {
  if (!input.riskDispute && !input.rejectedReason && !input.notes) {
    return [];
  }

  const note = [];
  if (input.riskDispute) {
    note.push(`Risk/ Dispute Notes: ${input.riskDispute} \n`);
  }
  if (input.rejectedReason) {
    note.push(`Rejected reason: ${input.rejectedReason} \n`);
  }
  if (input.notes) {
    note.push(`General Notes: ${input.notes} \n`);
  }
  return note;
};

export const getItemNotes = (note?: string): string => {
  if (note) {
    const printerNote = note === 'printed' ? 'Sent to printer' : note;
    return `Printer status: ${printerNote}`;
  }
  return '';
};

export const getTimeFilters = (): string[] => TIME_FILTERS;

export const disableResendToPrinter = (status: string) => {
  const statusReadyToResend = [
    'accepted',
    'in-process',
    'printing',
    'shipped',
    'error',
  ].includes(status);
  return !statusReadyToResend;
};
