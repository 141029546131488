import {ESlotTypes} from '@gfxco/contracts';
import React from 'react';
import {Form} from 'react-bootstrap';
import './DefaultText.scss';

type DefaultTextRequiredProps = {
  slotType: string;
  handleOptionsChange: (setting: string, value: boolean | string) => void;
};

type DefaultTextOptionalProps = {
  text?: string;
  fontFamily?: string;
};
type DefaultTextProps = DefaultTextRequiredProps &
  Partial<DefaultTextOptionalProps>;

const DefaultText: React.FC<DefaultTextProps> = (props) => {
  if (props.slotType !== ESlotTypes.Text) return null;

  return (
    <div id="DefaultText">
      <div>
        <Form.Group className="default-text-box default-box-align">
          <Form.Label>Default font:</Form.Label>
          <div className="input-icon-group">
            <Form.Control
              type="text"
              defaultValue={props.fontFamily}
              style={{fontFamily: props.fontFamily}}
              readOnly
            />
          </div>
        </Form.Group>
      </div>
      <div className="font_preview">
        <p>Text Preview:</p>
        <div className="text" style={{fontFamily: props.fontFamily}}>
          {props.text}
        </div>
      </div>
    </div>
  );
};

export default DefaultText;
