import React from 'react';

import GFXButton from '../Button';
import {ReactComponent as ArrowRightIcon} from '../../assets/icons/right-icon.svg';

import './CustomSelector.scss';

interface RequiredProps {
  actionText: string;
  action: () => void;
  text: string;
  children: React.ReactNode;
}
interface OptionalProps {
  disabled?: boolean;
  disabledAction?: boolean;
  onClear?: () => void;
}

type Props = RequiredProps & OptionalProps;

const CustomSelector: React.FC<Props> = (props) => {
  const [show, setShow] = React.useState(false);
  const divRef = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = (e: MouseEvent) => {
    if (show && divRef && !divRef.current!.contains(e.target as Node)) {
      handleCancel();
    }
  };

  React.useEffect(() => {
    if (show) {
      document.addEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [show]);

  const handleActionClick = () => {
    props.action();
    setShow(false);
  };

  const handleCancel = () => {
    setShow(false);
    if (props.onClear) {
      props.onClear();
    }
  };

  return (
    <div className="custom-selector" ref={divRef}>
      <GFXButton
        className="custom-selector-button"
        disabled={props.disabled}
        onClick={() => setShow((prev) => !prev)}
      >
        {props.text} <ArrowRightIcon />
      </GFXButton>
      {show && (
        <div className={`custom-selector-container`}>
          {props.children}
          <div className="custom-selector-actions">
            <GFXButton variant="link" onClick={() => handleCancel()}>
              Cancel
            </GFXButton>
            <GFXButton
              disabled={props.disabledAction}
              onClick={() => handleActionClick()}
            >
              {props.actionText}
            </GFXButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelector;
