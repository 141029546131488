import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import {Search, Plus, XSquareFill} from 'react-bootstrap-icons';
import debounce from 'lodash.debounce';
import {FontsDBModel, OnSubmitFont} from '@gfxco/contracts';
import Badge from 'react-bootstrap/Badge';

import SortSelect from '../../components/SortSelect/SortSelect';
import CustomPagination from '../../components/CustomPagination';
import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import EditFontForm from './FontEdit';
import FontsApi from '../../api/fonts';
import {GFXToastLaunch} from '../../components/ToastMessage/ToastMessage';
import DeleteFontWarning from './DeleteFontWarning';
import './FontsPage.scss';
import SectionHeader from '../../components/SectionHeader';

export default function FontsPage() {
  const [fonts, setFonts] = React.useState<FontsDBModel[]>([]);
  const [totalFonts, setTotalFonts] = React.useState(0);
  const [sortBy, setSortBy] = React.useState('all');
  const [nameFilter, setNameFilter] = React.useState('');
  const [offset, setOffset] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const [axiosErrorMessage, setAxiosErrorMessage] = React.useState<
    string | null
  >(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [editingFont, setEditingFont] = React.useState<FontsDBModel | null>(
    null,
  );
  const [limit] = React.useState(10);
  const [dependencies, setDependencies] = React.useState<number[]>([]);

  const selectedShop = useAppSelector(selectShop);

  const resetPagination = () => {
    setOffset(0);
    setCurrentPage(1);
  };

  const fetchFonts = async ({
    sortBy,
    name,
    offset,
    limit,
  }: {
    sortBy: string;
    name: string;
    offset: number;
    limit: number;
  }) => {
    if (selectedShop) {
      const fontsResponse = await FontsApi.getFonts({
        shopId: selectedShop.id,
        sortBy,
        name,
        offset,
        limit,
      });

      if (fontsResponse) {
        setFonts(fontsResponse.fonts);
        setTotalFonts(fontsResponse.totalFonts);
      }
    }
  };

  const loadFontFromUrl = async (fontName: string, fontUrl: string) => {
    const fontFace = new FontFace(fontName, `url(${fontUrl})`);
    (document as any).fonts.add(fontFace);
    try {
      await fontFace.load();
    } catch (error) {
      console.error(`Error loading font ${fontName} from ${fontUrl}`, error);
    }
  };

  const handleSortChange = (selectedOption: any) => {
    setSortBy(selectedOption.value);
    resetPagination();
  };

  const handlePagination = (action: string, page?: number) => {
    if (action === 'first') {
      setOffset(0);
      setCurrentPage(1);
    } else if (action === 'prev') {
      setOffset(offset - limit);
      setCurrentPage(currentPage - 1);
    } else if (action === 'next') {
      setOffset(offset + limit);
      setCurrentPage(currentPage + 1);
    } else if (
      (action === 'last' || action === 'page') &&
      typeof page === 'number'
    ) {
      setOffset(limit * page);
      setCurrentPage(page + 1);
    }
  };

  const onDeleteFont = async (fontId: number) => {
    const response = await FontsApi.deleteFontById(fontId, selectedShop!.id);

    if (response.removed) {
      const newFonts = fonts.filter((font) => font.id !== fontId);
      setFonts(newFonts);
      setTotalFonts(totalFonts - 1);
      GFXToastLaunch('Font Deleted', 5000, {
        showAt: 'bottom',
        showIcon: true,
        alertType: 'success',
      });
      return;
    }

    if ('depends' in response) {
      setDependencies(response.depends.map(({templateId}) => templateId));
    } else {
      GFXToastLaunch('Unexpected error', 5000, {
        showAt: 'bottom',
        showIcon: true,
        alertType: 'danger',
      });
    }
  };

  const onSubmitFont = async (
    data: OnSubmitFont,
    type: 'create' | 'update',
  ): Promise<boolean> => {
    const onSubmitFunctions = {
      create: FontsApi.createFont,
      update: FontsApi.updateFontById,
    };
    const submit = onSubmitFunctions[type];
    if (isCreating && !data.fontFile) {
      return false;
    }

    // build payload
    let payload = {};
    if (type === 'create') {
      payload = {
        ...data,
        fontFormat: data.fontFormat,
        shopId: undefined,
        id: undefined,
      };
    } else {
      payload = {
        ...data,
        id: undefined,
        fontFile: undefined,
        shopId: undefined,
        fontFormat: undefined,
      } as Partial<OnSubmitFont>;
    }

    try {
      setIsSubmitting(true);
      const success = await submit(
        {
          fontId: data.id,
          shopId: selectedShop!.id,
        },
        payload,
      );
      setIsSubmitting(false);
      if (success) {
        fetchFonts({sortBy, name: nameFilter, offset, limit});
        setEditingFont(null);
        setIsCreating(false);
        setIsModalOpen(false);
        GFXToastLaunch(
          <p>
            Your font has been <span className="highligh-text">saved </span>
            successfully
          </p>,
          5000,
          {
            showAt: 'bottom',
            showIcon: true,
            alertType: 'success',
          },
        );
      }
      return true;
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.status === 409) {
        setAxiosErrorMessage(error.response.data.error);
      }
      if (error.response && error.response.status === 500) {
        setAxiosErrorMessage('Internal Server error. Contact Support.');
      }
      setIsSubmitting(false);
      return false;
    }
  };

  React.useEffect(() => {
    fetchFonts({sortBy, name: nameFilter, offset, limit});
  }, [sortBy, nameFilter, offset, limit, selectedShop]);

  const onCreateNew = () => {
    setIsCreating(true);
    setIsModalOpen(true);
  };
  return (
    <div id="FontsPage">
      <SectionHeader
        title="Fonts"
        subtitle="Select edit and upload your fonts"
        rightContent={
          <div className="fonts-toolbar">
            <div className="toolbar-inputs-container">
              <SortSelect
                handleSortChange={handleSortChange}
                options={[
                  {label: 'All fonts', value: 'all'},
                  {label: 'System fonts', value: 'default'},
                  {label: 'User fonts', value: 'user'},
                  {label: 'Oldest', value: 'oldest'},
                  {label: 'Newest', value: 'newest'},
                ]}
                defaultValue="all"
                className="select-sort-fonts"
              />
              <InputGroup className="search-bar-group">
                <InputGroup.Text>
                  <Search />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search fonts by name"
                  onChange={debounce((event) => {
                    setNameFilter(event.target.value);
                    resetPagination();
                  }, 500)}
                  aria-label="SearchBar to filter by font name"
                />
              </InputGroup>
            </div>
            <Button onClick={onCreateNew}>
              <Plus /> Add font
            </Button>
          </div>
        }
      />
      {dependencies.length > 0 && (
        <DeleteFontWarning
          dependencies={dependencies}
          onClose={() => setDependencies([])}
        />
      )}
      <div className="main-content">
        <Table hover>
          <thead>
            <tr className="table-header-row">
              <th></th>
              <th>Preview</th>
              <th>Font Family</th>
              <th>Source</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {fonts.map((font) => {
              loadFontFromUrl(font.clientName, font.path);
              return (
                <tr className="table-body-row" key={font.id}>
                  <td></td>
                  <td style={{fontFamily: `"${font.clientName}"`}}>
                    {font.clientName}
                  </td>
                  <td className="font-family-name">{font.fontFamily}</td>
                  <td className="badge-column">
                    {font.shopId ? (
                      <Badge bg="primary">Uploaded by user</Badge>
                    ) : (
                      <Badge bg="secondary">Default</Badge>
                    )}
                  </td>
                  <td className="badge-column font-actions">
                    {/* TODO: Allow editing fonts. This is disabled temporarly until we can edit font name without damaging already created designs.  */}
                    {/* {font.shopId && (
                      <Pencil
                        style={{
                          marginRight: 20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setIsModalOpen(true);
                          setEditingFont(font);
                        }}
                      />
                    )} */}
                    {font.shopId && (
                      <XSquareFill
                        style={{cursor: 'pointer'}}
                        onClick={() => onDeleteFont(font.id!)}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <CustomPagination
          limit={limit}
          currentPage={currentPage}
          total={totalFonts}
          handlePagination={handlePagination}
          entity="Fonts"
        />
        <EditFontForm
          errorMessage={axiosErrorMessage}
          resetError={() => setAxiosErrorMessage(null)}
          font={editingFont!}
          isCreating={isCreating}
          onSubmit={onSubmitFont}
          isSubmitting={isSubmitting}
          isCreatingFont={isCreating}
          isModalOpen={isModalOpen}
          onCloseModal={() => {
            setEditingFont(null);
            setIsCreating(false);
            setIsModalOpen(false);
          }}
        />
      </div>
    </div>
  );
}
