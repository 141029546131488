import React from 'react';
import {Dropdown} from 'react-bootstrap';
import {ThreeDots} from 'react-bootstrap-icons';
import {DateTime} from 'luxon';

import {UserManagementData} from '@gfxco/contracts';

import ProfileImage from '../../../../assets/icons/ProfileImage.svg';

import './UserRow.scss';

interface Props {
  user: UserManagementData;
  onUpdateUser: (user: UserManagementData) => void;
  onDeleteUser: (user: UserManagementData) => void;
  onCleanPermissions: (user: UserManagementData) => void;
}

const UserRow: React.FC<Props> = (props) => {
  const isRegularUser = props.user.role === 'regular';
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const {name, checked} = event.target;
    props.onUpdateUser({
      ...props.user,
      permissions: {
        ...props.user.permissions,
        [name]: checked,
      },
    });
  };

  const getInitials = () => {
    return props.user?.username.charAt(0) || '-';
  };

  const creationDate = DateTime.fromISO(props.user.createdAt).toFormat(
    'MMM dd, yyyy',
  );

  return (
    <tr className="UserRow">
      <td scope="row">
        <div className="UserInfo">
          <img
            className="profile-image"
            src={`https://placehold.co/144/eef7ff/4573BB/png?text=${getInitials()}`}
            alt="Account Profile Image"
            onError={({currentTarget}) => {
              currentTarget.onerror = null;
              currentTarget.src = ProfileImage;
            }}
          />
          <div className="UserInfoContent">
            <span className="username">{props.user.username}</span>
            <br />
            <span className="email">{props.user.email}</span>
          </div>
        </div>
      </td>
      <td scope="row">
        <input
          type="checkbox"
          name="products"
          checked={props.user.permissions.products}
          disabled={props.user.role === 'owner'}
          onChange={(e) => {
            handleCheckboxChange(e);
          }}
        />
      </td>
      <td scope="row">
        <input
          type="checkbox"
          name="orders"
          checked={props.user.permissions.orders}
          disabled={props.user.role === 'owner'}
          onChange={(e) => {
            handleCheckboxChange(e);
          }}
        />
      </td>
      <td scope="row" className="hide">
        <input
          type="checkbox"
          name="samples"
          checked={props.user.permissions.samples}
          disabled={props.user.role === 'owner'}
          onChange={(e) => {
            handleCheckboxChange(e);
          }}
        />
      </td>
      <td scope="row">
        <input
          type="checkbox"
          name="myStore"
          checked={props.user.permissions.myStore}
          disabled={props.user.role === 'owner'}
          onChange={(e) => {
            handleCheckboxChange(e);
          }}
        />
      </td>
      <td scope="row">
        <input
          type="checkbox"
          name="printers"
          checked={props.user.permissions.printers}
          disabled={props.user.role === 'owner'}
          onChange={(e) => {
            handleCheckboxChange(e);
          }}
        />
      </td>
      <td scope="row">
        <input
          type="checkbox"
          name="settings"
          checked={props.user.permissions.settings}
          disabled={props.user.role === 'owner'}
          onChange={(e) => {
            handleCheckboxChange(e);
          }}
        />
      </td>
      <td scope="row">{creationDate}</td>
      {isRegularUser && (
        <td scope="row" className="UserActions">
          <Dropdown
            className="design-menu-icon"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Dropdown.Toggle variant="outline-light" size="sm">
              <ThreeDots />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => props.onCleanPermissions(props.user)}
              >
                Clean Permissions
              </Dropdown.Item>
              <Dropdown.Item onClick={() => props.onDeleteUser(props.user)}>
                Delete User
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      )}
    </tr>
  );
};

export default UserRow;
