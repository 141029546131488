import React from 'react';
import {Button} from 'react-bootstrap';
import {InfoCircleFill} from 'react-bootstrap-icons';
import {useNavigate} from 'react-router-dom';
import './ErrorMessage.scss';

interface RequiredProps {
  message: string;
}

interface OptionalProps {
  backToHome?: boolean;
  retryAction?: () => void;
  retryActionText?: string;
}

type ErrorMessageProps = RequiredProps & OptionalProps;

export const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  const navigate = useNavigate();

  const backHome = () => {
    navigate('/');
  };

  const handleRetryAction = () => {
    if (props.retryAction) {
      props.retryAction();
    }
  };

  return (
    <div className="ErrorMessageContainer">
      <h1>
        <InfoCircleFill color="#ff603a" />
      </h1>
      <h2>{props.message}</h2>
      <div className="actions">
        {props.backToHome && (
          <Button onClick={() => backHome()}>Back Home</Button>
        )}
        {props.retryAction && (
          <Button variant="light" onClick={() => handleRetryAction()}>
            {props.retryActionText || 'Try again'}
          </Button>
        )}
      </div>
    </div>
  );
};
