import {
  ELoadingStates,
  ILegacyLinkedSlotsResult,
  IGetLinkedSlotsRequest,
} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SlotsApi from '../../api/slots';
import {RootState} from '../../app/store';

export interface LinkedSlotState {
  entities?: ILegacyLinkedSlotsResult[];
  fechStatus: string;
  total?: number;
}

const initialState: LinkedSlotState = {
  entities: [],
  fechStatus: ELoadingStates.IDLE,
  total: 0,
};

export const getLinkedSlotsAsync = createAsyncThunk(
  'linkedSlot/fetch',
  async (parameters: IGetLinkedSlotsRequest) => {
    const response = await SlotsApi.getLinkedSlots(parameters);
    return response;
  },
);

export const loadLinkedSlots = createSlice({
  name: 'linkedSlot',
  initialState,
  reducers: {
    resetLinkedSlots: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLinkedSlotsAsync.pending, (state) => {
        state.fechStatus = ELoadingStates.LOADING;
      })
      .addCase(getLinkedSlotsAsync.fulfilled, (state, action) => {
        state.fechStatus = ELoadingStates.LOADED;
        state.entities = action.payload?.results;
        state.total = action.payload?.total;
      })
      .addCase(getLinkedSlotsAsync.rejected, (state) => {
        state.fechStatus = ELoadingStates.FAILED;
      });
  },
});

export const selectLinkedSlots = (state: RootState) =>
  state.linkedSlots.entities;
export const selectTotalLinkedSlots = (state: RootState) => {
  return state.linkedSlots.total || 0;
};

export const selectLinkedSlotsFetchstatus = (state: RootState) =>
  state.linkedSlots.fechStatus || ELoadingStates.IDLE;

export const {resetLinkedSlots} = loadLinkedSlots.actions;
export default loadLinkedSlots.reducer;
