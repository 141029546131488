import * as Contracts from '@gfxco/contracts';
import {handleError, loadClient} from './api';

export const putUpdateItem = async (body: Contracts.IPutUpdateItemRequest) => {
  try {
    const client = await loadClient();
    const response = await client.put<void>(`/moderation/item`, body);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getModerationItem = async (id: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.IGetModerationItemResponse>(
      `/moderation/item/${id}`,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteModerationHistoryItem = async (id: number) => {
  try {
    const client = await loadClient();
    const response = await client.delete<boolean>(`/moderation/item/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateModerationHistoryItem = async (
  id: number,
  details: string,
) => {
  try {
    const client = await loadClient();
    const response = await client.put<Contracts.IOrderItemHistory>(
      `/moderation/item/${id}`,
      {
        details,
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getDesignsCount = async (shopId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.IGetDesignsCountResponse>(
      `/moderation/designsCount`,
      {
        params: {shopId},
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateDesignViewed = async (designId: number) => {
  try {
    const client = await loadClient();
    await client.put<void>(`/moderation/designViewed/${designId}`);
  } catch (error) {
    return handleError(error);
  }
};

export const putBulkUpdateItem = async (
  body: Contracts.IPutBulkUpdateItemRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.put<void>(`/moderation/item/bulk`, body);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const putBulkAllUpdateItem = async (
  body: Contracts.IPutBulkAllUpdateItemRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.put<void>(`/moderation/item/bulkAll`, body);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
