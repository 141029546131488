import useQueryParams from './useQueryParams';

export interface IPagination {
  offset: number;
  limit: number;
  currentPage: number;
}
type PaginationParams = {
  limit?: number;
};
const LIMIT_DEFAULT_VALUE = 15;

const usePagination = (
  params?: PaginationParams,
): [IPagination, (action: string, page?: number) => void] => {
  const [queryParams, setQueryParams] = useQueryParams();
  const offset = +queryParams.offset || 0;
  const currentPage = +queryParams.currentPage || 1;
  const limit = +queryParams.limit || params?.limit || LIMIT_DEFAULT_VALUE;

  const setValues = (newPagination: IPagination) => {
    setQueryParams({
      ...queryParams,
      offset: `${newPagination.offset}`,
      limit: `${newPagination.limit || limit}`,
      currentPage: `${newPagination.currentPage}`,
    });
  };

  const handlePagination = (action: string, page?: number) => {
    if (action === 'reset') {
      setValues({offset: 0, currentPage: 1, limit});
      return;
    }

    if (action === 'first') {
      setValues({offset: 0, currentPage: 1, limit});
    } else if (action === 'prev') {
      setValues({offset: offset - limit, currentPage: currentPage - 1, limit});
    } else if (action === 'next') {
      setValues({offset: offset + limit, currentPage: currentPage + 1, limit});
    } else if (
      (action === 'last' || action === 'page') &&
      typeof page === 'number'
    ) {
      setValues({offset: limit * page, currentPage: page + 1, limit});
    }
  };

  return [{offset, limit, currentPage}, handlePagination];
};

export default usePagination;
