import {IImage, IColor, ITemplateImage} from '@gfxco/contracts';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import {ChevronDown} from 'react-bootstrap-icons';
import {ReactComponent as ImageSlotIcon} from '../../../../../assets/icons/image-slot-icon.svg';
import {ReactComponent as TextSlotIcon} from '../../../../../assets/icons/text-slot-icon.svg';
import {ReactComponent as FillColorPlusIcon} from '../../../../../assets/icons/fill-color-plus-icon.svg';
import {ReactComponent as FillColorIcon} from '../../../../../assets/icons/fill-color-icon.svg';
import {ReactComponent as FillColorBorderIcon} from '../../../../../assets/icons/fill-color-border-icon.svg';
import {ReactComponent as TextIcon} from '../../../../../assets/icons/text-reflect-icon.svg';
import ImageAction from '../../../../ShareActions/ImageAction';
import './RuleSelector.scss';
import DropdownList from '../../../../../pages/ExceptionsPage/ExceptionWizard/DropdownButton/DropdownList';
import ItemColor from '../../../ItemColor';
import ItemSlot from '../../../ItemSlot';
import {colors as defaultTextColors} from '../../../../../libs/getDefaultTextColors';
import {Actions} from '../../../../../libs/getLinkedSlotActions';

interface Option {
  label: string;
  value?: string;
  disabled?: boolean;
}

interface SlotOption {
  label?: string;
  value?: string | number;
  type?: string;
  side: 'front' | 'back';
  disabled: boolean;
  selected: boolean;
  blocked?: boolean;
}

interface OptionSelected {
  color?: IColor;
  image?: IImage;
  text?: IColor;
  slotName?: string;
  defaultImage?: string;
  slotSide?: 'front' | 'back';
  slotId?: number;
}

type RequiredProps = {
  options: Option[];
  onImageSelect: (params: {
    image?: IImage;
    slotId: number;
    previousSelected?: OptionSelected;
  }) => void;
  onColorSelect: (params: {
    color?: IColor;
    previousSelected?: OptionSelected;
  }) => void;
  onTextSelect: (params: {
    text?: string;
    slotId: number;
    previousSelected?: OptionSelected;
  }) => void;
  onReflectSelect?: (params: {
    slotId: number;
    previousSelected?: OptionSelected;
  }) => void;
};

type OptionalProps = {
  defaultValue?: Option;
  images?: IImage[];
  selectedOption?: OptionSelected;
  colors?: IColor[];
  slotOptions?: SlotOption[];
  slotImages?: ITemplateImage;
  disabled?: boolean;
  aligned?: 'right' | 'left';
  ruleType?: string;
  fillEnabled?: boolean;
};
type RuleSelectorProps = RequiredProps & OptionalProps;

const RuleSelector: React.FC<RuleSelectorProps> = ({
  defaultValue,
  options,
  colors,
  onColorSelect,
  onImageSelect,
  onTextSelect,
  slotOptions,
  slotImages,
  disabled = false,
  selectedOption,
  aligned = 'left',
  onReflectSelect,
  ruleType,
  fillEnabled = true,
}) => {
  const [isChoosingOption, setIsChoosingOption] =
    React.useState<boolean>(false);
  const [optionSelected, setOptionSelected] = React.useState<
    'color' | 'front' | 'back'
  >();

  const [slotItems, setSlotsItems] = React.useState<SlotOption[]>(slotOptions!);
  const [slotSelected, setSelectedSlot] = React.useState<number | undefined>(
    selectedOption?.slotId!,
  );
  const [textOptionSelected, setTextOptionSelected] = React.useState<
    'fill' | 'reflects' | undefined
  >();
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleOnClose);

    return () => {
      document.removeEventListener('mousedown', handleOnClose);
    };
  }, []);

  const handleOnClose = (event: any) => {
    if (divRef?.current && !divRef.current.contains(event.target)) {
      setIsChoosingOption(false);
      setOptionSelected(undefined);
      setSelectedSlot(undefined);
    }
  };

  React.useEffect(() => {
    if (slotOptions) setSlotsItems(slotOptions);
  }, [slotOptions]);

  const handleColorSelected = (params: {color?: IColor}) => {
    setOptionSelected(undefined);
    setIsChoosingOption(false);
    if (onColorSelect && params.color)
      onColorSelect({color: params.color, previousSelected: selectedOption});
  };

  const handleImageChange = (params: {image?: IImage}) => {
    const image = params.image;
    setOptionSelected(undefined);
    setIsChoosingOption(false);
    if (onImageSelect && slotSelected)
      return onImageSelect({
        image,
        slotId: slotSelected,
        previousSelected: selectedOption,
      });
  };

  const handleOnSlotChange = (slotId: number, isDisabled?: boolean) => {
    if (
      isDisabled &&
      (!selectedOption || (selectedOption && selectedOption.slotId !== slotId))
    )
      return;
    setSelectedSlot(slotId);
  };

  const handleOptionChange = (option: Option) => {
    if (
      option.disabled &&
      (!selectedOption || (selectedOption && !selectedOption.color))
    )
      return;
    setOptionSelected(option.value as 'color' | 'front' | 'back');
  };

  const handleChoosingOption = () => {
    if (disabled) return;
    setIsChoosingOption(!isChoosingOption);
  };

  const handleTextOptionChange = (type: 'fill' | 'reflects') => {
    if (
      ruleType !== Actions.Reflects &&
      type !== Actions.Reflects &&
      fillEnabled
    )
      setTextOptionSelected(type);

    if (
      (!ruleType || ruleType === Actions.Reflects) &&
      type === Actions.Reflects &&
      onReflectSelect &&
      slotSelected
    ) {
      onReflectSelect({slotId: slotSelected});
      setOptionSelected(undefined);
      setIsChoosingOption(false);
    }
  };

  const handleTextFillChange = (text: string) => {
    if (!slotSelected) return;
    setOptionSelected(undefined);
    setIsChoosingOption(false);
    onTextSelect({
      slotId: slotSelected,
      text,
      previousSelected: selectedOption,
    });
  };

  return (
    <div id="RuleSelector">
      <div className="select-rule" ref={divRef}>
        <div className={`option-selected ${disabled ? 'select-disabled' : ''}`}>
          {selectedOption && selectedOption.color && (
            <ItemColor color={selectedOption.color} />
          )}
          {selectedOption && selectedOption.image && (
            <ItemSlot
              image={selectedOption.image}
              side={selectedOption.slotSide!}
              slotName={selectedOption.slotName || ''}
            />
          )}
          {selectedOption && selectedOption.text && (
            <ItemSlot
              color={selectedOption.text}
              side={selectedOption.slotSide!}
              slotName={selectedOption.slotName || ''}
              defaultImage={selectedOption.defaultImage!}
            />
          )}
          {selectedOption &&
            selectedOption.defaultImage &&
            !selectedOption.image &&
            !selectedOption.text && (
              <ItemSlot
                side={selectedOption.slotSide!}
                slotName={selectedOption.slotName || ''}
                defaultImage={selectedOption.defaultImage!}
              />
            )}
          {!selectedOption && (
            <span>{defaultValue?.label || 'Select your condition'}</span>
          )}
        </div>
        <div className={`dropdown-icon`} onClick={() => handleChoosingOption()}>
          <ChevronDown color={`${disabled ? '#d2d2d2' : ''}`} />
        </div>
        {isChoosingOption && (
          <div className="selector-list">
            {options.map((option) => (
              <div
                className={`selector-option ${
                  optionSelected === option.value
                    ? 'selector-option-active'
                    : option.disabled &&
                      (!selectedOption ||
                        (selectedOption && !selectedOption.color))
                    ? 'option-disabled'
                    : ''
                }`}
                key={option.value}
                onClick={() => handleOptionChange(option)}
              >
                <span> {option.label}</span>
              </div>
            ))}
          </div>
        )}
        {isChoosingOption && optionSelected === 'color' && colors && (
          <DropdownList
            type="color"
            list={colors}
            onSelect={handleColorSelected}
            onClose={() => {}}
          />
        )}

        {isChoosingOption &&
          (optionSelected === 'front' || optionSelected === 'back') &&
          colors && (
            <div
              className={`slot-selector ${
                aligned === 'left'
                  ? 'slot-selector-left-aligned'
                  : 'slot-selector-right-aligned'
              }`}
            >
              <div className="title">
                <span>Image:</span>
                <span>Slot Type:</span>
                <span>Name:</span>
              </div>
              {slotItems
                .filter((i) => i.side === optionSelected)
                .map((option: any) => (
                  <div key={option.label}>
                    <div
                      className={`info ${
                        slotSelected === option.value ||
                        (selectedOption &&
                          selectedOption.slotId === option.value)
                          ? 'info-active'
                          : option.disabled || option.selected || option.blocked
                          ? 'info-disabled'
                          : ''
                      }`}
                      onClick={() =>
                        handleOnSlotChange(
                          option.value,
                          option.disabled || option.selected || option.blocked,
                        )
                      }
                    >
                      <div className="info_image">
                        <img src={option.defaultImage} />
                      </div>
                      <div className="info_type">
                        {option.type === 'image' && <ImageSlotIcon />}
                        {option.type === 'text' && <TextSlotIcon />}
                        <span>{option.type}</span>
                      </div>
                      <div className="info_name">
                        <p>{option.label}</p>
                      </div>
                    </div>
                    <div className="slot-image-box">
                      {slotImages &&
                        slotSelected &&
                        slotSelected === option.value &&
                        slotImages[slotSelected]?.length &&
                        slotImages[slotSelected].map((image) => (
                          <div
                            className="slot-image"
                            key={`slot-image-${image.id}`}
                          >
                            <ImageAction
                              name={image?.name as string}
                              url={image?.imageUrl as string}
                              id={image?.id}
                              showName={true}
                              onClick={handleImageChange}
                            />
                          </div>
                        ))}
                    </div>

                    <div>
                      {slotSelected &&
                        (!textOptionSelected ||
                          textOptionSelected === Actions.Reflects) &&
                        slotSelected === option.value &&
                        option.type === 'text' && (
                          <div className="slot-text-box">
                            <div
                              className={`slot-text-option ${
                                optionSelected === option.value
                                  ? 'slot-text-active-option'
                                  : (ruleType &&
                                      ruleType === Actions.Reflects) ||
                                    !fillEnabled
                                  ? 'select-disabled'
                                  : ''
                              }`}
                              onClick={() => handleTextOptionChange('fill')}
                            >
                              <FillColorPlusIcon />
                              <span>Fill with color</span>
                            </div>

                            <div
                              className={`slot-text-option ${
                                optionSelected === option.value
                                  ? 'slot-text-active-option'
                                  : ruleType && ruleType !== Actions.Reflects
                                  ? 'select-disabled'
                                  : ''
                              }`}
                              onClick={() =>
                                handleTextOptionChange(Actions.Reflects)
                              }
                            >
                              <TextIcon />
                              <span>Reflect on other text slot</span>
                            </div>
                          </div>
                        )}
                      {slotSelected &&
                        textOptionSelected === 'fill' &&
                        slotSelected === option.value &&
                        option.type === 'text' && (
                          <>
                            <div
                              className="slot-text-box slot-text-active-option"
                              onClick={() => setTextOptionSelected(undefined)}
                            >
                              <FillColorPlusIcon />
                              <span>Fill with color</span>
                            </div>
                            <Row className="slot-text-selector ">
                              {defaultTextColors.map((textColor) => (
                                <Col
                                  md="auto"
                                  key={textColor.hex}
                                  className="slot-text-option-selector"
                                  onClick={() =>
                                    handleTextFillChange(textColor.hex)
                                  }
                                >
                                  {textColor.name === 'White' ? (
                                    <FillColorBorderIcon />
                                  ) : (
                                    <FillColorIcon fill={textColor.hex} />
                                  )}
                                </Col>
                              ))}
                            </Row>
                          </>
                        )}
                    </div>
                  </div>
                ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default RuleSelector;
