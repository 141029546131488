import {ELoadingStates, ITemplateCollection} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getStickerCollections} from '../../api';
import {RootState} from '../../app/store';

export interface StickerCollectionsState {
  entities: ITemplateCollection[];
  total: number;
  fetchStatus: ELoadingStates;
}

const initialState: StickerCollectionsState = {
  entities: [],
  total: 0,
  fetchStatus: ELoadingStates.IDLE,
};
export const getStickerCollectionsAsync = createAsyncThunk(
  'stickersCollections/fetch',
  async (parameters: {templateId: number; offset?: number; limit?: number}) => {
    const filters = {
      templateId: parameters.templateId,
      offset: parameters.offset,
      limit: parameters.limit,
    };

    const response = await getStickerCollections(filters);
    return response;
  },
);

export const loadStickerCollections = createSlice({
  name: 'stickerCollections',
  initialState,
  reducers: {
    resetStickerCollections: (state) => {
      state.entities = initialState.entities;
      state.fetchStatus = initialState.fetchStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStickerCollectionsAsync.pending, (state) => {
        state.fetchStatus = ELoadingStates.LOADING;
      })
      .addCase(getStickerCollectionsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.entities = [...action.payload.results];
          state.total = action.payload.total || 0;

          state.fetchStatus = ELoadingStates.LOADED;
        }
      })
      .addCase(getStickerCollectionsAsync.rejected, (state) => {
        state.fetchStatus = ELoadingStates.FAILED;
      });
  },
});

export const selectStickerCollections = (state: RootState) => {
  if (!state.stickerCollections || !state.stickerCollections.entities) {
    return [];
  }

  return state.stickerCollections.entities;
};

export const selectStickerCollectionsFetchLoading = (state: RootState) => {
  if (!state.stickerCollections || !state.stickerCollections.fetchStatus) {
    return ELoadingStates.IDLE;
  }
  return state.stickerCollections.fetchStatus;
};

export const selectTotalCollections = (state: RootState) => {
  if (!state.stickerCollections) {
    return 0;
  }
  return state.stickerCollections.total;
};

export const {resetStickerCollections} = loadStickerCollections.actions;

export default loadStickerCollections.reducer;
