import React from 'react';
import './Toggle.scss';

interface GFXToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelOn?: string;
  labelOff?: string;
}

const GFXToggle: React.FC<GFXToggleProps> = (props) => {
  const labelOn = props.label ?? props.labelOn ?? 'On';
  const labelOff = props.label ?? props.labelOff ?? 'Off';

  return (
    <div className={`gfx-switch ${props.checked ? 'checked' : ''}`}>
      <label className="gfx-switch-label">
        {props.checked ? labelOn : labelOff}
      </label>
      <input
        {...props}
        type="checkbox"
        className={`gfx-switch-input ${props.className ?? ''}`}
      />
    </div>
  );
};

export default GFXToggle;
