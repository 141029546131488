import React from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import {SamplePortalLogDB} from '@gfxco/contracts';
import {getSamplePortalLogs} from '../../api';
import './SamplePortalLogPage.scss';

export const SamplePortalLogPage: React.FC = () => {
  const [logs, setLogs] = React.useState<SamplePortalLogDB[]>([]);
  const [logStatus, setLogStatus] = React.useState<
    'loading' | 'success' | 'error' | ''
  >('');
  const [offset, setOffset] = React.useState(0);
  const [limit] = React.useState(10);

  React.useEffect(() => {
    if (logStatus !== 'loading') {
      getLogs();
    }
  }, [offset, limit]);

  const getLogs = async () => {
    setLogStatus('loading');
    try {
      const logs = await getSamplePortalLogs({offset, limit});
      if (logs) {
        setLogs(logs);
      }
    } catch (error) {
      console.error(error);
      setLogStatus('error');
      return;
    }
    setLogStatus('success');
  };

  const handlePagination = (action: string) => {
    if (action === 'next') {
      setOffset(offset + limit);
    } else if (action === 'prev') {
      setOffset(offset - limit);
    }
  };

  if (logs.length === 0 && logStatus !== 'loading') {
    return <div>No logs found</div>;
  }

  if (logs.length === 0) {
    return <div>Loading logs...</div>;
  }

  return (
    <>
      <Table responsive striped className="sample-portal-table">
        <thead>
          <tr>
            <td>ID</td>
            <td>Created</td>
            <td>Updated</td>
            <td>TemplateID</td>
            <td>Printer</td>
            <td>Sku</td>
            <td>Neck Label</td>
            <td>Editor Url</td>
            <td>Order Name</td>
            <td>Recipient</td>
            <td>Address 1</td>
            <td>Address 2</td>
            <td>City</td>
            <td>State Code</td>
            <td>Zip</td>
            <td>Email</td>
            <td>Design Number</td>
            <td>Front Proof</td>
            <td>Front Art</td>
            <td>Back Proof</td>
            <td>Back Art</td>
          </tr>
        </thead>
        <tbody>
          {logs.map((log, index) => (
            <tr key={index}>
              {Object.values(log).map((value, index) => {
                const nodeValue = value as React.ReactNode;
                return <td key={index}>{nodeValue || 'None'}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        <Pagination.Prev onClick={() => handlePagination('prev')} />
        <Pagination.Next onClick={() => handlePagination('next')} />
      </Pagination>
    </>
  );
};
