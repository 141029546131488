import {ELoadingStates, IImageRequest, IImage} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';

import imagesApi from '../../api/images';
import {RootState} from '../../app/store';

const Images = new schema.Entity('images');
const imagesSchema = {images: [Images]};

export interface ImageState {
  entities: {[id: number]: IImage};
  pages: {[page: number]: number[]};
  pageFetchStatus: {[offset: number]: string};
  updateImageFetchStatus: string;
  total: number;
}

const initialState: ImageState = {
  entities: {},
  pages: {},
  pageFetchStatus: {},
  updateImageFetchStatus: ELoadingStates.IDLE,
  total: 0,
};

export const getImagesAsync = createAsyncThunk(
  'images/fetch',
  async (parameters: IImageRequest & {page: number}) => {
    const response = await imagesApi.getImages(parameters);

    const normalizedData = normalize({images: response?.results}, imagesSchema);

    return {
      entities: normalizedData.entities.images,
      pages: normalizedData.result.images,
      totalImages: response?.total,
    };
  },
);

export const updateImageAsync = createAsyncThunk(
  'update-image/fetch',
  async (parameters: IImage) => {
    const response = await imagesApi.updateImage(parameters);

    return response;
  },
);

export const updateStateImages = createAsyncThunk(
  'images/updateStateImages',
  async (images: IImage[]) => {
    const normalizedData = normalize({images}, imagesSchema);

    return {
      entities: normalizedData.entities.images,
      pages: normalizedData.result.images,
      totalImages: images.length,
    };
  },
);

export const loadImages = createSlice({
  name: 'images',
  initialState,
  reducers: {
    resetImages: (state) => {
      state = initialState;
      return state;
    },
    resetImagesPages: (state) => {
      state.pages = {};
      state.pageFetchStatus = {};
      state.total = 0;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getImagesAsync.pending, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] = ELoadingStates.LOADING;
      })
      .addCase(getImagesAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.pageFetchStatus[action.meta.arg.page] = ELoadingStates.LOADED;
          state.entities = {...state.entities, ...action.payload.entities};
          state.pages[action.meta.arg.page] = action.payload.pages;
          state.total = action.payload.totalImages || 0;
        }
      })
      .addCase(getImagesAsync.rejected, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] = ELoadingStates.FAILED;
      })
      .addCase(updateImageAsync.pending, (state) => {
        state.updateImageFetchStatus = ELoadingStates.LOADING;
      })
      .addCase(updateImageAsync.fulfilled, (state, action) => {
        if (action.payload && action.meta.arg.id) {
          state.updateImageFetchStatus = ELoadingStates.LOADED;
          state.entities[action.meta.arg.id] = {
            ...state.entities[action.meta.arg.id],
            ...action.payload,
          };
        }
      })
      .addCase(updateImageAsync.rejected, (state) => {
        state.updateImageFetchStatus = ELoadingStates.FAILED;
      })
      .addCase(updateStateImages.fulfilled, (state, action) => {
        state.entities = {...state.entities, ...action.payload.entities};
        state.pages['1'] = [...action.payload.pages, ...state.pages['1']];
        state.total = +state.total + action.payload.totalImages;
      });
  },
});

export const getEntityById = (state: RootState, id: number) => {
  return state.images.entities[id];
};

export const getImagesByPage = (state: RootState, page: number) => {
  const ids = state.images.pages[page] || [];
  if (ids.length > 0) {
    return ids.map((id) => getEntityById(state, id));
  } else {
    return [];
  }
};

export const fetchStatusByPage = (state: RootState, page: number) => {
  return state.images.pageFetchStatus[page] || ELoadingStates.IDLE;
};

export const selectTotalImages = (state: RootState) => {
  return state.images.total || 0;
};

export const fetchUpdateStatus = (state: RootState) => {
  return state.images.updateImageFetchStatus || ELoadingStates.IDLE;
};

export const {resetImages, resetImagesPages} = loadImages.actions;
export default loadImages.reducer;
