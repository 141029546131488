import React from 'react';
import Form from 'react-bootstrap/Form';
import {XLg} from 'react-bootstrap-icons';
import './RejectTooltip.scss';

interface RequiredProps {
  onClose: () => void;
  onAccept: (disableWarning: boolean) => void;
}

interface OptionalProps {
  text?: string;
  acceptText?: string;
  hideClose?: boolean;
  showCancel?: boolean;
}
type RejectTooltipProps = RequiredProps & OptionalProps;

const RejectTooltip: React.FC<RejectTooltipProps> = ({
  text,
  acceptText,
  hideClose,
  showCancel,
  onAccept,
  onClose,
}) => {
  const [disableRejectWarning, setDisableRejectWarning] =
    React.useState<boolean>(false);

  const tooltipText =
    text ??
    'You are about to reject this design. Before you do, it might be helpful if you leave a comment first.';

  const tooltipAcceptText = acceptText ?? 'Got it';
  return (
    <div id="RejectTooltip">
      {!hideClose && (
        <XLg className="tooltip-close" onClick={() => onClose()} />
      )}
      <span className="tooltip-text">{tooltipText}</span>
      <div className="tooltip-actions">
        <Form.Check
          id="keep-reject-in"
          name="reject"
          type="checkbox"
          label="Don't show me again"
          checked={disableRejectWarning}
          onChange={(e) => setDisableRejectWarning(e.target.checked)}
        />

        {showCancel && (
          <button
            className="tooltip-link tooltip-link--danger"
            onClick={() => onClose()}
            type="button"
          >
            Cancel
          </button>
        )}

        <button
          className="tooltip-link"
          onClick={() => onAccept(disableRejectWarning)}
        >
          {tooltipAcceptText}
        </button>
      </div>
    </div>
  );
};

export default RejectTooltip;
