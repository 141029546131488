import React from 'react';
import {Link} from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import {ChevronLeft} from 'react-bootstrap-icons';
import {InvoiceResponse} from '@gfxco/contracts';
import SortSelect from '../../components/SortSelect/SortSelect';
import CustomPagination from '../../components/CustomPagination';
import {useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';

import PaymentsApi from '../../api/payments';

import './InvoicePage.scss';
import {parseStripeAmount} from '../../libs/formatUtils';

export default function InvoicesPage() {
  const [invoices, setInvoices] = React.useState<InvoiceResponse[]>([]);
  const [totalInvoices, setTotalInvoices] = React.useState(0);
  const [sortBy, setSortBy] = React.useState('newest');
  const [offset, setOffset] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);

  const [limit] = React.useState(10);

  const selectedShop = useAppSelector(selectShop);

  const resetPagination = () => {
    setOffset(0);
    setCurrentPage(1);
  };

  const fetchInvoices = async ({
    sortBy,
    offset,
    limit,
  }: {
    sortBy: string;
    offset: number;
    limit: number;
  }) => {
    if (selectedShop) {
      const invoicesResponse = await PaymentsApi.getInvoices({
        sortBy,
        offset,
        limit,
      });

      if (invoicesResponse) {
        setInvoices(invoicesResponse.invoices);
        setTotalInvoices(invoicesResponse.totalInvoices);
      }
    }
  };

  const handleSortChange = (selectedOption: any) => {
    setSortBy(selectedOption.value);
    resetPagination();
  };

  const handlePagination = (action: string, page?: number) => {
    if (action === 'first') {
      setOffset(0);
      setCurrentPage(1);
    } else if (action === 'prev') {
      setOffset(offset - limit);
      setCurrentPage(currentPage - 1);
    } else if (action === 'next') {
      setOffset(offset + limit);
      setCurrentPage(currentPage + 1);
    } else if (
      (action === 'last' || action === 'page') &&
      typeof page === 'number'
    ) {
      setOffset(limit * page);
      setCurrentPage(page + 1);
    }
  };

  React.useEffect(() => {
    fetchInvoices({sortBy, offset, limit});
  }, [sortBy, offset, limit]);

  const renderDate = (date: Date) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  };

  return (
    <div id="InvoicesPage">
      <Link className="back-button" to="/">
        <ChevronLeft /> BACK
      </Link>
      <h1>Invoices</h1>
      <div className="invoices-toolbar">
        <SortSelect
          handleSortChange={handleSortChange}
          options={[
            {label: 'Oldest', value: 'oldest'},
            {label: 'Newest', value: 'newest'},
          ]}
          defaultValue="newest"
          className="select-sort-invoices"
        />
      </div>
      <Table hover>
        <thead>
          <tr>
            <th>Id</th>
            <th>Status</th>
            <th>Period Start</th>
            <th>Period End</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => {
            return (
              <tr key={invoice.stripeInvoiceId}>
                <td>{invoice.stripeInvoiceId}</td>
                <td>{invoice.status}</td>
                <td>{renderDate(invoice.periodStart)}</td>
                <td>{renderDate(invoice.periodEnd)}</td>
                <td>{parseStripeAmount(invoice.amount)}</td>
                <td>
                  {invoice.invoicePdf ? (
                    <a
                      href={invoice.invoicePdf}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      See PDF
                    </a>
                  ) : (
                    ''
                  )}
                  {invoice.hostedInvoiceUrl ? (
                    <a
                      href={invoice.hostedInvoiceUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      See Invoice Online
                    </a>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <CustomPagination
        limit={limit}
        currentPage={currentPage}
        total={totalInvoices}
        handlePagination={handlePagination}
        entity="Invoices"
      />
    </div>
  );
}
