import React from 'react';
import {ShopPageContent} from '@gfxco/contracts';
import {getPrivacyPolicy} from '../../api';
import './PrivacyPolicyPage.scss';

export default function PrivacyPolicyPage() {
  const [pageContent, setPageContent] = React.useState<ShopPageContent>({
    admin_graphql_api_id: '',
    body_html: '',
    created_at: '',
    handle: '',
    id: -1,
    published_at: '',
    shop_id: -1,
    template_suffix: '',
    title: '',
    updated_at: '',
  });

  React.useEffect(() => {
    getPageContent();
  }, []);

  const getPageContent = async () => {
    const pageContentResult = await getPrivacyPolicy();

    setPageContent(pageContentResult);
  };

  return (
    <div id="PrivacyPolicyPage">
      <div
        className="page-content"
        dangerouslySetInnerHTML={{__html: pageContent.body_html}}
      ></div>
    </div>
  );
}
