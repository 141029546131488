import React from 'react';
import uniqBy from 'lodash/uniqBy';
import {useParams} from 'react-router-dom';

import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import usePagination from '../../../hooks/usePagination';
import Button from '../../../components/Button';
import Icon from '../../../components/Icons/Icon';
import Loading from '../../../components/Loading';
import CollectionBreadcrumb from '../../../components/ImageDashboard/CollectionBreadcrumb';
import GFXCustomPagination from '../../../components/CustomPagination/GFXCustomPagination';

import {
  getStickerCollectionsAsync,
  selectStickerCollections,
  selectStickerCollectionsFetchLoading,
  resetStickerCollections,
  selectTotalCollections,
} from '../../../features/stickers/loadStickersCollections';

import {resetStickerImages} from '../../../features/stickers/loadStickersImages';

import {ELoadingStates} from '@gfxco/contracts';

import './StickersCollections.scss';
import StickersWizard from '../StickersWizard';
import {CollectionSticker} from '../../../components/CollectionsPanel/types';
import SearchNotFound from '../../../components/SearchNotFound';

interface RequiredProps {}

interface OptionalProps {
  type?: string;
}

type StickersCollectionsProps = RequiredProps & OptionalProps;

const StickersCollections: React.FC<StickersCollectionsProps> = ({type}) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const {id: templateId} = params;

  const [{offset, currentPage, limit}, handlePagination] = usePagination();

  const stickerCollections = useAppSelector(selectStickerCollections);
  const totalSticker = useAppSelector((state) => selectTotalCollections(state));

  const stickerCollectionsFetchStatus = useAppSelector(
    selectStickerCollectionsFetchLoading,
  );

  const [showModal, setShowModal] = React.useState<boolean>(false);

  const handleReset = () => {
    dispatch(resetStickerImages());
    dispatch(resetStickerCollections());
  };

  React.useEffect(() => {
    if (stickerCollectionsFetchStatus !== ELoadingStates.IDLE || !templateId)
      return;

    dispatch(
      getStickerCollectionsAsync({
        templateId: +templateId,
        offset,
        limit,
      }),
    );
  }, [stickerCollectionsFetchStatus, templateId]);

  const CollectionSticker = (props: {item: any}) => {
    const {item} = props;
    const items = [];
    if (item.parentId) {
      items.push({
        name: item.parentName,
        icon: <Icon name="folder" fillColor={{default: '#4573bb'}} />,
      });
    }

    items.push({
      name: item.name,
      icon: <Icon name="folder" fillColor={{default: '#4573bb'}} />,
    });
    return <CollectionBreadcrumb items={items} isLink={false} />;
  };

  return (
    <div id="StickersCollections">
      <div className="sticker-menu-container">
        <div className="body-menu">
          <span className="header-preview-title">Collections added:</span>
          {[ELoadingStates.LOADING, ELoadingStates.IDLE].includes(
            stickerCollectionsFetchStatus,
          ) && <Loading spinnerOnly />}
          {!stickerCollections.length &&
            stickerCollectionsFetchStatus === ELoadingStates.LOADED && (
              <SearchNotFound entity="Sticker collections" />
            )}
          {stickerCollectionsFetchStatus === ELoadingStates.LOADED &&
            !!stickerCollections.length &&
            uniqBy(stickerCollections, 'id').map((s) => (
              <div
                key={s.templateCollectionId}
                className="collection-sticker-item"
              >
                <CollectionSticker item={s} />
              </div>
            ))}
        </div>
        <div className="body-menu-footer">
          <Button
            className="add-edit-button"
            onClick={() => setShowModal(true)}
          >
            + Add or edit stickers
          </Button>
          <GFXCustomPagination
            limit={limit}
            currentPage={currentPage}
            total={totalSticker}
            handlePagination={handlePagination}
            skipInfo
          />
        </div>
      </div>
      <StickersWizard
        open={showModal}
        onClose={() => {
          handleReset();
          setShowModal(false);
        }}
        stickerCollections={stickerCollections as CollectionSticker[]}
        isEditing
        type={type}
      />
    </div>
  );
};

export default StickersCollections;
