import React from 'react';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {ReactComponent as RenderArtIcon} from '../../../assets/icons/render-art-icon.svg';
import {ReactComponent as RenderProofIcon} from '../../../assets/icons/render-proof-icon.svg';
import {ReactComponent as EditSquareIcon} from '../../../assets/icons/edit-square-icon.svg';
import {ReactComponent as EmptyArtIcon} from '../../../assets/icons/empty-art-file-icon.svg';
import {ColorBackground} from '../OrderModal';
import {
  IRenderFilesRequest,
  EModerationItemStatus,
  ELoadingStates,
} from '@gfxco/contracts';
import GFXImage from '../../Image';
import './DesignView.scss';
import {
  renderFilesAsync,
  selectRenderFilesFetchLoading,
  selectEntitiesLoading,
} from '../../../features/moderation/loadModerationOrders';

interface RequiredProps {
  proofFront: string | null;
  proofBack: string | null;
  artFront: string | null;
  artBack: string | null;
  colorBackground: ColorBackground;
  viewSelected: 'front' | 'back';
  designId: number;
  templateId: number;
  itemId: number;
  status: EModerationItemStatus;
  renderDisabled?: boolean;
}

interface OptionalProps {}
type DesignViewProps = RequiredProps & OptionalProps;

const TEXT_COLORS = {
  '#fff': '#838383',
  '#B2AFB5': '#525151',
  '#525151': '#FFF',
  '#000': '#FFF',
};
const DesignView: React.FC<DesignViewProps> = ({
  proofFront,
  proofBack,
  artBack,
  artFront,
  colorBackground,
  viewSelected,
  designId,
  templateId,
  itemId,
  status,
  renderDisabled = false,
}) => {
  const isRenderDisabled =
    status !== EModerationItemStatus.PENDING || renderDisabled;
  const proofFileUrl = viewSelected === 'front' ? proofFront! : proofBack!;
  const artFileUrl = viewSelected === 'front' ? artFront! : artBack!;
  const [isProofRendering, setIsProofRendering] = React.useState(false);
  const [isArtRendering, setIsArtRendering] = React.useState(false);
  const dispatch = useAppDispatch();
  const renderFetchStatus = useAppSelector((state) =>
    selectRenderFilesFetchLoading(state),
  );
  const entitiesLoading = useAppSelector((state) =>
    selectEntitiesLoading(state),
  );

  React.useEffect(() => {
    if (entitiesLoading.includes(itemId)) {
      setIsProofRendering(true);
      setIsArtRendering(true);
    }
  }, []);

  React.useEffect(() => {
    if (
      (renderFetchStatus === ELoadingStates.FAILED ||
        renderFetchStatus === ELoadingStates.LOADED) &&
      !entitiesLoading.includes(itemId)
    ) {
      setIsProofRendering(false);
      setIsArtRendering(false);
    }
  }, [renderFetchStatus, entitiesLoading]);

  const handleRenderFiles = async (
    section: 'front' | 'back',
    type: 'artfile' | 'proof',
  ) => {
    if (type === 'artfile') setIsArtRendering(true);
    else setIsProofRendering(true);

    if (!designId || !templateId) return;
    const params: IRenderFilesRequest = {
      section,
      type,
      designId: +designId,
      itemId,
      templateId,
    };
    dispatch(renderFilesAsync(params));
  };

  return (
    <div
      id="DesignView"
      className="order-design-content"
      style={{backgroundColor: colorBackground}}
    >
      <div
        className={`design-content ${isArtRendering ? 'design-loading' : ''}`}
      >
        {isArtRendering && (
          <div className="loading-box">
            <span className="loader"> </span>
          </div>
        )}
        {!isArtRendering && !artFileUrl && (
          <div className="empty-box">
            <EmptyArtIcon />
            <span className="empty-box-title">Oops!...</span>
            <span className="empty-box-warning">
              Looks like no designs have been created on this view yet, please
              change the side.
            </span>
          </div>
        )}
        <>
          {!isArtRendering && artFileUrl && (
            <GFXImage
              src={artFileUrl}
              alt=""
              placeholderSrc="/loading-render.gif"
            />
          )}
          <span
            style={{color: TEXT_COLORS[colorBackground], marginTop: '1.5rem'}}
          >
            Art file detail
          </span>
        </>
      </div>
      <div
        className={`design-content ${isProofRendering ? 'design-loading' : ''}`}
      >
        {isProofRendering && (
          <div className="loading-box">
            <span className="loader"> </span>
          </div>
        )}
        <>
          {!isProofRendering && (
            <GFXImage
              src={proofFileUrl}
              alt=""
              placeholderSrc="/loading-render.gif"
            />
          )}
          <span
            style={{color: TEXT_COLORS[colorBackground], marginTop: '1.5rem'}}
          >
            Proof file detail
          </span>
        </>
      </div>
      <div className="design-toolbar">
        <button
          className={`toolbar-icon button-tooltip ${
            isArtRendering || isRenderDisabled ? 'toolbar-icon-disabled' : ''
          }`}
          onClick={() => handleRenderFiles(viewSelected, 'artfile')}
          disabled={isArtRendering || isRenderDisabled}
          data-tooltip="Re-render artfiles"
        >
          <RenderArtIcon />
        </button>
        <button
          className={`toolbar-icon button-tooltip ${
            isProofRendering || isRenderDisabled ? 'toolbar-icon-disabled' : ''
          }`}
          onClick={() => handleRenderFiles(viewSelected, 'proof')}
          disabled={isProofRendering || isRenderDisabled}
          data-tooltip="Re-render proofs"
        >
          <RenderProofIcon />
        </button>
        <button className={`toolbar-icon action-hide`} disabled>
          <EditSquareIcon />
        </button>
      </div>
    </div>
  );
};

export default DesignView;
