import React from 'react';
import Icon from '../../Icons/Icon';
import {Color} from '@gfxco/contracts';
import ColorItem from '../../ConfigureProductInfo/Items/ColorItem';
import classNames from 'classnames';

type GarmentColorOptionalProps = {
  currentColor?: string;
};

type GarmentColorRequiredProps = {
  colors: Color[];
  onColorClick: (color: Color) => void;
  currentColor: string;
};

type GarmentColorProps = GarmentColorOptionalProps & GarmentColorRequiredProps;

const GarmentColorBar: React.FC<GarmentColorProps> = (props) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [runningAnimation, setRunningAnimation] = React.useState<string>('');

  const toggleExpand = () => {
    if (!props.colors.length) return;
    if (!isExpanded) {
      setRunningAnimation('toolbar-animation');
      setTimeout(() => {
        setIsExpanded(!isExpanded);
        setRunningAnimation('');
      }, 550);
    } else {
      setRunningAnimation('toolbar-animation-reverse');
      setIsExpanded(!isExpanded);
      setTimeout(() => {
        setRunningAnimation('');
      }, 220);
    }
  };
  const classes = {
    'gfx-builder-toolbar': true,
    'product-color-bar': true,
    [runningAnimation]: true,
    'toolbar-expanded-right': isExpanded,
  };

  const iconColor = props.currentColor;
  const renderColors = isExpanded;
  return (
    <div className={`${classNames(classes)}`}>
      <div className="icon-group">
        <Icon
          tooltip="Garment Color"
          name="garmentColor"
          fillColor={{default: iconColor}}
          box
          action={toggleExpand}
        />
      </div>
      <div className="color-list">
        {renderColors &&
          props.colors.map((color) => {
            return (
              <ColorItem
                hover
                markBorder={color.hex === '#FFFFFF'}
                key={color.hex}
                color={color}
                selected={false}
                onClick={() => props.onColorClick(color)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default GarmentColorBar;
