import React from 'react';
import './Steps.scss';
import StepToolTip from '../../Tooltip/Tooltip';
import classNames from 'classnames';

interface StepProperties {
  isSelected: boolean;
  index: number;
  renderLine: boolean;
  completionPercentage: number;
  step: {
    name: string;
    validateStep?: () => boolean;
  };
  setStep?: (step: number) => void;
}

const Steps: React.FC<StepProperties> = (props) => {
  const {isSelected, index, step, renderLine, setStep} = props;
  const [showAnimation, setShowAnimation] = React.useState(false);
  const [showToolTip, setShowToolTip] = React.useState(false);

  React.useEffect(() => {
    let timeoutFunc: ReturnType<typeof setTimeout> | null = null;
    if (isSelected && !showAnimation) {
      setShowAnimation(true);
      timeoutFunc = setTimeout(() => {
        setShowAnimation(false);
      }, 300);
    } else {
      setShowAnimation(false);
    }

    return () => {
      if (timeoutFunc) {
        clearTimeout(timeoutFunc);
      }
    };
  }, [isSelected]);

  const ProductTypeTooltip = () => (
    <div className="steps-tooltips-product-type">
      <h2 className="tittle">Not so fast</h2>
      <p className="message">
        For a different &rdquo;product type&rdquo;, you have to create a{' '}
        <span className="highlight">new template</span>
      </p>
    </div>
  );

  return (
    <>
      <div
        className={classNames({
          step: 'true',
          'selected-step': isSelected,
          'animation-selected-step': showAnimation,
          'clickable-step': setStep,
        })}
        key={step.name}
        onClick={(event) => {
          if (step.validateStep && step.validateStep()) {
            event.stopPropagation();
            setShowToolTip(true);
          } else {
            if (setStep) {
              setStep(index + 1);
            }
          }
        }}
      >
        <div
          className={`step__circle__line ${!renderLine ? 'no-color' : ''}`}
        />
        <div
          style={{width: renderLine ? '100%' : '0%'}}
          className="step__circle__line_big"
        />
        <div className="step__circle">
          <p className="step__index">{index + 1}</p>
          <span>{step.name}</span>
        </div>
      </div>
      {showToolTip && setStep && (
        <StepToolTip
          top={'11 rem'}
          onAccept={() => {
            setShowToolTip(false);
          }}
        >
          <ProductTypeTooltip />
        </StepToolTip>
      )}
    </>
  );
};

export default Steps;
