import React from 'react';

export const AddUserIcon: React.FC<{fill?: string; className?: any}> = ({
  fill = '#4573BB',
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8335 10.2084C5.8335 6.18134 9.09809 2.91675 13.1252 2.91675C17.1522 2.91675 20.4168 6.18134 20.4168 10.2084C20.4168 14.2355 17.1522 17.5001 13.1252 17.5001C9.09809 17.5001 5.8335 14.2355 5.8335 10.2084ZM13.1252 5.83341C10.7089 5.83341 8.75016 7.79217 8.75016 10.2084C8.75016 12.6247 10.7089 14.5834 13.1252 14.5834C15.5415 14.5834 17.5002 12.6247 17.5002 10.2084C17.5002 7.79217 15.5415 5.83341 13.1252 5.83341Z"
        fill={fill}
      />
      <path
        d="M8.75016 23.3334C7.58984 23.3334 6.47704 23.7944 5.65657 24.6149C4.8361 25.4353 4.37516 26.548 4.37516 27.7084V30.6251C4.37516 31.4305 3.72224 32.0834 2.91683 32.0834C2.11142 32.0834 1.4585 31.4305 1.4585 30.6251V27.7084C1.4585 25.7745 2.22672 23.9198 3.59418 22.5525C4.96163 21.185 6.8163 20.4167 8.75016 20.4167H17.5002C19.4341 20.4167 21.2888 21.185 22.6561 22.5525C24.0236 23.9198 24.7918 25.7745 24.7918 27.7084V30.6251C24.7918 31.4305 24.1389 32.0834 23.3335 32.0834C22.5281 32.0834 21.8752 31.4305 21.8752 30.6251V27.7084C21.8752 26.548 21.4142 25.4353 20.5937 24.6149C19.7733 23.7944 18.6604 23.3334 17.5002 23.3334H8.75016Z"
        fill={fill}
      />
      <path
        d="M29.1667 11.6668C29.1667 10.8614 28.5138 10.2085 27.7083 10.2085C26.9029 10.2085 26.25 10.8614 26.25 11.6668V14.5835H23.3333C22.5279 14.5835 21.875 15.2364 21.875 16.0418C21.875 16.8473 22.5279 17.5002 23.3333 17.5002H26.25V20.4168C26.25 21.2223 26.9029 21.8752 27.7083 21.8752C28.5138 21.8752 29.1667 21.2223 29.1667 20.4168V17.5002H32.0833C32.8888 17.5002 33.5417 16.8473 33.5417 16.0418C33.5417 15.2364 32.8888 14.5835 32.0833 14.5835H29.1667V11.6668Z"
        fill={fill}
      />
    </svg>
  );
};

export default AddUserIcon;
