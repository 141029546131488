import React from 'react';

// Components
import Icon from '../Icons/Icon';

// Contracts
import {ShopsResponse} from '@gfxco/contracts';

import './GFXStoreItemComponent.scss';

const GFXStoreItemComponent: React.FC<{
  shop: ShopsResponse;
  isSelectedStore?: boolean;
  onClick?: () => void;
  icon?: 'addImagePlus' | 'defaultStoreImage';
}> = ({shop, isSelectedStore = false, onClick, icon = 'addImagePlus'}) => {
  return (
    <div
      id="GFXStoreItemComponent"
      onClick={() => {
        onClick && onClick();
      }}
    >
      {shop.shop_image_url ? (
        <img
          className="StoreImage"
          src={`${shop.shop_image_url}?v=${Date.now()}`}
          loading="lazy"
          alt="Store Image"
        />
      ) : (
        <div className="StoreImage__blank">
          <Icon
            name={icon}
            classes="StoreImage__Blank_icon"
            width={27}
            height={27}
          />
        </div>
      )}

      <div className="StoreInfo">
        {isSelectedStore && (
          <div className="StoreInfo__selectedStore">
            <Icon
              name="checkIconBlue"
              classes="StoreInfo__selectedStore__icon"
            />
            <span className="StoreInfo__selectedStore__span">
              Selected store
            </span>{' '}
          </div>
        )}
        <p
          className={`gfx-ellipsis StoreInfo__shopName ${
            !isSelectedStore && 'no-selected'
          }`}
        >
          {shop.name}
        </p>
        <p
          className={`gfx-ellipsis StoreInfo__shopDescription ${
            !isSelectedStore && 'no-selected'
          }`}
        >
          {shop.description}
        </p>
      </div>
    </div>
  );
};

export default GFXStoreItemComponent;
