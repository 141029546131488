import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import CreatableSelect from 'react-select/creatable';
import {components, OptionProps} from 'react-select';
import {useGFXInstance} from '@best-apps/gfx-editor';
import {getNeckLabels, getSkus, generateArts} from '../../api';
import {
  NeckLabelDBModel,
  NeckLabelRequest,
  SkuDBModel,
  SkuRequest,
} from '@gfxco/contracts';
import './GenerateArtsForm.scss';

const NeckLabelOption = (props: OptionProps) => {
  const imageUrl = props.label.slice(props.label.indexOf('http'));
  return (
    <components.Option {...props}>
      <img src={imageUrl} alt={props.label} style={{width: '70px'}} />
      {props.label}
    </components.Option>
  );
};

interface RequiredProps {
  onGenerateArts: (
    frontProof: string,
    backProof: string,
    frontArt: string,
    backArt: string,
    designNumber: string,
    garmentType: string,
    neckLabel: string,
    SKU: string,
    editorUrl: string,
  ) => void;
  templateId: string;
  printer: string;
}

interface OptionalProps {}

type GenerateArtsFormProps = RequiredProps & OptionalProps;

export const GenerateArtsForm: React.FC<GenerateArtsFormProps> = (props) => {
  const {onGenerateArts, templateId, printer} = props;

  const gfx = useGFXInstance();

  const [generateArtsStatus, setGenerateArtsStatus] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const isLoading = generateArtsStatus === 'loading';

  // From requests
  const [skus, setSkus] = React.useState<SkuDBModel[] | undefined>([]);
  const [neckLabels, setNeckLabels] = React.useState<
    NeckLabelDBModel[] | undefined
  >([]);

  React.useEffect(() => {
    const color = gfx?.state.design.color;
    const garmentType = gfx?.state.design.printableType;
    const shopId = gfx?.state.initialData.v2Template.shop.id.toString();

    if (!color || !garmentType || !shopId || !templateId || !printer) {
      return;
    }

    const filters = {
      color,
      printer,
      garmentType,
      shopId,
      templateId,
    } as SkuRequest;

    getTemplateSkus(filters);
  }, [
    templateId,
    printer,
    gfx?.state.design.color,
    gfx?.state.design.printableType,
  ]);

  React.useEffect(() => {
    const shopId = gfx?.state.initialData.v2Template.shop.id.toString() || '';
    if (shopId) {
      getShopNeckLabels({shopId});
    }
  }, [gfx?.state.initialData.v2Template.shop.id]);

  const getTemplateSkus = async (filters: SkuRequest) => {
    const result = await getSkus(filters);
    setSkus(result);
  };

  const getShopNeckLabels = async (filters: NeckLabelRequest) => {
    const result = await getNeckLabels(filters);
    setNeckLabels(result);
  };

  const handleGenerateArts = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setGenerateArtsStatus('loading');

    if (printer === '') {
      setGenerateArtsStatus('error');
      setErrorMessage('Please select a printer');
      return;
    }

    let design;
    try {
      design = await gfx?.actions.saveDesign();
    } catch (error) {
      setGenerateArtsStatus('error');
      setErrorMessage('An error ocurred saving the design.');
      console.error('Error saved design', error);
      return;
    }

    const designNumber = design?.designNumber || '';

    const garmentType = design?.type || '';

    const target = event.target as HTMLFormElement;
    const editorUrl = target.editorUrl?.value || 'https://editor.gfx.tech/';
    const SKU = target.SKU?.value;
    const neckLabel = target.neckLabel?.value;

    const params = {
      v1TemplateId: templateId,
      designNumber,
      editorUrl,
      sku: target.SKU?.value,
      printer,
    };

    const frontProofParams = {
      ...params,
      renderType: 'proof',
      section: 'front',
    };

    const frontArtParams = {
      ...params,
      renderType: 'artfile',
      section: 'front',
    };

    const backProofParams = {
      ...params,
      renderType: 'proof',
      section: 'back',
    };

    const backArtParams = {
      ...params,
      renderType: 'artfile',
      section: 'back',
    };

    const artsPromises = [
      generateArts(frontProofParams),
      generateArts(frontArtParams),
      generateArts(backProofParams),
      generateArts(backArtParams),
    ];

    let allResults;
    try {
      allResults = await Promise.allSettled(artsPromises);
    } catch (error) {
      console.error('Error generating proofs and art', error);
      return;
    }

    const [frontProofResult, frontArtResult, backProofResult, backArtResult] =
      allResults;
    const frontProof =
      frontProofResult.status === 'fulfilled'
        ? frontProofResult.value.url
        : 'Error getting Front Proof';
    const backProof =
      backProofResult.status === 'fulfilled'
        ? backProofResult.value.url
        : 'Error getting Back Proof';
    const frontArt =
      frontArtResult.status === 'fulfilled'
        ? frontArtResult.value.url
        : 'Error getting Front Art';
    const backArt =
      backArtResult.status === 'fulfilled'
        ? backArtResult.value.url
        : 'Error getting Back Art';

    setGenerateArtsStatus('success');

    onGenerateArts(
      frontProof,
      backProof,
      frontArt,
      backArt,
      designNumber,
      garmentType,
      neckLabel,
      SKU,
      editorUrl,
    );
  };

  return (
    <div id="GenerateArtsForm">
      <Form className="printer-information" onSubmit={handleGenerateArts}>
        <Form.Group>
          <Form.Label htmlFor="SKU">SKU: * (3031, PR-5000-BLACK-S)</Form.Label>
          <CreatableSelect
            id="SKU"
            name="SKU"
            aria-label="SKU"
            isClearable
            required
            options={skus?.map((sku) => {
              return {
                label: `${sku.sku} (${sku.description} ${sku.size} ${sku.color})`,
                value: sku.sku,
              };
            })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="neckLabel">Neck Label: (Asset URL)</Form.Label>
          <CreatableSelect
            id="neckLabel"
            name="neckLabel"
            aria-label="neckLabel"
            components={{Option: NeckLabelOption}}
            isClearable
            options={neckLabels?.map((neckLabel) => {
              return {
                label: `${neckLabel.name} ${neckLabel.imageUrl}`,
                value: neckLabel.imageUrl,
              };
            })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="editorUrl">Editor Url: (PR URL)</Form.Label>
          <Form.Control
            type="text"
            id="editorUrl"
            name="editorUrl"
            aria-label="editorUrl"
            placeholder="https://gfx-editor-pr-450-best-apps.vercel.app/"
          />
        </Form.Group>
        {generateArtsStatus === 'error' && (
          <Alert variant="danger">{errorMessage}</Alert>
        )}
        <div className="submit-group">
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Generate Art Files'}
          </Button>
        </div>
      </Form>
    </div>
  );
};
