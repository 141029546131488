import React from 'react';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {
  updateImageAsync,
  fetchUpdateStatus,
} from '../../../features/images/loadImages';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {IImage, ELoadingStates} from '@gfxco/contracts';
import Loading from '../../Loading';
import './PreviewSection.scss';
import Preview from './Preview/Preview';

interface RequiredProps {
  type: 'images' | 'collections';
  list: IImage[];
  handleOnClick: (key: string, id?: number) => void;
  onDeleteCollection?: (id: number) => void;
  onDeleteImage?: (id: number) => void;
}

interface OptionalProps {
  isLoading?: boolean;
  listView?: boolean;
  handleOnEdit?: (data: IImage & {description?: string}) => void;
}

type PreviewSectionProps = RequiredProps & OptionalProps;

const PreviewSection: React.FC<PreviewSectionProps> = ({
  list,
  type,
  handleOnClick,
  handleOnEdit,
  isLoading = false,
  listView = true,
  onDeleteCollection,
  onDeleteImage,
}) => {
  const dispatch = useAppDispatch();
  const updateImageStatus = useAppSelector((state) => fetchUpdateStatus(state));
  const [imageIdEditing, setImageIdEditing] = React.useState<number>();
  const [showUpdateTooltip, setShowUpdateTooltip] =
    React.useState<boolean>(false);

  const isLoaded = updateImageStatus === ELoadingStates.LOADED;
  const isFailed = updateImageStatus === ELoadingStates.FAILED;

  const tooltipMessage =
    updateImageStatus === ELoadingStates.LOADED
      ? 'Name updated'
      : updateImageStatus === ELoadingStates.FAILED
      ? 'Something went wrong, please try again'
      : '';

  const handleEditClick = async (data: IImage & {description?: string}) => {
    if (type === 'images') {
      dispatch(updateImageAsync(data));
      setShowUpdateTooltip(true);
      setImageIdEditing(data.id);
      setTimeout(() => {
        setShowUpdateTooltip(false);
      }, 3000);
    } else {
      if (handleOnEdit) {
        handleOnEdit(data);
      }
    }
  };

  const handleOnDelete = (id: number) => {
    if (type === 'images' && onDeleteImage) {
      return onDeleteImage(id);
    }
    if (type === 'collections' && onDeleteCollection) {
      return onDeleteCollection(id);
    }
  };

  return (
    <>
      <Container fluid className="PreviewSection">
        {isLoading && <Loading text="Loading..." />}
        {list.length && !isLoading ? (
          <Row className="align-items-center">
            {list.map((item, index) => (
              <Col
                md="auto"
                key={`Preview-${index}`}
                className={`col-gallery-item ${
                  listView ? 'col-list-view' : ''
                }`}
              >
                <Preview
                  identifier={index.toString()}
                  {...item}
                  id={item.id!}
                  onDelete={handleOnDelete}
                  onClick={handleOnClick}
                  onEdit={handleEditClick}
                  type={type}
                  listView={listView}
                  imageUrl={item.largeImageUrl}
                />
                {showUpdateTooltip &&
                  imageIdEditing === item.id &&
                  (isLoaded || isFailed) && (
                    <div
                      className={`preview-tooltip ${
                        isLoaded
                          ? 'preview-tooltip-successful'
                          : 'preview-tooltip-failed'
                      }`}
                    >
                      <span>{tooltipMessage}</span>
                    </div>
                  )}
              </Col>
            ))}
          </Row>
        ) : null}
      </Container>
    </>
  );
};

export default PreviewSection;
