import React from 'react';
import {XLg} from 'react-bootstrap-icons';
import './WarningTooltip.scss';
import Loading from '../Loading';

interface RequiredProps {
  id: number;
  onClose: () => void;
  onAccept: (id: number) => void;
}

interface OptionalProps {
  text?: string | React.ReactNode;
  title?: string;
  warning?: string;
  isLoading?: boolean;
  actionText?: string;
}
type ActionsViewProps = RequiredProps & OptionalProps;

const WarningTooltip: React.FC<ActionsViewProps> = ({
  onAccept,
  onClose,
  id,
  text,
  isLoading,
  warning,
  title,
  actionText,
}) => {
  const handleOnAccept = (id: number) => {
    if (isLoading) return;
    onAccept(id);
  };

  const handleOnClose = () => {
    if (isLoading) return;
    onClose();
  };

  return (
    <div id="RejectTooltip" className="WarningTooltip">
      <XLg className="tooltip-close" onClick={onClose} />
      <div className="tooltip-text">
        {title && <span className="tooltip-title">{title}</span>}
        <div>
          {text ||
            `This design is currently listed as an active product in your Shopify
        Store. In order to modify it, we will set the product as a draft, you
        can activate it after your changes are made.`}
        </div>
      </div>
      {warning && <span className="warning-text">{warning}</span>}
      <div className="tooltip-actions">
        <span
          className={`tooltip-link cancel-link ${
            isLoading ? 'actions-disabled' : ''
          }`}
          onClick={() => handleOnClose()}
        >
          Cancel
        </span>
        <span
          className={`tooltip-link ${isLoading ? 'actions-disabled' : ''}`}
          onClick={() => handleOnAccept(id)}
        >
          {actionText || 'Got it'}
        </span>
        {isLoading && <Loading spinnerOnly />}
      </div>
    </div>
  );
};

export default WarningTooltip;
