import {IWebhook, ShopsResponse} from '@gfxco/contracts';
import React from 'react';
import {Alert, Badge, Button, Modal, ModalProps} from 'react-bootstrap';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {
  selectOrderPipelineV2Enabled,
  updateOrderPipelineAsync,
} from '../../../features/shops/loadDeveloperOptions';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';

interface OptionalProps {
  className?: string;
  shopSelected?: ShopsResponse;
  webhooks?: IWebhook[];
}

type ExperimentalsProps = OptionalProps;

export const Experimentals: React.FC<ExperimentalsProps> = (props) => {
  const [orderPipelineWarningModalShow, setOrderPipelineWarningModalShow] =
    React.useState(false);

  const orderPipelineV2Enabled = useAppSelector((state) =>
    selectOrderPipelineV2Enabled(state, props.shopSelected?.id),
  );

  const orderPipelineBtnText = orderPipelineV2Enabled
    ? 'Restore Order Pipeline Legacy'
    : 'Enable Order Pipeline v2';

  const dispatch = useAppDispatch();

  const updateOrderPipeline = async () => {
    if (props.shopSelected) {
      dispatch(
        updateOrderPipelineAsync({
          shopId: props.shopSelected.id,
          enableOrderPipelinev2: !orderPipelineV2Enabled,
        }),
      );
    }
  };

  return (
    <div className={props.className ? props.className : ''}>
      <header>
        <ScienceOutlinedIcon />
        <h5>Experimentals</h5>
      </header>
      <Alert variant={'warning'}>
        Be careful updating these options, enabiling one of this might be
        unstable.
      </Alert>
      <Button
        variant="primary"
        onClick={() => setOrderPipelineWarningModalShow(true)}
      >
        {orderPipelineBtnText}{' '}
        {!orderPipelineV2Enabled && (
          <Badge bg="light" text="dark">
            Beta
          </Badge>
        )}
      </Button>

      <OrderPipelineUpdatingModal
        btnTxt={orderPipelineBtnText}
        showBeta={!orderPipelineV2Enabled}
        onClick={updateOrderPipeline}
        show={orderPipelineWarningModalShow}
        onHide={() => setOrderPipelineWarningModalShow(false)}
      />
    </div>
  );
};

interface OrderPipelineUpdatingModalProps {
  btnTxt?: string;
  showBeta?: boolean;
  onClick: () => void;
}

const OrderPipelineUpdatingModal: React.FC<
  ModalProps & OrderPipelineUpdatingModalProps
> = (props) => {
  return (
    <Modal {...props} size="lg" centered>
      <Modal.Body>
        <h4>¿Are you sure?</h4>
        <p>
          This will be enable the new Order Pipeline v2 for all new orders. This
          feature might be unstable.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={props.onClick}>
          {props.btnTxt} {props.showBeta && <Badge bg="primary">Beta</Badge>}
        </Button>
        <Button onClick={props.onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Experimentals;
