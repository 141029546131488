import React from 'react';
import {ExclamationCircle, XCircle} from 'react-bootstrap-icons';
import IconButton from '@mui/material/IconButton';
import {IImage} from '@gfxco/contracts';
import './ImageAction.scss';

export interface RequiredProps {}
export interface OptionalProps {
  slotsOrder?: number[];
  slotId?: number;
  url?: string;
  name?: string;
  id?: number;
  children?: React.ReactNode;
  showName?: boolean;
  showSlot?: boolean;
  showDelete?: boolean;
  onDelete?: (params: {id?: number; image?: IImage}) => void;
  onClick?: (item: {image: IImage}) => void;
  actionId?: number;
  selected?: boolean;
}

type ImageActionProps = RequiredProps & OptionalProps;

export const ImageAction: React.FC<ImageActionProps> = ({
  name,
  url,
  slotsOrder,
  slotId,
  id,
  showName = false,
  showSlot = true,
  showDelete = false,
  actionId,
  onDelete,
  onClick,
  selected = false,
}) => {
  const handleOnDelete = () => {
    if (onDelete) {
      if (actionId) onDelete({id: actionId});
      if (name) onDelete({image: {name, imageUrl: url, id}});
    }
  };

  const handleOnClick = () => {
    if (onClick && name && url && id) {
      onClick({image: {name, imageUrl: url, id}});
    }
  };

  return (
    <div id="ImageAction" onClick={handleOnClick}>
      <div className={`content ${selected ? 'selected' : ''}`}>
        {showSlot && slotsOrder && slotId && (
          <div className="content">
            <span className="title">{`Slot ${
              slotsOrder.indexOf(slotId) + 1
            }`}</span>
            <div className="vertical-divider"></div>
          </div>
        )}

        <div className="content-image">
          {url ? (
            <img className="image" src={url} />
          ) : (
            <ExclamationCircle size={'2em'} color="#f10e06" />
          )}
          {showName && (
            <div className="image-text">
              <span>{name?.toLocaleLowerCase() || 'Not loaded'}</span>
            </div>
          )}
          {showDelete && (
            <IconButton onClick={() => handleOnDelete()}>
              <XCircle fontSize={'1rem'} />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};
export default ImageAction;
