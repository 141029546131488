import React from 'react';
import {Nav} from 'react-bootstrap';
import {Outlet, useNavigate} from 'react-router-dom';

import './ShopSettingsPage.scss';

export const ShopSettingsPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="ShopSettings">
      <h3>Shop Settings</h3>
      <Nav
        className="ShopSettings__nav"
        activeKey={window.location.pathname.split('/').pop()}
        onSelect={(selectedKey) => {
          if (selectedKey) navigate(selectedKey);
        }}
      >
        <Nav.Item>
          <Nav.Link eventKey="details">Shop Details</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="develop">Developer Options</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="subscriptions">Subscription</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="invoices">Invoices</Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="ShopSettings__container">
        <Outlet />
      </div>
    </div>
  );
};
