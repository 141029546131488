import React from 'react';
import {Form} from 'react-bootstrap';
import {useForm, Controller} from 'react-hook-form';

// Components
import CustomModal from '../../../components/CustomModal';
import GFXImageEditable from '../../../components/GFXImageEditable';
import GFXColorSelect from '../../../components/GFXColorSelect/GFXColorSelect';
import GFXStoreSelect from '../../../components/GFXStoreSelect/GFXStoreSelect';

// Hooks
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {
  createShopAsync,
  selectShops,
  updateShopAsync,
} from '../../../features/shops/loadShops';
import {selectShop} from '../../../features/shops/selectedShop';

import './CreateNewStoreForm.scss';
import uploadImage, {extractFileExtension} from '../../../libs/uploadImage';
import {useNavigate} from 'react-router-dom';
import {GFXToastLaunch} from '../../../components/ToastMessage/ToastMessage';

interface ColorOption {
  value: string;
  hex: string;
  label: string;
}

interface CreateNewStoreFormProps {
  show: boolean;
  onClose: () => void;
  colorOptions: ColorOption[];
}

interface FormValues {
  storeName: string;
  storeDescription: string;
  storeImage: File;
  storeColor: {value: string; hex: string; label: string};
  storeToImport: {value: number; label: string; isSelectedStore: boolean};
  importStoreConfiguration: boolean;
  importUserManagementSettings: boolean;
  importPrinterSettings: boolean;
}

const CreateNewStoreForm: React.FC<CreateNewStoreFormProps> = ({
  show,
  onClose,
  colorOptions,
}) => {
  const {register, handleSubmit, reset, control, watch, formState} =
    useForm<FormValues>();
  const dispatch = useAppDispatch();
  const shopsLoaded = useAppSelector(selectShops);
  const currentShop = useAppSelector(selectShop);
  const navigate = useNavigate();

  const shopOptions =
    shopsLoaded
      ?.filter((shop) => shop.is_owner)
      .map((shop) => ({
        label: shop.name,
        value: shop.id,
        isSelectedStore: currentShop?.id === shop.id,
      })) || [];

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const handleFormSubmit = async (params: FormValues) => {
    try {
      await dispatch(
        createShopAsync({
          importPrinterSettings: params.importPrinterSettings ?? false,
          importStoreConfigurations: params.importStoreConfiguration ?? false,
          storeColor: params.storeColor.value,
          storeDescription: params.storeDescription,
          storeName: params.storeName,
          storeToImport: params.storeToImport?.value,
          importUserManagementSettings:
            params.importUserManagementSettings ?? false,
        }),
      )
        .unwrap()
        .then(async (response) => {
          const uploadStoreImage = async (file: File) => {
            const fileExtension = extractFileExtension(file.name);
            const newFileName = `shop-picture.${fileExtension}`;
            return uploadImage({
              file,
              fileName: newFileName,
              shopId: response.id,
              omitImageReference: true,
            });
          };

          if (params.storeImage instanceof File) {
            const imageLoaded = await uploadStoreImage(
              params.storeImage as File,
            );
            await dispatch(
              updateShopAsync({
                shopId: response.id,
                shop_image_url: imageLoaded.imageUrl,
              }),
            );
          }

          await reset();
          await onCloseModal();

          navigate(0);
        });
    } catch (error) {
      GFXToastLaunch(
        "We couldn't create your new store, please try again",
        5000,
        {
          alertType: 'danger',
          showAt: 'top',
          right: '1rem',
          top: '3rem',
          showIcon: true,
          parentContainerId: 'gfx-select-store-dropdown',
        },
      );
      console.error(error);
    }
  };

  const form = (
    <div>
      <Form id="CreateNewStoreForm">
        <div className="form-container form-container--image">
          <Form.Group>
            <Form.Label>Store Image:</Form.Label>
            <Controller
              name="storeImage"
              control={control}
              render={({field}) => {
                return (
                  <GFXImageEditable
                    src={field.value}
                    onFileChange={(file) => {
                      field.onChange(file);
                    }}
                    onError={(error) => console.error(error)}
                    addImageText="Add store image"
                    limits={{
                      width: 3000,
                      height: 3000,
                      weight: 50,
                    }}
                  />
                );
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Store Color:</Form.Label>
            <Controller
              name="storeColor"
              control={control}
              defaultValue={colorOptions[0]}
              render={({field}) => (
                <GFXColorSelect
                  options={colorOptions}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Form.Group>
        </div>
        <div className="form-container form-container--text">
          <Form.Group>
            <Form.Label>Store Name*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Add a store name"
              maxLength={31}
              {...register('storeName', {
                required: true,
                validate: (value: string) => value && value.length > 0,
              })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Store Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Add a description"
              {...register('storeDescription')}
              maxLength={31}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Advanced Options</Form.Label>
            <Form.Check
              type="switch"
              label="Import my store configuration"
              {...register('importStoreConfiguration')}
            />
          </Form.Group>
          <Form.Group>
            <Controller
              name="storeToImport"
              control={control}
              render={({field}) => (
                <GFXStoreSelect
                  options={shopOptions}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  isDisabled={!watch('importStoreConfiguration')}
                />
              )}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="switch"
              label="Import user management settings"
              {...register('importUserManagementSettings')}
              disabled={!watch('importStoreConfiguration')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="switch"
              label="Import printer settings"
              {...register('importPrinterSettings')}
              disabled={!watch('importStoreConfiguration')}
            />
          </Form.Group>
        </div>
      </Form>
    </div>
  );
  return (
    <CustomModal
      className="CreateNewStoreForm-modal"
      variant="grey"
      isSaveEnabled={formState.isDirty}
      onClickSave={handleSubmit(handleFormSubmit)}
      isProgressWarningEnabled={formState.isDirty}
      show={show}
      modalTile="Create a new store"
      sizeType="middle-page"
      onCloseModal={onCloseModal}
      progressWarningTitle="There are unsaved changes"
      progressWarningMessage="Would you like to continue creating a new store or close the window? Any unsaved changes will be lost."
      saveText="Create new store"
    >
      {form}
    </CustomModal>
  );
};

export default CreateNewStoreForm;
