import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import GFXTooltip from '../Tooltip/Tooltip';
import {IColor, ISize} from '@gfxco/contracts';
import Icon from '../Icons/Icon';
import ColorItem from './Items/ColorItem';
import SizeItem from './Items/SizeItem';
import './ConfigureProductInfo.scss';
import HasNeckLabel from '../HasNeckLabel';

interface RequiredProps {
  productName: string;
  skuDescription: string;
  colors: IColor[];
  sizes: ISize[];
  selectedColors: IColor[];
  selectedSizes: ISize[];
  hasLabel: boolean;
  onSelectColors: (colors: IColor[]) => void;
  onSelectSizes: (sizes: ISize[]) => void;
  onNextStep?: () => void;
}

const SIZES_WARNING = ['XS', '2XL', '3XL', '4XL', '5XL'];

interface OptionalProps {}

type ConfigureProductInfoProps = RequiredProps & OptionalProps;

const ConfigureProductInfo: React.FC<ConfigureProductInfoProps> = (props) => {
  const {
    skuDescription,
    colors,
    sizes,
    onSelectColors,
    onSelectSizes,
    hasLabel,
    onNextStep,
  } = props;
  const [disableCreateDesignButton, setDisableCreateDesignButton] =
    useState<boolean>(true);

  const showSizeWarning = props.selectedSizes.filter((z) =>
    SIZES_WARNING.includes(z.code),
  );

  const [showSizeTooltip, setShowSizeTooltip] = useState<boolean>(false);

  const handleSelectAllColors = () => {
    let newSelectedColors: IColor[];
    if (props.selectedColors.length === colors.length) {
      newSelectedColors = [];
    } else {
      newSelectedColors = colors;
    }

    onSelectColors(newSelectedColors);
  };

  const handleSelectAllSizes = () => {
    let newSelectedSizes: ISize[];
    if (props.selectedSizes.length === sizes.length) {
      newSelectedSizes = [];
    } else {
      newSelectedSizes = sizes;
    }

    onSelectSizes(newSelectedSizes);
  };

  const handleColorClick = (color: IColor) => {
    let newSelectedColors: IColor[];
    if (props.selectedColors) {
      const index = props.selectedColors.findIndex(
        (c) => c.name === color.name,
      );
      if (index > -1) {
        newSelectedColors = [
          ...props.selectedColors.slice(0, index),
          ...props.selectedColors.slice(index + 1),
        ];
      } else {
        newSelectedColors = [...props.selectedColors, color];
      }
    } else {
      newSelectedColors = [color];
    }

    onSelectColors(newSelectedColors);
  };

  useEffect(() => {
    if (props.selectedColors.length > 0 && props.selectedSizes.length > 0) {
      setDisableCreateDesignButton(false);
    } else {
      setDisableCreateDesignButton(true);
    }
  }, [props.selectedColors, props.selectedSizes]);

  const handleSizeClick = (size: ISize) => {
    let newSelectedSizes: ISize[];
    if (props.selectedSizes) {
      const index = props.selectedSizes.findIndex((s) => s.code === size.code);
      if (index > -1) {
        newSelectedSizes = [
          ...props.selectedSizes.slice(0, index),
          ...props.selectedSizes.slice(index + 1),
        ];
      } else {
        newSelectedSizes = [...props.selectedSizes, size];
      }
    } else {
      newSelectedSizes = [size];
    }

    onSelectSizes(newSelectedSizes);
  };

  const isInvisibleColor = (color: IColor) => {
    return color.hex === '#FFFFFF';
  };

  return (
    <div className="configure-product-design">
      <div className="design-name">
        <h2>{props.productName}</h2>
      </div>
      <div className="configure-product-design__content">
        <div id="ConfigureProductInfo">
          <div className="product-name">{skuDescription}</div>
          <div className="ColorItemsWrapper">
            <p className="text-title">Choose your design Colors:</p>
            <div className="colors">
              {colors &&
                colors.map((color) => (
                  <ColorItem
                    key={color.name}
                    color={color}
                    markBorder={isInvisibleColor(color)}
                    selected={
                      !!props.selectedColors?.find((c) => c.name === color.name)
                    }
                    onClick={handleColorClick}
                  />
                ))}
            </div>
            <div className="select-all">
              <input
                type="checkbox"
                checked={props.selectedColors.length === colors.length}
                onChange={handleSelectAllColors}
                id="select-all-colors"
              />
              <label htmlFor="select-all-colors">Select all colors</label>
            </div>
          </div>

          <div className="SizeItemsWrapper">
            <div className="title-box">
              <p className="text-title">Choose your product Sizes:</p>
              {!!showSizeWarning.length && (
                <Icon
                  name="exclamationCircle"
                  onClick={() => setShowSizeTooltip(true)}
                />
              )}
              {showSizeTooltip && (
                <GFXTooltip
                  onAccept={() => setShowSizeTooltip(false)}
                  width="21rem"
                  height="auto"
                  top="-9.5rem"
                  left="-2rem"
                >
                  <div className="size-warning-tooltip">
                    In some of the selected colors the{' '}
                    <b>sizes are not available in the customer&apos;s view</b>,
                    either way, they will be allowed to select other sizes
                  </div>
                </GFXTooltip>
              )}
            </div>
            <div className="sizes">
              {sizes &&
                sizes.map((size) => (
                  <SizeItem
                    key={size.id}
                    size={size}
                    selected={
                      !!props.selectedSizes?.find((s) => s.code === size.code)
                    }
                    onClick={handleSizeClick}
                  />
                ))}
            </div>
            <div className="select-all">
              <input
                type="checkbox"
                checked={props.selectedSizes.length === sizes.length}
                onChange={handleSelectAllSizes}
                id="select-all-sizes"
              />
              <label htmlFor="select-all-sizes">Select all sizes</label>
            </div>
          </div>
          <div className="NeckLabelWrapper">
            <div className="title-with-icon">
              <p className="text-title">Neck label:</p>
              <OverlayTrigger
                placement="top"
                trigger={['hover', 'focus']}
                overlay={
                  <Tooltip className="white-tooltip" id="tooltip-disabled">
                    You can personalize the neck label of your products in{' '}
                    <span className="emphasis-text">Products &gt; Labels</span>
                  </Tooltip>
                }
              >
                <div>
                  <Icon name="info" />
                </div>
              </OverlayTrigger>
            </div>
            <HasNeckLabel
              hasLabel={hasLabel}
              hasCustomText="Available on this product"
              hasNotCustomText="Not available on this product"
            />
          </div>
          <OverlayTrigger
            placement="bottom"
            trigger={disableCreateDesignButton ? ['hover', 'focus'] : []}
            overlay={
              <Tooltip id="tooltip-disabled">
                Please ensure you have chosen both colors and sizes before
                proceeding.
              </Tooltip>
            }
          >
            <div className="customize-button-container">
              <Icon name="createYourDesignTemplate" />
              <Button
                color="primary"
                className="customize-button"
                onClick={onNextStep}
                disabled={disableCreateDesignButton}
              >
                Create your design template
              </Button>
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

export default ConfigureProductInfo;
