import React from 'react';
import AllImages from './AllImages';

interface IImagePageProps {
  handleOnDeleteImage: (id: number) => void;
}

const ImagePage: React.FC<IImagePageProps> = (props) => {
  return <AllImages handleOnDeleteImage={props.handleOnDeleteImage} />;
};

export default ImagePage;
