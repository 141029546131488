import React, {useState, useEffect, useRef} from 'react';
import SectionHeader from '../../components/SectionHeader';
import UsersSection from './UsersSection';
import {Button, Form, InputGroup} from 'react-bootstrap';
import {UserManagementData} from '@gfxco/contracts';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {
  getShopUsersAsync,
  selectShopUsers,
} from '../../features/shops/selectedShop';
import {
  deleteShopUserAsync,
  patchUserPermissionsAsync,
  inviteUserToShopAsync,
} from '../../features/user';
import GFXSimpleModal from '../../components/Modal/SimpleModal';

import AddUserIcon from '../../assets/icons/add-user-icon';

import './UserManagementPage.scss';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const UserManagementPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const shopUsers = useAppSelector(selectShopUsers);

  const emailRef = useRef<HTMLInputElement>(null);
  const [initialUsers, setInitialUsers] = useState<UserManagementData[]>([]);
  const [users, setUsers] = useState<UserManagementData[]>(shopUsers);
  const [userWasUpdated, setUserWasUpdated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    if (userWasUpdated) {
      dispatch(getShopUsersAsync());
      setUserWasUpdated(false);
    }
  }, [userWasUpdated]);

  useEffect(() => {
    setUsers(shopUsers);
    setInitialUsers(shopUsers);
  }, [shopUsers]);

  const handleOnInviteUser = () => {
    setShowModal(!showModal);
  };

  const onUpdateUsers = (user: UserManagementData) => {
    setUsers((prev) => {
      const index = prev.findIndex((u) => u.id === user.id);
      if (index === -1) return prev;
      const updated = JSON.parse(JSON.stringify(prev));
      updated[index] = user;
      return updated;
    });
  };

  const onDeleteUser = (user: UserManagementData) => {
    dispatch(deleteShopUserAsync(user.username)).then(() => {
      setUserWasUpdated(true);
    });
  };

  const onCleanPermissions = (user: UserManagementData) => {
    const userWithoutPermissions = {
      ...user,
      permissions: {
        products: false,
        orders: false,
        samples: false,
        myStore: false,
        printers: false,
        settings: false,
      },
    };

    dispatch(patchUserPermissionsAsync({users: [userWithoutPermissions]})).then(
      () => {
        setUserWasUpdated(true);
      },
    );
  };

  const onHandleSaveButton = () => {
    dispatch(patchUserPermissionsAsync({users: regulars})).then(() => {
      setUserWasUpdated(true);
    });
  };

  const disableSaveButton = () => {
    return JSON.stringify(users) === JSON.stringify(initialUsers);
  };

  const handleInviteByEmail = async () => {
    const email = emailRef.current?.value;

    if (!email || !emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
    } else {
      try {
        await dispatch(inviteUserToShopAsync(email)).unwrap();
        setEmailError('');
        setUserWasUpdated(true);
        setShowModal(false);
      } catch (error) {
        setEmailError((error as Error).name);
      }
    }
  };
  const owners = users.filter((u) => u.role === 'owner');
  const regulars = users.filter((u) => u.role === 'regular');

  return (
    <div id="UserManagementPage">
      <SectionHeader
        title="User Management"
        subtitle="View and manage all of your account users."
        rightContent={
          <Button
            color="primary"
            className="btn-add-new-user"
            onClick={handleOnInviteUser}
          >
            <AddUserIcon fill="#FFF" className="addUserIcon" /> &nbsp; Invite a
            new user
          </Button>
        }
      />

      <UsersSection
        userType="owner"
        users={owners}
        title="Owner Users"
        subtitle="Can add/remove users and manage organization-level settings."
      />

      <UsersSection
        userType="regular"
        users={regulars}
        title="Regular Users"
        subtitle="Can navigate any section that you give permission."
        disableSaveButton={disableSaveButton()}
        onUpdateUser={onUpdateUsers}
        onDeleteUser={onDeleteUser}
        onCleanPermissions={onCleanPermissions}
        onHandleSaveButton={onHandleSaveButton}
        onInviteNewUser={handleOnInviteUser}
      />

      <GFXSimpleModal
        modalTile="Add new User"
        show={showModal}
        onCloseModal={() => setShowModal(false)}
        className="addNewUserModal"
      >
        <div>
          <p className="inputLabel">Create an invitation via email.</p>
          <InputGroup>
            <Form.Control
              type="email"
              placeholder="Invite via Email"
              ref={emailRef}
              isInvalid={emailError !== ''}
            />
            {emailError && (
              <Form.Control.Feedback type="invalid">{` Oops, looks like something went wrong with that email, please try again`}</Form.Control.Feedback>
            )}
          </InputGroup>

          <p className="inviteUserInfo">
            The person you invite will need to confirm their address before
            joining your team.
          </p>
          <br />
          <div>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={handleInviteByEmail}
            >
              Send invitation
            </button>
          </div>
        </div>
      </GFXSimpleModal>
    </div>
  );
};

export default UserManagementPage;
