import React from 'react';

import './CollectionsSelected.scss';
import Icon from '../../../components/Icons/Icon';

import ImagesByCollection from '../ImagesByCollection';
import {ChevronLeft, ChevronDown, XLg, ChevronUp} from 'react-bootstrap-icons';
import {CollectionSticker} from '../types';

export interface RequiredProps {
  collections?: CollectionSticker[];
  onDelete: (id: number) => void;
  onSort: (index: number, newIndex: number) => void;
}
export interface OptionalProps {}

type CollectionsSelectedProps = RequiredProps & OptionalProps;

const CollectionsSelected: React.FC<CollectionsSelectedProps> = ({
  collections = [],
  onDelete,
  onSort,
}) => {
  const [collectionOpenId, setCollectionOpenId] = React.useState<number>();

  const collectionsSorted = [...collections].sort(
    (a, b) => a.sortOrder - b.sortOrder,
  );

  const handleSelectCollection = (id: number) => {
    if (id === collectionOpenId) return setCollectionOpenId(undefined);

    setCollectionOpenId(id);
  };

  const EmptyStickersSelected = () => (
    <div className="collections-selected-empty">
      <Icon name="stickerPlus" />
      <span className="collections-empty-message">
        Your selected collections will be displayed here.
      </span>
    </div>
  );

  return (
    <div id="CollectionsSelected">
      {!collectionsSorted.length && <EmptyStickersSelected />}
      <div className="selected-container">
        {!!collectionsSorted.length &&
          collectionsSorted.map((c, index) => (
            <div key={c.name} className="collection-item-box">
              <div className="collection-item-selected">
                <div className="selected-item">
                  {c.parentName && (
                    <>
                      <Icon name="folder" fillColor={{default: '#4573bb'}} />
                      {c.parentName && <span>{c.parentName}</span>}
                      {c.parentName && (
                        <ChevronLeft className="breadcrumb-separator" />
                      )}
                    </>
                  )}
                  <Icon name="folder" fillColor={{default: '#4573bb'}} />
                  <span>{c.name}</span>
                  <div className="selected-actions">
                    <div
                      className="action-item"
                      onClick={() => handleSelectCollection(c.id)}
                    >
                      <ChevronDown />
                    </div>
                    <div className="action-item" onClick={() => onDelete(c.id)}>
                      <XLg />
                    </div>
                  </div>
                </div>
                {collectionOpenId === c.id && (
                  <ImagesByCollection collectionId={c.id} />
                )}
              </div>
              <div className="sort-box-icon">
                <button
                  className="box-icon"
                  onClick={() => onSort(index, index + 1)}
                  disabled={index === collectionsSorted.length - 1}
                >
                  <ChevronDown />
                </button>
                <button
                  className="box-icon"
                  onClick={() => onSort(index, index - 1)}
                  disabled={c.sortOrder === 0 || index === 0}
                >
                  <ChevronUp />
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CollectionsSelected;
