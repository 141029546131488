import {
  IUpdateShopParams,
  IUpdateShopRequest,
  GetShopsResponse,
  CreateShopRequest,
} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getShops, updateShop, createShop} from '../../api';
import {RootState} from '../../app/store';

export interface ShopState {
  value?: GetShopsResponse[];
  status: 'idle' | 'loading' | 'failed' | 'loaded';
}

const initialState: ShopState = {
  value: [],
  status: 'idle',
};

export const getShopsAsync = createAsyncThunk('shops/fetch', async () => {
  const response = await getShops();
  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const updateShopAsync = createAsyncThunk(
  'shop/update',
  async (parameters: IUpdateShopRequest & IUpdateShopParams) => {
    const response = await updateShop(parameters);
    return response;
  },
);

export const createShopAsync = createAsyncThunk(
  'shop/create',
  async (parameters: CreateShopRequest) => {
    const response = await createShop(parameters);
    return response;
  },
);

export const loadShop = createSlice({
  name: 'shops',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getShopsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getShopsAsync.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.value = action.payload;
      })
      .addCase(getShopsAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateShopAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateShopAsync.fulfilled, (state, action) => {
        state.status = 'loaded';
        if (action.payload && state.value) {
          // update the updated shop in the state
          const updatedShop = action.payload[0];
          const shopIndex = state.value?.findIndex(
            (shop) => shop.id === updatedShop.id,
          );
          if (shopIndex !== -1) {
            state.value[shopIndex] = updatedShop;
          }
        }
      });
  },
});

export const selectShops = (state: RootState) => state.shops.value;
export const selectShopsFetchLoading = (state: RootState) => state.shops.status;

export default loadShop.reducer;
