function getArrayDiff<T>(array1: T[], array2: T[]) {
  if (!array1 || !array2) {
    return;
  }

  return array1.filter((object1: any) => {
    return !array2.some((object2: any) => {
      return object1.id === object2.id;
    });
  });
}

export default getArrayDiff;
