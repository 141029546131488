import React from 'react';
import {useParams} from 'react-router-dom';
import {
  IColor,
  ILegacyLinkedSlotsResult,
  GetTemplateByIdRequest,
  ITemplateImage,
  ILinkedSlots,
  ILinkedSlotAction,
} from '@gfxco/contracts';
import GFXModals from '../../Modal';
import Alert from 'react-bootstrap/Alert';
import {useAppSelector} from '../../../app/hooks';
import {selectShop} from '../../../features/shops/selectedShop';
import SelectorBox from './SelectorBox';
import Loading from '../../Loading';
import ProductModalHeader from '../../ProductModalHeader';
import SlotsApi from '../../../api/slots';
import './LinkedSlotWizard.scss';

export interface RequiredProps {
  open: boolean;
  onClose: () => void;
  onError: (msg?: string) => void;
  colors: IColor[];
  frontSlots: any[];
  backSlots: any[];
}
export interface OptionalProps {
  linkedSlot?: ILinkedSlots;
  isEdit?: boolean;
  onReload?: () => void;
}

type ExceptionsPageProps = RequiredProps & OptionalProps;

const ACTIONS_REQUEST = {
  update: SlotsApi.updateLinkedSlot,
  create: SlotsApi.createLinkedSlot,
};

const TITLE = 'Select your IF Statement';
const SUBTITLE = ' ';

interface Option {
  label?: string;
  value?: string | number;
  type?: string;
  side: 'front' | 'back';
  disabled: boolean;
  selected: boolean;
  defaultImage: string;
}

export const ExceptionModal: React.FC<ExceptionsPageProps> = ({
  colors,
  open,
  onClose,
  linkedSlot,
  frontSlots,
  backSlots,
  onReload,
  onError,
}) => {
  const shopSelected = useAppSelector(selectShop);
  const params = useParams();
  const shopId = shopSelected?.id;
  const {id} = params;
  const [newLinkedSlot, setNewLinkedSlot] = React.useState<
    ILinkedSlots | undefined
  >(linkedSlot);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [slotImages, setSlotImages] = React.useState<ITemplateImage>();
  const [slotOptions, setSlotOptions] = React.useState<Option[]>();
  const [actionsToRemove, setActionsToRemove] = React.useState<
    ILinkedSlotAction[]
  >([]);

  React.useEffect(() => {
    if (!isLoading) {
      getSlotImages();
    }
  }, [id]);

  React.useEffect(() => {
    setNewLinkedSlot(linkedSlot);
  }, [linkedSlot]);

  const getSlotImages = async () => {
    try {
      if (id && !isNaN(parseInt(id))) {
        setIsLoading(true);
        const params: GetTemplateByIdRequest = {
          templateId: +id,
        };
        const slotImages = await SlotsApi.getSlotsImages(params);
        if (slotImages) {
          setSlotImages(slotImages);
          const options = getSlotOptions(slotImages);
          setSlotOptions(options);
        }
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSlotInfo = (
    slots: any[],
    images: ITemplateImage,
    slotId?: number | string,
  ) => {
    let label = '';
    let type = '';
    let defaultImage = '';
    let disabled = false;
    if (!slotId) return {label, type, defaultImage, disabled};
    const index = slots.findIndex((slot) => slot.slotId === slotId);

    if (index < 0) return {label, type, defaultImage, disabled};
    const slot = slots[index];
    if (!slot || !slot.objects) return {label, type, defaultImage, disabled};

    label = slot.alias || `Slot name ${index + 1}`;
    type = slot.objects[0].type === 'textbox' ? 'text' : slot.objects[0].type;
    defaultImage = slot.objects[0].src || '';
    disabled = type === 'image' && !images[slotId];

    return {label, type, defaultImage, disabled};
  };

  const getSlotOptions = (images: ITemplateImage) => {
    const frontSlotOptions = frontSlots.map((slot) => ({
      value: slot.slotId,
      selected: false,
      side: 'front' as 'front' | 'back',
      ...getSlotInfo(frontSlots, images, slot.slotId),
    }));

    const backSlotOptions = backSlots.map((slot) => ({
      value: slot.slotId,
      selected: false,
      side: 'back' as 'front' | 'back',
      ...getSlotInfo(backSlots, images, slot.slotId),
    }));

    return [...frontSlotOptions, ...backSlotOptions];
  };

  const handleCloseModal = () => {
    setNewLinkedSlot(undefined);
    setActionsToRemove([]);
    onClose();
  };

  const handleSaveDraftRule = (ruleDraft: ILinkedSlots) => {
    setNewLinkedSlot(ruleDraft);
  };

  const removeLinkedSlotsActions = async (
    linkedSlotId: number,
    actions: ILinkedSlotAction[],
  ) => {
    if (!linkedSlotId) return;

    const actionsIds = actions.map(({id}) => id!);
    await SlotsApi.deleteBulkLinkedSlotActions({linkedSlotId, actionsIds});
  };

  const handleSaveClick = async () => {
    if (!newLinkedSlot) return;
    try {
      const body: ILinkedSlots = {
        templateId: +id!,
        comparison: newLinkedSlot.comparison,
        color: newLinkedSlot.color,
        actions: newLinkedSlot.actions,
        slotId: newLinkedSlot.slotId,
        imageId: newLinkedSlot.imageId,
        text: newLinkedSlot.text,
      };
      if (newLinkedSlot.id && actionsToRemove?.length) {
        await removeLinkedSlotsActions(newLinkedSlot.id, actionsToRemove);
      }
      if (newLinkedSlot.id) {
        const actions = newLinkedSlot.actions.map((i) => ({
          ...i,
          templateId: +id!,
        }));

        const updateBody: ILegacyLinkedSlotsResult = {
          ...body,
          actions,
          id: newLinkedSlot.id!,
        };
        await ACTIONS_REQUEST.update(updateBody);
      } else {
        await ACTIONS_REQUEST.create(body, shopId!);
      }

      handleCloseModal();
      if (onReload) onReload();
    } catch (error) {
      handleCloseModal();
      onError((error as Error)?.name);
    }
  };

  const handleRemoveAction = (action: ILinkedSlotAction) => {
    setActionsToRemove([...actionsToRemove, action]);
  };

  const isAllSet = () =>
    !!newLinkedSlot?.comparison && !!newLinkedSlot.actions.length;

  return (
    <GFXModals.SimpleModal
      className="modal-middle-page"
      isSaveEnabled={isAllSet()}
      modalTile={`Manage Linked Slots`}
      onCloseModal={handleCloseModal}
      show={open}
      onClickSave={handleSaveClick}
    >
      <div id="LinkedSlotWizard">
        {isLoading && <Loading />}
        {isError && (
          <Alert className="alert" variant="danger">
            Error loading slot images...
          </Alert>
        )}
        {!isLoading && (
          <div>
            <ProductModalHeader
              title={TITLE}
              subtitle={SUBTITLE}
              showInput={false}
            />
            <SelectorBox
              colors={colors}
              ruleDraft={newLinkedSlot}
              isFrontDisabled={!frontSlots.length}
              isBackDisabled={!backSlots.length}
              slotOptions={slotOptions}
              slotImages={slotImages}
              onSaveDraft={handleSaveDraftRule}
              onDeleteAction={handleRemoveAction}
              isEditing={linkedSlot && !!Object.keys(linkedSlot).length}
            />
          </div>
        )}
      </div>
    </GFXModals.SimpleModal>
  );
};

export default ExceptionModal;
