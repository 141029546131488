import React from 'react';
import {useNavigate} from 'react-router-dom';

import './CookieBanner.scss';

interface RequiredProps {
  accepted: boolean;
  onAccept: () => void;
}

interface OptionalProps {}

type CookieBannerProps = RequiredProps & OptionalProps;

const CookieBanner: React.FC<CookieBannerProps> = ({accepted, onAccept}) => {
  const navigate = useNavigate();

  const handleAnchorClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const href = event.currentTarget.getAttribute('href');
    if (href) {
      navigate(href);
    }
  };

  if (accepted) return <></>;

  return (
    <div id="CookieBanner">
      <div className="cookie-consent-text" style={{margin: '15px'}}>
        Gfx use cookies to provide necessary site functionality and improve your
        experience. By using our website, you agree to our{' '}
        <a href="/privacy-policy" onClick={handleAnchorClick}>
          privacy policy
        </a>{' '}
        and our{' '}
        <a href="/cookies" onClick={handleAnchorClick}>
          cookie policy
        </a>
        .
      </div>

      <div>
        <button
          className="cookie-accept-button"
          id="rcc-confirm-button"
          aria-label="Accept cookies"
          style={{
            border: '0px',
            color: 'white',
            cursor: 'pointer',
            padding: '5px 10px',
            margin: '0 15px 15px 15px',
            width: 'calc(100% - 30px)',
          }}
          onClick={onAccept}
        >
          ACCEPT
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
