import {ICollectionResponse, IImage, IImagesResponse} from '@gfxco/contracts';
import GFXCustomPagination from '../CustomPagination/GFXCustomPagination';
import Icon from '../Icons/Icon';
import './ShopImageSelector.scss';
import ImageAPI from '../../api/images';
import React from 'react';
import SimpleDropdown from '../GFXDropdowns/GFXSimpleDropdown';
import {selectShop} from '../../features/shops/selectedShop';
import {useAppSelector} from '../../app/hooks';
import usePagination from '../../hooks/usePagination';
import ShopImageSelectorItem from './ShopImageSelectorItem';
import {getCollectionsEntities} from '../../api';
import {Link} from 'react-router-dom';
import ShopCollectionsSelector from '../ShopCollectionsSelector/ShopCollectionsSelector';
import classNames from 'classnames';
import Loading from '../Loading';

type ShopImageSelectorProps = {
  onSelectImage: (image: IImage) => Promise<void>;
  onApplyImage?: () => void;
};

const ShopImageSelector: React.FC<ShopImageSelectorProps> = ({
  onSelectImage,
}) => {
  const shopSelected = useAppSelector(selectShop);
  const [images, setImages] = React.useState<IImage[]>([]);
  const [collections, setCollections] = React.useState<ICollectionResponse[]>(
    [],
  );
  const [currentSelectedCollection, setCurrentSelectedCollection] =
    React.useState<number | null>(null);
  const [imageSelected, setImageSelected] = React.useState<IImage | null>(null);
  const [sortBy, setSortBy] = React.useState<string>('Images');
  const [totalImages, setTotalImages] = React.useState<number>(0);
  const [isRequestLoading, setIsRequestLoading] =
    React.useState<boolean>(false);
  const [totalCollections, setTotalCollections] = React.useState<number>(0);
  const [isSelectingImage, setIsSelectingImage] =
    React.useState<boolean>(false);
  const [inputSearch, setInputSearch] = React.useState<string>('');
  const [{offset, currentPage, limit}, handlePagination] = usePagination({
    limit: 16,
  });

  const pagination = {
    limit,
    total: sortBy === 'Images' ? totalImages : totalCollections,
    totalByPage: sortBy === 'Images' ? images.length : collections.length,
    currentPage,
    handlePagination,
  };

  React.useEffect(() => {
    if (isRequestLoading) {
      return;
    }

    if (sortBy === 'Images') {
      getImages();
      return;
    }

    if (sortBy === 'Collections') {
      getCollections();
    }
  }, [currentPage, sortBy, inputSearch]);

  const onNameChange = async (
    evt:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const target = evt.target as HTMLInputElement;
    setInputSearch(() => target.value);
    handlePagination('first');
  };

  const handleOnSelectImage = async (image: IImage) => {
    if (image.id === imageSelected?.id) {
      setImageSelected(null);
      return;
    }
    setImageSelected(image);
  };

  const onApplyImage = async () => {
    if (isSelectingImage || !imageSelected) {
      return;
    }
    setIsSelectingImage(true);
    onSelectImage(imageSelected).finally(() => {
      setIsSelectingImage(false);
    });
  };

  const getImages = async () => {
    if (isRequestLoading) return;
    setIsRequestLoading(true);
    const collections = [];

    if (currentSelectedCollection) {
      collections.push(currentSelectedCollection);
    }

    const images = await ImageAPI.getImages({
      shopId: shopSelected?.id,
      limit,
      offset,
      name: inputSearch || undefined,
      hasCollection: true,
      collections,
    });

    setIsRequestLoading(false);
    if (!images) {
      return;
    }

    setImages(images.results || []);
    setTotalImages(images.total);

    return images as IImagesResponse;
  };

  const getCollections = async () => {
    if (isRequestLoading) return;
    try {
      setIsRequestLoading(true);
      const data = await getCollectionsEntities({
        shopId: shopSelected?.id,
        name: inputSearch || undefined,
        limit,
        offset,
      });

      if (!data) return;

      setCollections(data.results);
      setTotalCollections(data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRequestLoading(false);
    }
  };

  const onCollectionClick = async (collection: ICollectionResponse) => {
    if (currentSelectedCollection === collection.id) {
      resetStates();
      return;
    }
    setCurrentSelectedCollection(collection.id);
  };

  const onReloadButtonClick = () => {
    handlePagination('first');
    setImageSelected(null);
    if (sortBy === 'Images') {
      getImages();
    }
    if (sortBy === 'Collections') {
      getCollections();
    }
  };

  const resetStates = () => {
    setImages([]);
    setCurrentSelectedCollection(null);
    setImageSelected(null);
    setInputSearch('');
  };

  const ImageItems = images.map((image) => (
    <ShopImageSelectorItem
      onClick={handleOnSelectImage}
      key={image.id}
      isSelected={imageSelected?.id === image.id}
      image={image}
    />
  ));

  const CollectionItems = (
    <ShopCollectionsSelector
      getCollections={getCollections}
      imageIdSelected={imageSelected?.id || null}
      onSelectImage={handleOnSelectImage}
      shopId={shopSelected?.id!}
      collections={collections}
      currentSelectedCollection={currentSelectedCollection}
      onCollectionClick={onCollectionClick}
    />
  );

  const AddNewImageInvitation = () => {
    return (
      <div className="add-image-invitation">
        <p>Personalize your design</p>
        <Link target="_blank" to="/designs/images">
          + Add new images
        </Link>
      </div>
    );
  };

  const isCollectionOrImagesEmpty =
    sortBy === 'Collections' ? collections.length === 0 : images.length === 0;

  const showEmptyCollectionImages =
    isCollectionOrImagesEmpty && !isRequestLoading && !inputSearch;
  const showNotFoundSearch = isCollectionOrImagesEmpty && inputSearch !== '';

  const contentClasses = classNames('shop-image-selector__content', {
    'collection-content': sortBy === 'Collections',
    'empty-content':
      showEmptyCollectionImages || showNotFoundSearch || isRequestLoading,
  });

  return (
    <div
      className="shop-image-selector"
      onClick={(evt) => evt.stopPropagation()}
    >
      <div className="shop-image-selector__header">
        <div className="shop-image-selector__header--reload-button">
          <Icon name="reload" onClick={onReloadButtonClick} />
        </div>
        <div className="shop-image-selector__header--search-bar">
          <Icon name="magnifierIcon" />
          <input
            type="text"
            value={inputSearch}
            onChange={(e) => onNameChange(e)}
            onKeyDown={(e) => onNameChange(e)}
            placeholder={`Search for ${sortBy}`}
          />
        </div>
        <div className="shop-image-selector__header--sortBy">
          <SimpleDropdown
            label="Sort by"
            items={['Collections', 'Images']}
            onSelect={(v: string) => {
              if (v !== sortBy) {
                resetStates();
                handlePagination('first');
                setSortBy(v);
              }
            }}
            selected={sortBy}
          />
        </div>
      </div>
      <div className={contentClasses}>
        {isRequestLoading && sortBy === 'Images' && (
          <div className="shop-image-selector__content__loading">
            <Loading spinnerOnly />
          </div>
        )}
        {sortBy === 'Collections' && CollectionItems}
        {sortBy === 'Images' && !isRequestLoading && ImageItems}

        {showEmptyCollectionImages && (
          <div className="shop-image-selector__content__no-images">
            <div className="shop-image-selector__content__no-images--icon">
              <Icon name="notFound" />
            </div>
            <div className="shop-image-selector__content__no-images--text">
              Looks like you {"haven't"} created any image collection yet.
            </div>
            <div className="shop-image-selector__content__no-images--buttons">
              <Link target="_blank" to="/designs/images">
                Create Collection
              </Link>
            </div>
          </div>
        )}

        {showNotFoundSearch && (
          <div className="shop-image-selector__content__no-images">
            <div className="shop-image-selector__content__no-images--icon">
              <Icon name="notFound" />
            </div>

            <div className="shop-image-selector__content__no-images--title">
              Item not found
            </div>

            <div className="shop-image-selector__content__no-images--text">
              Looks like the items youre looking for, does not exist. Try again.
            </div>
          </div>
        )}
      </div>
      <div className="shop-image-selector__footer">
        <div className="shop-image-selector__footer--paginator">
          {sortBy === 'Collections' && <AddNewImageInvitation />}
          {sortBy === 'Images' && (
            <GFXCustomPagination maxPages={1} {...pagination} skipInfo={true} />
          )}
        </div>
        <div className="shop-image-selector__footer--select-image-button">
          <button
            disabled={!imageSelected}
            className="button button--primary"
            onClick={onApplyImage}
          >
            Select Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShopImageSelector;
