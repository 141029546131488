import React from 'react';
import {GFXEditor} from '../../components/GFXEditor';
import {GenerateArtsForm} from '../../components/GenerateArtsForm';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import debounce from 'lodash.debounce';
import {PortalDesign} from '@gfxco/contracts';
import './SamplePortalPage.scss';
import {PrintForm} from '../../components/PrintForm';

export const SamplePortalPage: React.FC = () => {
  const [templateId, setTemplateId] = React.useState('');

  const [printer, setPrinter] = React.useState('');

  const [designs, setDesigns] = React.useState<PortalDesign[]>([]);

  const handleGenerateArts = async (
    frontProof: string,
    backProof: string,
    frontArt: string,
    backArt: string,
    designNumber: string,
    garmentType: string,
    neckLabel: string,
    SKU: string,
    editorUrl: string,
  ) => {
    setDesigns([
      ...designs,
      {
        printer,
        designNumber,
        garmentType,
        editorUrl,
        SKU,
        neckLabel,
        frontProof,
        backProof,
        frontArt,
        backArt,
        templateId,
      },
    ]);
  };

  return (
    <>
      <Form.Group className="template-id-group">
        <Form.Label htmlFor="templateId">Template ID: * (5592)</Form.Label>
        <Form.Control
          type="text"
          id="templateId"
          name="templateId"
          aria-label="templateId"
          placeholder="5592"
          required
          onChange={debounce((event: any) => {
            setTemplateId(event.target.value);
          }, 500)}
        />
      </Form.Group>
      <div id="SamplePortalPage">
        <GFXEditor templateId={templateId} />
        <div className="forms-container">
          <Form.Group>
            <Form.Label htmlFor="printer">Printer *</Form.Label>
            <Form.Select
              id="printer"
              name="printer"
              defaultValue=""
              aria-label="printer"
              disabled={designs.length > 0}
              onChange={(event) => setPrinter(event.target.value)}
              required
            >
              <option value="" disabled>
                Select...
              </option>
              <option value="printful">Printful</option>
              <option value="dubow">Dubow</option>
            </Form.Select>
          </Form.Group>
          <Tabs defaultActiveKey="generateArt" id="sample-portal-tabs">
            <Tab eventKey="generateArt" title="1. Generate Art">
              <GenerateArtsForm
                onGenerateArts={handleGenerateArts}
                templateId={templateId}
                printer={printer}
              />
            </Tab>
            <Tab eventKey="Review" title="2. Review designs">
              <Accordion
                className="review-designs-accordion"
                defaultActiveKey="0"
              >
                {designs.map((design, index) => {
                  const {
                    designNumber,
                    frontProof,
                    backProof,
                    frontArt,
                    backArt,
                    templateId,
                  } = design;
                  return (
                    <Accordion.Item eventKey={`${index}`} key={designNumber}>
                      <Accordion.Header>
                        <CloseButton
                          onClick={(event) => {
                            event.stopPropagation();
                            const filteredDesigns = designs.filter(
                              (design) => design.designNumber !== designNumber,
                            );
                            setDesigns(filteredDesigns);
                          }}
                          style={{marginBottom: '3px', marginRight: '10px'}}
                        />
                        <span>
                          TemplateId: {templateId} - Design: {designNumber}
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ListGroup className="arts-result-container">
                          <ListGroup.Item>
                            Design Number: {designNumber}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            Front Proof:{' '}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={frontProof}
                            >
                              {frontProof}
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            Back Proof:{' '}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={backProof}
                            >
                              {backProof}
                            </a>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <>
                              Front Art:{' '}
                              {frontArt.includes('http') ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={frontArt}
                                >
                                  {frontArt}
                                </a>
                              ) : (
                                <span>{frontArt}</span>
                              )}
                            </>
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <>
                              Back Art:{' '}
                              {backArt.includes('http') ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={backArt}
                                >
                                  {backArt}
                                </a>
                              ) : (
                                <span>{backArt}</span>
                              )}
                            </>
                          </ListGroup.Item>
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Tab>
            <Tab eventKey="Print" title="3. Print">
              <PrintForm designs={designs} printer={printer} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};
