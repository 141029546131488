import React from 'react';
import {useParams} from 'react-router-dom';

import {useAppSelector, useAppDispatch} from '../../app/hooks';

import Button from '../../components/Button';
import Icon from '../../components/Icons/Icon';
import StickersCollections from './StickersCollections';
import StickersWizard from './StickersWizard';

import {
  getStickerImagesAsync,
  selectStickerImages,
  selectStickerImagesFetchLoading,
  selectTotalStickerImages,
  resetStickerImages,
} from '../../features/stickers/loadStickersImages';

import {resetStickerCollections} from '../../features/stickers/loadStickersCollections';

import {ELoadingStates} from '@gfxco/contracts';

import './StickersPage.scss';
import StickersImages from './StickersImages';
import Loading from '../../components/Loading';

type StickersPageProps = {
  type?: string;
};

const StickersPage: React.FC<StickersPageProps> = ({type}) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const {id: templateId} = params;
  const stickerImages = useAppSelector(selectStickerImages);
  const stickerImagesFetchStatus = useAppSelector(
    selectStickerImagesFetchLoading,
  );

  const stickerImagesTotal = useAppSelector(selectTotalStickerImages);

  const [showModal, setShowModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (stickerImagesFetchStatus !== ELoadingStates.IDLE || !templateId) return;

    dispatch(
      getStickerImagesAsync({
        templateId: +templateId,
      }),
    );
  }, [stickerImagesFetchStatus, templateId, dispatch]);

  React.useEffect(() => {
    handleReset();
  }, [templateId]);

  const handleReset = () => {
    dispatch(resetStickerImages());
    dispatch(resetStickerCollections());
  };

  const EmptyStickersBox = () => (
    <div className="sticker-empty">
      <div className="sticker-empty-box">
        <Icon name="stickerPlus" />
        <span className="empty-title">
          Currently, there isn&apos;t a sticker collection added to this
          template.
        </span>
        <Button className="btn add-sticker" onClick={() => setShowModal(true)}>
          + Add Sticker
        </Button>
      </div>
    </div>
  );

  return (
    <div id="StickersPage">
      <div className="stickers-tab-body">
        {stickerImagesFetchStatus === ELoadingStates.LOADING && (
          <Loading spinnerOnly />
        )}
        {stickerImagesFetchStatus === ELoadingStates.LOADED &&
          !stickerImagesTotal && <EmptyStickersBox />}

        {stickerImagesFetchStatus === ELoadingStates.LOADED &&
          (!!stickerImagesTotal || !!stickerImages.length) && (
            <div className="sticker-body-list">
              <div className="sticker-body-menu">
                <StickersCollections />
                <div className="vertical-divider"></div>
              </div>
              <div className="sticker-body-preview">
                <div className="sticker-preview-header">
                  <span className="header-preview-title">
                    Stickers preview:
                  </span>
                </div>
                <StickersImages />
              </div>
            </div>
          )}
      </div>
      <StickersWizard
        open={showModal}
        onClose={() => {
          setShowModal(false);
          handleReset();
        }}
        type={type}
      />
    </div>
  );
};

export default StickersPage;
