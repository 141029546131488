import {loadClient, handleError} from './api';

interface AddLabelParams {
  imageUrl: string;
  shopId: number;
  printer: string;
}

const getLabel = async (shopId: number) => {
  try {
    const client = await loadClient();
    const response = await client.get(`/labels?shopId=${shopId}`);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const addLabel = async ({imageUrl, shopId, printer}: AddLabelParams) => {
  try {
    const client = await loadClient();
    const bodyParams = {
      imageUrl,
      shopId,
      printer,
    };
    const response = await client.post(`/labels`, bodyParams);

    return response.data.id;
  } catch (error) {
    return handleError(error);
  }
};

const updateLabel = async (
  id: number,
  {imageUrl, shopId, printer}: AddLabelParams,
) => {
  try {
    const client = await loadClient();
    const bodyParams = {
      imageUrl,
      shopId,
      printer,
    };
    const response = await client.put(`/labels/${id}`, bodyParams);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const deleteLabel = async (id: number) => {
  try {
    const client = await loadClient();
    const response = await client.delete(`/labels/${id}`);

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export default {
  getLabel,
  addLabel,
  updateLabel,
  deleteLabel,
};
