import React from 'react';
import Modal from '../../components/Modal';
import Button from 'react-bootstrap/Button';
import './ModalSamplePage.scss';

export default function ModalSamplePage() {
  const [show, setShow] = React.useState(true);

  return (
    <div id="ForgotPasswordPage">
      <Modal.StepsModal
        show={show}
        isSaveEnabled={false}
        isProgressWarningEnabled={true}
        steps={[
          {
            name: 'Step 1',
            component: (
              <>
                <h2>Modal Content 2</h2>
              </>
            ),
          },
          {
            name: 'Step 2',
            component: (
              <>
                <h2>Modal Content 2</h2>
              </>
            ),
          },
        ]}
        selectedStepNumber={1}
        onCloseModal={() => setShow(false)}
      ></Modal.StepsModal>
      <Button onClick={() => setShow(true)}>Show modal</Button>
    </div>
  );
}
