import React from 'react';
import {Form} from 'react-bootstrap';
import './SlotProperties.scss';

type FormValues = {
  alias: string;
  Removable?: boolean;
  Movable?: boolean;
  Swappable?: boolean;
  EnableSearch?: boolean;
  CycleOnly?: boolean;
  Customizable?: boolean;
  Negative?: boolean;
  CustomizationCheck?: boolean;
  RemoveBackground?: boolean;
  AutoCropFace?: boolean;
  FitContainer?: boolean;
  ScalableImage?: boolean;
  GreyScale?: boolean;
  ModerationStage?: string;
};

type SettingsForImageSlotsRequiredProps = {
  formValues: FormValues;
  handleOptionsChange: (setting: string, value: boolean) => void;
  slotType: string;
};

type SettingsForImageSlotsOptionalProps = {};

type SettingsForImageSlotsProps = SettingsForImageSlotsRequiredProps &
  Partial<SettingsForImageSlotsOptionalProps>;

const ImageSlotSettings = [
  'Negative',
  'Removable',
  'Movable',
  'Swappable',
  'Enable Search',
  'Customizable',
  'Customization Check',
];

const BackgroundSlotSettings: Array<String> = ['Swappable'];

const TextSlotSettings = [
  'Negative',
  'Movable',
  'Customizable',
  'Customization Check',
  'Alphanumeric',
  'Force Capitalize',
];

const DisabledSettings = [
  {
    keyName: 'EnableSearch',
    needs: 'Swappable',
  },
  {
    keyName: 'CycleOnly',
    needs: 'Swappable',
  },
  {
    keyName: 'ForceCapitalize',
    needs: 'Customizable',
  },
  {
    keyName: 'Alphanumeric',
    needs: 'Customizable',
  },
  {
    keyName: 'CustomizationCheck',
    needs: 'Customizable',
  },
];

const isDisabledField = (field: string, formValues: FormValues) => {
  const rule = DisabledSettings.find((item) => item.keyName === field);
  if (!rule) return false;
  return !formValues[rule.needs as keyof FormValues];
};

const getSettingsToRender = (slotType: string) => {
  switch (slotType) {
    case 'image':
      return ImageSlotSettings;
    case 'background':
      return BackgroundSlotSettings;
    case 'text':
      return TextSlotSettings;
    default:
      return [];
  }
};

const SettingsForImageSlots: React.FC<SettingsForImageSlotsProps> = (props) => {
  const {formValues, handleOptionsChange} = props;
  const handleSettings = (
    evt: React.ChangeEvent<HTMLInputElement>,
    setting: string,
  ) => {
    evt.target.blur();
    handleOptionsChange(setting, evt.target.checked);
  };
  const settingsToRender = getSettingsToRender(props.slotType);

  if (settingsToRender && settingsToRender.length > 0) {
    return (
      <Form.Group id="SlotProperties">
        <Form.Label className="properties-title">
          Slot configuration:{' '}
        </Form.Label>
        <div className="toggle-items-group">
          {settingsToRender.map((setting, idx) => {
            const settingKey = setting.replace(/ /gi, '') as keyof FormValues;
            return (
              <div className="toggle-item" key={idx}>
                <Form.Check
                  disabled={isDisabledField(settingKey, formValues)}
                  type="switch"
                  id={setting.toLowerCase().replace(/ /gi, '')}
                  key={idx}
                  label={setting}
                  checked={formValues[settingKey] as boolean}
                  onChange={(evt) => handleSettings(evt, settingKey)}
                />
                {idx > 0 && (idx + 1) % 3 === 0 && (
                  <hr key={`componente-${idx}`} />
                )}
              </div>
            );
          })}
        </div>
      </Form.Group>
    );
  }

  return <></>;
};

export default SettingsForImageSlots;
