import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import './StripePayment.scss';
import CardForm from './CardForm';

const {REACT_APP_STRIPE_PUBLIC_KEY: stripePublicKey = ''} = process.env;

const stripePromise = loadStripe(stripePublicKey);

interface OptionalProps {
  onPaymentMethodCreated?(paymentMethodId: string): void;
}

const StripePaymentForm: React.FC<OptionalProps> = ({
  onPaymentMethodCreated,
}) => {
  return (
    <>
      <h3>Cards Details</h3>
      {stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            mode: 'setup',
            currency: 'usd',
            paymentMethodCreation: 'manual',
            appearance: {
              theme: 'flat',
              variables: {
                colorPrimary: '#f58721',
                colorIconCardError: '#f10e06',
                colorDanger: '#f10e06',
                colorText: '#262e3a',
              },
            },
          }}
        >
          <CardForm onPaymentMethodCreated={onPaymentMethodCreated} />
        </Elements>
      )}
    </>
  );
};

export default StripePaymentForm;
