import React from 'react';
import {useAppSelector} from './app/hooks';
import {selectShop} from './features/shops/selectedShop';
import {ShopSelectorPage} from './pages/ShopSelectorPage';
import {RouteProps, Outlet} from 'react-router-dom';

const SelectedShopRoute: React.FC<RouteProps> = () => {
  const shopSelected = useAppSelector(selectShop);

  if (!shopSelected) {
    return <ShopSelectorPage />;
  }

  if (shopSelected) {
    return <Outlet />;
  }

  return <></>;
};

export default SelectedShopRoute;
