import {
  IAddImagesRequest,
  IImage,
  uploadPathParameters,
  uploadQueryParameters,
  uploadRequest,
} from '@gfxco/contracts';
import getKeywordsInformation, {
  getMetadataImage,
} from './getMetadataInformation';
import {requestUploadLink} from '../api';
import imageAPI from '../api/images';
import axios from 'axios';

const {REACT_APP_ASSETS_BUCKET_NAME} = process.env;

export interface IImageFile extends IImage {
  isLoading?: boolean;
  isError?: boolean;
  error?: any;
  reason?: any;
  file?: File & {
    newFileName?: string;
  };
  fileSize?: number;
  dimensions?: {
    width: number;
    height: number;
  };
}

export type uploadImageParams = {
  file: File;
  fileName: string;
  shopId: number;
  fileFolder?: string;
  collectionId?: number;
  limits?: {
    width: number;
    height: number;
    weight: number;
  };
  omitImageReference?: boolean;
};

export const allowedImageExtensionsTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
];

export function extractFileExtension(fileName: string): string {
  const nameParts = fileName.split('.');
  return nameParts[nameParts.length - 1]?.toLowerCase();
}

export function updateFileName(fileName: string, type: string): string {
  const indexToSplit = fileName.lastIndexOf('.');

  if (indexToSplit !== -1) {
    const name = fileName.substring(0, indexToSplit);
    const newFileName = `${name}_${Date.now()}.${type.split('/').pop()}`;

    return newFileName;
  } else {
    return fileName;
  }
}

export const validateFileExtension = (file: File): boolean => {
  return allowedImageExtensionsTypes.includes(file.type);
};

export async function validateFileLimits(
  params: uploadImageParams,
): Promise<IImageFile> {
  const {file, limits, fileName: name} = params;

  if (!validateFileExtension(file)) {
    throw new Error(
      `The image does not meet the requirements. Allowed extensions: ${allowedImageExtensionsTypes.join(
        ', ',
      )}`,
    );
  }

  const fileSizeMB = file.size / 1024 / 1024;

  if (limits && fileSizeMB > limits.weight) {
    throw new Error(
      `The image does not meet the requirements. Max weight: ${limits.weight}MB`,
    );
  }

  const metadata = await getMetadataImage(file);
  const dimensions = {
    width: metadata.width,
    height: metadata.height,
  };

  if (
    limits &&
    (dimensions.width > limits.width || dimensions.height > limits.height)
  ) {
    throw new Error(
      `The image does not meet the requirements. Max width: ${limits.width}px, max height: ${limits.height}px.`,
    );
  }

  return {fileSize: file.size, dimensions, name};
}

async function uploadImage(params: uploadImageParams): Promise<IImageFile> {
  const {file, shopId, fileName, collectionId, fileFolder} = params;
  const folder = fileFolder || `settings`;
  const key = fileName;
  const path = `${shopId}/${folder}/${key}`;
  const pathParams: uploadPathParameters = {shopId};
  const queryParams: uploadQueryParameters = {folder};
  const body: uploadRequest = {mime: file.type, key};

  const {fileSize, dimensions} = await validateFileLimits(params);

  const url = await requestUploadLink(pathParams, queryParams, body);
  if (!url) {
    throw new Error('Unable to get url');
  }
  const imageUrl = url.split('?')[0];
  await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
      'Access-Control-Allow-Origin': '*',
    },
  });

  if (params.omitImageReference) {
    return {
      name: fileName,
      imageUrl: `https://${REACT_APP_ASSETS_BUCKET_NAME}/${path}`,
      fileSize,
    } as IImageFile;
  }
  const keywords = await getKeywordsInformation(file);
  const bodyParams: IAddImagesRequest = {
    imageUrl,
    shopId,
    imageName: fileName,
    size: fileSize!,
    path,
    keywords,
    collectionId,
    width: dimensions?.width,
    height: dimensions?.height,
  };

  const image = await imageAPI.addImage(bodyParams);
  return image as IImageFile;
}

export default uploadImage;
