import {
  IDesignTemplateRequest,
  IDesignTemplateResponse,
  IPublishDesignRequest,
  IPublishDesignResponse,
} from '@gfxco/contracts';
import {handleError, loadClient} from './api';

export const getSizes = async (templateId: number) => {
  try {
    const client = await loadClient();
    const queryParams = {
      templateId,
    };
    const response = await client.get<string[]>(`/product-listing/sizes`, {
      params: queryParams,
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const addToProductListing = async (templateId: number) => {
  try {
    const client = await loadClient();
    await client.post<void>(`/product-listing`, {
      templateId,
    });
  } catch (error) {
    return handleError(error);
  }
};

export const publishDesign = async (params: IPublishDesignRequest) => {
  try {
    const client = await loadClient();
    const response = await client.post<IPublishDesignResponse>(
      `/product-listing/publish`,
      {
        ...params,
      },
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updatePublishStatus = async (params: {
  templateId: number;
  status: 'draft' | 'active';
}) => {
  try {
    const client = await loadClient();
    const response = await client.put<IPublishDesignResponse>(
      `/product-listing/`,
      {
        ...params,
      },
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getPublishedProductEntities = async (
  params: IDesignTemplateRequest,
) => {
  try {
    const client = await loadClient();
    const response = await client.get<IDesignTemplateResponse>(
      `/product-listing`,
      {
        params,
      },
    );

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const unPublishedProducts = async (params: {shopId: number}) => {
  try {
    const client = await loadClient();
    const response = await client.post<any>(`/product-listing/unpublish`, {
      ...params,
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};
