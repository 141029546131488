import React from 'react';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {IOrderItemHistory} from '@gfxco/contracts';

import './CommentInput.scss';
import {Trash3, Pencil} from 'react-bootstrap-icons';

interface OptionalProps {
  comments?: IOrderItemHistory[];
  showInput?: boolean;
  onDelete?: (key: number) => void;
  onEdit: (comment: IOrderItemHistory) => void;
  onSubmit?: (key: string) => void;
  actionsDisabled?: boolean;
  defaultValue?: string;
}

interface RequiredProps {
  onCancel: () => void;
}

type CommentInputProps = OptionalProps & RequiredProps;

const CommentInput: React.FC<CommentInputProps> = ({
  showInput = true,
  onDelete,
  onSubmit,
  comments,
  onCancel,
  actionsDisabled,
  defaultValue = '',
  onEdit,
}) => {
  const {user} = useAuthenticator((context) => [context.user]);
  const [commentInput, setCommentInput] = React.useState<string>(defaultValue);

  const KEY = {
    enter: 'Enter',
  };

  React.useEffect(() => {
    setCommentInput(defaultValue);
  }, [defaultValue]);

  const handlePostComment = () => {
    if (onSubmit && commentInput.length) onSubmit(commentInput);
  };

  const handleOnDelete = (id: number) => {
    if (onDelete) onDelete(id);
  };

  const handleOnCancel = () => {
    setCommentInput('');
    onCancel();
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const keyPressed = event.key;

    if (keyPressed === KEY.enter) {
      if (onSubmit && commentInput.length) onSubmit(commentInput);
    }
  };

  return (
    <div id="CommentInput">
      {!showInput &&
        comments?.map((comment) => (
          <div key={comment.id}>
            <div className="comment-container">
              <div className="details">
                <div className="comment-details">
                  <div>
                    <span className="user-title">{`${comment.createdBy} said:`}</span>
                    <span className="date-title">{`${comment.createdAt}`}</span>
                  </div>
                  <span className="comment-text">{comment.details}</span>
                </div>

                {!actionsDisabled && (
                  <div className="icons-container">
                    <div
                      className={`comment-icon ${
                        comment.createdBy !== user.username
                          ? 'comment-icon-disabled'
                          : ''
                      }`}
                      onClick={() => onEdit(comment)}
                    >
                      <Pencil />
                    </div>
                    <div
                      className="comment-icon"
                      onClick={() => handleOnDelete(comment.id)}
                    >
                      <Trash3 />
                    </div>
                  </div>
                )}
              </div>
              <hr />
            </div>
          </div>
        ))}

      {showInput && (
        <>
          <div className="comment-container">
            <div className="comment-user">
              <span className="user-title">{`${user.username} said:`}</span>
            </div>
            <div className="comment-input">
              <textarea
                placeholder="Add a new comment"
                maxLength={250}
                onKeyDown={handleOnKeyDown}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setCommentInput(event.target.value)
                }
                value={commentInput}
              />
            </div>
          </div>
          <div className="publish-link">
            <span className="cancel-link" onClick={() => handleOnCancel()}>
              Cancel
            </span>
            <span className="post-link" onClick={() => handlePostComment()}>
              Post
            </span>
          </div>
        </>
      )}
    </div>
  );
};
export default CommentInput;
