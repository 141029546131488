import React from 'react';

import './DesignsHeader.scss';
import NewDesign from '../../../assets/icons/icon_create_new_design.svg';
import NewProduct from '../../../assets/icons/icon_create_on_product_disabled.svg';

interface RequiredProps {
  onScratchDesign: () => void;
}

interface OptionalProps {}

type DesignsHeaderProps = RequiredProps & OptionalProps;

const DesignsHeader: React.FC<DesignsHeaderProps> = (props) => {
  return (
    <div id="DesignsHeader">
      <div className="title-content">
        <h1 className="title">Designs</h1>
        <h2 className="subtitle">{"Let's get started!"}</h2>
        <h2 className="subtitle">
          {'Choose how you want to create your design'}
        </h2>
      </div>
      <div className="start-options">
        <div className="option" onClick={props.onScratchDesign}>
          <img src={NewDesign} />
          <p className="info">Create a design from scratch</p>
        </div>
        <div className="option disabled-option">
          <img src={NewProduct} />
          <p className="info text-disabled">
            Create a design on a product from your store
          </p>
        </div>
      </div>
    </div>
  );
};

export default DesignsHeader;
