import React from 'react';
import {IBuilderSlots} from '@gfxco/contracts';
import {Pencil} from 'react-bootstrap-icons';

import BackgroundSlotIcon from '../../assets/icons/background-slot-icon.svg';
import ImageSlotIcon from '../../assets/icons/image-slot-icon.svg';
import TextSlotIcon from '../../assets/icons/text-slot-icon.svg';

import './SlotItem.scss';

interface Required {
  slot: IBuilderSlots;
  index: number;
}

interface Optional {
  onClickEdit?: () => void;
  onSelect?: (slot: IBuilderSlots) => void;
  readonly?: boolean;
  selected?: boolean;
  disabled?: boolean;
  'data-tooltip'?: string;
}

type Props = Required & Optional;

const SlotItem: React.FC<Props> = (props) => {
  const slotContentMask = (element: any) => {
    if (element.objects[0].type === 'textbox') {
      return 'Textbox';
    }
    if (element.isBackground) {
      return 'Background';
    }

    if (element.objects[0].type === 'image') {
      return 'Image';
    }
  };

  if (!props.slot || !props.slot.objects) return null;
  const selectTypeImage = (type: string) => {
    switch (type) {
      case 'image':
        return ImageSlotIcon;
      case 'textbox':
        return TextSlotIcon;
      default:
        return BackgroundSlotIcon;
    }
  };
  const type = props.slot.objects[0].type; // image, textbox
  const defaultImage = props.slot.objects[0].src;
  const alias = props.slot.objects[0]?.alias || props.slot.alias;

  const handleOnEditButton = () => {
    if (props.onClickEdit) {
      props.onClickEdit();
    }
  };

  const handleSelect = () => {
    if (props.onSelect && !props.disabled) {
      props.onSelect(props.slot);
    }
  };

  return (
    <div
      className={`slot-configuration-item ${props.disabled ? 'disabled' : ''} ${
        props.selected ? 'selected' : ''
      } ${props.onSelect && !props.disabled ? 'selectable' : ''}`}
      onClick={() => handleSelect()}
      data-tooltip={props['data-tooltip']}
    >
      <div className="slot-configuration-item__info">
        <div className="slot-configuration-item__info_image">
          <img src={defaultImage} alt={alias || 'Slot name ' + props.index} />
        </div>
        <div className="slot-configuration-item__info_type">
          <img src={selectTypeImage(type)} alt={`Slot ${type} icon`} />
          <p>{slotContentMask(props.slot)}</p>
        </div>
        <div className="slot-configuration-item__info_name">
          <p>{alias || 'Slot name ' + props.index}</p>
        </div>
      </div>
      {!props.readonly && (
        <div className="slot-configuration-item__actions">
          <button onClick={() => handleOnEditButton()}>
            <Pencil />
          </button>
        </div>
      )}
    </div>
  );
};

export default SlotItem;
