import React from 'react';
import {Badge, Button} from 'react-bootstrap';
import {ELoadingStates} from '@gfxco/contracts';
import Loading from '../Loading';

import './ButtonBadgeTab.scss';

interface RequiredProps {
  buttonText: string;
  active: boolean;
  onClick: () => void;
  countValue: number;
  fetchStatus: ELoadingStates;
}

interface OptionalProps {
  className?: string;
  badgeClass?: string;
}

type ButtonBadgeTabProps = RequiredProps & OptionalProps;

const ButtonBadgeTab: React.FC<ButtonBadgeTabProps> = (props) => {
  const CountValue = (innerProps: {value: number; invert?: boolean}) => {
    if (props.fetchStatus === ELoadingStates.IDLE) {
      return <></>;
    }

    if (props.fetchStatus === ELoadingStates.LOADING) {
      return (
        <Loading
          variant={innerProps.invert ? 'dark' : 'light'}
          spinnerOnly
          size="sm"
        />
      );
    }

    if (props.fetchStatus === ELoadingStates.FAILED) {
      return <>0</>;
    }

    return <>{innerProps.value}</>;
  };

  return (
    <Button
      id="ButtonBadgeTab"
      className={props.className || ''}
      active={props.active}
      onClick={props.onClick}
    >
      {props.buttonText}{' '}
      <Badge className={props.badgeClass || 'default'}>
        <CountValue value={props.countValue} invert={!props.badgeClass} />
      </Badge>
      <span className="visually-hidden">{props.buttonText}</span>
    </Button>
  );
};

export default ButtonBadgeTab;
