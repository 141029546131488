import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ImageAction from '../../../../../components/ShareActions/ImageAction';
import GarmentColor from '../../../../../components/ShareActions/GarmentColor';
import Tags from '../../../../../components/ShareActions/Tags';
import {IColor, IException} from '@gfxco/contracts';
import './HideExceptions.scss';

export interface RequiredProps {
  colors: IColor[];
  title: string;
  exceptions: IException[];
  onDelete: (param: {id: number | number[]}) => void;
}
export interface OptionalProps {}

type ExceptionItemProps = RequiredProps & OptionalProps;

export const Exceptions: React.FC<ExceptionItemProps> = ({
  exceptions,
  onDelete,
  title,
}) => {
  const handleOnDelete = (param: {id?: number}) => {
    if (param.id) onDelete({id: param.id});
  };

  return (
    <Container fluid id="HideExceptions">
      <Row className="align-items-center justify-content-center">
        <span className="title-exception-type">{`${title}:`}</span>
        {exceptions.map(({image, id, tag, fillColor}) => (
          <Col xs={8} md="auto" key={`item-${id}`}>
            {image && (
              <ImageAction
                url={image?.imageUrl}
                name={image?.name}
                showSlot={false}
                showDelete={true}
                onDelete={handleOnDelete}
                actionId={id}
              />
            )}

            {tag && (
              <Tags
                tagExceptionName={tag}
                showDelete={true}
                tagExceptionId={id}
                onDelete={handleOnDelete}
              />
            )}

            {fillColor && (
              <GarmentColor
                color={{
                  hex: fillColor,
                  name: fillColor,
                  id: 0,
                }}
                title={'Text fill color'}
                showHex={true}
                showName={false}
                id={id}
                showDelete
                onDelete={handleOnDelete}
              />
            )}
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Exceptions;
