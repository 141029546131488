export const ColorOptions = [
  {value: '#4573BB', hex: '#4573BB', label: 'DEEP BLUE'},
  {value: '#7F53B6', hex: '#7F53B6', label: 'PURPLE'},
  {value: '#B8F2FF', hex: '#B8F2FF', label: 'LIGHT BLUE'},
  {value: '#45BB74', hex: '#45BB74', label: 'GREEN'},
  {value: '#D33842', hex: '#D33842', label: 'RED'},
  {value: '#F68421', hex: '#F68421', label: 'ORANGE'},
  {value: '#ECCB1B', hex: '#ECCB1B', label: 'YELLOW'},
  {value: '#FFFFFF', hex: '#FFFFFF', label: 'WHITE'},
  {value: '#545454', hex: '#545454', label: 'BLACK'},
];

export interface IColorOption {
  value: string;
  hex: string;
  label: string;
}
