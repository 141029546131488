import React from 'react';
import {GFXInstance} from '@best-apps/gfx-editor';
import './GFXEditor.scss';

const {
  REACT_APP_LEGACY_API_URL = 'http://localhost:3000/api',
  REACT_APP_EDITOR_URL = 'https://gfx-editor-staging-best-apps.vercel.app',
  REACT_APP_API_URL = 'http://localhost:4005/',
  REACT_APP_ASSETS_BUCKET_NAME = 'stg.assets.gfxco.com',
} = process.env;

interface RequiredProps {}

type InterfaceType = 'full' | 'basic' | 'dynamic' | 'builder';

interface OptionalProps {
  isTemplateBuilder: boolean;
  templateId: string;
  builderProductType: string;
  builderManufacturerId: number;
  builderPrinterCode: string;
  builderShopId: string;
  designNumber: string;
  interfaceType: InterfaceType;
  height: number | null;
  width: number | null;
  canvasId: number;
  displayColors: string;
  padding?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
}

type GFXEditorProps = RequiredProps & Partial<OptionalProps>;

export const GFXEditor: React.FC<GFXEditorProps> = (props) => {
  const {width, height, ...restProps} = props;
  // Props in this object (restProps) cause remounting the GFX Editor component.
  // This will cause losing all not saved changes.
  const {
    templateId,
    designNumber,
    interfaceType,
    isTemplateBuilder,
    builderProductType,
    builderShopId,
    canvasId,
    displayColors,
    builderManufacturerId,
    builderPrinterCode,
  } = restProps;
  return (
    <div
      id="GFXEditor"
      style={{
        height: `100%`,
        paddingTop: `${props.padding?.top || 0}px`,
        paddingBottom: `${props.padding?.bottom || 0}px`,
        paddingLeft: `${props.padding?.left || 0}px`,
        paddingRight: `${props.padding?.right || 0}px`,
        maxHeight: height ? `${height}px` : undefined,
        maxWidth: `${width || 400}px`,
      }}
    >
      <GFXInstance
        v1TemplateId={templateId!} // The v1TemplateId for you product and design
        canvasId={canvasId?.toString()} // If this is a slot template, it will search for the design data in canvas table
        designNumber={designNumber}
        __editorUrl__={REACT_APP_EDITOR_URL}
        key={encodeURIComponent(`${JSON.stringify(restProps)}`)}
        gfxConfig={{
          interfaceType,
          displayColors,
          isTemplateBuilder,
          builderProductType,
          builderManufacturerId,
          printer: builderPrinterCode,
          builderShopId,
          apiUrl: REACT_APP_LEGACY_API_URL,
          adminApiV2Url: REACT_APP_API_URL,
          assetsUrl: `https://${REACT_APP_ASSETS_BUCKET_NAME}`,
        }}
      />
    </div>
  );
};
