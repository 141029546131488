import React from 'react';
import {ReactComponent as CommentIcon} from '../../../../assets/icons/new-comment-icon.svg';
import CommentInput from '../../../CommentInput';
import {EModerationItemStatus, IOrderItemHistory} from '@gfxco/contracts';

import './Comment.scss';
import Loading from '../../../Loading';

interface RequiredProps {
  itemId: number;
  onAction: (
    status: EModerationItemStatus,
    id: number,
    isComment?: boolean,
    details?: string,
  ) => void;
  onDeleteComment: (id: number) => void;
  onEditComment: (id: number, details: string) => void;
}

interface OptionalProps {
  isLoading?: boolean;
  comments?: IOrderItemHistory[];
  disabled?: boolean;
}

type ActionsViewProps = RequiredProps & OptionalProps;

const ActionsView: React.FC<ActionsViewProps> = ({
  itemId,
  onAction,
  isLoading,
  comments,
  onDeleteComment,
  onEditComment,
  disabled,
}) => {
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const [isNewComment, setIsNewComment] = React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [commentEditingId, seCommentEditingId] = React.useState<
    number | undefined
  >();
  const [defaultValue, setDefaultValue] = React.useState<string>('');
  const isCommentsEmpty = !comments?.length;
  const inputDisabled =
    !isLoading && !isNewComment && !isCommentsEmpty && !disabled && !isEditing;

  const CODE = {
    spaceBar: 'Space',
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleOnKeyDown);

    return () => {
      window.removeEventListener('keydown', handleOnKeyDown);
    };
  }, [isNewComment]);

  React.useEffect(() => {
    setIsEditing(false);
    setIsNewComment(false);
  }, [itemId]);

  const handleOnKeyDown = (event: KeyboardEvent) => {
    const keyPressed = event.code;
    if (keyPressed === CODE.spaceBar && !isNewComment) {
      setIsNewComment(true);
      event.preventDefault();
    }
  };

  const handlePostComment = (comment: string) => {
    if (disabled) return;
    if (commentEditingId) {
      onEditComment(commentEditingId, comment);
    } else {
      onAction(EModerationItemStatus.PENDING, itemId, true, comment);
    }
    restoreValues();
  };

  const handleEditComment = (comment: IOrderItemHistory) => {
    setIsEditing(true);
    setIsNewComment(true);
    seCommentEditingId(comment.id);
    setDefaultValue(comment.details);
  };

  const restoreValues = () => {
    setIsNewComment(false);
    setIsEditing(false);
    seCommentEditingId(undefined);
    setDefaultValue('');
  };

  return (
    <div id="Comment">
      <div
        className={`comment-box ${
          isHovered && isCommentsEmpty ? 'comment-button-hovered' : ''
        }`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isLoading && <Loading />}
        {!isLoading && !isNewComment && isCommentsEmpty && (
          <div
            className={`comment-empty-button ${
              disabled ? 'comment-disabled' : ''
            }`}
          >
            <CommentIcon onClick={() => setIsNewComment(true)} />
            <span>Add a comment</span>
            {isHovered && (
              <div className="button-tooltip">
                <span>Press spacebar or click to add a comment</span>
              </div>
            )}
          </div>
        )}
        {!isLoading && (
          <CommentInput
            onSubmit={handlePostComment}
            showInput={isNewComment || isEditing}
            comments={comments}
            onDelete={onDeleteComment}
            onEdit={handleEditComment}
            onCancel={() => setIsNewComment(false)}
            actionsDisabled={disabled}
            defaultValue={defaultValue}
          />
        )}
      </div>
      {inputDisabled && (
        <div className="new-comment-link" onClick={() => setIsNewComment(true)}>
          <span>+ Add New Comment</span>
        </div>
      )}
    </div>
  );
};

export default ActionsView;
