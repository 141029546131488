import {IImage} from '@gfxco/contracts';
import classNames from 'classnames';
import React from 'react';

type ShopImageSelectorItemProps = {
  image: IImage;
  onClick: (image: IImage) => Promise<void>;
  isSelected?: boolean;
};

const ShopImageSelectorItem: React.FC<ShopImageSelectorItemProps> = ({
  image,
  onClick,
  isSelected,
}) => {
  const [tryCount, setTryCount] = React.useState<number>(0);
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
  const onLoadError = (evt: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = evt.target as HTMLImageElement;
    target.src = image.imageUrl!;
    setTryCount((current) => current + 1);
  };
  const onLoad = () => {
    setImageLoaded(true);
  };

  if (tryCount > 1) {
    return null;
  }

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (imageLoaded) {
      onClick(image);
    }
  };

  const titleText = `
      Image: ${image.name}
      Collection: ${image.collectionName}
    `;

  const clases = classNames('image-selector-item', {
    'image-selector-item--selected': isSelected,
  });

  return (
    <div className={clases} onClick={handleOnClick} title={titleText}>
      <img
        onError={onLoadError}
        onLoad={onLoad}
        src={image.thumbnail}
        alt={'Image loaded by user:' + image.name}
      />
    </div>
  );
};

export default ShopImageSelectorItem;
