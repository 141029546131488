import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getDesignsCount, updateDesignViewed} from '../../api';
import {RootState} from '../../app/store';
import {ELoadingStates, IGetDesignsCountResponse} from '@gfxco/contracts';

export interface DesignsCountState {
  value: IGetDesignsCountResponse;
  status: ELoadingStates;
}

const initialState: DesignsCountState = {
  value: {total: 0, inReview: 0, new: 0, onHold: 0},
  status: ELoadingStates.IDLE,
};

export const getDesignsCountAsync = createAsyncThunk(
  'moderationStatusCount/fetch',
  async (shopId: number) => {
    const response = await getDesignsCount(shopId);
    return response;
  },
);

export const updateViewedItemAsync = createAsyncThunk(
  'moderationStatusCount/updateViewedItem',
  async (designId: number) => {
    await updateDesignViewed(designId);
  },
);

export const loadDesignsCount = createSlice({
  name: 'moderationStatusCount',
  initialState,
  reducers: {
    resetModerationOrdersCountFetchStatus: (state) => {
      state.status = ELoadingStates.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDesignsCountAsync.pending, (state) => {
        state.status = ELoadingStates.LOADING;
      })
      .addCase(getDesignsCountAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.value = action.payload;
          state.status = ELoadingStates.LOADED;
        }
      })
      .addCase(getDesignsCountAsync.rejected, (state) => {
        state.status = ELoadingStates.FAILED;
      });
  },
});

export const selectDesignsCount = (state: RootState) => {
  return state.designsCount.value;
};

export const selectDesignsCountFetchLoading = (state: RootState) => {
  return state.designsCount.status;
};

export const {resetModerationOrdersCountFetchStatus} = loadDesignsCount.actions;

export default loadDesignsCount.reducer;
