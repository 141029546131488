import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import {ReactComponent as InformationIcon} from '../../../assets/icons/information-icon.svg';

import './UserTable.scss';

interface RequiredProps {
  children: React.ReactNode;
  isRegularUser: boolean;
}

const UserTable: React.FC<RequiredProps> = (props) => {
  const [userNameExpanded] = React.useState(false);
  const [creationDateExpanded] = React.useState(false);

  return (
    <div id="UserTable">
      <table className="table">
        <thead>
          <tr>
            <th scope="col" className="InformationRow">
              User Name {userNameExpanded ? <ExpandLess /> : <ExpandMore />}
            </th>
            <th scope="col" className="PermissionRow">
              Products &nbsp;
              <OverlayTrigger
                placement="top"
                delay={{show: 200, hide: 400}}
                overlay={
                  <Tooltip className="userPermissionTooltip">
                    Access to: Designs, Images, Fonts, Labels
                  </Tooltip>
                }
              >
                <InformationIcon className="info" />
              </OverlayTrigger>
            </th>
            <th scope="col" className="PermissionRow">
              Orders &nbsp;
              <OverlayTrigger
                placement="top"
                delay={{show: 200, hide: 400}}
                overlay={
                  <Tooltip className="userPermissionTooltip">
                    Access to: Management, Moderation
                  </Tooltip>
                }
              >
                <InformationIcon className="info" />
              </OverlayTrigger>
            </th>
            <th scope="col" className="PermissionRow hide">
              Samples &nbsp;
              <OverlayTrigger
                placement="top"
                delay={{show: 200, hide: 400}}
                overlay={
                  <Tooltip className="userPermissionTooltip">
                    Access to: Sample editor, History
                  </Tooltip>
                }
              >
                <InformationIcon className="info" />
              </OverlayTrigger>
            </th>
            <th scope="col" className="PermissionRow">
              My Store &nbsp;
              <OverlayTrigger
                placement="top"
                delay={{show: 200, hide: 400}}
                overlay={
                  <Tooltip className="userPermissionTooltip">
                    Access to: My store
                  </Tooltip>
                }
              >
                <InformationIcon className="info" />
              </OverlayTrigger>
            </th>
            <th scope="col" className="PermissionRow">
              Printers &nbsp;
              <OverlayTrigger
                placement="top"
                delay={{show: 200, hide: 400}}
                overlay={
                  <Tooltip className="userPermissionTooltip">
                    Access to: Printer configuration
                  </Tooltip>
                }
              >
                <InformationIcon className="info" />
              </OverlayTrigger>
            </th>
            <th scope="col" className="PermissionRow">
              Settings &nbsp;
              <OverlayTrigger
                placement="top"
                delay={{show: 200, hide: 400}}
                overlay={
                  <Tooltip className="userPermissionTooltip">
                    Access to: Subscription, Billing/Invoices, User Manager
                  </Tooltip>
                }
              >
                <InformationIcon className="info" />
              </OverlayTrigger>
            </th>
            <th scope="col" className="InformationRow">
              Date Added{' '}
              {creationDateExpanded ? <ExpandLess /> : <ExpandMore />}
            </th>
            {props.isRegularUser && <th scope="col"></th>}
          </tr>
        </thead>
        <tbody>{props.children}</tbody>
      </table>
    </div>
  );
};

export default UserTable;
