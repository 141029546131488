import React from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import * as Contracts from '@gfxco/contracts';
import {changePassword} from '../../../features/auth';
import {useAppDispatch} from '../../../app/hooks';
import PasswordField from '../../../components/PasswordField';
import './PasswordForm.scss';
import GFXButton from '../../../components/Button';

interface PasswordFormRequiredProps {
  user: Contracts.ICognitoUser;
}

const PasswordForm: React.FunctionComponent<PasswordFormRequiredProps> = ({
  user,
}) => {
  const defaultPasswords = {
    currentPasswordInput: '',
    newPasswordInput: '',
    confirmPasswordInput: '',
  };
  const formRef = React.useRef<HTMLFormElement>(null);
  const [submitError, setSubmitError] = React.useState('');
  const [requestStatus, setRequestStatus] =
    React.useState<Contracts.ELoadingStates>(Contracts.ELoadingStates.IDLE);
  const [passwords, setPasswords] = React.useState(defaultPasswords);
  const dispatch = useAppDispatch();

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setRequestStatus(Contracts.ELoadingStates.LOADING);
    if (passwords.newPasswordInput !== passwords.confirmPasswordInput) {
      setRequestStatus(Contracts.ELoadingStates.FAILED);
      setSubmitError('Passwords do not match');
      return;
    }
    try {
      await dispatch(
        changePassword({
          user,
          currentPassword: passwords.currentPasswordInput,
          newPassword: passwords.newPasswordInput,
        }),
      ).unwrap();
    } catch (error: any) {
      setRequestStatus(Contracts.ELoadingStates.FAILED);
      setSubmitError(error.message);
      return;
    }
    setRequestStatus(Contracts.ELoadingStates.LOADED);
    setPasswords(defaultPasswords);
    formRef.current?.reset();
  };

  const formHasChanges = () => {
    return (
      passwords.currentPasswordInput !== '' &&
      passwords.newPasswordInput !== '' &&
      passwords.confirmPasswordInput !== ''
    );
  };

  return (
    <Form
      className="password-form account-page-form"
      onSubmit={handleSubmitForm}
      ref={formRef}
    >
      <Form.Group
        className="form-group-accounts form-group-icon"
        controlId="current-password-input"
      >
        <Form.Label>Current password*</Form.Label>
        <PasswordField
          name="current-password-input"
          onChangeInput={(value) =>
            setPasswords((currentPasswords) => ({
              ...currentPasswords,
              currentPasswordInput: value,
            }))
          }
        />
        <span className="input-info">*Required to make changes</span>
      </Form.Group>
      <Form.Group
        className="form-group-accounts form-group-icon"
        controlId="new-password-input"
      >
        <Form.Label>New password</Form.Label>
        <PasswordField
          name="new-password-input"
          onChangeInput={(value) =>
            setPasswords((currentPasswords) => ({
              ...currentPasswords,
              newPasswordInput: value,
            }))
          }
        />
        <span className="input-info">
          *<strong>Password requirements</strong>: Minimum length{' '}
          <strong>8 character(s)</strong>. Contains at least{' '}
          <strong>1 number</strong>. Contains at least{' '}
          <strong>1 special character</strong>. Contains at least{' '}
          <strong>1 uppercase letter</strong>. Contains at least{' '}
          <strong>1 lowercase letter</strong>.
        </span>
      </Form.Group>
      <Form.Group
        className="form-group-accounts form-group-icon"
        controlId="confirm-password-input"
      >
        <Form.Label>Confirm password</Form.Label>
        <PasswordField
          name="confirm-password-input"
          onChangeInput={(value) =>
            setPasswords((currentPasswords) => ({
              ...currentPasswords,
              confirmPasswordInput: value,
            }))
          }
        />
      </Form.Group>
      {requestStatus === Contracts.ELoadingStates.FAILED && (
        <Alert variant="danger">{submitError}</Alert>
      )}
      {requestStatus === Contracts.ELoadingStates.LOADED && (
        <Alert variant="info">Password updated successfully</Alert>
      )}
      <GFXButton
        className="submit-button"
        variant="primary"
        disabled={!formHasChanges() || requestStatus === 'loading'}
        type="submit"
      >
        {requestStatus === Contracts.ELoadingStates.LOADING
          ? 'Loading...'
          : 'Save changes'}
      </GFXButton>
    </Form>
  );
};

export default PasswordForm;
