import {ESlotTypes, IBuilderSlots} from '@gfxco/contracts';
import React from 'react';
import {Form} from 'react-bootstrap';

type FormValues = {
  alias: string;
  Removable?: boolean;
  Movable?: boolean;
  Swappable?: boolean;
  EnableSearch?: boolean;
  CycleOnly?: boolean;
  Customizable?: boolean;
  Negative?: boolean;
  CustomizationCheck?: boolean;
  RemoveBackground?: boolean;
  AutoCropFace?: boolean;
  FitContainer?: boolean;
  ScalableImage?: boolean;
  GrayScale?: boolean;
  ModerationStage?: string;
  CustomizationMessage?: string;
  MaxLength?: number;
  Alphanumeric?: boolean;
  ForceCapitalize?: boolean;
  DynamicColor?: boolean;
  TextColors?: string[];
};

type CustomizableConfigsRequiredProps = {
  Customizable: boolean;
  formValues: FormValues;
  slot: IBuilderSlots;
  slotType: string;
  handleOptionsChange: (
    setting: string,
    value: boolean | string | number,
  ) => void;
};

type CustomizableConfigsOptionalProps = {};

type CustomizableConfigsProps = CustomizableConfigsRequiredProps &
  Partial<CustomizableConfigsOptionalProps>;

const CustomizableConfigs: React.FC<CustomizableConfigsProps> = (props) => {
  if (props.slotType === ESlotTypes.Background) return null;

  const {handleOptionsChange, formValues} = props;
  const ImageCustomizationSettings = ['Remove Background', 'Gray Scale'];
  const ImageCustomizationComponents = (
    <div className="toggle-items-group">
      {ImageCustomizationSettings.map((setting, idx) => {
        const key = setting.replace(/ /gi, '');
        return (
          <div className="toggle-item" key={idx}>
            <Form.Check
              type="switch"
              disabled={!props.Customizable}
              id={key}
              key={key}
              defaultChecked={formValues[key as keyof FormValues] as boolean}
              label={setting}
              onChange={(evt) => handleOptionsChange(key, evt.target.checked)}
            />
          </div>
        );
      })}
    </div>
  );

  const TextCustomizationComponents = (
    <div className="text-customization-settings">
      <Form.Label className="text-customize-label">
        Max Length, Limit: 100:
      </Form.Label>
      <Form.Control
        onChange={(evt) => handleOptionsChange('MaxLength', +evt.target.value)}
        value={formValues.MaxLength || ''}
        max={100}
        min={1}
        type="number"
        disabled={!props.Customizable}
      />
    </div>
  );

  const isImage = props.slotType === ESlotTypes.Image;

  return (
    <Form.Group id="SlotProperties">
      <div>
        {isImage ? ImageCustomizationComponents : TextCustomizationComponents}
      </div>
      <div className="moderation-settings">
        <Form.Group>
          <Form.Select
            onChange={(evt) =>
              handleOptionsChange('ModerationStage', evt.target.value)
            }
            disabled={!props.Customizable}
            value={formValues.ModerationStage}
          >
            <option value={0}>Select a stage</option>
            <option value={'modifying'}>When Modifying the element</option>
            <option value={'saving'}>When Saving design</option>
          </Form.Select>
        </Form.Group>
      </div>
    </Form.Group>
  );
};

export default CustomizableConfigs;
