import React from 'react';
import './ContentLayout.scss';

interface ContentLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const ContentLayout: React.FC<ContentLayoutProps> = ({
  children,
  className = '',
}) => {
  return <div className={`content-layout ${className}`}>{children}</div>;
};

export default ContentLayout;
