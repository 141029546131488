import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Alert, Badge} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import usePagination from '../../../hooks/usePagination';
import {selectShop} from '../../../features/shops/selectedShop';

import CustomPagination from '../../CustomPagination';
import GFXImage from '../../Image/Image';
import NoResultView from '../NoResultView';
import WarningTooltip from '../../WarningTooltip';

import {updatePublishStatus} from '../../../api';

import {
  getPublishedProductsAsync,
  getPublishedProductsByPage,
  selectTotalPublishedProducts,
  fetchStatusByPage,
  resetPublishedProducts,
} from '../../../features/publishedProducts/loadPublishedProducts';
import useQueryParams from '../../../hooks/useQueryParams';

import './PublishedList.scss';

interface RequiredProps {}

interface OptionalProps {}

type PublishedListProps = RequiredProps & OptionalProps;
const PublishedList: React.FC<PublishedListProps> = () => {
  const [queryParams] = useQueryParams();
  const sortBy = queryParams.sortBy || 'newest';
  const search = queryParams.search || '';
  const navigate = useNavigate();
  const [showDesignWarning, setShowDesignWarning] = React.useState<
    number | undefined
  >();
  const [showError, setShowError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [{offset, currentPage, limit}, handlePagination] = usePagination();
  const publishedProducts = useAppSelector((state) =>
    getPublishedProductsByPage(state, currentPage),
  );
  const totalPublishedProducts = useAppSelector(selectTotalPublishedProducts);

  const selectedShop = useAppSelector(selectShop);
  const dispatch = useAppDispatch();

  const designTemplateStatus = useAppSelector((state) =>
    fetchStatusByPage(state, currentPage),
  );

  const lookForDesigns = () => {
    if (selectedShop) {
      dispatch(
        getPublishedProductsAsync({
          shopId: selectedShop.id,
          offset,
          page: currentPage,
          limit,
          sortBy,
          search,
        }),
      );
    }
  };

  React.useEffect(() => {
    handlePagination('reset');
    dispatch(resetPublishedProducts());
  }, [queryParams.sortBy, queryParams.search]);

  React.useEffect(() => {
    dispatch(resetPublishedProducts());
  }, [selectedShop]);

  React.useEffect(() => {
    if (designTemplateStatus === 'idle') {
      lookForDesigns();
    }
  }, [selectedShop, designTemplateStatus, offset, limit]);

  const handleEditSlotControls = (id: number, status?: 'active' | 'draft') => {
    if (status === 'active') {
      return setShowDesignWarning(id);
    }
    navigate(`controls/${id}`, {
      state: {params: queryParams},
    });
  };

  const handleUpdateToDraft = async (id: number) => {
    try {
      setIsLoading(true);
      const product = await updatePublishStatus({
        templateId: id,
        status: 'draft',
      });
      navigate(`controls/${id}`, {
        state: {params: queryParams},
      });
      setTimeout(() => {
        if (product) window.open(product.url, '_blank', 'noreferrer');
      }, 2000);
    } catch (error) {
      setShowError(true);
    }
  };

  const contentLoaded = !['idle', 'loading'].includes(designTemplateStatus);

  return (
    <div id="DesignsList" className="published-list">
      {designTemplateStatus === 'error' && (
        <Alert variant="danger">
          There was an error loading your published products. Please reload the
          page or contact support.
        </Alert>
      )}
      {showError && (
        <Alert variant="danger">
          There was an error loading your product. Please reload the page or
          contact support.
        </Alert>
      )}
      {contentLoaded && publishedProducts.length > 0 && (
        <>
          <div className="design-list-container">
            {publishedProducts.map((design) => {
              return (
                <div key={design.id} className="design-item">
                  <div
                    className="published-item"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleEditSlotControls(design.id, design.publishStatus);
                    }}
                  >
                    <Badge className="published-badge">Published</Badge>
                    <Badge
                      className={`published-badge ${
                        design.publishStatus === 'active'
                          ? 'active-badge'
                          : 'draft-badge'
                      }`}
                    >
                      {design.publishStatus}
                    </Badge>
                    <GFXImage
                      src={
                        design.startingSide === 'front'
                          ? design.proofFront + `?v=${Date.now()}`
                          : design.proofBack + `?v=${Date.now()}`
                      }
                      className="design-item-image"
                      placeholderSrc="/loading-render.gif"
                    />
                    <p className="design-item-name" title={design.name}>
                      {design.name}
                    </p>
                  </div>
                  {showDesignWarning && showDesignWarning === design.id && (
                    <WarningTooltip
                      id={design.id}
                      onClose={() => setShowDesignWarning(undefined)}
                      isLoading={isLoading}
                      onAccept={handleUpdateToDraft}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <CustomPagination
            limit={limit}
            currentPage={currentPage}
            total={totalPublishedProducts}
            handlePagination={handlePagination}
            entity="Design Templates"
          />
        </>
      )}
      {contentLoaded && publishedProducts.length === 0 && <NoResultView />}
      {!contentLoaded && (
        <Skeleton
          className="design-item skeleton"
          containerClassName="design-list-container"
          count={limit}
          inline
        />
      )}
    </div>
  );
};

export default PublishedList;
