import React from 'react';
import {Button, Modal} from 'react-bootstrap';

interface RequiredProps {
  show: boolean;
  onAccept: () => void;
  onReject: () => void;
}

interface OptionalProps {
  className?: string;
  acceptText?: string;
  rejectText?: string;
  children?: React.ReactNode;
  title?: string;
}

type Props = RequiredProps & OptionalProps;

const ConfirmationDialog: React.FC<Props> = (props) => {
  return (
    <Modal
      className={props.className ? props.className : ''}
      backdropClassName={props.className ? `${props.className}-backdrop` : ''}
      centered
      show={props.show}
      onHide={() => props.onReject()}
    >
      <Modal.Header closeButton>
        {props.title && <Modal.Title>{props.title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{props.children ? props.children : null}</Modal.Body>

      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            props.onAccept();
          }}
        >
          {props.acceptText ? props.acceptText : 'Accept'}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            props.onReject();
          }}
        >
          {props.rejectText ? props.rejectText : 'Cancel'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
