import {Actions} from './getLinkedSlotActions';

export const colors = [
  {id: 0, name: 'White', hex: '#FFFFFF'},
  {id: 1, name: 'Black', hex: '#000000'},
  {id: 2, name: 'DimGray', hex: '#696969'},
  {id: 3, name: 'Gray', hex: '#808080'},
  {id: 4, name: 'DarkGray', hex: '#a9a9a9'},
  {id: 5, name: 'Silver', hex: '#c0c0c0'},
  {id: 6, name: 'LightGrey', hex: '#d3d3d3'},
  {id: 7, name: 'Gainsboro', hex: '#dcdcdc'},
  {id: 8, name: 'WhiteSmoke', hex: '#f5f5f5'},
  {id: 9, name: 'MintCream', hex: '#f5fffa'},
  {id: 10, name: 'HoneyDew', hex: '#f0fff0'},
  {id: 11, name: 'GhostWhite', hex: '#f8f8ff'},
  {id: 12, name: 'LightCyan', hex: '#e0ffff'},
  {id: 13, name: 'PaleTurquoise', hex: '#afeeee'},
  {id: 14, name: 'PowderBlue', hex: '#b0e0e6'},
  {id: 15, name: 'LightBlue', hex: '#add8e6'},
  {id: 16, name: 'LightSteelBlue', hex: '#b0c4de'},
  {id: 17, name: 'SlateGray', hex: '#708090'},
  {id: 18, name: 'LightSlateGray', hex: '#778899'},
  {id: 19, name: 'Teal', hex: '#008080'},
  {id: 20, name: 'LightSeaGreen', hex: '#20b2aa'},
  {id: 21, name: 'Turquoise', hex: '#40e0d0'},
  {id: 22, name: 'MediumTurquoise', hex: '#48d1cc'},
  {id: 23, name: 'MediumAquamarine', hex: '#66cdaa'},
  {id: 24, name: 'Aquamarine', hex: '#7fffd4'},
  {id: 25, name: 'DarkTurquoise', hex: '#00ced1'},
  {id: 26, name: 'Aqua', hex: '#00ffff'},
  {id: 27, name: 'DeepSkyBlue', hex: '#00bfff'},
  {id: 28, name: 'DodgerBlue', hex: '#1e90ff'},
  {id: 29, name: 'MidnightBlue', hex: '#191970'},
  {id: 30, name: 'Navy', hex: '#000080'},
  {id: 31, name: 'MediumBlue', hex: '#0000cd'},
  {id: 32, name: 'Blue', hex: '#0000ff'},
  {id: 33, name: 'RoyalBlue', hex: '#4169e1'},
  {id: 34, name: 'SteelBlue', hex: '#4682b4'},
  {id: 35, name: 'SkyBlue', hex: '#87ceeb'},
  {id: 36, name: 'LightSkyBlue', hex: '#87cefa'},
  {id: 37, name: 'Aliceblue', hex: '#f0f8ff'},
  {id: 38, name: 'Azure', hex: '#f0ffff'},
  {id: 39, name: 'Lavender', hex: '#e6e6fa'},
  {id: 40, name: 'MediumPurple', hex: '#9370d8'},
  {id: 41, name: 'MediumSlateBlue', hex: '#7b68ee'},
  {id: 42, name: 'SlateBlue', hex: '#6a5acd'},
  {id: 43, name: 'DarkSlateBlue', hex: '#483d8b'},
  {id: 44, name: 'Indigo', hex: '#4b0082'},
  {id: 45, name: 'RebeccaPurple', hex: '#663399'},
  {id: 46, name: 'BlueViolet', hex: '#8a2be2'},
  {id: 47, name: 'DarkViolet', hex: '#9400d3'},
  {id: 48, name: 'DarkOrchid', hex: '#9932cc'},
  {id: 49, name: 'MediumOrchid', hex: '#ba55d3'},
  {id: 50, name: 'Purple', hex: '#800080'},
  {id: 51, name: 'DarkMagenta', hex: '#8b008b'},
  {id: 52, name: 'Thistle', hex: '#d8bfd8'},
  {id: 53, name: 'Plum', hex: '#dda0dd'},
  {id: 54, name: 'Violet', hex: '#ee82ee'},
  {id: 55, name: 'Orchid', hex: '#da70d6'},
  {id: 56, name: 'Fuchsia', hex: '#ff00ff'},
  {id: 58, name: 'MediumVioletRed', hex: '#c71585'},
  {id: 59, name: 'DeepPink', hex: '#ff1493'},
  {id: 60, name: 'HotPink', hex: '#ff69b4'},
  {id: 61, name: 'PaleVioletRed', hex: '#d87093'},
  {id: 62, name: 'LightPink', hex: '#ffb6c1'},
  {id: 63, name: 'Pink', hex: '#ffc0cb'},
  {id: 64, name: 'MistyRose', hex: '#ffe4e1'},
  {id: 65, name: 'RosyBrown', hex: '#bc8f8f'},
  {id: 66, name: 'Salmon', hex: '#fa8072'},
  {id: 67, name: 'LightCoral', hex: '#f08080'},
  {id: 68, name: 'IndianRed', hex: '#cd5c5c'},
  {id: 69, name: 'FireBrick', hex: '#b22222'},
  {id: 70, name: 'Brown', hex: '#a52a2a'},
  {id: 71, name: 'DarkRed', hex: '#8b0000'},
  {id: 72, name: 'Maroon', hex: '#800000'},
  {id: 73, name: 'Red', hex: '#ff0000'},
  {id: 74, name: 'OrangeRed', hex: '#ff4500'},
  {id: 75, name: 'Tomato', hex: '#ff6347'},
  {id: 76, name: 'LightSalmon', hex: '#ffa07a'},
  {id: 77, name: 'DarkSalmon', hex: '#e9967a'},
  {id: 78, name: 'PeachPuff', hex: '#ffdab9'},
  {id: 79, name: 'LightGoldenrodYellow', hex: '#fafad2'},
  {id: 80, name: 'LemonChiffon', hex: '#fffacd'},
  {id: 81, name: 'PaleGoldenrod', hex: '#eee8aa'},
  {id: 82, name: 'Khaki', hex: '#f0e68c'},
  {id: 83, name: 'Gold', hex: '#ffd700'},
  {id: 84, name: 'Yellow', hex: '#ffff00'},
  {id: 85, name: 'GreenYellow', hex: '#adff2f'},
  {id: 86, name: 'LawnGreen', hex: '#7cfc00'},
  {id: 87, name: 'LimeGreen', hex: '#32cd32'},
  {id: 88, name: 'Lime', hex: '#00ff00'},
  {id: 89, name: 'SpringGreen', hex: '#00ff7f'},
  {id: 90, name: 'MediumSpringGreen', hex: '#00fa9a'},
  {id: 91, name: 'PaleGreen', hex: '#98fb98'},
  {id: 92, name: 'LightGreen', hex: '#90ee90'},
  {id: 93, name: 'DarkSeaGreen', hex: '#8fbc8f'},
  {id: 94, name: 'MediumSeaGreen', hex: '#3cb371'},
  {id: 95, name: 'SeaGreen', hex: '#2e8b57'},
  {id: 96, name: 'ForestGreen', hex: '#228b22'},
  {id: 97, name: 'Green', hex: '#008000'},
  {id: 98, name: 'DarkGreen', hex: '#006400'},
  {id: 100, name: 'DarkSlateGray', hex: '#2f4f4f'},
  {id: 101, name: 'DarkOliveGreen', hex: '#556b2f'},
  {id: 102, name: 'OliveDrab', hex: '#6b8e23'},
  {id: 103, name: 'Olive', hex: '#808000'},
  {id: 104, name: 'DarkKhaki', hex: '#bdb76b'},
  {id: 105, name: 'DarkGoldenrod', hex: '#b8860b'},
  {id: 106, name: 'Goldenrod', hex: '#daa520'},
  {id: 107, name: 'Orange', hex: '#ffa500'},
  {id: 108, name: 'DarkOrange', hex: '#ff8c00'},
  {id: 109, name: 'SandyBrown', hex: '#f4a460'},
  {id: 110, name: 'Peru', hex: '#cd853f'},
  {id: 111, name: 'Sienna', hex: '#a0522d'},
  {id: 112, name: 'SaddleBrown', hex: '#8b4513'},
  {id: 113, name: 'Tan', hex: '#d2b48c'},
  {id: 114, name: 'BurlyWood', hex: '#deb887'},
  {id: 115, name: 'NavajoWhite', hex: '#ffdead'},
  {id: 116, name: 'Moccasin', hex: '#ffe4b5'},
  {id: 117, name: 'Wheat', hex: '#f5deb3'},
  {id: 118, name: 'Bisque', hex: '#ffe4c4'},
  {id: 119, name: 'BlanchedAlmond', hex: '#ffebcd'},
  {id: 120, name: 'Antiquewhite', hex: '#faebd7'},
  {id: 121, name: 'Ivory', hex: '#fffff0'},
  {id: 122, name: 'Beige', hex: '#f5f5dc'},
  {id: 123, name: 'LightYellow', hex: '#ffffe0'},
  {id: 124, name: 'PapayaWhip', hex: '#ffefd5'},
  {id: 125, name: 'LavenderBlush', hex: '#fff0f5'},
  {id: 126, name: 'Snow', hex: '#fffafa'},
  {id: 127, name: 'Linen', hex: '#faf0e6'},
  {id: 128, name: 'OldLace', hex: '#fdf5e6'},
  {id: 129, name: 'SeaShell', hex: '#fff5ee'},
  {id: 130, name: 'FloralWhite', hex: '#fffaf0'},
];

export const getTextColorTitle = (
  slotId: number,
  action: string,
  order: number[],
) => {
  const slotName = `Slot ${order.indexOf(slotId) + 1}`;
  return `${slotName} (Text ${
    action === Actions.Fill ? 'fill' : 'stroke'
  } color)`;
};
