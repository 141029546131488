import React from 'react';

import Card from 'react-bootstrap/Card';
import ProductModalHeader from '../ProductModalHeader';
import Icon from '../Icons/Icon';
import {INextStep} from '@gfxco/contracts';

import './SetDesignType.scss';

interface RequiredProps {
  onNextStep: (params: INextStep) => void;
}

interface OptionalProps {
  onInputChange?: (name: string) => void;
  initialProductName?: string;
}

const TITLE = 'Name your design and select your design method';
const INPUT_NAME = 'Enter design name:';

type SetDesignTypeProps = RequiredProps & OptionalProps;

export const SetDesignType: React.FC<SetDesignTypeProps> = ({
  onNextStep,
  onInputChange,
  initialProductName = '',
}) => {
  const [productName, setProductName] = React.useState(initialProductName);
  const [isHoveringCard, setIsHoveringCard] = React.useState(false);

  React.useEffect(() => {
    if (productName === '') {
      setProductName(initialProductName);
    }
  }, [initialProductName]);

  const handleInputChange = (value: string) => {
    setProductName(value);
    if (onInputChange) {
      onInputChange(value);
    }
  };

  const handleOnClick = () => {
    if (productName.length) {
      const nextStep = {step: 2, data: {productName}};
      onNextStep(nextStep);
    }
  };

  return (
    <div className="SetDesignType">
      <ProductModalHeader
        title={TITLE}
        inputName={INPUT_NAME}
        handleInputChange={handleInputChange}
        centered
        inputDefaultValue={productName}
      />
      <div className="SetDesignType__Container">
        <Card
          className={`${
            productName.length ? 'SetDesignType__Card' : 'disabled'
          } SetDesignType__Card__ChooseProductType`}
          onClick={handleOnClick}
        >
          <Card.Body
            onMouseOver={() => {
              setIsHoveringCard(true);
            }}
            onMouseOut={() => {
              setIsHoveringCard(false);
            }}
          >
            <Icon
              name="chooseProductType"
              fillColor={{
                default: productName.length ? 'black' : '#d2d2d2',
                hover: productName.length ? '#4573BB' : '#d2d2d2',
              }}
              isHovering={isHoveringCard}
            />
            <Card.Title>Choose a product type</Card.Title>
          </Card.Body>
        </Card>
        <Card className="CreateCustomCanvaOption disabled">
          <Card.Body>
            <Icon name="createCustomCanva" />
            <Card.Title>Create Your Custom Design Canvas</Card.Title>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};
export default SetDesignType;
