import React from 'react';
import Icon from '../../Icons/Icon';
import {useGFXInstance} from '@best-apps/gfx-editor';

type AlignPosition =
  | 'center'
  | 'centerHorizontally'
  | 'centerVertically'
  | 'alignToTop';

type LeftToolbarProps = {
  setImageMapForDuplicated: (
    parentSlotUuid: string,
    parentImageId: number | null | undefined,
    newSlotUuid: string,
  ) => void;
  activateSaveWarning: () => void;
};

const LeftToolbarComponent: React.FC<LeftToolbarProps> = (props) => {
  const gfx = useGFXInstance();
  const [fillColor, setFillColor] = React.useState<string>('blue');
  const zoomIcon = gfx?.state.isZooming ? 'zoomOut' : 'zoomIn';
  const [operationStatus, setOperationStatus] = React.useState<string>('');
  const activeObject = gfx?.state.activeObject;
  React.useEffect(() => {
    if (gfx?.state.activeObject) {
      setFillColor('#4573BB');
    } else {
      setFillColor('#838383');
    }
  }, [gfx?.state.activeObject?.uuid]);

  const onFitToContainerClick = (action: string) => {
    if (action === 'fit-to-container') {
      return gfx?.actions.setFitToContainer(true);
    }
    return gfx?.actions.setFitToContainer(false);
  };

  const handleRotate = async (angle: number) => {
    if (gfx && gfx.state.activeObject && operationStatus === '') {
      const currentAngle = gfx.state.activeObject?.angle || 0;
      let newAngle = currentAngle + angle;
      if (angle === 0) {
        newAngle = 0;
      }
      setOperationStatus('rotating');
      await gfx?.actions.rotateActiveObject(newAngle, true);
      setOperationStatus('');
    }
  };

  const handleCenter = (centerMode: AlignPosition) => {
    if (gfx && gfx.state.activeObject) {
      gfx?.actions.alignActiveObject(centerMode);
    }
  };

  const toggleZoom = () => {
    gfx?.actions.toggleZoom();
  };

  const onDuplicateObject = async () => {
    const uuid = await gfx?.actions.duplicate();

    if (
      uuid &&
      activeObject.type === 'group' &&
      activeObject.objects.some((obj: any) => obj.type === 'image')
    ) {
      props.setImageMapForDuplicated(
        activeObject.uuid,
        activeObject.imageId || activeObject.shopImageId,
        uuid,
      );
    }

    props.activateSaveWarning();
  };

  const isActiveObjectABackground = gfx?.state?.activeObject?.isBackground;
  const disabledRotate =
    activeObject?.type === 'textbox' || isActiveObjectABackground;

  const disabledCopyOrDuplicate =
    gfx?.state?.activeObject === null || isActiveObjectABackground;

  const isFramedPoster = gfx?.state?.design.printableType === 'framedPoster';

  return (
    <div className="gfx-builder-toolbar left-builder-toolbar">
      {!isFramedPoster && (
        <div className="icon-group">
          <Icon
            name="undoAction"
            tooltip="Undo"
            action={() => null}
            classes="hidden"
          />
          <Icon
            name="redoAction"
            tooltip="Redo"
            action={() => null}
            classes="hidden"
          />
          <Icon name={zoomIcon} tooltip="Zoom" action={toggleZoom} />
        </div>
      )}
      <div className="icon-group">
        <Icon
          action={() => handleCenter('centerVertically')}
          name="centerVertically"
          tooltip="Center Vertically"
          box
          fillColor={{default: fillColor}}
        />
        <Icon
          action={() => handleCenter('centerHorizontally')}
          name="centerHorizontally"
          tooltip="Center Horizontally"
          box
          fillColor={{default: fillColor}}
        />
      </div>
      <div className="icon-group">
        <Icon
          action={async () => await handleRotate(30)}
          box
          tooltip="Rotate Right"
          name="rotateRight"
          disabled={disabledRotate}
          fillColor={{default: fillColor}}
        />
        <Icon
          action={async () => await handleRotate(-30)}
          box
          tooltip="Rotate Left"
          name="rotateLeft"
          disabled={disabledRotate}
          fillColor={{default: fillColor}}
        />
        <Icon
          action={async () => await handleRotate(0)}
          box
          tooltip="Rotate Restore"
          name="rotateRestore"
          disabled={disabledRotate}
          fillColor={{default: fillColor}}
        />
      </div>
      <div className="icon-group">
        <Icon
          box
          tooltip="Duplicate Object"
          action={onDuplicateObject}
          name="duplicate"
          // @ts-ignore
          disabled={disabledCopyOrDuplicate}
          fillColor={{default: fillColor}}
        />
      </div>

      <div className="icon-group">
        <Icon
          action={() => onFitToContainerClick('fit-to-container')}
          box
          tooltip="Fit to Container"
          name="fullScreen"
          disabled={
            gfx &&
            gfx.state &&
            gfx.state.activeObject &&
            gfx.state.activeObject.type === 'textbox'
          }
          fillColor={{default: fillColor}}
        />
        <Icon
          box
          tooltip="Crop to Container"
          action={() => onFitToContainerClick('crop-to-container')}
          name="backFullScreen"
          disabled={
            gfx &&
            gfx.state &&
            gfx.state.activeObject &&
            gfx.state.activeObject.type === 'textbox'
          }
          fillColor={{default: fillColor}}
        />
      </div>
    </div>
  );
};

export default LeftToolbarComponent;
