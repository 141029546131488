import React from 'react';
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import ImageDashboard from './ImageDashboard';
import ImagePage from '../../pages/ImagePage';
import CollectionsPage from '../../pages/CollectionsPage';
import SubcollectionPage from '../../pages/SubcollectionPage';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectShop} from '../../features/shops/selectedShop';
import DeleteImageCollectionWarn from './DeleteImageCollectionWarning';
import GFXTooltip from '../Tooltip/Tooltip';
import {GFXToastLaunch} from '../ToastMessage/ToastMessage';
import {deleteCollection} from '../../api';
import {IDeleteDependencies} from '@gfxco/contracts';
import imagesAPI from '../../api/images';
import {
  selectCollections,
  deleteCollection as deleteCollectionStore,
  deleteSubCollection as deleteSubCollectionStore,
  resetCollectionDetails,
} from '../../features/collections/loadCollectionsDetails';

import {reduceCollectionCount} from '../../features/collections/loadCollectionsCount';
import {resetImagesPages} from '../../features/images/loadImages';

interface IDeleteAssetsInfo {
  open: boolean;
  imageId?: number;
  dependsOn?: number[];
  collectionId?: number;
  confirmationMessage?: string;
  subCollectionAlertOpen?: boolean;
  entity?: 'image' | 'collection';
  loading?: boolean;
}

const ImagesDashboardRoot = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const shopSelected = useAppSelector(selectShop);
  const collections = useAppSelector(selectCollections);
  const [imageDeleted, setImageDeleted] = React.useState<number | undefined>();
  const shopId = shopSelected?.id;

  const [deleteAssetsInfo, setDeleteAssetsInfo] =
    React.useState<IDeleteAssetsInfo>({
      open: false,
      loading: false,
    });

  const handleOnDeleteImage = (id: number) => {
    if (!deleteAssetsInfo.open) {
      setDeleteAssetsInfo((prev) => ({
        open: true,
        imageId: id,
        entity: 'image',
      }));
    }
  };

  const handleOnDeleteCollection = (id: number) => {
    if (!deleteAssetsInfo.open) {
      const collection = collections?.find(
        (collection) => collection.id === id,
      );
      if (collection?.subCollections?.length) {
        return setDeleteAssetsInfo((prev) => ({
          ...prev,
          subCollectionAlertOpen: true,
          entity: 'collection',
        }));
      }

      setDeleteAssetsInfo((prev) => ({
        ...prev,
        open: true,
        collectionId: id,
      }));
    }
  };

  const handleConfirmDeleteCollection = () => {
    if (!shopId || (!deleteAssetsInfo.collectionId && deleteAssetsInfo.loading))
      return;

    const isSubcollection = collections?.find((collection) =>
      collection.subCollections?.find(
        (subCollection) => subCollection.id === deleteAssetsInfo.collectionId,
      ),
    );

    setDeleteAssetsInfo((prev) => ({
      ...prev,
      loading: true,
    }));
    deleteCollection(deleteAssetsInfo.collectionId!, shopId!).then((result) => {
      if (result?.status === 409 && result.data.depends.length) {
        return setDeleteAssetsInfo({
          open: false,
          collectionId: deleteAssetsInfo.collectionId,
          dependsOn: result.data.depends.map(
            (data: IDeleteDependencies) => data.template_id,
          ),
        });
      }

      if (result?.status === 200) {
        GFXToastLaunch('Collection Deleted', 5000, {
          showAt: 'bottom',
          showIcon: true,
          alertType: 'success',
        });

        if (location.pathname === '/designs/collections') {
          navigate('/designs/images');
        } else {
          navigate('/designs/collections');
        }

        if (isSubcollection) {
          dispatch(
            deleteSubCollectionStore({
              id: deleteAssetsInfo.collectionId!,
            }),
          );
        } else {
          dispatch(
            deleteCollectionStore({
              id: deleteAssetsInfo.collectionId!,
            }),
          );
          dispatch(reduceCollectionCount());
        }
      } else if (result?.status >= 500) {
        GFXToastLaunch('Something went wrong deleting your collection', 5000, {
          showAt: 'bottom',
          alertType: 'danger',
          showIcon: true,
        });
      }

      // Reset vars
      handleCloseDeleteModal();
    });
  };

  const handleCloseDeleteModal = () => {
    setDeleteAssetsInfo(() => ({
      open: false,
      loading: false,
      subCollectionAlertOpen: false,
      collectionId: undefined,
      confirmationMessage: undefined,
      entity: undefined,
      imageId: undefined,
      dependsOn: undefined,
    }));
  };

  const handleDeleteImageConfirmation = () => {
    if (!deleteAssetsInfo.imageId) return;
    setDeleteAssetsInfo((prev) => ({
      ...prev,
      loading: true,
    }));

    imagesAPI.deleteImage(deleteAssetsInfo.imageId!).then((result) => {
      if (result.status === 409) {
        return setDeleteAssetsInfo((prev) => ({
          ...prev,
          open: false,
          entity: 'image',
          dependsOn: result.data.depends.map(
            (data: IDeleteDependencies) => data.template_id,
          ),
        }));
      }

      if (result.status === 200) {
        GFXToastLaunch('Image Deleted', 3000, {
          showAt: 'bottom',
          showIcon: true,
          alertType: 'success',
        });
        dispatch(resetImagesPages());
        dispatch(resetCollectionDetails());
        setImageDeleted(deleteAssetsInfo.imageId);
      } else if (result.status === 400) {
        GFXToastLaunch(
          'You cannot delete the last Image of a Collection.',
          3000,
          {
            showAt: 'bottom',
            showIcon: true,
            alertType: 'info',
          },
        );
      } else if (result.status >= 500) {
        GFXToastLaunch('Something went wrong deleting your image', 5000, {
          showAt: 'bottom',
          alertType: 'danger',
          showIcon: true,
        });
      }
      handleCloseDeleteModal();
    });
  };

  const onAcceptConfirmation =
    deleteAssetsInfo.entity === 'image'
      ? handleDeleteImageConfirmation
      : handleConfirmDeleteCollection;

  const DeleteAssetToolTip = () => {
    if (!deleteAssetsInfo.open) return null;
    return (
      <GFXTooltip
        onClose={handleCloseDeleteModal}
        onAccept={onAcceptConfirmation}
        top="30%"
        left="50%"
        acceptText={deleteAssetsInfo.loading ? 'Deleting' : 'Delete'}
      >
        <div className="delete-tooltip-content">
          <h2 className="tooltip-title">Wait</h2>
          <p className="main-text">
            Deleting {deleteAssetsInfo.entity || 'Collections'} could make
            changes on your existing templates, stickers, exceptions and linked
            slots
          </p>
        </div>
      </GFXTooltip>
    );
  };

  const HasSubCollectionTooltip = () => {
    if (!deleteAssetsInfo.subCollectionAlertOpen) return null;
    return (
      <GFXTooltip
        onClose={handleCloseDeleteModal}
        onAccept={handleCloseDeleteModal}
        top="25%"
        left="50%"
        height="200px"
        acceptText="Ok"
      >
        <div className="delete-tooltip-content">
          <h3>Oops!</h3>
          <p className="main-text">
            This collection has sub-collections, please delete them first
          </p>
        </div>
      </GFXTooltip>
    );
  };

  const AlertsComponent = () => {
    return (
      <>
        <DeleteAssetToolTip />
        <HasSubCollectionTooltip />
        {deleteAssetsInfo.dependsOn && (
          <DeleteImageCollectionWarn
            dependences={deleteAssetsInfo.dependsOn || []}
            onClose={handleCloseDeleteModal}
            type={deleteAssetsInfo.entity || 'collection'}
          />
        )}
      </>
    );
  };

  return (
    <>
      <AlertsComponent />
      <Routes>
        <Route
          key={'/'}
          path="/"
          element={
            <ImageDashboard
              confirmImageDeleted={imageDeleted}
              handleOnDeleteCollection={handleOnDeleteCollection}
              handleOnDeleteImage={handleOnDeleteImage}
            />
          }
        >
          <Route
            key={'images'}
            path="images"
            element={<ImagePage handleOnDeleteImage={handleOnDeleteImage} />}
          />
          <Route path="collections" key={'/collections'}>
            <Route
              key={'/collections/all'}
              index
              element={
                <CollectionsPage
                  handleOnDeleteCollection={handleOnDeleteCollection}
                  handleOnDeleteImage={handleOnDeleteImage}
                />
              }
            />
            <Route path=":collection">
              <Route
                key={'/:collections'}
                index
                element={
                  <CollectionsPage
                    handleOnDeleteCollection={handleOnDeleteCollection}
                    handleOnDeleteImage={handleOnDeleteImage}
                  />
                }
              />
              <Route
                key={'/:subcollection'}
                path=":subcollection"
                element={
                  <SubcollectionPage
                    handleOnDeleteImage={handleOnDeleteImage}
                    handleOnDeleteCollection={handleOnDeleteCollection}
                  />
                }
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default ImagesDashboardRoot;
