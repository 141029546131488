import React from 'react';
import {useParams} from 'react-router-dom';
import useQueryParams from '../../../hooks/useQueryParams';

import CustomModal from '../../../components/CustomModal';
import CollectionsPanel from '../../../components/CollectionsPanel';
import CollectionsSelected from '../../../components/CollectionsPanel/CollectionsSelected';
import {GFXToastLaunch} from '../../../components/ToastMessage/ToastMessage';
import GFXTooltip from '../../../components/Tooltip/Tooltip';
import {CollectionSticker} from '../../../components/CollectionsPanel/types';
import Button from '../../../components/Button';

import {
  addStickerCollections,
  updateBulkStickerCollections,
  deleteBulkStickerCollections,
} from '../../../api';

import './StickersWizard.scss';

export interface RequiredProps {
  open: boolean;
  onClose: () => void;
  stickerCollections?: CollectionSticker[];
}
export interface OptionalProps {
  isEditing?: boolean;
  type?: string;
}

type StickersWizardProps = RequiredProps & OptionalProps;

const StickersWizard: React.FC<StickersWizardProps> = ({
  open,
  onClose,
  stickerCollections,
  type,
}) => {
  const params = useParams();
  const [queryParams, setQueryParams] = useQueryParams();
  const templateId = params.id;

  const [showUnsavedAlert, setShowUnsavedAlert] =
    React.useState<boolean>(false);

  const [collectionStickerSelected, setCollectionStickerSelected] =
    React.useState<CollectionSticker[]>(stickerCollections || []);

  const [stickersToDelete, setStickersToDelete] = React.useState<number[]>([]);
  const [stickersToUpdate, setStickersToUpdate] = React.useState<
    CollectionSticker[]
  >([]);

  React.useEffect(() => {
    if (stickerCollections && stickerCollections?.length) {
      setCollectionStickerSelected(stickerCollections);
    }
  }, [stickerCollections]);

  const handleOnDelete = (id: number) => {
    let itemToDelete: number[] = [];
    collectionStickerSelected.forEach((r) => {
      if (r.id === id && !!r.templateCollectionId) {
        itemToDelete = [...itemToDelete, r.id];
      }
    });

    const items = collectionStickerSelected?.filter((c) => c.id !== id);

    if (itemToDelete.length) {
      setStickersToDelete([...stickersToDelete, ...itemToDelete]);
    }

    setCollectionStickerSelected(items);
  };

  const handleSave = async () => {
    if (!templateId) return;

    const collectionsToSave = collectionStickerSelected.filter(
      (c) => !c.templateCollectionId,
    );

    if (
      !collectionsToSave.length &&
      !stickersToDelete.length &&
      !stickersToUpdate.length
    )
      return;
    try {
      let message = 'Your sticker collection has been successfully deleted';
      if (stickersToDelete.length) {
        await deleteBulkStickerCollections({
          ids: stickersToDelete,
          templateId: +templateId,
        });
      }

      if (collectionsToSave.length) {
        message = 'Your sticker collection has been successfully added';
        await addStickerCollections({
          templateId: +templateId,
          collections: collectionsToSave,
          type,
        });
      }

      if (stickersToUpdate.length) {
        message = 'Your sticker collection has been successfully updated';
        await updateBulkStickerCollections({
          templateId: +templateId,
          collections: stickersToUpdate,
        });
      }

      setCollectionStickerSelected([]);
      setStickersToDelete([]);
      setQueryParams({...queryParams, search: ''});
      onClose();
      GFXToastLaunch(message, 5000, {
        showAt: 'bottom',
        showIcon: true,
        alertType: 'success',
      });
    } catch (error) {
      console.error(error);
      GFXToastLaunch('Error', 5000, {
        showAt: 'bottom',
        showIcon: true,
        alertType: 'danger',
      });
    }
  };

  const handleOnSelect = (items: CollectionSticker[]) => {
    let itemsRemoved: number[] = [];
    collectionStickerSelected.forEach((r) => {
      if (!items.find((i) => i.name === r.name) && !!r.templateCollectionId) {
        itemsRemoved = [...itemsRemoved, r.id];
      }
    });

    if (itemsRemoved.length) {
      setStickersToDelete([...stickersToDelete, ...itemsRemoved]);
    }
    setCollectionStickerSelected(items);
  };

  const handleOnClose = () => {
    const changesUnsaved =
      !!collectionStickerSelected.filter((i) => !i.templateCollectionId)
        .length ||
      !!stickersToDelete.length ||
      !!stickersToUpdate.length;

    if (changesUnsaved) {
      return setShowUnsavedAlert(true);
    }

    setCollectionStickerSelected([]);
    setStickersToDelete([]);
    setQueryParams({...queryParams, search: ''});
    onClose();
  };

  const handleOnChangeSort = async (index: number, newIndex: number) => {
    let list = [...collectionStickerSelected];
    const newSort = list[newIndex].sortOrder;
    const sort = list[index].sortOrder;
    const listToUpdate: CollectionSticker[] = [];

    list = list.map((l, i) => {
      if (i === newIndex) {
        if (l.templateCollectionId) {
          listToUpdate.push({...l, sortOrder: sort});
        }
        return {...l, sortOrder: sort};
      }
      if (i === index) {
        if (l.templateCollectionId) {
          listToUpdate.push({...l, sortOrder: newSort});
        }
        return {...l, sortOrder: newSort};
      }
      return l;
    });

    list = swapElements(list, index, newIndex);
    setCollectionStickerSelected(list);
    setStickersToUpdate(listToUpdate);
  };

  const swapElements = (
    list: CollectionSticker[],
    index1: number,
    index2: number,
  ) => {
    list[index1] = list.splice(index2, 1, list[index1])[0];
    return list;
  };

  return (
    <CustomModal
      variant="grey"
      sizeType="middle-page"
      className="fonts-modal"
      isSaveEnabled={false}
      modalTile={`Add sticker collection`}
      onCloseModal={handleOnClose}
      show={open}
    >
      <div id="StickersModalBody">
        <div className="stickers-selector">
          <div className="first_col">
            <p className="select-title">Available collections:</p>
            <CollectionsPanel
              onSave={handleOnSelect}
              collectionStickers={collectionStickerSelected}
            />
          </div>
          <div className="second_col">
            <p className="select-title">Selected collections:</p>
            <CollectionsSelected
              collections={collectionStickerSelected}
              onDelete={handleOnDelete}
              onSort={handleOnChangeSort}
            />
            <div className="selected-box-footer">
              <Button
                className="collections-save-button"
                disabled={
                  !collectionStickerSelected.filter(
                    (i) => !i.templateCollectionId,
                  ).length &&
                  !stickersToDelete.length &&
                  !stickersToUpdate.length
                }
                onClick={() => handleSave()}
              >
                Save stickers
              </Button>
            </div>
            {showUnsavedAlert && (
              <GFXTooltip
                onAccept={() => setShowUnsavedAlert(false)}
                width="22rem"
                height="auto"
                top="0%"
                left="65%"
              >
                <div className="alert-message">
                  <span>
                    There are unsaved changes, any unsaved changes will be lost.
                  </span>
                </div>
              </GFXTooltip>
            )}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default StickersWizard;
