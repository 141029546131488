import React from 'react';
import {ELoadingStates} from '@gfxco/contracts';
import {OrderCard} from '../../../components/OrderCard';
import {OrderModal} from '../../../components/OrderModal';
import Loading from '../../../components/Loading';

import {IModerationOrderExtended} from '../../../features/moderation/loadModerationOrders';

import './ModerationContent.scss';

type ModerationContentProps = {
  orders: IModerationOrderExtended[];
  ordersFetchStatus: ELoadingStates;
  printerFetchStatus: ELoadingStates;
  printerStatus: boolean;
  allSelected?: boolean;
  onSelectItem?: (itemId: number, selected: boolean) => void;
  onReset: () => void;
};

const ModerationContent: React.FC<ModerationContentProps> = (props) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [itemSelected, setItemSelected] = React.useState<number>();
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const handleViewDesign = (itemId: number) => {
    const index = props.orders.findIndex((o) => o.itemId === itemId);
    setItemSelected(itemId);
    setCurrentIndex(index);
    setOpenModal(true);
  };

  const handleNextViewDesign = () => {
    if (typeof currentIndex === 'undefined') return;
    const newOrderToView = props.orders[currentIndex + 1];
    if (newOrderToView) {
      setCurrentIndex(currentIndex + 1);
      setItemSelected(newOrderToView.itemId);
    }
  };

  const handlePreviousViewDesign = () => {
    if (typeof currentIndex === 'undefined') return;
    const newOrderToView = props.orders[currentIndex - 1];
    if (newOrderToView) {
      setCurrentIndex(currentIndex - 1);
      setItemSelected(newOrderToView.itemId);
    }
  };

  return (
    <>
      <OrderModal
        open={openModal}
        closeModal={() => {
          setOpenModal(false);
          props.onReset();
        }}
        itemSelected={props.orders.find(
          (order) => order.itemId === itemSelected,
        )}
        onNext={handleNextViewDesign}
        onPrevious={handlePreviousViewDesign}
        currentIndex={currentIndex}
        totalOrders={props.orders.length}
        printerStatus={props.printerStatus}
        printerFetchStatus={props.printerFetchStatus}
      />

      {props.ordersFetchStatus === ELoadingStates.IDLE && <></>}

      <section className="moderationByShopPage__ordersList">
        {props.orders.map((order, index: number) => (
          <OrderCard
            key={`order-${index}`}
            viewDesignAction={handleViewDesign}
            item={order}
            selected={order.selected}
            onSelectChange={(selected) => {
              if (props.onSelectItem) {
                props.onSelectItem(order.itemId, selected);
              }
            }}
          />
        ))}
      </section>
      {props.ordersFetchStatus === ELoadingStates.LOADING && <Loading />}
      {props.ordersFetchStatus === ELoadingStates.FAILED && <span>Error</span>}
    </>
  );
};

export default ModerationContent;
