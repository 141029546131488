import {
  ELoadingStates,
  ICollectionsRequest,
  ICollectionResponse,
} from '@gfxco/contracts';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';

import {getCollections, getSubCollections} from '../../api/collections';
import {RootState} from '../../app/store';

const Collections = new schema.Entity('collections');
const collectionsSchema = {collections: [Collections]};
export interface CollectionState {
  entities: {[id: number]: ICollectionResponse};
  pages: {[page: number]: number[]};
  pageFetchStatus: {[offset: number]: string};
  subCollectionsFetchStatus: string;
  total: number;
}

const initialState: CollectionState = {
  entities: {},
  pages: {},
  pageFetchStatus: {},
  subCollectionsFetchStatus: 'idle',
  total: 0,
};

export const getCollectionsAsync = createAsyncThunk(
  'collections/fetch',
  async (parameters: ICollectionsRequest & {page: number}) => {
    const response = await getCollections(parameters);

    const normalizedData = normalize(
      {collections: response?.results},
      collectionsSchema,
    );

    return {
      entities: normalizedData.entities.collections,
      pages: normalizedData.result.collections,
      totalCollections: response?.total,
    };
  },
);

export const getSubCollectionsAsync = createAsyncThunk(
  'collections/fetch_subcollections',
  async (params: {shopId: number; collectionId: number}) => {
    const response = await getSubCollections(
      params.shopId,
      params.collectionId,
    );

    return response;
  },
);

export const loadCollections = createSlice({
  name: 'AllCollections',
  initialState,
  reducers: {
    resetCollections: (state) => {
      state = initialState;
      return state;
    },
    resetCollectionsPages: (state) => {
      state.pages = {};
      state.pageFetchStatus = {};
      state.total = 0;

      return state;
    },
    resetSubCollectionsFetchStatus: (state) => {
      state.subCollectionsFetchStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionsAsync.pending, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] = ELoadingStates.LOADING;
      })
      .addCase(getCollectionsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.pageFetchStatus[action.meta.arg.page] = ELoadingStates.LOADED;
          state.entities = {...state.entities, ...action.payload.entities};
          state.pages[action.meta.arg.page] = action.payload.pages;
          state.total = action.payload.totalCollections || 0;
        }
      })
      .addCase(getCollectionsAsync.rejected, (state, action) => {
        state.pageFetchStatus[action.meta.arg.page] = ELoadingStates.FAILED;
      })
      .addCase(getSubCollectionsAsync.pending, (state) => {
        state.subCollectionsFetchStatus = ELoadingStates.LOADING;
      })
      .addCase(getSubCollectionsAsync.fulfilled, (state, action) => {
        state.subCollectionsFetchStatus = ELoadingStates.LOADED;
        state.entities[action.meta.arg.collectionId] = {
          ...state.entities[action.meta.arg.collectionId],
          subCollections: action.payload,
        };
      })
      .addCase(getSubCollectionsAsync.rejected, (state) => {
        state.subCollectionsFetchStatus = ELoadingStates.FAILED;
      });
  },
});

export const getEntityById = (state: RootState, id: number) => {
  return state.collections.entities[id];
};

export const getCollectionsByPage = (state: RootState, page: number) => {
  const ids = state.collections.pages[page] || [];
  if (ids.length > 0) {
    return ids.map((id) => getEntityById(state, id));
  } else {
    return [];
  }
};

export const fetchStatusByPage = (state: RootState, page: number) => {
  return state.collections.pageFetchStatus[page] || ELoadingStates.IDLE;
};

export const selectTotalCollections = (state: RootState) => {
  return state.collections.total || 0;
};

export const getSubcollectionsFetchStatus = (state: RootState) => {
  return state.collections.subCollectionsFetchStatus || 'idle';
};

export const {
  resetCollections,
  resetCollectionsPages,
  resetSubCollectionsFetchStatus,
} = loadCollections.actions;
export default loadCollections.reducer;
