import React from 'react';
import {RouteProps, Outlet} from 'react-router-dom';
import {PublicDashboard} from './components/Dashboard';

const PublicRoute: React.FC<RouteProps> = () => {
  return (
    <PublicDashboard>
      <Outlet />
    </PublicDashboard>
  );
};

export default PublicRoute;
