import * as Contracts from '@gfxco/contracts';
import {loadClient, handleError} from './api';

export const getStickerImages = async (params: {templateId: number}) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.IImagesResponse>(
      `/templates/${params.templateId}/collections/images`,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getStickerCollections = async (params: {
  templateId: number;
  offset?: number;
  limit?: number;
}) => {
  try {
    const client = await loadClient();
    const response = await client.get<Contracts.ITemplateCollectionResponse>(
      `/templates/${params.templateId}/collections`,
      {
        params: {offset: params.offset, limit: params.limit},
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const addStickerCollections = async (params: {
  templateId: number;
  collections: Contracts.ITemplateCollection[];
  type?: string;
}) => {
  try {
    const client = await loadClient();
    const bodyParams = {
      collections: params.collections,
      type: params.type,
    };
    const response = await client.post<any>(
      `/templates/${params.templateId}/collections`,
      bodyParams,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateBulkStickerCollections = async (params: {
  templateId: number;
  collections: Contracts.ITemplateCollection[];
}) => {
  try {
    const client = await loadClient();
    const bodyParams = {
      collections: params.collections,
    };
    const response = await client.put<any>(
      `/templates/${params.templateId}/collections`,
      bodyParams,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteBulkStickerCollections = async (params: {
  ids: number[];
  templateId: number;
}) => {
  try {
    const client = await loadClient();

    const response = await client.delete<any>(
      `/templates/${params.templateId}/collections`,
      {data: {ids: params.ids}},
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
