import React from 'react';
import './FontsSizePicker.scss';

type FontsSizePicker = {
  startCount: number;
  currentSize: number;
  count: number;
  onSizeClick: (size: number) => void;
};

const FontSizePicker: React.FC<FontsSizePicker> = (props) => {
  const arraySize = Array.from(new Array(props.count));
  return (
    <div className="fonts-size-picker">
      <div className="current-size-section">
        <p>{props.currentSize}</p>
      </div>
      <div className="fonts-size-picker__content">
        {arraySize.map((_, i) => {
          const number = i + 1 + props.startCount;
          return (
            <button onClick={() => props.onSizeClick(number)} key={number}>
              {number}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default FontSizePicker;
