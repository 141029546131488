import React from 'react';

import './Image.scss';

interface IImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  placeholderSrc?: string;
  defaultImageSrc?: string;
  fallbackSrc?: string;
}

const GFXImage: React.FC<IImageProps> = (props) => {
  const defaultImage = props.defaultImageSrc ?? '';

  const [imgSrc, setImgSrc] = React.useState(props.placeholderSrc || props.src);
  const [customClass, setCustomClass] = React.useState(
    props.placeholderSrc && imgSrc === props.placeholderSrc
      ? 'loading'
      : 'loaded',
  );

  const includeSkeleton = !props.placeholderSrc && customClass === 'loading';

  const addDefaultImage = (
    evt: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    evt.currentTarget.src = defaultImage;
    evt.currentTarget.onerror = null; // prevents looping
  };

  React.useEffect(() => {
    const img = new Image();
    img.src = props.src ?? '';

    setImgSrc(props.placeholderSrc || props.src);
    setCustomClass(
      props.placeholderSrc && imgSrc === props.placeholderSrc
        ? 'loading'
        : 'loaded',
    );

    img.onload = () => {
      setImgSrc(props.src);
    };

    img.onerror = () => {
      setImgSrc(props.fallbackSrc);
    };
  }, [props.src]);

  const imageProps = {...props};
  delete imageProps.placeholderSrc;
  delete imageProps.defaultImageSrc;

  return (
    <div
      className={`gfx-image ${customClass} ${
        includeSkeleton ? 'skeleton' : ''
      } ${props.className || ''}`}
    >
      <img
        {...imageProps}
        src={imgSrc}
        onError={addDefaultImage}
        style={{
          backgroundImage: defaultImage ? 'url(' + defaultImage + ')' : 'none',
        }}
        loading="lazy"
      />
    </div>
  );
};

export default React.memo(GFXImage);
