import {Link} from 'react-router-dom';
import GFXTooltip from '../../components/Tooltip/Tooltip';

interface DeleteFontWarningProps {
  dependencies: number[];
  onClose: () => void;
}

const DeleteFontWarning = (props: DeleteFontWarningProps) => {
  const Dependencies = () => {
    const url = '/designs/edit';
    const dependenciesToShow = props.dependencies.slice(0, 5);
    return (
      <ul>
        {dependenciesToShow.map((dep, index) => (
          <li key={index}>
            <Link to={`${url}/${dep}`}># {dep}</Link>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <GFXTooltip
      onAccept={props.onClose}
      onClose={props.onClose}
      acceptText="Got it"
      width="45rem"
      height="auto"
      top="25%"
      left="40%"
    >
      <div className="delete-image-collection-warning">
        <div className="title">
          <h2>Hold On!</h2>
        </div>
        <div className="content">
          <p>
            Looks like the font you are trying to delete is already being used
            in the following templates:
          </p>
          <Dependencies />
          <p>
            To continue, <b>remove from Slots.</b> The font(s) from the
            template(s) mentioned above must be removed before they can be
            deleted.
          </p>
          <p>
            <b>Attention:</b> This action cannot be undone. Deleting fonts could
            make changes to your existing templates.
          </p>
        </div>
      </div>
    </GFXTooltip>
  );
};

export default DeleteFontWarning;
