import React from 'react';

import MyStoreForm from '../../../../components/MyStoreForm';

import {useAppSelector} from '../../../../app/hooks';
import {selectShop} from '../../../../features/shops/selectedShop';

interface OptionalProps {
  className?: string;
}

type Props = OptionalProps;

const StoreSection: React.FC<Props> = (props) => {
  const shopSelected = useAppSelector(selectShop);
  if (!shopSelected) return <></>;

  return (
    <div className={`store_section ${props.className}`}>
      <MyStoreForm shop={shopSelected} />
    </div>
  );
};

export default StoreSection;
