import React from 'react';
import {createRoot} from 'react-dom/client';
import {Toast} from 'react-bootstrap';

import './ToastMessage.scss';
import classNames from 'classnames';
import Icon from '../Icons/Icon';

interface RequiredProps {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
}

interface OptionalProps {
  delay?: number;
}

type ToastMessageProps = RequiredProps & OptionalProps;

const ToastMessage: React.FC<ToastMessageProps> = (props) => {
  return (
    <Toast
      id="toast-message"
      onClose={() => props.onClose()}
      show={props.show}
      delay={props.delay || 2000}
      autohide
    >
      <Toast.Body>{props.children}</Toast.Body>
    </Toast>
  );
};

interface ILaunchMessageOptions {
  top?: string;
  left?: string;
  right?: string;
  size?: 'sm' | 'md' | 'lg';
  containerClassName?: string;
  parentContainerId?: string;
  showAt?: 'top' | 'bottom';
  alertType?: 'success' | 'danger' | 'info' | 'none';
  showIcon?: boolean;
  onDelayCompleted?: () => void;
}

export const GFXToastLaunch = (
  message: string | React.ReactNode,
  delay?: number,
  options?: ILaunchMessageOptions,
) => {
  const parent = document.getElementById(
    options?.parentContainerId || 'Dashboard',
  );

  const clases = classNames({
    'toast-message-container': true,
    'toast-message-container--danger': options?.alertType === 'danger',
    'toast-message-container--success': options?.alertType === 'success',
    'toast-message-container--info': options?.alertType === 'info',
    'toast-message-container--lg': options?.size === 'lg',
  });

  if (parent) {
    const tempDiv = document.createElement('div');
    const temporalStyles = {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      position: 'absolute',
    };

    if (options?.showAt === 'bottom') {
      temporalStyles.bottom = '3.5rem';
      temporalStyles.right = '3.5rem';
    }

    if (options?.showAt === 'top') {
      temporalStyles.top = '3.5rem';
      temporalStyles.left = '3.5rem';
    }

    if (options?.right) {
      temporalStyles.right = options?.right;
      temporalStyles.left = 'auto';
    }

    if (options?.top) {
      temporalStyles.top = options?.top;
      temporalStyles.bottom = 'auto';
    }

    if (options?.left) {
      temporalStyles.left = options?.left;
      temporalStyles.right = 'auto';
    }

    clases.split(' ').forEach((clase) => {
      tempDiv.classList.add(clase);
    });

    tempDiv.style.zIndex = '9999';
    tempDiv.style.top = temporalStyles.top;
    tempDiv.style.left = temporalStyles.left;
    tempDiv.style.right = temporalStyles.right;
    tempDiv.style.bottom = temporalStyles.bottom;
    tempDiv.style.position = temporalStyles.position;

    const Icons = {
      success: <Icon name="checkIcon" />,
      danger: <Icon name="xIcon" />,
      info: <Icon name="infoIcon" />,
      none: null,
    };

    const container = createRoot(tempDiv);
    const ToastComponent = (
      <ToastMessage
        show={true}
        delay={delay || 2000}
        onClose={() => {
          container.unmount();
          parent.removeChild(tempDiv);
          if (options?.onDelayCompleted) {
            options?.onDelayCompleted();
          }
        }}
      >
        <div className="alert-internal">
          {options?.showIcon && Icons[options?.alertType || 'none']}
          {typeof message === 'string' ? <p>{message}</p> : message}
        </div>
      </ToastMessage>
    );
    container.render(ToastComponent);
    parent.appendChild(tempDiv);
  }
};

export default ToastMessage;
