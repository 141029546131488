import React from 'react';
import {ChevronDown} from 'react-bootstrap-icons';
import Nav from 'react-bootstrap/Nav';

import {Actions} from '../../../libs/getLinkedSlotActions';
import './SlotsMenu.scss';

export interface RequiredProps {
  onChangeTab: (tab: string) => void;
  type: string;
}
export interface OptionalProps {}

type SlotsMenuProps = RequiredProps & OptionalProps;

export const SlotsMenu: React.FC<SlotsMenuProps> = ({onChangeTab, type}) => {
  return (
    <div id="SlotsMenu">
      <div className="menu">
        <span className="title">Types of linked slots</span>
        <Nav key={type} variant="underline" className="flex-column content">
          <Nav.Item className="link" onClick={() => onChangeTab('all')}>
            <Nav.Link
              eventKey={'all'}
              className={`${!type || type === 'all' ? 'active' : ''}`}
            >
              <span>All linked slots</span>
              <ChevronDown />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="link" onClick={() => onChangeTab(Actions.Color)}>
            <Nav.Link
              eventKey={Actions.Color}
              className={`${type === Actions.Color ? 'active' : ''}`}
            >
              <span>Item Colors</span>
              <ChevronDown />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="link" onClick={() => onChangeTab(Actions.Slot)}>
            <Nav.Link
              eventKey={Actions.Slot}
              className={`${type === Actions.Slot ? 'active' : ''}`}
            >
              <span>Images Slots</span>
              <ChevronDown />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="link" onClick={() => onChangeTab('text')}>
            <Nav.Link
              eventKey="text"
              className={`${type === 'text' ? 'active' : ''}`}
            >
              <span>Text slots</span>
              <ChevronDown />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </div>
  );
};

export default SlotsMenu;
