import React from 'react';
import useQueryParams from '../../hooks/useQueryParams';
import {useParams} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import {ChevronDown, Search, Plus} from 'react-bootstrap-icons';
import debounce from 'lodash.debounce';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import usePagination from '../../hooks/usePagination';
import {ReactComponent as NotFound} from '../../assets/icons/not-found.svg';
import Chips from '../../components/Chips';
import {selectShop} from '../../features/shops/selectedShop';

import {
  getExceptionsAsync,
  selectExceptions,
  selectTotalExceptions,
  selectExceptionsFetchStatus,
  resetExceptions,
} from '../../features/exceptions/loadExceptions';

import Loading from '../../components/Loading';
import SortLabel from '../../components/SortLabel';
import CustomPagination from '../../components/CustomPagination';
import {
  IColor,
  ELoadingStates,
  GetTemplateByIdResponse,
  IExceptionResult,
  IGetExceptionsRequest,
} from '@gfxco/contracts';
import './ExceptionsPage.scss';
import ExceptionList from './ExceptionList';
import ExceptionWizard from './ExceptionWizard';
import Alert from 'react-bootstrap/Alert';

export interface RequiredProps {
  garmentColors: IColor[];
  hideExceptions: boolean;
  onUpdateTemplate: (canvaTemplate: Partial<GetTemplateByIdResponse>) => void;
  isUpdating: boolean;
}
export interface OptionalProps {
  children?: React.ReactNode;
}

type ExceptionsPageProps = RequiredProps & OptionalProps;

const getLabels = (searchBy: string, type: string) => {
  const labels = [];
  if (type === 'tag') labels.push(`Type: collections`);
  else if (type === 'all') labels.push(`Type: All exceptions`);
  else labels.push(`Type: ${type}`);

  if (searchBy.length) labels.push(`Search By: ${searchBy}`);
  return labels;
};

export const ExceptionsPage: React.FC<ExceptionsPageProps> = (props) => {
  const shopSelected = useAppSelector(selectShop);
  const dispatch = useAppDispatch();
  const shopId = shopSelected?.id;
  const [{offset, currentPage, limit}, handlePagination] = usePagination();

  const params = useParams();
  const {id} = params;

  const exceptions = useAppSelector(selectExceptions);
  const totalExceptions = useAppSelector(selectTotalExceptions);
  const exceptionsFetchStatus = useAppSelector((state) =>
    selectExceptionsFetchStatus(state),
  );

  const [queryParams, setQueryParams] = useQueryParams();
  const type = queryParams?.exceptionType || 'all';
  const sortBy = queryParams.sortBy || 'newest';
  const [searchBy, setSearch] = React.useState('');
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [exceptionToEdit, setExceptionToEdit] =
    React.useState<IExceptionResult>({});
  const [isEditModal, setIsEditModal] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<string[]>([`Type: ${type}`]);

  React.useEffect(() => {
    if (exceptionsFetchStatus === ELoadingStates.IDLE) {
      getTemplateExceptions();
    }
  }, [shopId, offset, exceptionsFetchStatus, limit]);

  React.useEffect(() => {
    handlePagination('reset');
    dispatch(resetExceptions());
    const labels = getLabels(searchBy, type);
    setFilters(labels);
  }, [sortBy, searchBy, queryParams.exceptionType]);

  const getTemplateExceptions = async () => {
    if (!id) return;
    const params: IGetExceptionsRequest = {
      shopId,
      templateId: +id,
      offset,
      limit,
      sortBy: sortBy === 'newest' ? 'desc' : 'asc',
    };

    if (type && type !== 'all') params.exceptionType = type;
    if (searchBy.length) params.searchBy = searchBy;
    dispatch(getExceptionsAsync(params));
  };

  const handleSortChange = (selectedOption: any) => {
    setQueryParams({...queryParams, sortBy: selectedOption.value});
  };

  const handleEdit = (exception: IExceptionResult) => {
    setExceptionToEdit(exception);
    setIsEditModal(true);
    setShowModal(true);
  };

  const handleSwitchTab = (type: string) => {
    setQueryParams({...queryParams, exceptionType: type});
  };

  const handleOnReload = () => {
    if (type !== 'all') {
      return handleSwitchTab('all');
    }
    handlePagination('reset');
    dispatch(resetExceptions());
  };

  const handleDeleteFilter = (key: string) => {
    const value = key.split(':');
    if (value.length) {
      if (value[0] === 'Search By') setSearch('');
    }
  };

  const isEmptyNotFound = !exceptions?.length && !!searchBy.length;

  return (
    <div id="ExceptionsPage">
      <Row>
        <Col sm={3}>
          <div className="manage-container">
            <Button className="btn" onClick={() => setShowModal(true)}>
              + Add an Exception
            </Button>
          </div>
        </Col>
        <Col sm={9}>
          <div className="list-toolbar">
            <div className="filter-box">
              <InputGroup className="search-bar-group search-bar-list">
                <InputGroup.Text>
                  <Search />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search exceptions by collection, color or image name"
                  onChange={debounce((event) => {
                    setSearch(event.target.value);
                  }, 500)}
                  aria-label="SearchBar to filter exceptions"
                />
              </InputGroup>
              <div className="filters-selected">
                <Chips
                  defaultValue={filters}
                  showInput={false}
                  onDelete={handleDeleteFilter}
                  staticValue={[`Type: ${type}`]}
                />
                <span className="total-filtered">
                  {totalExceptions} exceptions found
                </span>
              </div>
            </div>

            <SortLabel
              handleSortChange={handleSortChange}
              className="select-sort-list"
            />
          </div>
        </Col>
      </Row>
      <Tab.Container activeKey={type}>
        <Row>
          <Col sm={3} className="navigation">
            <div className="menu">
              <span className="title">Types of exceptions</span>
              <Nav variant="underline" className="flex-column content">
                <Nav.Item
                  className="link"
                  onClick={() => handleSwitchTab('all')}
                >
                  <Nav.Link
                    eventKey="all"
                    className={`${type === 'all' ? 'active' : ''}`}
                  >
                    <span>All exceptions</span>
                    <ChevronDown />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className="link"
                  onClick={() => handleSwitchTab('tag')}
                >
                  <Nav.Link
                    eventKey="tag"
                    className={`${type === 'tag' ? 'active' : ''}`}
                  >
                    <span>Collections</span>
                    <ChevronDown />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className="link"
                  onClick={() => handleSwitchTab('image')}
                >
                  <Nav.Link
                    eventKey="image"
                    className={`${type === 'image' ? 'active' : ''}`}
                  >
                    <span>Images</span>
                    <ChevronDown />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className="link"
                  onClick={() => handleSwitchTab('color')}
                >
                  <Nav.Link
                    eventKey="color"
                    className={`${type === 'color' ? 'active' : ''}`}
                  >
                    <span>Colors</span>
                    <ChevronDown />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <div className="vertical-divider"></div>
          </Col>
          <Col sm={9}>
            <Tab.Content
              className={`${
                isEmptyNotFound
                  ? 'empty-not-found'
                  : !exceptions?.length
                  ? 'empty-box'
                  : ''
              }`}
            >
              {exceptionsFetchStatus === ELoadingStates.FAILED && (
                <Alert>Error loading Exceptions</Alert>
              )}
              {exceptionsFetchStatus === ELoadingStates.LOADING && (
                <Loading text="Loading template Exceptions..." />
              )}
              {exceptionsFetchStatus === ELoadingStates.LOADED &&
                !!exceptions?.length && (
                  <Tab.Pane eventKey={type}>
                    <ExceptionList
                      colors={props.garmentColors}
                      sortBy={sortBy}
                      searchBy={searchBy}
                      onEdit={handleEdit}
                      onReload={getTemplateExceptions}
                    />
                    <CustomPagination
                      limit={limit}
                      currentPage={currentPage}
                      total={totalExceptions}
                      handlePagination={handlePagination}
                      entity={'Exceptions'}
                    />
                  </Tab.Pane>
                )}
              {exceptionsFetchStatus === ELoadingStates.LOADED &&
                !exceptions?.length &&
                !!searchBy.length && (
                  <div className="not-found-box">
                    <NotFound />
                    <span className="not-found-title">Exception not found</span>
                    <span className="not-found-text">{`Looks like the exception you're looking for does not exist,`}</span>
                    <span className="not-found-text">please try again</span>
                  </div>
                )}
              {exceptionsFetchStatus === ELoadingStates.LOADED &&
                !exceptions?.length &&
                !searchBy.length && (
                  <div
                    className="empty-box-content"
                    onClick={() => setShowModal(true)}
                  >
                    <div className="add-icon">
                      <Plus size="1.5rem" />
                    </div>
                    <span>Add an</span>
                    <span>Exception</span>
                  </div>
                )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <ExceptionWizard
        open={showModal}
        colors={props.garmentColors}
        onClose={() => {
          setShowModal(false);
          setExceptionToEdit({});
          setIsEditModal(false);
        }}
        exception={exceptionToEdit}
        onReload={handleOnReload}
        isEdit={isEditModal}
      />
    </div>
  );
};

export default ExceptionsPage;
