import React from 'react';
import debounce from 'lodash.debounce';
import {useParams} from 'react-router-dom';
import {Plus, Search} from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import useQueryParams from '../../hooks/useQueryParams';
import usePagination from '../../hooks/usePagination';
import SlotsApi from '../../api/slots';
import {selectShop} from '../../features/shops/selectedShop';
import {
  selectLinkedSlots,
  selectLinkedSlotsFetchstatus,
  selectTotalLinkedSlots,
  resetLinkedSlots,
  getLinkedSlotsAsync,
} from '../../features/linkedSlots/loadLinkedSlots';
import {
  IGetLinkedSlotsRequest,
  ILegacyLinkedSlotsResult,
  IColor,
  SideCanva,
  ILinkedSlots,
  ELoadingStates,
} from '@gfxco/contracts';
import ActionItem from './ActionItem';

import {Actions} from '../../libs/getLinkedSlotActions';
import CustomPagination from '../CustomPagination';
import Chips from '../../components/Chips';
import SortLabel from '../SortLabel';
import SlotsMenu from './SlotsMenu';
import {colors as defaultTextColors} from '../../libs/getDefaultTextColors';
import {ReactComponent as NotFound} from '../../assets/icons/not-found.svg';
import {ReactComponent as CheckIcon} from '../../assets/icons/check-circule-icon.svg';
import LinkedSlotWizard from './LinkedSlotWizard';

import './LinkedSlot.scss';
import Loading from '../Loading';
import {GFXToastLaunch} from '../ToastMessage/ToastMessage';

export interface RequiredProps {
  garmentColors: IColor[];
  slots: number[];
  frontSlots: SideCanva;
  backSlots: SideCanva;
}
export interface OptionalProps {
  text?: string;
  children?: React.ReactNode;
}

type LinkedSlotsProps = RequiredProps & OptionalProps;

const LABELS = {
  text: 'Text slots',
  all: 'All linked slots',
  [Actions.Color]: 'Item Colors',
  [Actions.Slot]: ' Images Slots',
};

const getLabels = (
  searchBy: string,
  type: 'text' | Actions.Color | Actions.Slot | 'all',
) => {
  const labels = [];
  labels.push(`Type: ${LABELS[type]}`);

  if (searchBy.length) labels.push(`Search By: ${searchBy}`);
  return labels;
};

export const LinkedSlots: React.FC<LinkedSlotsProps> = (props) => {
  const [queryParams, setQueryParams] = useQueryParams();
  const dispatch = useAppDispatch();
  const sortBy = queryParams.sortBy || 'newest';
  const type = queryParams?.type || 'all';
  const search = queryParams.search || '';
  const [{offset, currentPage, limit}, handlePagination] = usePagination();

  const shopSelected = useAppSelector(selectShop);
  const shopId = shopSelected?.id;
  const params = useParams();
  const {id} = params;

  const [showModal, setShowModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<string[]>([`Type: ${type}`]);

  const linkedSlots = useAppSelector((state) => selectLinkedSlots(state));
  const totalLinkedSlots = useAppSelector((state) =>
    selectTotalLinkedSlots(state),
  );
  const LinkedSlotsStatus = useAppSelector((state) =>
    selectLinkedSlotsFetchstatus(state),
  );

  const [linkedSlotToEdit, setLinkedSlotToEdit] =
    React.useState<ILinkedSlots>();

  const timeoutRef = React.useRef<number | null>(null);

  React.useEffect(() => {
    if (LinkedSlotsStatus === ELoadingStates.IDLE) {
      const params: IGetLinkedSlotsRequest = {
        shopId,
        templateId: +id!,
        offset,
        limit,
        type,
        sortBy: sortBy === 'newest' ? 'desc' : 'asc',
      };
      if (search.length) params.searchBy = search;
      dispatch(getLinkedSlotsAsync(params));
    }
  }, [shopId, offset, LinkedSlotsStatus, limit]);

  React.useEffect(() => {
    handleReset();
    const labels = getLabels(search, type);
    setFilters(labels);
  }, [queryParams.sortBy, queryParams.search, queryParams.type]);

  const handleReset = () => {
    handlePagination('reset');
    dispatch(resetLinkedSlots());
  };

  const handleOnReload = () => {
    handleOnSucess();
    if (type !== 'all') {
      return handleSwitchTab('all');
    }
    handleReset();
  };

  const handleOnDelete = async (linkedSlotId: number) => {
    try {
      await SlotsApi.deleteLinkedSlot(linkedSlotId);
      dispatch(resetLinkedSlots());
    } catch (error) {
      console.log(error);
      setErrorMessage('Error deleting a linked slot');
    }
  };

  const handleOnEdit = (linkedSlotId: number) => {
    if (!linkedSlots) return;

    const linkedSlot = linkedSlots.find(
      (linkedSlot) => linkedSlot.id === linkedSlotId,
    );
    if (linkedSlot) {
      setLinkedSlotToEdit(linkedSlot as ILinkedSlots);
      setShowModal(true);
    }
  };

  const handleSearchChange = (search: string) => {
    setQueryParams({...queryParams, search});
  };

  const handleSortChange = (selectedOption: any) => {
    setQueryParams({...queryParams, sortBy: selectedOption.value});
  };

  const getColor = (linkedSlot: ILegacyLinkedSlotsResult) => {
    if (linkedSlot.comparison === Actions.Color) {
      return props.garmentColors.find(
        (color) => color.name === linkedSlot.color,
      );
    }

    return defaultTextColors.find((color) => color.hex === linkedSlot.text);
  };

  const handleOnSucess = () => {
    setSuccessMessage(true);
    timeoutRef.current = window.setTimeout(() => {
      setSuccessMessage(false);
    }, 2000);
  };

  const handleSwitchTab = (type: string) => {
    setQueryParams({...queryParams, type});
  };

  const handleDeleteFilter = (key: string) => {
    const value = key.split(':');
    if (value.length) {
      if (value[0] === 'Search By') handleSearchChange('');
    }
  };

  const handleError = (err?: string) => {
    err = err || 'unknown';

    const ErrorMapMessages = {
      ActionAlreadyExists: (
        <p>
          <b>Attention:</b> The slot you’re trying to link is already linked to
          another rule. <b>Please try again with a different one</b>
        </p>
      ),
      unknown: (
        <p>
          <b>Error:</b> Your linked slot could not have been updated.
        </p>
      ),
    } as {[key: string]: React.ReactNode};

    const message = ErrorMapMessages[err] || ErrorMapMessages.unknown;

    return GFXToastLaunch(message, 4000, {
      parentContainerId: 'toast-container-notifications',
      showIcon: true,
      alertType: err === 'unknown' ? 'danger' : 'info',
      right: '1rem',
      showAt: 'bottom',
      size: 'lg',
    });
  };

  return (
    <div id="LinkedSlot">
      <div className="linked-action-box" id="toast-container-notifications">
        <div className="manage-container">
          <Button className="btn" onClick={() => setShowModal(true)}>
            Manage Linked Slots
          </Button>

          <SlotsMenu onChangeTab={handleSwitchTab} type={type} />
        </div>
        <div className="vertical-divider"></div>
      </div>
      <div className="content-box">
        <div className="list-toolbar">
          <div className="filter-box">
            <InputGroup className="search-bar-group search-bar-list">
              <InputGroup.Text>
                <Search />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder={'Search linked slots'}
                onChange={debounce((event) => {
                  handleSearchChange(event.target.value);
                }, 500)}
                aria-label="SearchBar to filter linked slots"
              />
            </InputGroup>
            <div className="filters-selected">
              <Chips
                defaultValue={filters}
                showInput={false}
                onDelete={handleDeleteFilter}
                staticValue={[`Type: ${type}`]}
              />
              <span className="total-filtered">
                {totalLinkedSlots} linked slots found
              </span>
            </div>
          </div>
          <SortLabel
            handleSortChange={handleSortChange}
            className="select-sort-list"
          />
        </div>
        {LinkedSlotsStatus === ELoadingStates.LOADING && (
          <Loading text="Loading linked slots..." />
        )}

        {LinkedSlotsStatus === ELoadingStates.LOADED &&
          !!linkedSlots?.length &&
          linkedSlots.map((linkedSlot) => (
            <ActionItem
              key={linkedSlot.id}
              comparison={linkedSlot.comparison}
              text={linkedSlot.text}
              image={linkedSlot.image}
              color={getColor(linkedSlot)}
              actions={linkedSlot.actions}
              frontSlots={props.frontSlots?.objects || []}
              backSlots={props.backSlots?.objects || []}
              slotId={linkedSlot.slotId}
              linkedSlotId={linkedSlot.id}
              onDelete={handleOnDelete}
              onEdit={handleOnEdit}
              colors={props.garmentColors}
            />
          ))}

        {LinkedSlotsStatus === ELoadingStates.LOADED &&
          !linkedSlots?.length &&
          !!search.length && (
            <div className="empty-box">
              <div className="not-found-box">
                <NotFound />
                <span className="not-found-title">Linked slot not found</span>
                <span className="not-found-text">{`Looks like the linked slot you're looking for does not exist,`}</span>
                <span className="not-found-text">please try again</span>
              </div>
            </div>
          )}

        {LinkedSlotsStatus === ELoadingStates.LOADED &&
          !linkedSlots?.length &&
          !search.length && (
            <div className="empty-box">
              <div
                className="empty-box-content"
                onClick={() => setShowModal(true)}
              >
                <div className="add-icon">
                  <Plus size="1.5rem" />
                </div>
                <span>Create a</span>
                <span>Linked Slot</span>
              </div>
            </div>
          )}
        <CustomPagination
          limit={limit}
          currentPage={currentPage}
          total={totalLinkedSlots}
          handlePagination={handlePagination}
          entity="Linked Slots"
        />
      </div>

      <Alert variant="danger" show={errorMessage !== ''}>
        {errorMessage}
      </Alert>
      {successMessage && (
        <div className="confirmation-tooltip success-tooltip">
          <CheckIcon />
          <div>
            <span>Your linked slots has been</span>
            <span className="success-text"> updated</span>
            <span> successfully</span>
          </div>
        </div>
      )}
      <LinkedSlotWizard
        open={showModal}
        colors={props.garmentColors}
        onClose={() => {
          setShowModal(false);
          setLinkedSlotToEdit(undefined);
        }}
        linkedSlot={linkedSlotToEdit}
        onReload={handleOnReload}
        frontSlots={props.frontSlots?.objects || []}
        backSlots={props.backSlots?.objects || []}
        onError={handleError}
      />
    </div>
  );
};

export default LinkedSlots;
