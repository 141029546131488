import React from 'react';
import Icon from '../Icons/Icon';
import './CustomPagination.scss';
import classNames from 'classnames';
interface RequiredProps {
  limit: number;
  total: number;
  currentPage: number;
  handlePagination: (action: string, page?: number) => void;
}
interface OptionalProps {
  entity?: string;
  maxPages?: number;
  skipInfo?: boolean;
}

type CustomPaginationProps = RequiredProps & OptionalProps;

const PaginationItem = (props: any) => {
  const {active, children, onClick} = props;
  const clases = classNames(
    'gfx-pagination-item',
    active && 'gfx-pagination-item--active',
  );

  return (
    <p className={clases} onClick={onClick}>
      {children}
    </p>
  );
};

const GFXCustomPagination: React.FC<CustomPaginationProps> = (props) => {
  const {
    limit,
    total,
    currentPage,
    handlePagination,
    skipInfo = false,
    entity = 'Orders',
  } = props;

  let totalPages = Math.ceil(total / limit);

  if (total === 0) {
    totalPages = 1;
  }

  const pages = [];
  for (let i = 0; i < totalPages; i++) {
    pages.push(i);
  }

  const totalLoaded = Math.min(limit * currentPage, total);

  const lastPage = pages.splice(-1)[0];
  const start = currentPage - 1 > 1 ? currentPage - 1 : 1;
  let end = currentPage + 1 < pages.length ? currentPage + 1 : pages.length;

  if (props.maxPages) {
    end = start + props.maxPages;
  }

  const handlePaginationClick = (
    evt: React.SyntheticEvent,
    action: string,
    page?: number,
  ) => {
    evt.stopPropagation();
    handlePagination(action, page);
  };

  return (
    <div id="GFXCustomPagination">
      <div className="info-pagination">
        {!skipInfo && (
          <span className="pagination-count">{`${totalLoaded} out of ${total} ${entity}`}</span>
        )}
      </div>
      <div className="pagination-content">
        <Icon
          box
          fillColor={{default: '#4573bb', disabled: '#d6d6d6'}}
          name="leftIcon"
          disabled={currentPage === 1}
          action={(e) => handlePaginationClick(e, 'prev')}
        />
        {pages.slice(start - 1, end).map((number) => (
          <PaginationItem
            key={number}
            onClick={(e: React.SyntheticEvent) =>
              handlePaginationClick(e, 'page', number)
            }
            active={currentPage === number + 1}
          >
            {(number || 0) + 1}
          </PaginationItem>
        ))}
        {end !== pages.length && <p>…</p>}
        <PaginationItem
          key={lastPage}
          onClick={(e: React.SyntheticEvent) =>
            handlePaginationClick(e, 'page', lastPage)
          }
          active={currentPage === lastPage + 1}
        >
          {(lastPage || 0) + 1}
        </PaginationItem>
        <Icon
          box
          fillColor={{default: '#4573bb', disabled: '#d6d6d6'}}
          name="rightIcon"
          action={(e) => handlePaginationClick(e, 'next')}
          disabled={currentPage === lastPage + 1}
        />
      </div>
    </div>
  );
};

export default GFXCustomPagination;
