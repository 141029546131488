import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ImageAction from '../../../../components/ShareActions/ImageAction';
import GarmentColor from '../../../../components/ShareActions/GarmentColor';
import {ChevronDown, ChevronUp, XLg, Pencil} from 'react-bootstrap-icons';
import Tags from '../../../../components/ShareActions/Tags';
import HideExceptions from './HideExceptions';
import {IColor, IImage, IException, IExceptionResult} from '@gfxco/contracts';
import './ExceptionItem.scss';

export interface RequiredProps {
  colors: IColor[];
  type: 'tag' | 'image' | 'color' | 'fillColor';
  exceptions: IException[];
  onDelete: (params: {id?: number | number[]}) => void;
  onEdit: (exception: IExceptionResult) => void;
}
export interface OptionalProps {
  children?: React.ReactNode;
  tag?: string;
  colorName?: string;
  image?: IImage;
}

type ExceptionItemProps = RequiredProps & OptionalProps;

export const ExceptionItem: React.FC<ExceptionItemProps> = ({
  type,
  colorName,
  image,
  tag,
  exceptions,
  colors,
  onDelete,
  onEdit,
}) => {
  const imageExceptions = exceptions.filter(({type}) => type === 'image');
  const tagExceptions = exceptions.filter(({type}) => type === 'tag');
  const fillColorExceptions = exceptions.filter(
    ({type}) => type === 'fillColor',
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const showExpand = exceptions.length > 1;

  const tagsCount = tagExceptions.length;
  const imagesCount = imageExceptions.length;
  const fillColorsCount = fillColorExceptions.length;

  const showTagExceptions = !!tagsCount && (!showExpand || open);
  const showImageExceptions = !!imagesCount && (!showExpand || open);
  const showFillColorExceptions = !!fillColorsCount && (!showExpand || open);

  const handleDelete = () => {
    const exceptionsIds = exceptions.map((exception) => exception.id!);
    if (exceptionsIds) onDelete({id: exceptionsIds});
  };

  const handleOnEdit = () => {
    const exception = {
      type,
      color: colorName,
      image,
      imageId: image?.id,
      tag,
      exceptions,
    };

    onEdit(exception);
  };

  return (
    <div id="ExceptionItem">
      <div className="actions">
        <div className="container first-action">
          <div className="description">
            <span className="action-text">IF </span>
            <span className="action-subtitle-text">
              (when the user selects this)
            </span>
          </div>
          <div className="item">
            {type === 'tag' && tag && <Tags tagExceptionName={tag} />}

            {type === 'color' && colorName && (
              <GarmentColor
                color={colors.find((color) => color.name === colorName)}
                name={colorName}
              />
            )}
            {type === 'image' && (
              <ImageAction
                url={image?.imageUrl}
                name={image?.name}
                showSlot={false}
              />
            )}
          </div>
        </div>

        <div className="container second-action">
          <div className="description">
            <span className="action-text">HIDE </span>
            <span className="action-subtitle-text">
              (it will not reflect this)
            </span>
          </div>
          <div className="item">
            <Container fluid>
              <Row className="align-items-center justify-content-center">
                {showExpand && !open && (
                  <div className="hidden-items">
                    {!!tagsCount && (
                      <div className="hidden-item">
                        <span className="hidden-title">
                          Hidden collections:
                        </span>
                        <div className="hidden-content">
                          <span className="hidden-details">{`${tagsCount} Tags`}</span>
                        </div>
                      </div>
                    )}
                    {!!imagesCount && (
                      <div className="hidden-item">
                        <span className="hidden-title">Hidden images:</span>
                        <div className="hidden-content">
                          {imagesCount > 1 && (
                            <ImageAction
                              url={imageExceptions[0].image?.imageUrl}
                              name={imageExceptions[0].image?.name}
                              showSlot={false}
                            />
                          )}
                          <span className="hidden-details">
                            {imagesCount > 1
                              ? `and ${imagesCount - 1} more`
                              : `${imagesCount} Images`}
                          </span>
                        </div>
                      </div>
                    )}
                    {!!fillColorsCount && (
                      <div className="hidden-item">
                        <span className="hidden-title">Hidden color:</span>
                        <div className="hidden-content">
                          {fillColorsCount > 1 &&
                            fillColorExceptions[0].fillColor && (
                              <GarmentColor
                                color={{
                                  hex: fillColorExceptions[0].fillColor,
                                  name: fillColorExceptions[0].fillColor,
                                  id: 0,
                                }}
                                showName={false}
                              />
                            )}
                          <span className="hidden-details">
                            {fillColorsCount > 1
                              ? `and ${fillColorsCount - 1} more`
                              : `${fillColorsCount} Colors`}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {showTagExceptions && (
                  <HideExceptions
                    colors={colors}
                    title={'Hidden Collections'}
                    exceptions={tagExceptions}
                    onDelete={onDelete}
                  />
                )}

                {showImageExceptions && (
                  <HideExceptions
                    colors={colors}
                    title={'Hidden Images'}
                    exceptions={imageExceptions}
                    onDelete={onDelete}
                  />
                )}

                {showFillColorExceptions && (
                  <HideExceptions
                    colors={colors}
                    title={'Hidden Fill colors'}
                    exceptions={fillColorExceptions}
                    onDelete={onDelete}
                  />
                )}
              </Row>
            </Container>
          </div>
        </div>

        <div className="icon-container">
          <div onClick={() => setOpen(!open)} className="icon">
            {open ? <ChevronUp /> : <ChevronDown />}
          </div>

          {open && (
            <div className="icon" onClick={() => handleOnEdit()}>
              <Pencil />
            </div>
          )}

          {open && (
            <div className="icon" onClick={() => handleDelete()}>
              <XLg />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExceptionItem;
