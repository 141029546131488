import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {
  selectShopSubscription,
  selectShop,
  postCreateSubscriptionAsync,
} from '../../features/shops/selectedShop';

import './SubscriptionPage.scss';
import StripePaymentForm from '../../components/Stripe/StripePayment';
import StripeSubscription from './StripeSubscription';
import ShopifySubscription from './ShopifySubscription';

export default function SubscriptionPage() {
  const shopSubscription = useAppSelector(selectShopSubscription);
  const shopSelected = useAppSelector(selectShop);
  const dispatch = useAppDispatch();

  const handleCreteSubscription = (paymentMethodId: string) => {
    if (
      paymentMethodId &&
      ![undefined, null, ''].includes(paymentMethodId) &&
      shopSelected
    ) {
      dispatch(postCreateSubscriptionAsync(paymentMethodId));
    }
  };

  return (
    <div id="SubscriptionPage">
      {shopSubscription && shopSubscription.stripe_subscription_token && (
        <StripeSubscription subscription={shopSubscription} />
      )}
      {shopSubscription && shopSubscription.shopify_subscription_id && (
        <ShopifySubscription subscription={shopSubscription} />
      )}
      {!shopSubscription && (
        <StripePaymentForm onPaymentMethodCreated={handleCreteSubscription} />
      )}
    </div>
  );
}
