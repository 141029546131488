import React from 'react';
import {useNavigate, useMatch} from 'react-router-dom';
import {useAppDispatch} from '../../../app/hooks';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Search} from 'react-bootstrap-icons';
import debounce from 'lodash.debounce';

import {ReactComponent as ImageIcon} from '../../../assets/icons/image-icon.svg';
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder-icon.svg';
import {ReactComponent as ImagePlusIcon} from '../../../assets/icons/image-plus-icon.svg';
import useQueryParams from '../../../hooks/useQueryParams';
import uploadImage, {
  IImageFile,
  updateFileName,
} from '../../../libs/uploadImage';
import {updateStateImages} from '../../../features/images/loadImages';
import {
  addCollectionImageCount,
  addSubCollectionImageCount,
} from '../../../features/collections/loadCollectionsDetails';

import SortLabel from '../../SortLabel';
import FileInput from '../../FileInput';

import './ImageFilters.scss';

interface RequiredProps {}

interface OptionalProps {
  shop?: number;
}
type ImageFiltersProps = RequiredProps & OptionalProps;
const DEFAULT_LIMITS = {
  width: 6000,
  height: 6000,
  weight: 50,
};

const ImageFilters: React.FC<ImageFiltersProps> = ({shop}) => {
  const match = useMatch({path: '*'});
  const [queryParams, setQueryParams] = useQueryParams();
  const path = match?.pathname || 'images';
  const tab = path.includes('images') ? 'images' : 'collections';
  const dispatch = useAppDispatch();

  const collectionId = queryParams.collectionId;
  const subcollectionId = queryParams.subId;

  const [tabActive, setTabActive] = React.useState<'images' | 'collections'>(
    tab,
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    const order = queryParams.sortBy || 'newest';
    const search = queryParams.search || '';

    setQueryParams({...queryParams, order, search});
  }, []);

  const handleSearchChange = (search: string) => {
    setQueryParams({...queryParams, search});
  };

  const handleSortChange = (selectedOption: any) => {
    setQueryParams({...queryParams, sortBy: selectedOption.value});
  };

  const handleTabChange = (tab: 'images' | 'collections') => {
    setTabActive(tab);
    setQueryParams({sortBy: 'newest', search: ''});
  };

  const handlImageChange = async (file: File) => {
    const newFileName = updateFileName(file.name, file.type);
    return uploadImage({
      file,
      fileName: newFileName,
      shopId: shop!,
      fileFolder: 'images',
      collectionId: subcollectionId || collectionId,
      limits: DEFAULT_LIMITS,
    });
  };

  const handleImageUploaded = (imagesFile: IImageFile[]) => {
    dispatch(updateStateImages(imagesFile));
    const imagesAddedCount = imagesFile.filter((i) => !i.isError).length;
    if (!subcollectionId) {
      dispatch(
        addCollectionImageCount({collectionId, count: imagesAddedCount}),
      );
      return;
    }

    dispatch(
      addSubCollectionImageCount({
        collectionId,
        subcollectionId,
        count: imagesAddedCount,
      }),
    );
  };

  return (
    <div id="ImageFilters">
      <div className="list-toolbar">
        <button
          className={`button-tab images-tab ${
            tabActive === 'images' ? 'images-tab-active' : ''
          }`}
          onClick={() => {
            handleTabChange('images');
            navigate('/designs/images');
          }}
        >
          <ImageIcon /> <span>View Images</span>
        </button>
        <button
          className={`button-tab collections-tab ${
            tabActive === 'collections' ? 'collections-tab-active' : ''
          }`}
          onClick={() => {
            handleTabChange('collections');
            navigate('/designs/collections');
          }}
        >
          <FolderIcon /> <span>View Collections</span>
        </button>
        <InputGroup className="search-bar-group search-bar-list">
          <InputGroup.Text>
            <Search />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search by name"
            onChange={debounce((event) => {
              handleSearchChange(event.target.value);
            }, 500)}
            aria-label="SearchBar to filter by design templates name"
          />
        </InputGroup>
        <div
          className="button-tab images-tab button-tooltip"
          data-tooltip={`${
            collectionId || subcollectionId
              ? 'Add images'
              : 'To upload images please go to your collections'
          }`}
        >
          <FileInput
            text={''}
            onFileChange={handlImageChange}
            onFileLoaded={handleImageUploaded}
            icon={<ImagePlusIcon />}
            disabled={!collectionId && !subcollectionId}
          />
        </div>
        <SortLabel
          handleSortChange={handleSortChange}
          className="select-sort-design-templates"
        />
      </div>
    </div>
  );
};

export default ImageFilters;
