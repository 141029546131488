import {IColor, IImage, IExceptionResult, IException} from '@gfxco/contracts';
import React from 'react';
import {useParams} from 'react-router-dom';
import GFXModals from '../../../components/Modal';
import FirstStatement from './FirstStatement';
import ImagesApi from '../../../api/images';
import TemplateApi from '../../../api/templateApi';
import ExceptionsApi from '../../../api/exceptions';
import HideStatement from './HideStatement';
import {IExceptionNextStep, ITagList} from './types';
import Alert from 'react-bootstrap/Alert';

export interface RequiredProps {
  open: boolean;
  onClose: () => void;
  colors: IColor[];
  exception: IExceptionResult;
}
export interface OptionalProps {
  isEdit?: boolean;
  onReload?: () => void;
  setDefaultTab?: (param: string) => void;
}

type ExceptionsPageProps = RequiredProps & OptionalProps;

export const ExceptionModal: React.FC<ExceptionsPageProps> = ({
  colors,
  open,
  onClose,
  onReload,
  exception = {},
  isEdit = false,
}) => {
  const params = useParams();
  const {id} = params;
  const [isLoading, setIsLoading] = React.useState(false);
  const [tags, setTags] = React.useState<ITagList[]>([]);
  const [images, setImages] = React.useState<IImage[]>([]);
  const [newException, setNewException] =
    React.useState<IExceptionResult>(exception);
  const [selectedStep, setSelectedStep] = React.useState(1);
  const [errorWarning, setErrorWarning] = React.useState('');

  React.useEffect(() => {
    if (!isLoading) {
      getTagsImages();
    }
  }, [id]);

  React.useEffect(() => {
    const defaultStep = Object.keys(exception).length ? 2 : 1;
    setSelectedStep(defaultStep);
    setNewException(exception);
  }, [exception]);

  const getTagsImages = async () => {
    try {
      setIsLoading(true);
      const params: any = {
        templateId: +id!,
      };

      const tags = await ImagesApi.getTemplateImagesTags(params);
      const images = await TemplateApi.getTemplateImages(params);

      if (tags && images) {
        setImages(images.results);
        setTags(tags.results);
      }
    } catch (error) {
      console.error(error);
      setErrorWarning('Error loading tags and images to create exceptions');
    } finally {
      setIsLoading(false);
    }
  };

  const onNextStep = (params: IExceptionNextStep) => {
    if (selectedStep === 1) {
      setNewException(params.data.exception);
    } else {
      setNewException((oldData) => {
        if (oldData) return {...oldData, ...params.data.exception};
        return params.data.exception;
      });
    }

    if (params.step) setSelectedStep(params.step);
  };

  const handleSaveClick = async () => {
    if (!newException || !id) return;
    try {
      const body: IExceptionResult = {
        color: newException.color,
        imageId: newException.imageId,
        tag: newException.tag,
        type: newException.type,
        exceptions: newException.exceptions,
        templateId: +id,
      };
      if (!isEdit) await ExceptionsApi.addTemplateExceptions(body);
      else handleEditException();

      if (onReload) onReload();
      handleCloseModal();
    } catch (error) {
      setErrorWarning('Error creating new template exceptions');
      console.log(error);
    }
  };

  const handleEditException = async () => {
    const currentExceptionList = exception.exceptions;
    const newExceptionList = newException.exceptions;

    const itemsRemoved = currentExceptionList?.filter(
      (item) =>
        !newExceptionList?.some((newItem) => item.id && newItem.id === item.id),
    );

    const itemsAdded = newExceptionList?.filter((newItem) => !newItem.id);

    if (itemsRemoved?.length) await removeExceptions(itemsRemoved);
    if (itemsAdded?.length) {
      const body: IExceptionResult = {
        color: newException.color,
        imageId: newException.imageId,
        tag: newException.tag,
        type: newException.type,
        exceptions: itemsAdded,
        templateId: +id!,
      };
      try {
        await ExceptionsApi.addTemplateExceptions(body);
      } catch (error) {
        setErrorWarning('Error creating template exceptions');
        console.log(error);
      }
    }
    if (onReload) onReload();
    handleCloseModal();
  };

  const removeExceptions = async (exceptions: IException[]) => {
    const exceptionIds = exceptions.map(({id}) => id!);
    try {
      if (!id) return;
      const params = {templateId: +id, exceptionIds};
      await ExceptionsApi.deleteBulkExceptions(params);
    } catch (error) {
      setErrorWarning('Error deleting template exceptions');
      console.log(error);
    }
  };

  const isAllSet = () =>
    !!newException?.exceptions && !!newException.exceptions.length;

  const steps = [
    {
      name: 'If Statement',
      component: (
        <FirstStatement
          colors={colors}
          tags={tags}
          images={images}
          exception={newException}
          onNextStep={onNextStep}
        />
      ),
    },
    {
      name: 'Hide Statement',
      component: (
        <HideStatement
          colors={colors}
          tags={tags}
          images={images}
          exception={newException}
          onNextStep={onNextStep}
        />
      ),
    },
  ];

  const handleBackClick = () => {
    if (selectedStep === 1) {
      return;
    }

    setSelectedStep(selectedStep - 1);
  };

  const handleCloseModal = () => {
    setSelectedStep(1);
    setNewException({});
    onClose();
  };

  const handleNextClick = () => {
    if (newException?.exceptions?.length) setSelectedStep(selectedStep + 1);
  };

  return (
    <div id="ExceptionWizard" className="exception-modal">
      {!!errorWarning.length && <Alert>{errorWarning}</Alert>}
      <GFXModals.StepsModal
        showSteps={false}
        isSaveEnabled={selectedStep === 2 && isAllSet()}
        onCloseModal={handleCloseModal}
        isProgressWarningEnabled={false}
        show={open}
        steps={steps}
        selectedStepNumber={selectedStep}
        onClickBack={handleBackClick}
        onClickSave={handleSaveClick}
        onClickNext={handleNextClick}
        isNextEnabled={selectedStep > 1 && isAllSet()}
        showNextButton={selectedStep === 1}
        showSaveButton={selectedStep === 2}
        isLoading={false}
        nextStepLabel={'Preview'}
        saveLabel={'Save'}
        centered
        isBackDisabled={isEdit && selectedStep === 2}
      />
    </div>
  );
};

export default ExceptionModal;
