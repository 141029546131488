import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {IColor} from '@gfxco/contracts';
import classnames from 'classnames';
import {capitalizeFirstLetter} from '../../../libs/formatUtils';
interface ColorItemProps {
  color: Omit<IColor, 'name'> & {name?: string};
  onClick: (color: IColor) => void;
  selected: boolean;
  markBorder?: boolean;
  size?: string;
  hover?: boolean;
}

const ColorItem: React.FC<ColorItemProps> = ({
  color,
  selected,
  onClick,
  markBorder,
  hover,
  size,
}) => {
  const sizeClass = size || '';

  const classes = {
    [sizeClass]: !!size,
    'color-item': true,
    bordered: markBorder,
    'color-hover': hover,
    selected,
  };

  return (
    <OverlayTrigger
      key={color.name || color.hex}
      overlay={
        <Tooltip id={`${color.name || color.hex}`} className="color-tooltip">
          {color.name && capitalizeFirstLetter(color.name)} {color.hex}
        </Tooltip>
      }
    >
      <button
        className={`${classnames(classes)}`}
        onClick={(evt) => {
          evt.preventDefault();
          onClick(color as IColor);
        }}
        style={{backgroundColor: color.hex}}
        role="button"
        aria-label={`${color.name || color.hex} color`}
      />
    </OverlayTrigger>
  );
};

export default ColorItem;
