import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ThreeDots, ChevronDown, ChevronUp} from 'react-bootstrap-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import useQueryParams from '../../../hooks/useQueryParams';
import {ReactComponent as LockIcon} from '../../../assets/icons/lock-icon.svg';
import {ReactComponent as ImageIcon} from '../../../assets/icons/image-fill-icon.svg';
import {ReactComponent as ArrowRight} from '../../../assets/icons/arrow-down-right.svg';
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder-open-icon.svg';
import GFXImage from '../../Image/Image';
import {ICollectionEntity, ICollectionResponse} from '@gfxco/contracts';
import './CollectionItem.scss';

interface RequiredProps {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  largeImageUrl: string;
  active: boolean;
}

interface OptionalProps {
  subCollections?: ICollectionEntity[];
  onClick?: (id: number) => void;
  imagesCount?: string;
  onDeleteCollection: (id: number) => void;
  openModal?: (params?: {
    parentId?: number;
    collection?: ICollectionResponse;
  }) => void;
  locked?: boolean;
}
type NavigationListProps = RequiredProps & OptionalProps;

const CollectionItem: React.FC<NavigationListProps> = ({
  id,
  name,
  description,
  imageUrl,
  largeImageUrl,
  imagesCount,
  subCollections,
  active,
  onDeleteCollection,
  onClick,
  openModal,
  locked = false,
}) => {
  const [queryParams] = useQueryParams();
  const subcollectionId = queryParams.subId;
  const subIdSelected = subcollectionId ? +subcollectionId : undefined;
  const [open, setOpen] = React.useState<boolean>(active);
  const [subcollectionSelected, setSubCollectionSelected] = React.useState<
    number | undefined
  >(subIdSelected);
  const navigate = useNavigate();

  React.useEffect(() => {
    setOpen(active);
    setSubCollectionSelected(undefined);
  }, [active]);

  React.useEffect(() => {
    const subcollectionId = queryParams.subId;
    const subIdSelected = subcollectionId ? +subcollectionId : undefined;

    setSubCollectionSelected(subIdSelected);
  }, [queryParams.subId]);

  const handleOnClick = (subName?: string, subId?: number) => {
    const nameEncoded = encodeURIComponent(name);
    if (!subName && id && onClick) {
      navigate(`/designs/collections/${nameEncoded}?collectionId=${id}`);
      onClick(id);
    }
    if (subName && id)
      navigate(
        `/designs/collections/${nameEncoded}/${encodeURIComponent(
          subName,
        )}?collectionId=${id}&subId=${subId}`,
      );
  };

  const handleDeleteCollection = (id: number) => {
    onDeleteCollection(id);
  };

  const SubCollectionItem = (props: {
    name: string;
    description: string;
    total: string;
    id: number;
    active: boolean;
    imageUrl?: string;
  }) => {
    return (
      <div className="SubItem">
        <ArrowRight className="icon" />
        <div
          className={`item-container ${props.active ? 'box-active' : ''}`}
          onClick={() => {
            handleOnClick(props.name, props.id);
            setSubCollectionSelected(props.id);
          }}
        >
          <div className="item-counter">
            <ImageIcon />
            <span>{props.total}</span>
          </div>
          <div className="collection-item-title">
            <span>{props.name}</span>
            <span className="item-subtitle">{props.description}</span>
          </div>
          {openModal && (
            <div className="collection-menu">
              <Dropdown
                className="collection-dropdown-menu"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Dropdown.Toggle variant="outline-light">
                  <ThreeDots />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      openModal({
                        parentId: id,
                        collection: {
                          id: props.id,
                          name: props.name,
                          description: props.description,
                          imageUrl: props.imageUrl!,
                          largeImageUrl: props.imageUrl!,
                        },
                      })
                    }
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => onDeleteCollection(props.id)}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        onClick={() => {
          handleOnClick();
        }}
        className={`CollectionItem ${active ? 'box-active' : ''}`}
      >
        <div className="gallery">
          <div className="box first">
            <GFXImage
              src={imageUrl}
              fallbackSrc={largeImageUrl}
              placeholderSrc="/loading-render.gif"
            />
          </div>
          <div className="box second"></div>
          <div className="box third"></div>
        </div>
        <div className="collection-item-title">
          <div className="counter">
            {!!imagesCount && (
              <div className="item-counter">
                <ImageIcon />
                <span>{imagesCount}</span>
              </div>
            )}
            {!!subCollections?.length && (
              <div className="item-counter item-collection-counter">
                <FolderIcon />
                <span>{subCollections?.length}</span>
              </div>
            )}
          </div>
          <span>{name}</span>
          <span className="item-subtitle">{description}</span>
        </div>
        {openModal && (
          <div className="collection-menu">
            <Dropdown
              className="collection-dropdown-menu"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Dropdown.Toggle variant="outline-light">
                <ThreeDots />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    openModal({
                      collection: {
                        id,
                        name,
                        description,
                        imageUrl,
                        largeImageUrl,
                        imagesCount,
                        subCollections,
                      },
                    })
                  }
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => openModal({parentId: id})}>
                  Add sub collection
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDeleteCollection(id)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {locked && <LockIcon className="locked-icon" />}
        {!!subCollections?.length && (
          <div onClick={() => setOpen(!open)}>
            {open ? (
              <ChevronDown className="icon-active" />
            ) : (
              <ChevronUp className="icon-active" />
            )}
          </div>
        )}
      </div>
      {open &&
        subCollections &&
        subCollections.map((subCollection) => (
          <SubCollectionItem
            key={subCollection.id}
            name={subCollection.name}
            description={subCollection.description}
            total={subCollection.imagesCount}
            id={subCollection.id}
            active={subCollection.id === subcollectionSelected}
            imageUrl={subCollection.imageUrl}
          />
        ))}
    </>
  );
};

export default CollectionItem;
