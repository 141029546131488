import React from 'react';

import {ReactComponent as EyeIcon} from '../../../assets/icons/eye-fill-icon.svg';
import GFXImage from '../../Image';

import './SelectorView.scss';

interface RequiredProps {
  proofFront: string | null;
  proofBack: string | null;
  onChangeColor: (key: string) => void;
  onChangeView: (key: 'front' | 'back') => void;
  colorSelected: string;
  viewSelected: string;
  artFront: string | null;
  artBack: string | null;
}

interface OptionalProps {}
type SelectorViewProps = RequiredProps & OptionalProps;

const CONTRAST_COLORS = ['#fff', '#B2AFB5', '#525151', '#000'];
const SelectorView: React.FC<SelectorViewProps> = ({
  proofFront,
  proofBack,
  onChangeColor,
  onChangeView,
  colorSelected,
  viewSelected,
  artBack,
  artFront,
}) => {
  const handleChangeView = (view: string) => {
    if (view === 'front' && artFront) onChangeView('front');
    if (view === 'back' && artBack) onChangeView('back');
  };
  return (
    <div id="SelectorView">
      {proofFront && (
        <div
          className={`proof-box ${
            !artFront
              ? 'proof-box-disabled'
              : viewSelected === 'front'
              ? 'proof-box-selected'
              : ''
          }`}
          onClick={() => handleChangeView('front')}
        >
          <span className="box-title">Front</span>
          {artFront && (
            <GFXImage
              src={proofFront}
              alt=""
              placeholderSrc="/loading-render.gif"
            />
          )}
          {!artFront && (
            <>
              <span className="title-disabled">No</span>
              <span className="title-disabled"> Design Created</span>
            </>
          )}
        </div>
      )}

      {proofBack && (
        <div
          className={`proof-box ${
            !artBack
              ? 'proof-box-disabled'
              : viewSelected === 'back'
              ? 'proof-box-selected'
              : ''
          }`}
          onClick={() => handleChangeView('back')}
        >
          <span className="box-title">Back</span>
          {artBack && (
            <GFXImage
              src={proofBack}
              alt=""
              placeholderSrc="/loading-render.gif"
            />
          )}
          {!artBack && (
            <>
              <span className="title-disabled">No</span>
              <span className="title-disabled"> Design Created</span>
            </>
          )}
        </div>
      )}
      <div className="contrast-view">
        <span>
          <EyeIcon /> Color
        </span>
        <span className="view-text">contrast view</span>
        <div className="contrast-box">
          {CONTRAST_COLORS.map((color, index) => (
            <div
              key={`colorPicker-${index}`}
              className={`colorPicker ${
                colorSelected === color ? 'color-selected' : ''
              }`}
              style={{backgroundColor: color}}
              onClick={() => {
                onChangeColor(color);
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectorView;
