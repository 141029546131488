import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import {Search} from 'react-bootstrap-icons';
import debounce from 'lodash.debounce';
import SortLabel from '../SortLabel';
import DesignsList from './DesignsList';
import DraftCanvasList from './DraftCanvasList';
import useQueryParams from '../../hooks/useQueryParams';
import PublishedList from './PublishedList';
import {ISlotTemplateCanvas, IDraftCanva} from '@gfxco/contracts';

import './DesignsContent.scss';

interface RequiredProps {
  onEdit: (draftCanva: ISlotTemplateCanvas) => void;
  onEditDraft: (draftCanva: IDraftCanva) => void;
}

const DesignsContent: React.FC<RequiredProps> = (props) => {
  const [queryParams, setQueryParams] = useQueryParams();
  const currentTab = queryParams.tab || 'designs';
  const inputSearchRef = React.useRef<HTMLInputElement>(null);

  const handleSortChange = (selectedOption: any) => {
    setQueryParams({...queryParams, sortBy: selectedOption.value});
  };

  const handleTabChange = (key: string | null) => {
    if (key) {
      setQueryParams({tab: key, sortBy: 'newest', search: ''});

      inputSearchRef.current!.value = '';
    }
  };

  const handleSearchChange = (search: string) => {
    setQueryParams({...queryParams, search});
  };

  React.useEffect(() => {
    const tab = queryParams.tab || 'designs';
    const sortBy = queryParams.sortBy || 'newest';
    const search = queryParams.search || '';

    setQueryParams({...queryParams, tab, sortBy, search});
  }, []);

  return (
    <div id="DesignsContent">
      <Row className="tabs-bg" xs="auto">
        <Col>
          <Tabs
            className="tab-options"
            onSelect={handleTabChange}
            activeKey={currentTab}
          >
            <Tab className="tab-option" title="Designs" eventKey={'designs'} />
            <Tab className="tab-option" title="Drafts" eventKey={'draft'} />
            <Tab
              className="tab-option"
              title="Published"
              eventKey={'published'}
            />
          </Tabs>
        </Col>
        <Col className="flex-grow-1">
          <div className="list-toolbar">
            <InputGroup className="search-bar-group search-bar-design-templates">
              <InputGroup.Text>
                <Search />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search design templates by name"
                onChange={debounce((event) => {
                  handleSearchChange(event.target.value);
                }, 500)}
                aria-label="SearchBar to filter by design templates name"
                ref={inputSearchRef}
                defaultValue={queryParams.search}
              />
            </InputGroup>
            <SortLabel
              handleSortChange={handleSortChange}
              className="select-sort-design-templates"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {currentTab === 'designs' && <DesignsList onEdit={props.onEdit} />}
          {currentTab === 'draft' && (
            <DraftCanvasList onEdit={props.onEditDraft} />
          )}
          {currentTab === 'published' && <PublishedList />}
        </Col>
      </Row>
    </div>
  );
};

export default DesignsContent;
