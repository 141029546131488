import {
  ICreateWalkthroughImageRequest,
  ICreateWalkthroughStepRequest,
  IGetWalkthroughImagesRequest,
  IGetWalkthroughImagesResponse,
  IGetWalkthroughStepsRequest,
  IGetWalkthroughStepsResponse,
  IUpdateWalkthroughStepRequest,
  TWalkthroughImage,
  TWalkthroughStep,
} from '@gfxco/contracts';
import {loadClient, handleError} from './api';

export const getWalkthroughSteps = async (
  params: IGetWalkthroughStepsRequest,
) => {
  try {
    const client = await loadClient();

    const response = await client.get<IGetWalkthroughStepsResponse>(
      `/walkthrough`,
      {
        params,
      },
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getWalkthroughImages = async (
  params: IGetWalkthroughImagesRequest,
) => {
  try {
    const client = await loadClient();

    const response = await client.get<IGetWalkthroughImagesResponse>(
      `/walkthrough/images`,
      {
        params,
      },
    );

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const createWalkthroughStep = async (
  data: ICreateWalkthroughStepRequest,
) => {
  try {
    const client = await loadClient();

    const response = await client.post<TWalkthroughStep>(`/walkthrough`, data);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateWalkthroughStep = async (
  stepId: number,
  data: IUpdateWalkthroughStepRequest,
) => {
  try {
    const client = await loadClient();

    const response = await client.put<TWalkthroughStep>(
      `/walkthrough/${stepId}`,
      data,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteWalkthroughStep = async (stepId: number) => {
  try {
    const client = await loadClient();

    await client.delete(`/walkthrough/${stepId}`);
  } catch (error) {
    return handleError(error);
  }
};

export const createWalkthroughImage = async (
  data: ICreateWalkthroughImageRequest,
) => {
  try {
    const client = await loadClient();

    const response = await client.post<TWalkthroughImage>(
      `/walkthrough/images`,
      data,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
