import React, {useEffect} from 'react';
import Loading from '../Loading';
import Icon from '../Icons/Icon';

import Modal from '../Modal';

import './LoadingModal.scss';

interface RequiredProps {
  loading: boolean;
  showSuccess: boolean;
  onClose: () => void;
}

interface OptionalProps {
  productUrl?: string;
  title?: string;
  description?: string;
}

type LoadingModalProps = OptionalProps & RequiredProps;

const LoadingModal: React.FC<LoadingModalProps> = ({
  loading,
  showSuccess,
  onClose,
  productUrl,
  title,
  description,
}) => {
  const [showLoading, setShowLoading] = React.useState(loading);
  const [showSuccessMessage, setShowSuccessMessage] =
    React.useState(showSuccess);

  useEffect(() => {
    setShowLoading(loading);
    setShowSuccessMessage(showSuccess);
  }, [loading, showSuccess]);

  const handleOnClose = () => {
    if (loading) return;
    onClose();
  };

  title = title || 'We are creating something amazing';
  description =
    description ||
    'Good things take time, one moment while we are preparing the new design.';

  return (
    <div>
      <Modal.SimpleModal
        className="modal-middle-page modal-styled"
        modalTile=""
        show={showLoading || showSuccessMessage}
        onCloseModal={handleOnClose}
        isSaveEnabled={false}
        addCloseButton={!!onClose}
      >
        {showLoading && (
          <div id="LoadingModal">
            <Loading spinnerOnly />
            <span className="modal-loading-title">{title}</span>
            <span className="modal-loading-description">{description}</span>
          </div>
        )}
        {!showLoading && showSuccessMessage && (
          <div id="LoadingModal">
            <Icon name="checkIcon" />
            <span className="modal-loading-title">Excellent job!</span>
            <div className="modal-loading-description">
              The product has been{' '}
              <b className="text-bold green-bold">published successfully</b>
            </div>
            <div className="modal-loading-description">
              <b className="text-bold grey-bold">Reminder:</b> To visualize the
              product properly, you need to integrate our visualizer into your
              store’s product page.
            </div>
            {productUrl && (
              <a
                href={productUrl}
                target="_blank"
                className="info-button info-publish-button"
                rel="noreferrer"
              >
                <Icon name="shopifyIcon" />
                See my product
              </a>
            )}
          </div>
        )}
      </Modal.SimpleModal>
    </div>
  );
};

export default LoadingModal;
