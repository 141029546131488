import React from 'react';
import {XLg} from 'react-bootstrap-icons';

import './ImageBanner.scss';
import Icon from '../Icons/Icon';

interface RequiredProps {
  show: boolean;
  onClose: () => void;
}

interface OptionalProps {}

type ImageBannerProps = RequiredProps & OptionalProps;

const ImageBanner: React.FC<ImageBannerProps> = ({show, onClose}) => {
  if (!show) return null;
  return (
    <div id="ImageBanner">
      <Icon name="imageNote" />
      <div>
        <span className="banner-title">Designer tip:</span>
        When you are designing with images, make sure to upload high resolution
        pictures, for
        <span className="banner-text-highlight">
          printed articles the recommended resolution is 300 DPI.
        </span>
      </div>
      <XLg className="close-button" onClick={() => onClose()} />
    </div>
  );
};

export default ImageBanner;
