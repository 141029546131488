import React from 'react';
import {Plus, Trash} from 'react-bootstrap-icons';
import {Row, Col, Container} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {Actions} from '../../../../libs/getLinkedSlotActions';
import {colors as defaultTextColors} from '../../../../libs/getDefaultTextColors';

import './SelectorBox.scss';
import {
  IColor,
  ITemplateImage,
  IImage,
  ILinkedSlots,
  ILinkedSlotAction,
} from '@gfxco/contracts';
import RuleSelector from './RuleSelector';
import ItemTextbox from './ItemTextbox';

interface Option {
  label?: string;
  value?: string | number;
  type?: string;
  side: 'front' | 'back';
  disabled: boolean;
  selected: boolean;
  blocked?: boolean;
  defaultImage: string;
}
export interface RequiredProps {
  colors: IColor[];
  onSaveDraft: (params: ILinkedSlots) => void;
  onDeleteAction: (param: ILinkedSlotAction) => void;
  isFrontDisabled: boolean;
  isBackDisabled: boolean;
}
export interface OptionalProps {
  ruleDraft?: ILinkedSlots;
  slotOptions?: Option[];
  slotImages?: ITemplateImage;
  isEditing?: boolean;
}

interface OptionSelected {
  color?: IColor;
  image?: IImage;
  text?: IColor;
  slotName?: string;
  slotSide?: 'front' | 'back';
  slotId?: number;
}

type SelectorBoxProps = RequiredProps & OptionalProps;

export const SelectorBox: React.FC<SelectorBoxProps> = ({
  colors,
  ruleDraft,
  slotOptions,
  slotImages,
  onSaveDraft,
  onDeleteAction,
  isFrontDisabled,
  isBackDisabled,
  isEditing = false,
}) => {
  const DEFAULT_OPTIONS = [
    {label: 'Item color', value: 'color', disabled: false},
    {label: 'Front design slots', value: 'front', disabled: isFrontDisabled},
    {label: 'Back design slots', value: 'back', disabled: isBackDisabled},
  ];
  const [options, setOptions] = React.useState<Option[]>(slotOptions!);
  const [dropdownOptions, setDropdownOptions] = React.useState(DEFAULT_OPTIONS);

  const params = useParams();
  const {id: templateId} = params;

  React.useEffect(() => {
    setOptions(slotOptions!);
  }, [slotOptions]);

  React.useEffect(() => {
    if (ruleDraft) {
      const isColorDisabled =
        ruleDraft?.comparison === Actions.Color ||
        ruleDraft.comparison === Actions.Reflects ||
        ruleDraft?.actions.findIndex((i) => i.action === Actions.Color) >= 0;
      const newOptions = dropdownOptions;
      newOptions[0].disabled = isColorDisabled;
      setDropdownOptions(newOptions);

      const optionsUpdated = options.map((item) => ({
        ...item,
        selected:
          item.value === ruleDraft.slotId ||
          ruleDraft.actions.some((i) => i.slotId === item.value),
        blocked:
          ruleDraft.comparison === Actions.Reflects && item.type !== 'text',
      }));
      setOptions(optionsUpdated);
    }
  }, [ruleDraft]);

  const handleSelectColor = (item: {color?: IColor}) => {
    const color = item.color;
    if (!color) return;
    let actions = ruleDraft?.actions || [];
    if (ruleDraft && ruleDraft.color !== color.name) {
      actions = [];
    }
    const ruleUpdated: ILinkedSlots = {
      color: color.name,
      comparison: Actions.Color,
      actions,
      templateId: +templateId!,
    };

    const newOptions = dropdownOptions;
    newOptions[0].disabled = true;
    setDropdownOptions(newOptions);
    onSaveDraft(ruleUpdated);
  };

  const handleSelectImage = (item: {image?: IImage; slotId: number}) => {
    const slotId = item.slotId;
    if (!item.image || !slotId) return;

    const {id, imageUrl, name} = item.image;
    let actions = ruleDraft?.actions || [];

    if (
      ruleDraft &&
      ruleDraft.slotId !== slotId &&
      ruleDraft.comparison === Actions.Slot
    ) {
      actions = [];
    }

    const imageData = {id, imageUrl: imageUrl!, name};
    const ruleUpdated: ILinkedSlots = {
      image: imageData,
      imageId: id,
      slotId,
      comparison: Actions.Slot,
      actions,
      templateId: +templateId!,
    };

    const optionsUpdated = options.map((item) => ({
      ...item,
      selected: item.value === slotId,
    }));
    setOptions(optionsUpdated);
    onSaveDraft(ruleUpdated);
  };

  const buildAction = (params: {
    image?: IImage;
    slotId?: number;
    color?: IColor;
    text?: string;
    previousSelected?: OptionSelected;
  }) => {
    const {image, color, slotId, previousSelected: selected, text} = params;
    let actions = ruleDraft?.actions || [];
    if (selected) {
      let action;
      if (selected.color && !color) {
        action = ruleDraft?.actions.find((a) => a.action === Actions.Color);
      } else if (selected.slotId && selected.slotId !== slotId) {
        action = ruleDraft?.actions.find((a) => a.slotId === selected.slotId);
      }

      if (action) {
        actions = removeAction(action);
      }
    }

    if (color) {
      handleOnChangeActionColor(color, actions);
    }

    if (slotId && !image && !text) {
      handleOnChangeActionReflectText(slotId, actions);
    }

    if (image && slotId) {
      handleOnChangeActionImage(image, slotId, actions);
    }

    if (text && slotId) {
      handleOnChangeActionTextColor(text, slotId, actions);
    }
  };

  const buildNewRandomAction = () => {
    const colorOption = dropdownOptions.find(
      (o) => o.value === 'color',
    )?.disabled;

    if (!ruleDraft?.comparison) return;

    const actions = ruleDraft?.actions || [];
    if (!colorOption) {
      const defaultColor = colors[0];
      const newAction = {
        action: Actions.Color,
        templateId: +templateId!,
        color: defaultColor.name,
      };
      const newOptions = dropdownOptions;
      newOptions[0].disabled = true;
      setDropdownOptions(newOptions);
      return onSaveDraft({...ruleDraft, actions: [...actions, newAction]});
    }

    const firstOptionAvailable = options.find(
      (o) => !o.disabled && !o.selected,
    );
    if (!firstOptionAvailable) return;
    const slot = firstOptionAvailable.value;

    if (firstOptionAvailable?.type === 'image' && slotImages) {
      const images = slotImages[slot!];
      const defaultImage = {
        id: images[0].id,
        imageUrl: images[0].imageUrl!,
        name: images[0].name,
      };
      const newAction = {
        action: Actions.Slot,
        templateId: +templateId!,
        slotId: +slot!,
        image: defaultImage!,
        imageId: defaultImage.id,
      };
      updateSlotOption(slot as number);
      return onSaveDraft({...ruleDraft, actions: [...actions, newAction]});
    } else {
      const newAction = {
        action: Actions.Fill,
        text: defaultTextColors[0].hex,
        templateId: +templateId!,
        slotId: +slot!,
      };
      updateSlotOption(slot as number);
      return onSaveDraft({...ruleDraft, actions: [...actions, newAction]});
    }
  };

  const handleOnChangeActionColor = (
    color: IColor,
    actions: ILinkedSlotAction[],
  ) => {
    if (!ruleDraft?.comparison) return;
    const action = actions.find((a) => a.action === Actions.Color);

    if (action) {
      actions = actions.map((a) => {
        if (a.action === Actions.Color) {
          return {...a, color: color.name};
        }
        return a;
      });
    } else {
      actions.push({
        color: color.name,
        action: Actions.Color,
        templateId: +templateId!,
      });
    }
    const newOptions = dropdownOptions;
    newOptions[0].disabled = true;
    setDropdownOptions(newOptions);
    onSaveDraft({...ruleDraft, actions});
  };

  const handleOnChangeActionImage = (
    image: IImage,
    slotId: number,
    actions: ILinkedSlotAction[],
  ) => {
    if (!ruleDraft?.comparison) return;
    const {id, imageUrl, name} = image;
    const imageData = {id, imageUrl: imageUrl!, name};
    const action = actions.find(
      (a) => a.action === Actions.Slot && a.slotId === slotId,
    );

    if (action) {
      actions = actions.map((a) => {
        if (a.action === Actions.Slot && a.slotId === slotId) {
          return {...a, image: imageData, imageId: imageData.id};
        }
        return a;
      });
    } else {
      actions.push({
        image: imageData,
        imageId: imageData.id,
        slotId,
        action: Actions.Slot,
        templateId: +templateId!,
      });
    }
    updateSlotOption(slotId);
    onSaveDraft({...ruleDraft, actions});
  };

  const handleOnChangeActionTextColor = (
    text: string,
    slotId: number,
    actions: ILinkedSlotAction[],
  ) => {
    if (!ruleDraft?.comparison) return;
    const action = actions.find(
      (a) => a.action === Actions.Fill && a.slotId === slotId,
    );

    if (action) {
      actions = actions.map((a) => {
        if (a.action === Actions.Fill && a.slotId === slotId) {
          return {...a, text};
        }
        return a;
      });
    } else {
      actions.push({
        text,
        slotId,
        action: Actions.Fill,
        templateId: +templateId!,
      });
    }
    updateSlotOption(slotId);
    onSaveDraft({...ruleDraft, actions});
  };

  const isAddDisabled = () => {
    const optionsAvailable = options.filter((o) => !o.disabled && !o.selected);
    return !ruleDraft?.actions.length || !optionsAvailable.length;
  };

  const removeAction = (item: ILinkedSlotAction) => {
    if (!ruleDraft?.comparison) return [];
    const newActions = ruleDraft?.actions.filter(({action, slotId}) => {
      if (action === item.action && item.action === Actions.Color) return false;

      return item.slotId !== slotId;
    });

    if (item.color) {
      const newOptions = dropdownOptions;
      newOptions[0].disabled = false;
      setDropdownOptions(newOptions);
    } else {
      const optionsUpdated = options.map((o) => {
        if (o.value === item.slotId) {
          return {
            ...o,
            selected: false,
          };
        } else {
          return o;
        }
      });
      setOptions(optionsUpdated);
    }
    return newActions;
  };

  const handleRemove = (item: ILinkedSlotAction) => {
    if (!ruleDraft?.comparison) return;
    const newActions = removeAction(item);
    if (item.id) {
      onDeleteAction(item);
    }
    onSaveDraft({...ruleDraft, actions: newActions!});
  };

  const updateSlotOption = (slotId: number) => {
    const optionsUpdated = options.map((item) => {
      if (item.value === slotId) {
        return {
          ...item,
          selected: item.value === slotId,
        };
      } else {
        return item;
      }
    });
    setOptions(optionsUpdated);
  };

  const getOptionSelected = (item?: ILinkedSlots | ILinkedSlotAction) => {
    if (!item) return;
    const text = defaultTextColors.find(({hex}) => item?.text === hex);

    const color = colors.find(({name}) => item?.color === name);
    const image = item?.image;
    const option = options.find((option) => option.value === item?.slotId);
    const slotName = option?.label;
    const slotSide = option?.side;
    const defaultImage = option?.defaultImage!;

    return {
      text,
      image,
      slotName,
      slotSide,
      color,
      slotId: item.slotId,
      defaultImage,
    };
  };

  const handleTextColorChange = (item: {slotId: number; text?: string}) => {
    const slotId = item.slotId;
    if (!item.text || !slotId) return;

    let actions = ruleDraft?.actions || [];

    if (
      ruleDraft &&
      ruleDraft.slotId !== slotId &&
      ruleDraft.comparison === Actions.Fill
    ) {
      actions = [];
    }

    if (
      ruleDraft &&
      ruleDraft.slotId === slotId &&
      ruleDraft.comparison === Actions.Reflects
    ) {
      actions = [];
    }

    const ruleUpdated: ILinkedSlots = {
      text: item.text,
      slotId,
      comparison: Actions.Fill,
      actions,
      templateId: +templateId!,
    };

    const optionsUpdated = options.map((item) => ({
      ...item,
      selected: item.value === slotId,
    }));
    setOptions(optionsUpdated);
    onSaveDraft(ruleUpdated);
  };

  const handleReflectsSelected = (item: {slotId: number}) => {
    const slotId = item.slotId;
    if (!slotId) return;

    let actions = ruleDraft?.actions || [];

    if (
      ruleDraft &&
      ruleDraft.slotId !== slotId &&
      ruleDraft.comparison === Actions.Reflects
    ) {
      actions = [];
    }

    const ruleUpdated: ILinkedSlots = {
      slotId,
      comparison: Actions.Reflects,
      actions,
      templateId: +templateId!,
    };

    const optionsUpdated = options.map((item) => ({
      ...item,
      selected: item.value === slotId,
      blocked: item.type !== 'text',
    }));
    onSaveDraft(ruleUpdated);
    setOptions(optionsUpdated);
  };

  const handleOnChangeActionReflectText = (
    slotId: number,
    actions: ILinkedSlotAction[],
  ) => {
    if (!ruleDraft?.comparison) return;

    const action = actions.find(
      (a) =>
        (a.action === Actions.Reflects || a.action === Actions.Fill) &&
        a.slotId === slotId,
    );

    if (action) {
      actions = actions.map((a) => {
        if (a.action === Actions.Fill && a.text && a.slotId === slotId) {
          return {...a, text: undefined, action: Actions.Reflects};
        }
        return a;
      });
    } else {
      actions.push({
        slotId,
        action: Actions.Reflects,
        templateId: +templateId!,
      });
    }

    updateSlotOption(slotId);
    onSaveDraft({...ruleDraft, actions});
  };

  return (
    <div id="SelectorBox">
      <Container fluid className="selector-body">
        <Row className="select-rule-box">
          <Col className="d-inline-block">
            <div className="title-description-box">
              <span className="description-title">IF </span>
              <span className="description-subtitle">
                (when the user selects this)
              </span>
            </div>

            <div className="select-rule-box-select">
              <RuleSelector
                defaultValue={{
                  label: 'Select your Slot condition #1',
                }}
                colors={colors}
                options={dropdownOptions}
                onColorSelect={handleSelectColor}
                onImageSelect={handleSelectImage}
                onTextSelect={handleTextColorChange}
                onReflectSelect={handleReflectsSelected}
                selectedOption={getOptionSelected(ruleDraft)}
                slotOptions={options}
                slotImages={slotImages}
                fillEnabled={false}
                disabled={isEditing}
              />
              {(ruleDraft?.comparison === Actions.Reflects ||
                (ruleDraft?.comparison === Actions.Fill && ruleDraft.text)) && (
                <ItemTextbox
                  type={ruleDraft.comparison}
                  text={ruleDraft.text}
                />
              )}
              <div className="add-action">
                <button
                  onClick={() => buildNewRandomAction()}
                  className="button-action"
                  disabled={isAddDisabled()}
                >
                  <Plus />
                </button>
              </div>
            </div>
          </Col>

          <Col>
            <div className="title-description-box">
              <span className="description-title">THEN </span>
              <span className="description-subtitle">(will reflect this)</span>
            </div>

            <div className="actions-item">
              <div className="box-action-item">
                <RuleSelector
                  defaultValue={{
                    label: 'Select your Slot condition #2',
                  }}
                  options={dropdownOptions}
                  colors={colors}
                  onColorSelect={buildAction}
                  onImageSelect={buildAction}
                  onTextSelect={buildAction}
                  onReflectSelect={buildAction}
                  disabled={!ruleDraft || !Object.keys(ruleDraft).length}
                  selectedOption={getOptionSelected(ruleDraft?.actions[0])}
                  slotOptions={options}
                  slotImages={slotImages}
                  ruleType={ruleDraft?.comparison}
                  aligned="right"
                />
                {!!ruleDraft?.actions.length &&
                  ['reflects', 'has-fill-color'].includes(
                    ruleDraft.actions[0].action,
                  ) && (
                    <ItemTextbox
                      type={
                        ruleDraft.actions[0].action as
                          | 'reflects'
                          | 'has-fill-color'
                      }
                      text={ruleDraft.actions[0].text}
                    />
                  )}
                <div className="remove-button">
                  <button
                    className="button-action"
                    disabled={!ruleDraft?.actions.length}
                    onClick={() => handleRemove(ruleDraft?.actions[0]!)}
                  >
                    <Trash />
                  </button>
                </div>
              </div>
            </div>

            <div className="actions-box">
              {ruleDraft?.actions.map((action, index) => (
                <div key={`action-select-${index}`} className="actions-item">
                  {index > 0 && (
                    <>
                      <div className="box-action-item">
                        <RuleSelector
                          defaultValue={{
                            label: 'Select your Slot condition #2',
                          }}
                          options={dropdownOptions}
                          colors={colors}
                          slotOptions={options}
                          onColorSelect={buildAction}
                          onImageSelect={buildAction}
                          onTextSelect={buildAction}
                          onReflectSelect={buildAction}
                          disabled={
                            !ruleDraft || !Object.keys(ruleDraft).length
                          }
                          selectedOption={getOptionSelected(action)}
                          slotImages={slotImages}
                          ruleType={ruleDraft?.comparison}
                          aligned="right"
                        />
                        {action &&
                          ['reflects', 'has-fill-color'].includes(
                            action.action,
                          ) && (
                            <ItemTextbox
                              type={
                                action.action as 'reflects' | 'has-fill-color'
                              }
                              text={action.text}
                            />
                          )}

                        <div className="remove-button">
                          <button
                            className="button-action"
                            onClick={() => handleRemove(action)}
                          >
                            <Trash />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SelectorBox;
