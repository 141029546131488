import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import * as Contracts from '@gfxco/contracts';
import {RootState} from '../../app/store';
import {
  getUserPermissions,
  deleteUserFromShop,
  patchUserPermissions,
  inviteUserToShop,
} from '../../api/user';

export interface UserState {
  permissions: Contracts.userPermissionsResponse['permissions'];
  isLoading: boolean;
  isError: boolean;
  isUserPermissionsPatchLoading: boolean;
  isUserPermissionsPatchError: boolean;
  isInviteUserToShopLoading: boolean;
  isInviteUserToShopError: boolean;
}

const initialState: UserState = {
  permissions: [],
  isLoading: false,
  isError: false,
  isUserPermissionsPatchLoading: false,
  isUserPermissionsPatchError: false,
  isInviteUserToShopLoading: false,
  isInviteUserToShopError: false,
};

export const getUserPermissionsAsync = createAsyncThunk(
  'userPermissions/fetch',
  async () => {
    const response = await getUserPermissions();
    return {permissions: response?.permissions || []};
  },
);

export const patchUserPermissionsAsync = createAsyncThunk(
  'userPermissions/patch',
  async (body: Contracts.IPatchUserPermissionsRequest) => {
    await patchUserPermissions(body);
  },
);

export const deleteShopUserAsync = createAsyncThunk(
  'shopsUsers/delete',
  async (username: string) => {
    await deleteUserFromShop(username);
  },
);

export const inviteUserToShopAsync = createAsyncThunk(
  'shopsUsers/invite',
  async (email: string, {rejectWithValue}) => {
    try {
      return await inviteUserToShop(email);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserPermissionsAsync.fulfilled, (state, action) => {
        state.permissions = action.payload.permissions;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(getUserPermissionsAsync.rejected, (state) => {
        state.permissions = [];
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getUserPermissionsAsync.pending, (state, action) => {
        state.permissions = [];
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(patchUserPermissionsAsync.fulfilled, (state) => {
        state.isUserPermissionsPatchLoading = false;
        state.isUserPermissionsPatchError = false;
      })
      .addCase(patchUserPermissionsAsync.rejected, (state) => {
        state.isUserPermissionsPatchLoading = false;
        state.isUserPermissionsPatchError = true;
      })
      .addCase(patchUserPermissionsAsync.pending, (state) => {
        state.isUserPermissionsPatchLoading = true;
        state.isUserPermissionsPatchError = false;
      })
      .addCase(inviteUserToShopAsync.fulfilled, (state) => {
        state.isInviteUserToShopLoading = false;
        state.isInviteUserToShopError = false;
      })
      .addCase(inviteUserToShopAsync.rejected, (state) => {
        state.isInviteUserToShopLoading = false;
        state.isInviteUserToShopError = true;
      })
      .addCase(inviteUserToShopAsync.pending, (state) => {
        state.isInviteUserToShopLoading = true;
        state.isInviteUserToShopError = false;
      });
  },
});

export const selectUser = (state: RootState) => state.user;

export const selectUserPermissions = (state: RootState) =>
  state.user.permissions;

export const checkUserPermission = (state: RootState, permission: string) =>
  state.user.permissions.includes(permission);

export default userSlice.reducer;
