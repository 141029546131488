import React from 'react';
import {Button, ButtonProps} from 'react-bootstrap';

import './GFXButton.scss';

interface GFXButtonProps extends ButtonProps {
  variant: 'gfx-light-shadow';
}

const GFXButton: React.FC<GFXButtonProps> = (props) => {
  let className = props.className ?? '';
  const {variant, ...rest} = props;

  switch (props.variant) {
    case 'gfx-light-shadow':
      className += ' gfx-light-shadow';
      break;
    default:
      className += '';
      break;
  }

  return (
    <Button {...rest} className={`gfx-btn ${className}`}>
      {props.children}
    </Button>
  );
};

export default GFXButton;
