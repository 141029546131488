import React from 'react';
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {IPrinterIntegrationFormValues} from '@gfxco/contracts';
import {useForm} from 'react-hook-form';
import classnames from 'classnames';

import Icon from '../../../components/Icons/Icon';
import Button from '../../../components/Button';
import PrinterCollapsedContent from '../PrinterCollapseContent';

type PrinterFormProps = {
  id: string;
  storeId?: string;
  apiKey?: string;
  loading?: boolean;
  connected?: boolean;
  image?: string | React.ReactNode;
  learnHowUrl?: string;
  onSubmitted?: (params: IPrinterIntegrationFormValues) => void;
};

const PrinterForm: React.FC<PrinterFormProps> = ({
  id,
  storeId,
  apiKey,
  loading = false,
  connected = false,
  image,
  learnHowUrl,
  onSubmitted,
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IPrinterIntegrationFormValues>({
    defaultValues: {storeId: '', apiKey: ''},
    values: {storeId: storeId ?? '', apiKey: apiKey ?? ''},
  });

  const handleFormSubmit = (params: IPrinterIntegrationFormValues) => {
    if (onSubmitted) {
      onSubmitted(params);
    }
  };

  return (
    <PrinterCollapsedContent
      id={id}
      loading={loading}
      connected={connected}
      image={image}
      errored={!!errors.storeId || !!errors.apiKey}
    >
      <form
        className="PrinterForm"
        onSubmit={!loading ? handleSubmit(handleFormSubmit) : undefined}
      >
        <Form.Group controlId="formStoreId">
          <Form.Label>Store ID*</Form.Label>
          <OverlayTrigger
            placement="right"
            delay={{show: 200, hide: 400}}
            overlay={
              <Tooltip id={`${id}-storeIdTooltip`}>
                This ID is provided by your fulfillment services to authenticate
                an user. See our documentation to learn more
              </Tooltip>
            }
          >
            <span className="d-inline-block tooltip-icon">
              <Icon name="information" />
            </span>
          </OverlayTrigger>
          {errors.storeId?.type === 'required' && (
            <span className="form-error">Information required to continue</span>
          )}
          <Form.Control
            type="text"
            placeholder="547650"
            readOnly={connected}
            disabled={connected}
            autoComplete="off"
            aria-invalid={errors.storeId ? 'true' : 'false'}
            {...register('storeId', {
              required: true,
            })}
          />
        </Form.Group>

        <Form.Group controlId="formApiKey">
          <Form.Label>API Key*</Form.Label>
          <OverlayTrigger
            placement="right"
            delay={{show: 200, hide: 400}}
            overlay={
              <Tooltip id={`${id}-apiKeyTooltip`}>
                The API key is a code used to identify and authenticate a user.
                See our documentation to learn how to find this key
              </Tooltip>
            }
          >
            <span className="d-inline-block tooltip-icon">
              <Icon name="information" />
            </span>
          </OverlayTrigger>
          {errors.apiKey?.type === 'required' && (
            <span className="form-error">
              Key required to connect your printer
            </span>
          )}
          <Form.Control
            type={connected ? 'text' : 'password'}
            placeholder="2eeb9ba9294174de"
            readOnly={connected}
            disabled={connected}
            autoComplete="printful-integration api-key"
            aria-invalid={errors.apiKey ? 'true' : 'false'}
            {...register('apiKey', {required: true})}
          />
        </Form.Group>
        <div
          className={classnames({
            'connect-container': true,
            'connect-container--spaced': learnHowUrl,
          })}
        >
          {learnHowUrl && (
            <Button
              variant="link"
              href={learnHowUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="externalLink" /> Learn how to set up this printer
            </Button>
          )}
          {connected ? (
            <OverlayTrigger
              placement="top"
              delay={{show: 200, hide: 400}}
              overlay={
                <Tooltip id={`${id}-connect-button`}>
                  To connect a new printer, contact support
                </Tooltip>
              }
            >
              <span className="d-inline-block">
                <Button variant="primary" type="submit" disabled>
                  <Icon height={18} width={18} name={'checkIcon'} />
                  Connected
                </Button>
              </span>
            </OverlayTrigger>
          ) : (
            <Button variant="primary" type="submit" disabled={loading}>
              <Icon height={18} width={18} name={'samplePortal'} />
              {loading ? 'Loading…' : 'Connect'}
            </Button>
          )}
        </div>
      </form>
    </PrinterCollapsedContent>
  );
};

export default PrinterForm;
