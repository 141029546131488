import React, {useState} from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from '@stripe/react-stripe-js';
import {StripeError} from '@stripe/stripe-js';

interface OptionalProps {
  onPaymentMethodCreated?(paymentMethodId: string): void;
}

const SetupForm: React.FC<OptionalProps> = ({onPaymentMethodCreated}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleError = (error: Error | StripeError) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the PaymentMethod using the details collected by the Payment Element
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      elements,
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
      return;
    }

    if (onPaymentMethodCreated) {
      onPaymentMethodCreated(paymentMethod?.id || '');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        options={{layout: {type: 'tabs', defaultCollapsed: false}}}
      />
      <AddressElement options={{mode: 'billing'}} />
      <button
        type="submit"
        disabled={!stripe || loading}
        className="btn btn-primary"
      >
        Subscribe
      </button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};
export default SetupForm;
