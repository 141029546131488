import React, {FormEvent, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import useQuery from '../../hooks/useQuery';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PasswordField from '../../components/PasswordField';
import {AUTH_SESSION_STATUSES} from '../../constants';
import '@aws-amplify/ui-react/styles.css';
import './SignUpPage.scss';
import {getAuthStatus, singUpAsync} from '../../features/auth';
import useQueryParams from '../../hooks/useQueryParams';

const SignUpSide = () => {
  const [signUpStatus, setSignUpStatus] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [shopNameError, setShopNameError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [formError, setFormError] = React.useState('');
  const query = useQuery();
  const [queryParams] = useQueryParams();
  const params = decodeURIComponent(
    new URLSearchParams(queryParams).toString(),
  );

  const authSession = useAppSelector(getAuthStatus);
  const dispatch = useAppDispatch();

  const {route} = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();

  const isSingUpLoading = signUpStatus === 'loading';

  useEffect(() => {
    if (
      route === 'authenticated' &&
      authSession === AUTH_SESSION_STATUSES.LOGIN
    ) {
      if (query.has('redirect')) {
        const redirect = query.get('redirect');
        if (redirect) {
          navigate(redirect, {replace: true});
          return;
        }
      }
      navigate('/', {replace: true});
    }
  }, [route, navigate, authSession]);

  const handleSignUp = async (event: FormEvent) => {
    setSignUpStatus('loading');
    setPasswordError('');
    setEmailError('');
    setShopNameError('');
    setFormError('');

    event.preventDefault();
    event.stopPropagation();
    const shopNameRegex = /^[a-zA-Z0-9\s]+$/;
    const target = event.target as HTMLFormElement;
    const password = target.password.value;
    const repeatPassword = target.repeatPassword.value;
    const shopName: string = target.shopName.value;
    let email = target.email.value;

    let isFormErrored = false;

    if (!password) {
      setPasswordError('Please type a password');
      isFormErrored = true;
    }

    if (!email) {
      setEmailError('Please type an email');
      isFormErrored = true;
    }

    if (!shopName || shopName.length < 3 || !shopNameRegex.test(shopName)) {
      setShopNameError(
        'Min length is 3 characters. Only letters and numbers are allowed.',
      );
      isFormErrored = true;
    }

    if (password !== repeatPassword) {
      setPasswordError('Passwords do not match');
      isFormErrored = true;
    }

    if (isFormErrored) {
      setSignUpStatus('error');
      return;
    }

    email = email.toLowerCase();
    const username = email;

    try {
      await dispatch(
        singUpAsync({username, password, email, shopName}),
      ).unwrap();
      setSignUpStatus('success');
      navigate(`/signup/confirm?${params}`, {state: {username, password}});
    } catch (error: any) {
      setSignUpStatus('error');
      console.log('Error signUpg in', error);
      if (error.message.toLowerCase().includes('password')) {
        setFormError(
          'Password should contain: Symbols, uppercase and lowercase characters. Min length: 8',
        );
        return;
      }
      setFormError(error.message.replace(/preSignUp|failed with error/gi, ''));
    }
  };

  return (
    <div id="SignUpPage" className="form-container">
      <h2 className="sign-in-header">Create an account</h2>
      <Form className="sign-in-form" noValidate onSubmit={handleSignUp}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>
            Store name: <span className="required-symbol">*</span>
          </Form.Label>
          <Form.Control
            name="shopName"
            className={`${shopNameError ? 'form-error' : ''}`}
            placeholder="Store name"
            required
          />
          {shopNameError && (
            <span className="form-feedback-error">{shopNameError}</span>
          )}
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label>
            Enter your email address: <span className="required-symbol">*</span>
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Your email address"
            name="email"
            required
          />
          {emailError && (
            <span className="form-feedback-error">{emailError}</span>
          )}
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>
            Password
            <span className="required-symbol">*</span>
          </Form.Label>
          <PasswordField
            name="password"
            className={`${passwordError ? ' input-error' : ''}`}
          />
          {passwordError && (
            <span className="form-feedback-error">{passwordError}</span>
          )}
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>
            Repeat Password <span className="required-symbol">*</span>
          </Form.Label>
          <PasswordField name="repeatPassword" />
        </Form.Group>
        <br />

        {formError && <span className="form-feedback-error">{formError}</span>}
        <Button variant="primary" type="submit" disabled={isSingUpLoading}>
          {isSingUpLoading ? 'Loading...' : 'Create Account'}
        </Button>
      </Form>
    </div>
  );
};

export default SignUpSide;
