import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './Loading.scss';

export interface RequiredProps {}
export interface OptionalProps {
  text?: string;
  children?: React.ReactNode;
  variant?: string;
  spinnerOnly?: boolean;
  size?: 'sm';
}

type LoadingProps = RequiredProps & OptionalProps;

export const Loading: React.FC<LoadingProps> = ({
  text,
  variant,
  spinnerOnly = false,
  size,
}) => {
  return (
    <div className="Loading">
      <Spinner animation="border" variant={variant || 'warning'} size={size} />
      {!spinnerOnly && <span className="text">{text || 'Loading...'}</span>}
    </div>
  );
};

export default Loading;
