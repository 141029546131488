import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {Auth} from 'aws-amplify';
import * as Contracts from '@gfxco/contracts';
import {AUTH_SESSION_STATUSES, AUTH_STATUSES} from '../../constants';
import {RootState} from '../../app/store';

const KEY_NAME = 'authStatus';

export interface AuthState {
  type: string;
  keepMeLoggedIn: boolean;
}

interface LoginPayload {
  username: string;
  password: string;
  keepMeLoggedIn?: boolean;
  setCookie?: boolean;
}

interface CompletePasswordPayload {
  user: Contracts.ICognitoUser;
  password: string;
}

interface VerifyUserAttrPayload {
  user: Contracts.ICognitoUser;
  attributeName: string;
}

interface VerifyUserAttrSubmitPayload extends VerifyUserAttrPayload {
  code: string;
}

interface ChangePasswordPayload {
  user: Contracts.ICognitoUser;
  currentPassword: string;
  newPassword: string;
}

interface CognitoUserAttributes {
  given_name?: string;
  family_name?: string;
  name?: string;
  email?: string;
}

interface UpdateUserAttributesPayload {
  user: Contracts.ICognitoUser;
  updatedAttributes: CognitoUserAttributes;
}

interface CurrentAuthenticatedUserPayload {
  bypassCache?: boolean;
}

interface SignUpPayload {
  username: string;
  password: string;
  email: string;
  shopName: string;
}

interface ResendSignUpPayload {
  username: string;
}

interface ConfirmSignUpPayload {
  username: string;
  code: string;
}

function setCookieItem(
  cookieName: string,
  cookieValue: string,
  expirationDays?: number,
) {
  let expires = '';
  if (expirationDays && expirationDays > 0) {
    const todayDate = new Date();
    todayDate.setTime(
      todayDate.getTime() + expirationDays * 24 * 60 * 60 * 1000,
    );
    expires = `expires=${todayDate.toUTCString()};`;
  }
  document.cookie = `${cookieName}=${cookieValue};${expires}path=/;`;
}

function getCookieItem(cookieName: string) {
  const name = `${cookieName}=`;
  const cookieArray = document.cookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

const initialState: AuthState = {
  type: getCookieItem(KEY_NAME),
  keepMeLoggedIn: false,
};

export const singUpAsync = createAsyncThunk(
  'auth/signup',
  async (singUpPayload: SignUpPayload, {rejectWithValue}) => {
    try {
      const {username, password, email, shopName} = singUpPayload;
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          'custom:shopName': shopName,
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed --- NOT WORKING YET BECAUSE OF THE BUG IN THE AMPLIFY LIBRARY AND CUSTOM COMPONENTS
          enabled: true,
        },
      });
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const confirmSignUpAsync = createAsyncThunk(
  'auth/confirmSignup',
  async (confirmSignUpPayload: ConfirmSignUpPayload, {rejectWithValue}) => {
    try {
      await Auth.confirmSignUp(
        confirmSignUpPayload.username,
        confirmSignUpPayload.code,
      );

      setCookieItem(KEY_NAME, AUTH_SESSION_STATUSES.LOGIN, 0);
      return {type: AUTH_SESSION_STATUSES.LOGIN, keepMeLoggedIn: false};
    } catch (error: any) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
      });
    }
  },
);

export const resendSignUpAsync = createAsyncThunk(
  'auth/resendSignUp',
  async (resendSignUpPayload: ResendSignUpPayload, {rejectWithValue}) => {
    try {
      await Auth.resendSignUp(resendSignUpPayload.username);
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const loginAsync = createAsyncThunk(
  'auth/login',
  async (loginPayload: LoginPayload, {rejectWithValue}) => {
    try {
      const {
        username,
        password,
        keepMeLoggedIn = false,
        setCookie = false,
      } = loginPayload;

      const user = await Auth.signIn(username, password);

      if (setCookie) {
        const expirationDays = keepMeLoggedIn ? 10 : 0;
        setCookieItem(KEY_NAME, AUTH_SESSION_STATUSES.LOGIN, expirationDays);
      }

      return {type: AUTH_SESSION_STATUSES.LOGIN, keepMeLoggedIn, user};
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
        name: error.name,
        code: error.code,
      });
    }
  },
);

export const completeNewPassword = createAsyncThunk(
  'auth/completeNewPassword',
  async (
    confirmPasswordPayload: CompletePasswordPayload,
    {rejectWithValue},
  ) => {
    try {
      const {user, password} = confirmPasswordPayload;

      await Auth.completeNewPassword(user, password);

      return {type: AUTH_SESSION_STATUSES.COMPLETE_PASSWORD};
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const verifyUserAttribute = createAsyncThunk(
  'auth/verifyUserAttribute',
  async (verifyUserAttrPayload: VerifyUserAttrPayload, {rejectWithValue}) => {
    try {
      const {user, attributeName} = verifyUserAttrPayload;

      await Auth.verifyUserAttribute(user, attributeName);

      return {type: AUTH_STATUSES.SUCCESS};
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const verifyUserAttributeSubmit = createAsyncThunk(
  'auth/verifyUserAttributeSubmit',
  async (
    verifyUserAttrSubmitPayload: VerifyUserAttrSubmitPayload,
    {rejectWithValue},
  ) => {
    try {
      const {user, attributeName, code} = verifyUserAttrSubmitPayload;

      await Auth.verifyUserAttributeSubmit(user, attributeName, code);

      return {type: AUTH_STATUSES.SUCCESS};
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (changePasswordPayload: ChangePasswordPayload, {rejectWithValue}) => {
    try {
      const {user, currentPassword, newPassword} = changePasswordPayload;

      await Auth.changePassword(user, currentPassword, newPassword);

      return {type: AUTH_STATUSES.SUCCESS};
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const updateUserAttributes = createAsyncThunk(
  'auth/updateUserAttributes',
  async (
    updateUserAttributesPayload: UpdateUserAttributesPayload,
    {rejectWithValue},
  ) => {
    try {
      const {user, updatedAttributes} = updateUserAttributesPayload;

      await Auth.updateUserAttributes(user, updatedAttributes);

      return {type: AUTH_STATUSES.SUCCESS};
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const currentAuthenticatedUser = createAsyncThunk(
  'auth/currentAuthenticatedUser',
  async (
    currentAuthenticatedUserPayload: CurrentAuthenticatedUserPayload,
    {rejectWithValue},
  ) => {
    try {
      const {bypassCache = false} = currentAuthenticatedUserPayload;

      const user = await Auth.currentAuthenticatedUser({bypassCache});

      return {type: AUTH_STATUSES.SUCCESS, user};
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const logoutAsync = createAsyncThunk(
  'auth/logout',
  async (args, thunkAPI) => {
    await Auth.signOut();
    setCookieItem(KEY_NAME, AUTH_SESSION_STATUSES.LOGOUT, 0);

    await thunkAPI.dispatch({type: 'system/ResetAll'});

    // Resting Authorization Provider Amplify
    window.location.reload();
    return {type: AUTH_SESSION_STATUSES.LOGOUT, keepMeLoggedIn: false};
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    checkUser: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.type = action.payload.type;
        state.keepMeLoggedIn = action.payload.keepMeLoggedIn;
      })
      .addCase(loginAsync.rejected, (state) => {
        state.type = AUTH_SESSION_STATUSES.LOGOUT;
        state.keepMeLoggedIn = false;
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.type = action.payload.type;
        state.keepMeLoggedIn = action.payload.keepMeLoggedIn;
      })
      .addCase(confirmSignUpAsync.fulfilled, (state, action) => {
        state.type = action.payload.type;
        state.keepMeLoggedIn = action.payload.keepMeLoggedIn;
      });
  },
});

export const getAuthStatus = (state: RootState) => {
  const authStatus = getCookieItem(KEY_NAME);
  if (authStatus === AUTH_SESSION_STATUSES.LOGIN) {
    return AUTH_SESSION_STATUSES.LOGIN;
  }
  return AUTH_SESSION_STATUSES.LOGOUT;
};
export const getKeepMeLoggedIn = (state: RootState) =>
  state.auth.keepMeLoggedIn;

export const {checkUser} = authSlice.actions;

export default authSlice.reducer;
