import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuthenticator} from '@aws-amplify/ui-react';
import SignInForm, {ILoginProps} from './SignInForm/SignInForm';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {getAuthStatus, loginAsync} from '../../features/auth';
import useQuery from '../../hooks/useQuery';
import {AUTH_SESSION_STATUSES} from '../../constants';

import '@aws-amplify/ui-react/styles.css';
import './SignInPage.scss';
import ChangePasswordForm from './ChangePasswordForm';
import {ICognitoUser} from '@gfxco/contracts';

export default function SignInSide() {
  const [showChangePasswordForm, setShowChangePasswordForm] =
    React.useState(false);
  const [user, setUser] = React.useState<ICognitoUser>();
  const [username, setUsername] = React.useState('');
  const query = useQuery();

  const authSession = useAppSelector(getAuthStatus);
  const dispatch = useAppDispatch();

  const {route, submitForm} = useAuthenticator((context) => [
    context.route,
    context.submitForm,
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    if (route === 'signIn' || authSession !== AUTH_SESSION_STATUSES.LOGIN) {
      return;
    }

    if (route === 'forceNewPassword') {
      return setShowChangePasswordForm(true);
    }

    if (route === 'authenticated' && query.has('redirect')) {
      const redirect = query.get('redirect') as string;
      return navigate(redirect, {replace: true});
    }

    if (route === 'verifyUser') {
      return navigate('/account', {replace: true});
    }

    return navigate('/', {replace: true});
  }, [route, navigate, authSession]);

  const handleSignIn = async ({
    username,
    password,
    keepSignedIn,
  }: ILoginProps) => {
    setUsername(username);

    const {user} = await dispatch(
      loginAsync({
        username,
        password,
        keepMeLoggedIn: keepSignedIn,
        setCookie: true,
      }),
    ).unwrap();
    setUser(user);
    submitForm({type: 'SIGN_IN', username, password});
  };

  return (
    <>
      {showChangePasswordForm ? (
        <ChangePasswordForm username={username} user={user} />
      ) : (
        <SignInForm handleLogin={handleSignIn} />
      )}
    </>
  );
}
