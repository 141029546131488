import React from 'react';
import UnderlineTabs from '../UnderlineTabs';
import './SectionHeader.scss';

interface RequiredProps {
  title: string;
  subtitle: string;
}

interface OptionalProps {
  description?: string;
  tabs?: [{key: number; name: string; path: string}];
  rightContent?: React.ReactNode;
}

type SectionHeaderProps = RequiredProps & OptionalProps;

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subtitle,
  description,
  tabs,
  rightContent,
}) => {
  return (
    <div id="SectionHeader">
      <div className="title-content">
        <div>
          <h1 className="title">{title}</h1>
          <h2 className="subtitle">{subtitle}</h2>
          {description && <h2 className="subtitle">{description}</h2>}
        </div>
        {rightContent && <div className="right-content">{rightContent}</div>}
      </div>
      {tabs && tabs.length > 0 && (
        <div id="TabsSection">
          <UnderlineTabs tabs={tabs} defaultActiveTab={1} />
        </div>
      )}
    </div>
  );
};

export default SectionHeader;
