import React from 'react';
import {
  IGetDesignsCountResponse,
  IModerationOrdersRequest,
  EModerationItemStatus,
  ELoadingStates,
} from '@gfxco/contracts';

import './ModerationStatuses.scss';
import ButtonBadgeTab from '../../../components/ButtonBadgeTab';

interface RequiredProps {
  statuses: IGetDesignsCountResponse;
  filters: IModerationOrdersRequest;
  fetchStatus: ELoadingStates;
  onFiltersChange: (filters: IModerationOrdersRequest) => void;
}

interface OptionalProps {}

type ModerationStatusesProps = RequiredProps & OptionalProps;

export const ModerationStatuses: React.FC<ModerationStatusesProps> = (
  props,
) => {
  const {statuses, filters} = props;

  return (
    <div id="moderation-statuses">
      <ButtonBadgeTab
        buttonText="All orders"
        active={filters.status === undefined && filters.review === undefined}
        onClick={() => {
          props.onFiltersChange({
            ...filters,
            status: undefined,
            review: undefined,
          });
        }}
        countValue={statuses.total}
        fetchStatus={props.fetchStatus}
      />
      <ButtonBadgeTab
        buttonText="New"
        active={filters.review === false}
        onClick={() => {
          props.onFiltersChange({
            ...filters,
            status: EModerationItemStatus.PENDING,
            review: false,
          });
        }}
        countValue={statuses.new}
        fetchStatus={props.fetchStatus}
        badgeClass="new"
      />
      <ButtonBadgeTab
        buttonText="In Review"
        active={!!filters.review}
        onClick={() => {
          props.onFiltersChange({
            ...filters,
            status: EModerationItemStatus.PENDING,
            review: true,
          });
        }}
        countValue={statuses.inReview}
        fetchStatus={props.fetchStatus}
        badgeClass="in-review"
      />
      <ButtonBadgeTab
        buttonText="On Hold"
        active={
          filters.status === EModerationItemStatus.ON_HOLD && !filters.review
        }
        onClick={() => {
          props.onFiltersChange({
            ...filters,
            status: EModerationItemStatus.ON_HOLD,
            review: undefined,
          });
        }}
        countValue={statuses.onHold}
        fetchStatus={props.fetchStatus}
        badgeClass="on-hold"
      />
    </div>
  );
};

export default ModerationStatuses;
