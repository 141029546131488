"use strict";
/**
 * DEPRECATED - this is only used by server - PLEASE USE INTERFACES IN ./apps/server/src/data/types
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESlotTypes = void 0;
var ESlotTypes;
(function (ESlotTypes) {
    ESlotTypes["Text"] = "text";
    ESlotTypes["Image"] = "image";
    ESlotTypes["Background"] = "background";
})(ESlotTypes = exports.ESlotTypes || (exports.ESlotTypes = {}));
