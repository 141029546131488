import React from 'react';
import Table from 'react-bootstrap/Table';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {
  deleteCancelSubscriptionAsync,
  selectShop,
} from '../../../features/shops/selectedShop';
import {Service, GetSubscriptionResponse} from '@gfxco/contracts';
import {parseStripeAmount} from '../../../libs/formatUtils';
import {Button} from 'react-bootstrap';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';

import './StripeSubscription.scss';

interface RequiredProps {
  subscription: GetSubscriptionResponse;
}

const StripeSubscription: React.FC<RequiredProps> = ({subscription}) => {
  const [showDialog, setShowDialog] = React.useState(false);

  const shopSelected = useAppSelector(selectShop);
  const dispatch = useAppDispatch();

  const handleCancelSubscription = async () => {
    if (shopSelected) {
      await dispatch(deleteCancelSubscriptionAsync(shopSelected.id));
      setShowDialog(false);
    }
  };

  const handleCancelSubscriptionReject = () => {
    setShowDialog(false);
  };

  return (
    <div id="StripeSubscription">
      <h1>
        Services{' '}
        <Button
          variant="danger"
          onClick={() => {
            setShowDialog(true);
          }}
        >
          Cancel Subscription
        </Button>
      </h1>
      <ConfirmationDialog
        title="Cancel Subscription"
        onAccept={handleCancelSubscription}
        onReject={handleCancelSubscriptionReject}
        show={showDialog}
      >
        <span>Do you want to cancel your subscription?.</span>
        <span>You&apos;ll lose all access immediately</span>
      </ConfirmationDialog>
      <Table hover>
        <thead>
          <tr>
            <th>name</th>
            <th>Description</th>
            <th>Price</th>
            <th>Billing Scheme</th>
          </tr>
        </thead>
        {!!subscription.services.length && (
          <tbody>
            {subscription?.services.map((service: Service) => {
              return (
                <tr key={service.price_id}>
                  <td>{service.name}</td>
                  <td>{service.description}</td>
                  <td>{parseStripeAmount(service.amount)}</td>
                  <td>{service.billing_scheme}</td>
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default StripeSubscription;
