import React from 'react';

import {ChevronDown, ChevronUp} from 'react-bootstrap-icons';
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Icon from '../../Icons/Icon';

import {ICollectionResponse, ICollectionEntity} from '@gfxco/contracts';
import './CollectionsPanelOption.scss';

type ActionParams = ICollectionResponse & {parentName?: string};

interface RequiredProps {
  id: number;
  name: string;
  description: string;
  onClick: (params: ActionParams) => void;
  checked: boolean;
}

interface OptionalProps {
  subCollections?: ICollectionEntity[];
  imagesCount?: string;
  isSubItem?: boolean;
  onCheck?: (params: ActionParams) => void;
  parentName?: string;
  isOpened?: boolean;
  showCheckbox?: boolean;
}
type CollectionsPanelOptionProps = RequiredProps & OptionalProps;

const CollectionsPanelOption: React.FC<CollectionsPanelOptionProps> = ({
  name,
  id,
  isOpened,
  description,
  imagesCount,
  subCollections,
  parentName,
  onClick,
  isSubItem,
  onCheck,
  checked,
  showCheckbox = true,
}) => {
  const collection = {
    name,
    id,
    imageUrl: '',
    largeImageUrl: '',
    description,
    imagesCount,
    subCollections,
  };

  const handleOnCheck = () => {
    if (onCheck) {
      onCheck(collection);
    }
  };

  return (
    <div className="panel-option">
      <div className="panel-option-box">
        <div className="option-box-item">
          {showCheckbox && (
            <Form.Check
              type="checkbox"
              onChange={(e) => handleOnCheck()}
              checked={checked}
            />
          )}

          <div className={`option-item ${isSubItem ? 'option-subItem' : ''}`}>
            {isSubItem && <Icon name="arrowDownRight" />}
            <div
              className="option-item-content"
              onClick={() => onClick({...collection, parentName})}
            >
              <div className="counter">
                {!!subCollections?.length && (
                  <div className="item-counter item-collection-counter">
                    <Icon name="folder" />
                    <span>{subCollections?.length}</span>
                  </div>
                )}
                {!!imagesCount && (
                  <div className="item-counter">
                    <Icon name="imageFill" />
                    <span>{imagesCount}</span>
                  </div>
                )}
              </div>
              <span className="item-name">{name}</span>
              {!!description && (
                <span className="item-name item-description">
                  {description}
                </span>
              )}

              <OverlayTrigger
                placement="top"
                delay={{show: 200, hide: 200}}
                overlay={<Tooltip id="sticker-tooltip">See details</Tooltip>}
              >
                <div className="collection-icon">
                  {!isOpened ? <ChevronDown /> : <ChevronUp />}
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionsPanelOption;
