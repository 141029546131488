import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getCollectionsCount} from '../../api';
import {RootState} from '../../app/store';
import {ELoadingStates} from '@gfxco/contracts';

export interface DesignsCountState {
  value: {total: number};
  status: ELoadingStates;
}

const initialState: DesignsCountState = {
  value: {total: 0},
  status: ELoadingStates.IDLE,
};

export const getCollectionsCountAsync = createAsyncThunk(
  'collectionsCount/fetch',
  async (shopId: number) => {
    const response = await getCollectionsCount(shopId);
    return response;
  },
);

export const loadCollectionsCount = createSlice({
  name: 'collectionsCount',
  initialState,
  reducers: {
    resetCollectionsCountFetchStatus: (state) => {
      state.status = ELoadingStates.IDLE;
    },
    reduceCollectionCount: (state) => {
      const newState = {...state};
      const newValue = newState.value.total - 1;
      newState.value.total = newValue < 0 ? 0 : newValue;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionsCountAsync.pending, (state) => {
        state.status = ELoadingStates.LOADING;
      })
      .addCase(getCollectionsCountAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.value = action.payload;
          state.status = ELoadingStates.LOADED;
        }
      })
      .addCase(getCollectionsCountAsync.rejected, (state) => {
        state.status = ELoadingStates.FAILED;
      });
  },
});

export const selectCollectionsCount = (state: RootState) => {
  return state.collectionsCount.value;
};

export const selectCollectionsCountFetchStatus = (state: RootState) => {
  return state.collectionsCount.status;
};

export const {resetCollectionsCountFetchStatus, reduceCollectionCount} =
  loadCollectionsCount.actions;

export default loadCollectionsCount.reducer;
