import React from 'react';
import ColorItem from '../ConfigureProductInfo/Items/ColorItem';
import {colors} from '../../libs/getDefaultTextColors';
import './FontsColorPicker.scss';

type FontsColorPickerProps = {
  onColorClick: (color: string) => void;
};

const FontsColorPicker: React.FC<FontsColorPickerProps> = (props) => {
  return (
    <div className="fonts-color-picker">
      {colors.map((color) => (
        <ColorItem
          key={color.id}
          onClick={() => props.onColorClick(color.hex)}
          selected={false}
          color={color}
          markBorder={color.name === 'White'}
        />
      ))}
    </div>
  );
};

export default FontsColorPicker;
