import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './ProductModalHeader.scss';

const DEFAULT_SUBTITLE =
  'Create your design by choosing a product type from our catalog or create a custom design canva from scratch';

interface OptionalProps {
  centered?: boolean;
  subtitle?: string;
  handleKeyAction?: (event: any) => void;
  inputDefaultValue?: string;
  showInput?: boolean;
  inputName?: string;
  handleInputChange?: (key: string) => void;
}

interface RequiredProps {
  title: string;
}

type ProductModalHeaderProps = RequiredProps & Partial<OptionalProps>;

export const ProductModalHeader: React.FC<ProductModalHeaderProps> = ({
  title,
  subtitle,
  inputName,
  inputDefaultValue,
  handleInputChange,
  handleKeyAction,
  centered,
  showInput = true,
}) => {
  const handleOnKeyDown = (event: any) => {
    if (handleKeyAction) handleKeyAction(event);
  };

  return (
    <div className="ProductModalHeader">
      <div>
        <h5>{title}</h5>
      </div>

      <div>
        <p className="ProductModalHeader__Subtitle">
          {subtitle || DEFAULT_SUBTITLE}
        </p>
      </div>

      {showInput && handleInputChange && (
        <Container>
          <Row>
            <Col md={{span: 7, offset: centered ? 3 : 0}}>
              <p className="ProductModalHeader__InputTitle">{inputName}</p>
            </Col>
          </Row>
          <Row>
            <Col md={{span: 6, offset: centered ? 3 : 0}}>
              <Form.Control
                size="sm"
                type="text"
                onChange={(event) => handleInputChange(event.target.value)}
                onKeyDown={handleOnKeyDown}
                defaultValue={inputDefaultValue}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};
export default ProductModalHeader;
